import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  StreetViewPanorama,
  useLoadScript,
  Marker,
} from "@react-google-maps/api";
import "./PropertyRegionStreetView.css";

export default function PropertyRegionStreetView(props) {

  useEffect(() => {
    setLat(props.lat);
    setLng(props.lng);
  }, [props]);

  const [lat, setLat] = useState();
  const [lng, setLng] = useState();

  const mapContainerStyle = {
    height: "100%",
    // width: "800px",
  };

  const center = {
    lat: lat,
    lng: lng,
  };

  return (
    <>
      {lat && lng && (
        <div>
          <div
            style={{
              height: props.height,
              width: "100%",
              // backgroundColor: "#eeeeee",
            }}
          >
            <useLoadScript googleMapsApiKey="AIzaSyCxZlpDt6yJWXjHqVQYa__pHusnmbY7YSo">
              <GoogleMap
                id="circle-example"
                mapContainerStyle={mapContainerStyle}
                zoom={14}
                center={center}
              >
                <Marker
                  // onLoad={onLoad}
                  position={center}
                />
                <StreetViewPanorama position={center} visible={true} />
              </GoogleMap>
            </useLoadScript>
          </div>
        </div>
      )}
    </>
  );
}
