import React from "react";
import "../PropertyPage.css";

const InvestmentReturnsGraph = (props) => (
  <div className="InvestmentReturnsGraph">
    <div className="dp-section-title">
      <h4>Calculate Your Return</h4>
    </div>
    <div className="InvestmentReturnsGraph-img">
      <img
        src={require("../../Images/investment-graph.png")}
        alt="investment graph"
      />
    </div>
  </div>
);

export default InvestmentReturnsGraph;
