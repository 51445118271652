import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../../Component/Footer/Footer";
import Header from "../../../Component/Header/Header";
// import "./regionpage.css"
// import style
import styles from "./Regionpage.module.css";

export default function RegionTypes() {
  return (
    <section className="region-container">
      <Header />
      <div className={styles.regions_wrapper}>
        <ul className="breadcrumb region-breadcrumb arr-right bg-ffffff">
          <li className="breadcrumb-item">
            <Link to="/">
              <img src={require("../../../Images/breadcrumb-home.svg")} alt="Exuity Home Icon" />
            </Link>
          </li>
          <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
          <li className="breadcrumb-item">
            <Link to="/regiontypes">Region type</Link>
          </li>
        </ul>

        <h1>Region Types</h1>
        <div className={styles.region_cards}>
          <Link to="/states">
            <div className={styles.region_card}>
              <img src={require("../../../Images/region-states.svg")} alt="Region State" />
              <h3>States</h3>
            </div>
          </Link>
          <Link to="/counties">
            <div className={styles.region_card}>
              <img src={require("../../../Images/region-counties.svg")} alt="Region Counties" />
              <h3>Counties</h3>
            </div>
          </Link>
          <Link to="/cities">
            <div className={styles.region_card}>
              <img src={require("../../../Images/region-cities.svg")} alt="Region Cities" />
              <h3>Cities</h3>
            </div>
          </Link>
          <Link to="/zipcodes">
            <div className={styles.region_card}>
              <img src={require("../../../Images/region-zip-codes.svg")} alt="Region Zip Codes" />
              <h3>Zip Codes</h3>
            </div>
          </Link>
          <Link to="/hpis">
            <div className={styles.region_card}>
              <img
                src={require("../../../Images/region-home-price.svg")}
                alt="Region Home Price Indexes"
              />
              <h3>Home Price Indexes</h3>
            </div>
          </Link>
          <Link to="/cbsas">
            <div className={styles.region_card}>
              <img src={require("../../../Images/region-cbsas.svg")} alt="Region CBSAs" />
              <h3>CBSAs</h3>
            </div>
          </Link>
          <Link to="/csas">
            <div className={styles.region_card}>
              <img src={require("../../../Images/region-csas.svg")} alt="Region CSAs" />
              <h3>CSAs</h3>
            </div>
          </Link>
          <Link to="/msas">
            <div className={styles.region_card}>
              <img src={require("../../../Images/region-msas.svg")} alt="Region MSAss" />
              <h3>MSAs</h3>
            </div>
          </Link>
        </div>
      </div>
      <div className={styles.region_footer}>
        <Footer />
      </div>
    </section>
  );
}
