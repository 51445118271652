import React from "react";
import { URL_PROPERTY } from "../../config/propertyImagesUrl";
import { str_into_float_num, str_into_num } from "../../utills/string-into-number";
import "../Dashboard.css";

const AuctionCard = (props) => (
  <div className="AuctionCard">
    <div className="AuctionCardGroup">
      <div className="auction-img">
        <img src={URL_PROPERTY(props.auction.property.images2)} alt="Auction img" />
      </div>
      <div className="AuctionCardText">
        <div className="AuctionCardTitle">
          <h4>{props.auction.property.property_address}</h4>
        </div>
        <div className="AuctionCardSubGroup">
          <div className="AuctionCardSubWrapper">
            <h6 className="AuctionSubTitle">Start Date</h6>
            <span>
              {new Date(props.auction.start_date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric"
              })}
            </span>
          </div>
          <div className="AuctionCardSubWrapper">
            <h6 className="AuctionSubTitle">End Date</h6>
            <span>
              {new Date(props.auction.end_date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric"
              })}
            </span>
          </div>
          <div className="AuctionCardSubWrapper">
            <h6 className="AuctionSubTitle">Min Investment</h6>
            <span className="acsg-amount">$2,500</span>
          </div>
          <div className="AuctionCardSubWrapper">
            <h6 className="AuctionSubTitle">Amount Raising</h6>
            <span className="acsg-amount">${str_into_num(props.auction.amount_raising)}</span>
          </div>
          <div className=" AuctionCardSubWrapper">
            <h6 className="AuctionSubTitle">Max Yield</h6>
            <span>{str_into_float_num(props.auction.max_yield * 100)}%</span>
          </div>
          <div className="AuctionCardSubWrapper">
            <h6 className="AuctionSubTitle">Contract Length</h6>
            <span>
              {props.auction.contract_length}
              {props.auction.contract_length === 1 ? " year" : " years"}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AuctionCard;
