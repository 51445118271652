import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Component/Footer/Footer";
import Header from "../../Component/Header/Header";
import styles from "./ArticlesTypes.module.css";

export default function ArticlesTypes() {
  return (
    <section className="region-container">
      <Header />
      <div className={styles.article_wrapper}>
        <ul className="breadcrumb region-breadcrumb arr-right bg-ffffff">
          <li className="breadcrumb-item">
            <Link to="/">
              <img src={require("../../Images/breadcrumb-home.svg")} alt="Exuity Home Icon" />
            </Link>
          </li>
          <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
          <li className="breadcrumb-item">
            <Link to="/articletypes">Article type</Link>
          </li>
        </ul>

        <h1>Article Types</h1>
        <div className={styles.article_cards}>
          <Link to="/homefinancecomparisons">
            <div className={styles.article_card}>
              <img
                src={require("../../Images/region-home-price.svg")}
                alt="Article Home Finance Comparisons"
              />
              <h3>Home Finance Comparisons</h3>
            </div>
          </Link>
          <Link to="/homefinanceconsiderations">
            <div className={styles.article_card}>
              <img
                src={require("../../Images/region-home-price.svg")}
                alt="Article Home Finance Considerations"
              />
              <h3>Home Finance Considerations</h3>
            </div>
          </Link>
          <Link to="/exuityprogramdetails">
            <div className={styles.article_card}>
              <img
                src={require("../../Images/region-home-price.svg")}
                alt="Article Exuity Program Details"
              />
              <h3>Exuity Program Details</h3>
            </div>
          </Link>
          <Link to="/reicomparisons">
            <div className={styles.article_card}>
              <img
                src={require("../../Images/region-home-price.svg")}
                alt="Article Real Estate Investing Comparisons"
              />
              <h3>Real Estate Investing Comparisons</h3>
            </div>
          </Link>
          <Link to="/reiconsiderations">
            <div className={styles.article_card}>
              <img
                src={require("../../Images/region-home-price.svg")}
                alt="Article Real Estate Investing Considerations"
              />
              <h3>Real Estate Investing Considerations</h3>
            </div>
          </Link>
        </div>
      </div>
      <div className={styles.article_footer}>
        <Footer />
      </div>
    </section>
  );
}
