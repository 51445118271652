import { combineReducers } from "redux";
import alertReducer from "./alert";
import toastReducer from "./toast";
import authReducer from "./auth";
import heroReducer from "./hero";



export default combineReducers({
  alerts: alertReducer,
  toasts: toastReducer,
  auth: authReducer,
  heroData: heroReducer
});



