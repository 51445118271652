import * as atypes from "./types";
import { axios_addAuction, axios_addBid } from "../../utills/auction-routes";

export const saveAuction =
  (property_id, amount_raising, max_yield, contract_length, start_date) =>
  async (dispatch) => {
    dispatch({
      type: atypes.START_LOADING,
    });

    axios_addAuction(
      property_id,
      amount_raising,
      max_yield / 100,
      contract_length,
      start_date
    )
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: atypes.SET_TOAST,
            payload: [
              { msg: "Auction created successfully.", type: "success" },
            ],
          });

          setTimeout(() => {
            window.location.href = "/auctions/";
          }, 2000);
        } else {
          dispatch({
            type: atypes.SET_TOAST,
            payload: [
              {
                msg: "All fields are required. Kindly check and resubmit",
                type: "error",
              },
            ],
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: atypes.SET_TOAST,
          payload: [
            { msg: "Something went wrong. Please try again", type: "error" },
          ],
        });
      });
  };
export const saveBid =
  (auctionId, amountToInvest, centOnDollar) => async (dispatch) => {
    dispatch({
      type: atypes.START_LOADING,
    });

    axios_addBid(auctionId, amountToInvest, centOnDollar)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: atypes.SET_TOAST,
            payload: [{ msg: "Bid added successfully", type: "success" }],
          });
          
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          dispatch({
            type: atypes.SET_TOAST,
            payload: [
              {
                msg: "All fields are required. Kindly check and resubmit",
                type: "error",
              },
            ],
          });
        }
      })
      .catch((err) => {
        console.log(err.response.data.msg);
        dispatch({
          type: atypes.SET_TOAST,
          payload: [{ msg: err.response.data.msg, type: "error" }],
        });
      });
  };
