import React from "react";
import { Navbar } from "react-bootstrap";
import "./Dashboard.css";

class Sidenav extends React.Component {
  render() {
    return (
      <div>
        <Navbar className="navbar-bg">
          <div className="nav-wrapper">
            <div className="nav-left-side"></div>
            <div className="nav-right-side">
            </div>
          </div>
        </Navbar>
      </div>
    );
  }
}

export default Sidenav;
