import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import getIsLoggedIn from "../utills/getIsLoggedIn";
import waitOneSecond from "../utills/waitOneSecond";
import { setToast } from "../store/actions/toast";
import store from "../store/store";
import { NavigationContext } from "../hooks/createNavigationContext";
import ScrollToTop from "../components/ScrollToTop";

// homepage route
import App from "../App";

//auth not required routes
import Contact from "../Page/Contact/Contact";
import PropertyPage from "../components/PropertyPage";
import SearchPage from "../Page/SearchPage/SearchPage";
import ModelPage from "../components/ModelPage/ModelPage";
import PropertiesProposal from "../components/PropertiesProposal/PropertiesProposal";

//Auth and user related routes
import SignInForm from "../login-signup-screens/SignInForm";
import SignUpForm from "../login-signup-screens/SignUpForm";
import ForgotPassword from "../login-signup-screens/ForgotPass";
import LogoutPage from "../login-signup-screens/Logout";
import Activation from "../login-signup-screens/Activation";
import ResetPassword from "../login-signup-screens/ResetPass";

//Logged in user routes
import Dashboard from "../components/Dashboard";
import Calculator from "../Page/Calculator/Calculator";
//owner tabs routes
import PropertiesOwner from "../components/properties/PropertiesOwner";
import AddProperty from "../components/properties/AddProperty";
import EditProperty from "../components/properties/EditProperty";
import PropertyDetailPage from "../components/properties/DetailsPage";
import Auctions from "../components/auctions/Auctions";
import Auction from "../components/auctions/Auction";
import AuctionCreate from "../components/auctions/AuctionCreate";
import IssuancesOwner from "../components/issuances/IssuancesOwner";
import IssuancesOwnerDetails from "../components/issuances/IssuancesOwnerDetails";
import PaymentOwedOwner from "../components/PaymentOwed/PaymentOwedOwner";
import PayamentOwedDetails from "../components/PaymentOwed/PayamentOwedDetails";
import PaymentMade from "../components/PaymentMade/PaymentMade";
import PayamentMadeDetail from "../components/PaymentMade/PaymentMadeDetails";
import InvestmentsReceivedOwner from "../components/InvestmentReceived/InvestmentsReceivedOwner";

//investor tabs routes
import PropertiesInvestor from "../components/properties/PropertiesInvestor";
import InvestorTableDetails from "../components/properties/InvestorTableDetails";
import AuctionsInvestor from "../components/auctions/AuctionsInvestor";
import AuctionInvestorDetail from "../components/auctions/AuctionInvestorDetail";
import IssuancesInvestor from "../components/issuances/IssuanceInvestor";
import IssuancesInvestorDetails from "../components/issuances/IssuancesInvestorDetails";
import PaymentOwedInvestor from "../components/PaymentOwed/PaymentOwedInvestor";
import PaymentsInvestor from "../components/InvestmentReceived/PaymentsInvestor";
import PaymentOwedInvestorDetails from "../components/PaymentOwed/PaymentOwedInvestorDetails";
import PaymentMadeDetails from "../components/PaymentMade/PaymentMadeDetails";
import PaymentReceivedInvestorDetail from "../components/InvestmentReceived/PaymentReceivedInvestorDetail";

//account tabs routes
import Profile from "../Page/Profile/profile";
import ProfileUpdate from "../Page/ProfileUpdate/profile-update";
import Identity from "../components/Identity/Identity";
import Accreditation from "../Page/Accreditation/Accreditation";
import UploadDoc from "../components/test/UploadDoc";
import Taxinfo from "../Page/Taxinfo/Taxinfo";
import Balance from "../Page/Balance/Balance";
import SelectAccount from "../Page/SelectBankAccount/SelectBankAccount";

//Region Pages Routes
import RegionTypes from "../Page/RegionPages/Regions/RegionsPage";

import StatesSeoPage from "../Page/RegionPages/StatesSeoPage/StatesSeoPage";
import StatePage from "../Page/RegionPages/StatePage/StatePage";

import CountiesSeoPage from "../Page/RegionPages/CountiesSeoPage/CountiesSeoPage";
import CountiesPage from "../Page/RegionPages/CountiesPage/CountiesPage";

import CitiesSeoPage from "../Page/RegionPages/CitiesSeoPage/CitiesSeoPage";
import CityPage from "../Page/RegionPages/CityPage/CityPage";

import ZipcodeSeoPage from "../Page/RegionPages/ZipcodeSeoPage/ZipcodeSeoPage";
import ZipcodePage from "../Page/RegionPages/ZipcodePage/ZipcodePage";

import HpisSeoPage from "../Page/RegionPages/HpisSeoPage/HpisSeoPage";
import HpiRegionPage from "../Page/RegionPages/HpiRegionPage/HpiRegionPage";

import CbsasSeoPage from "../Page/RegionPages/CbsasSeoPage/CbsasSeoPage";
import CbsasPage from "../Page/RegionPages/CbsasPage/CbsasPage";

import CsasSeoPage from "../Page/RegionPages/CsasSeoPage/CsasSeoPage";
import CsasPage from "../Page/RegionPages/CsasPage/CsasPage";

import MsaPage from "../Page/RegionPages/MsaPage/MsaPage";
import MsasSeoPage from "../Page/RegionPages/MsasSeoPage/MsasSeoPage";

//Article Routes
import ArticlesTypes from "../Page/ArticlesTypes/ArticlesTypes";
import ArticlesList from "../Page/ArticlesList/ArticlesList";
import ArticlesPages from "../Page/ArticlesPages/ArticlesPages";

//404 Route - page Not found
import PageNotFound from "../Page/PageNotFound/PageNotFound";

var gap = null;

const requireLogin = (to, from, next) => {
  if (gap != null) {
    gap.pageview(to.location.pathname);
  }
  if (to.meta.auth) {
    if (getIsLoggedIn()) {
      next();
    } else {
      store.dispatch(
        setToast([{ msg: "You need to be logged in to access that.", type: "error" }])
      );
      next.redirect("/");
    }
    // next.redirect("/?require_login=1");
  } else {
    next();
  }
};
const NotFound = ({ error }) => (
  <div>
    <h1>Not found.</h1>
    <p>{error}</p>
  </div>
);

const Loading = () => (
  <div>
    <div id="loader" />
  </div>
);

const Router = () => {
  const [activeNav, setActiveNav] = useState("a");
  const investerProperty = window.location.href.includes("propertiesinvestor");

  useEffect(() => {
    if (investerProperty) {
      setActiveNav("b");
    }
  }, [investerProperty]);

  return (
    <NavigationContext.Provider value={[activeNav, setActiveNav]}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <GuardProvider guards={[requireLogin, waitOneSecond]} loading={Loading} error={NotFound}>
          {/* <Switch> */}
          <ScrollToTop>
            <Switch>
              {/* auth not required routes */}
              <GuardedRoute path="/" component={App} exact={true} />
              <GuardedRoute path="/contact" component={Contact} meta={{ auth: false }} />
              <GuardedRoute path="/property/:id" component={PropertyPage} meta={{ auth: false }} />
              <GuardedRoute path="/search" component={SearchPage} meta={{ auth: false }} />
              <GuardedRoute path="/model" component={ModelPage} meta={{ auth: false }} />
              <GuardedRoute path="/p/:code" component={PropertiesProposal} meta={{ auth: false }} />

              {/* Auth and user related routes */}
              <GuardedRoute path="/signin" component={SignInForm} />
              <GuardedRoute path="/logout" component={LogoutPage} meta={{ auth: true }} />
              <GuardedRoute path="/signup" component={SignUpForm} />
              <GuardedRoute path="/forgotpassword" component={ForgotPassword} />
              <GuardedRoute path="/resetpassword" component={ResetPassword} />
              <GuardedRoute path="/activate-account" component={Activation} />

              {/* Logged in user routes */}
              <GuardedRoute path="/dashboard" component={Dashboard} meta={{ auth: true }} />
              <GuardedRoute path="/calculator" component={Calculator} meta={{ auth: true }} />

              {/* owner tabs routes */}
              <GuardedRoute
                path="/properties"
                exact={true}
                component={PropertiesOwner}
                meta={{ auth: true }}
              />
              <GuardedRoute path="/properties/create" exact={true} component={AddProperty} />
              <GuardedRoute
                path="/edit/property/:id"
                exact={true}
                component={EditProperty}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/property-details/:code"
                component={PropertyDetailPage}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/auctions"
                exact={true}
                component={Auctions}
                meta={{ auth: true }}
              />
              <GuardedRoute path="/auction/:id" component={Auction} meta={{ auth: true }} />
              <GuardedRoute
                path="/auctions/create"
                exact={true}
                component={AuctionCreate}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/issuancesowner"
                component={IssuancesOwner}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/issuancesowner-details/:code"
                component={IssuancesOwnerDetails}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/paymentowedowner"
                component={PaymentOwedOwner}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/paymentowedowner-details/:code"
                component={PayamentOwedDetails}
                meta={{ auth: true }}
              />
              <GuardedRoute path="/paymentmade" component={PaymentMade} meta={{ auth: true }} />
              <GuardedRoute
                path="/paymentmadeowner-details/:code"
                component={PayamentMadeDetail}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/investmentsreceivedowner"
                component={InvestmentsReceivedOwner}
                meta={{ auth: true }}
              />
              {/* owner tabs routes  end*/}

              {/* investor tabs routes start */}
              <GuardedRoute
                path="/propertiesinvestor"
                component={PropertiesInvestor}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/propertiesinvestor-details/:code"
                component={InvestorTableDetails}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/auctioninvestor"
                exact={true}
                component={AuctionsInvestor}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/AuctionInvestor-details/:code"
                component={AuctionInvestorDetail}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/issuancesinvestor"
                component={IssuancesInvestor}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/issuancesinvestor-details/:code"
                component={IssuancesInvestorDetails}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/paymentowedinvestor"
                component={PaymentOwedInvestor}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/paymentsinvestor"
                component={PaymentsInvestor}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/paymentowedinvestor-details/:code"
                component={PaymentOwedInvestorDetails}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/paymentmade-details/:code"
                component={PaymentMadeDetails}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/paymentsReceived-details/:code"
                component={PaymentReceivedInvestorDetail}
                meta={{ auth: true }}
              />
              {/* investor tabs routes end */}

              {/* account tabs routes start */}
              <GuardedRoute path="/profile" component={Profile} meta={{ auth: true }} />
              <GuardedRoute
                path="/profile-update"
                component={ProfileUpdate}
                meta={{ auth: true }}
              />
              <GuardedRoute path="/identity" component={Identity} meta={{ auth: true }} />
              <GuardedRoute path="/accreditation" component={Accreditation} meta={{ auth: true }} />
              <GuardedRoute path="/upload-doc" component={UploadDoc} meta={{ auth: true }} />
              <GuardedRoute path="/taxinfo" component={Taxinfo} meta={{ auth: true }} />
              <GuardedRoute path="/balance" component={Balance} meta={{ auth: true }} />
              <GuardedRoute
                path="/select-account"
                component={SelectAccount}
                meta={{ auth: true }}
              />
              {/* account tabs routes end */}

              {/* Region Pages Routes start */}
              <GuardedRoute path="/regiontypes" component={RegionTypes} meta={{ auth: false }} />

              <GuardedRoute
                path="/states"
                component={StatesSeoPage}
                meta={{ auth: false }}
                exact={true}
              />
              <GuardedRoute path="/states/:slug" component={StatePage} meta={{ auth: false }} />

              <GuardedRoute
                path="/counties"
                component={CountiesSeoPage}
                meta={{ auth: false }}
                exact={true}
              />
              <GuardedRoute
                path="/counties/:state/:county"
                component={CountiesPage}
                meta={{ auth: false }}
              />

              <GuardedRoute
                path="/cities"
                component={CitiesSeoPage}
                meta={{ auth: false }}
                exact={true}
              />
              <GuardedRoute
                path="/cities/:state/:city"
                component={CityPage}
                meta={{ auth: false }}
              />

              <GuardedRoute
                path="/zipcodes"
                component={ZipcodeSeoPage}
                meta={{ auth: false }}
                exact={true}
              />
              <GuardedRoute path="/zipcodes/:slug" component={ZipcodePage} meta={{ auth: false }} />

              <GuardedRoute
                path="/hpis"
                component={HpisSeoPage}
                meta={{ auth: false }}
                exact={true}
              />
              <GuardedRoute path="/hpis/:slug" component={HpiRegionPage} meta={{ auth: false }} />

              <GuardedRoute
                path="/cbsas"
                component={CbsasSeoPage}
                meta={{ auth: false }}
                exact={true}
              />
              <GuardedRoute path="/cbsas/:slug" component={CbsasPage} meta={{ auth: false }} />

              <GuardedRoute
                path="/csas"
                component={CsasSeoPage}
                meta={{ auth: false }}
                exact={true}
              />
              <GuardedRoute path="/csas/:slug" component={CsasPage} meta={{ auth: false }} />

              <GuardedRoute
                path="/msas"
                component={MsasSeoPage}
                meta={{ auth: false }}
                exact={true}
              />
              <GuardedRoute path="/msas/:slug" component={MsaPage} meta={{ auth: false }} />
              {/* Region Pages Routes end */}

              {/* Article Pages Routes start */}
              <GuardedRoute
                path="/articletypes"
                component={ArticlesTypes}
                meta={{ auth: false }}
                exact={true}
              />
              <GuardedRoute
                path="/homefinancecomparisons"
                component={ArticlesList}
                meta={{ auth: false }}
                exact={true}
              />
              <GuardedRoute
                path="/homefinanceconsiderations"
                component={ArticlesList}
                meta={{ auth: false }}
                exact={true}
              />

              <GuardedRoute
                path="/exuityprogramdetails"
                component={ArticlesList}
                meta={{ auth: false }}
                exact={true}
              />

              <GuardedRoute
                path="/reicomparisons"
                component={ArticlesList}
                meta={{ auth: false }}
                exact={true}
              />

              <GuardedRoute
                path="/reiconsiderations"
                component={ArticlesList}
                meta={{ auth: false }}
                exact={true}
              />
              <GuardedRoute
                path="/:articleType/:slug"
                component={ArticlesPages}
                meta={{ auth: false }}
              />
              {/* Article Pages Routes end */}

              <GuardedRoute path="*" component={PageNotFound} meta={{ auth: false }} />
            </Switch>
          </ScrollToTop>
          {/* </Switch> */}
        </GuardProvider>
      </BrowserRouter>
    </NavigationContext.Provider>
  );
};

export default Router;
