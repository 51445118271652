import React from "react";
import "../../Page/SearchPage/SearchPage.css";
import { BrowserRouter as Router } from "react-router-dom";
import SearchCard from "./SearchCard";

class SearchCards extends React.Component {
  constructor(props) {
    super();
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
    this.setMarker = this.setMarker.bind(this);
    this.setHover = this.setHover.bind(this);
  }

  handleChange(checked) {
    this.setState({ checked });
  }
  setMarker(property) {
    this.props.setMarker(property);
  }
  setHover(property) {
    this.props.setHover(property);
  }
  render() {
    return (
      <Router>
        <div className="search-cards-component">
          <div className="search-cards-component-group">
            {this.props.found === 2 ? (
              <div className="PropertyCardGroup mt-0">
                {this.props.properties.map(function (i) {
                  return (
                    <SearchCard
                      key={i.id}
                      property={i}
                      setMarker={this.setMarker}
                      setHover={this.setHover}
                    />
                  );
                }, this)}
              </div>
            ) : (
              <div className="PropertyCardGroup text-center">
                {this.props.found === 1 ? (
                  <div>
                    <div className="spinner-grow text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <p>Loading. Please wait...</p>
                  </div>
                ) : (
                  <p>No Property Found</p>
                )}
              </div>
            )}
          </div>
        </div>
      </Router>
    );
  }
}

export default SearchCards;
