import React, { useEffect, useState } from "react";
// import { imageChecker } from "../../config/imgBaseUrl";
import "./paymentowed.css";
import { useTable, usePagination } from "react-table";
import { str_into_float_num } from "../../utills/string-into-number";
import { Link, Redirect } from "react-router-dom";
import moment from "moment";
import { getPaymentOwedInvestor } from "../../utills/paymentOwed-routes";
import { URL_PROPERTY } from "../../config/propertyImagesUrl";

export default function TablePaymentInvestor() {
  const [tableData, setTableData] = useState([]);
  const [redirect, setredirect] = useState(null);
  const [dataFound, setDataFound] = useState(0);

  useEffect(() => {
    setDataFound(1);
    getPaymentOwedInvestor(getPaymentCallback, errorCallback);
    setDataFound(0);
  }, []);

  const getPaymentCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setTableData(response.data.data);
        if (response.data.data.length > 0) {
          setDataFound(2);
        } else {
          setDataFound(0);
        }
      }
    } else {
      console.log("Oops Something went wrong", response);
    }
  };

  const errorCallback = (error) => {
    console.log("getting error handling", error);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Property",
        accessor: "issuance.auction.property.property_address",
        Cell: (row) => {
          return (
            <div className="property-col dash-tbl-col">
              <img
                width={50}
                height={50}
                style={{ objectFit: "cover" }}
                src={URL_PROPERTY(
                  row &&
                    row.row &&
                    row.row.original &&
                    row.row.original.issuance.auction.property.images2
                )}
                alt="Auction img"
              />
              <h6>{row.value}</h6>
            </div>
          );
        },
      },
      {
        Header: "Amount Owed",
        accessor: "amount_owed",
        Cell: (row) => {
          const value = str_into_float_num(row.value);
          return <>${value}</>;
        },
      },
      {
        Header: "Amount Paid",
        accessor: "payment_distributions[0].totalAmountPaid",
        Cell: (row) => {
          const value = str_into_float_num(row.value);
          return <>${value.toLocaleString("en-US")}</>;
        },
      },
      {
        Header: "Due Date",
        accessor: "created_at",
        Cell: (row) => {
          return ` ${
            moment(row.value).isValid()
              ? moment(row.value).add(1, "y").format("MMM DD, YYYY")
              : "-"
          }`;
        },
      },
      {
        Header: "Est. Home Value",
        accessor: "payments_owed.home_value",
        Cell: (row) => {
          const value = str_into_float_num(row.value);
          return <>${value.toLocaleString("en-US")}</>;
        },
      },
    ],
    []
  );

  const data = tableData;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    // nextPage,
    // previousPage,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // state,
    // gotoPage,
    // pageCount,
    // setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  // const { pageIndex, pageSize } = state;

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <div>
        <div className="paymentowed-table">
          <table
            {...getTableProps()}
            className="table table-responsive"
            responsive="lg"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr className="header" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                <>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        onClick={() =>
                          setredirect(
                            "/paymentowedinvestor-details/" + row.original.code
                          )
                        }
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              className="dash-tbl-col"
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                  {dataFound === 1 ? (
                    <td colSpan="12" className="date-col">
                      <div className="text-center pt-5">
                        <div
                          className="spinner-grow text-warning"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <p>Loading. Please wait...</p>
                      </div>
                    </td>
                  ) : (
                    <tr className="no-hover">
                      <td colSpan="6" className="date-col">
                        <div className="table-empty-state">
                          <div className="empty-state-img">
                            <img
                              src={require("../../Images/empty-state-icon.svg")}
                              alt="empty state img"
                            />
                            <p>
                              No payment owed yet <br />
                              Reason: No{" "}
                              <Link to="/issuancesowner">
                                home equity issued
                              </Link>
                            </p>
                            <div className="createauction-btn"></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
