import React, { useEffect, useState } from "react";
import { axios_getAllStates } from "../../../utills/state-routes";
import { Link } from "react-router-dom";
import Footer from "../../../Component/Footer/Footer";
import Head from "../../../Component/Head";
import Header from "../../../Component/Header/Header";
import StateTable from "../../../Component/RegionComponents/StateTable";

export default function StatesSeoPage() {
  const [stateData, setStateData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [totalPageCount, setTotalPageCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    axios_getAllStates(currentPage, getStateCallback);
  }, [currentPage]);

  const getStateCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setStateData(response.data.data.rows);
        setIsLoading(false);
        setTotalPageCount(response.data.data.count);
      }
    }
  };

  return (
    <>
      <Head title={`List of US States - Exuity`} />
      <div className="App">
        <Header />

        <section className="region-sec" style={{ marginTop: 140 }}>
          <ul className="breadcrumb region-breadcrumb arr-right bg-ffffff">
            <li className="breadcrumb-item">
              <Link to="/">
                <img src={require("../../../Images/breadcrumb-home.svg")} alt="Exuity Home Icon" />
              </Link>
            </li>
            <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
            <li className="breadcrumb-item">
              <Link to="/regiontypes">Region type</Link>
            </li>
            <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
            <li className="breadcrumb-item">
              <Link to="/states">States</Link>
            </li>
          </ul>

          {isLoading === true && (
            <div className="text-center pt-5">
              <div className="spinner-grow text-warning" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p>Loading. Please wait...</p>
            </div>
          )}
          {stateData && stateData.length > 0 && (
            <>
              <h1 className="region-sub-title">All States List</h1>
              <StateTable
                tableData={stateData}
                totalPageCount={totalPageCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </>
          )}
        </section>

        <div className="footer-section">
          <Footer />
        </div>
      </div>
    </>
  );
}
