import "./editProperty.css";
import React, { useState, useEffect } from "react";
import Sidenav from "../Sidenav";
import Sidebar from "../../Component/Sidebar/Sidebar";
// import Dropdown from "./Dropdown";
import { useParams } from "react-router-dom";
import {
  axios_getSingleProperty,
  editProperties,
} from "../../utills/property-routes";
// import { Form } from "react-bootstrap";
import UploadImages from "./UploadImages";
import Head from "../../Component/Head";
import { Image_URL } from "../../config/api";
import { getImagesArray } from "../../config/imgBaseUrl";

const objectToFormData = (data, formData, namespace) => {
  let fd = formData || new FormData();

  if (Array.isArray(data)) {
    data.forEach((item) => {
      fd.append(namespace, item);
    });
  }

  return fd;
};

const EditProperty = () => {
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const [propertyType, setPropertyType] = useState();
  const [bedrooms, setBedrooms] = useState();
  const [bathrooms, setBathrooms] = useState();
  const [builtupArea, setBuiltupArea] = useState();
  const [address, setaddress] = useState();
  const [estimatedPropertyValue, setEstimatedPropertyvalue] = useState();
  const [desiredCash, setDesiredCash] = useState();
  const [mortageBalance, setMortageBalance] = useState();
  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [oldFiles, setOldFiles] = useState([]);
  // const [filesObj, setFilesObj] = useState([]);
  const [status, setstatus] = useState();
  const [propId, setpropId] = useState();
  const [loading, setLoading] = useState(true);
  const allfILES = oldFiles && oldFiles.concat(newFiles);

  useEffect(() => {
    const getPropertyData = async () => {
      const response = await axios_getSingleProperty(id);
      if (response) {
        const property = response.data.data;
        setPropertyType(property && property.property_type);
        setBedrooms(property && property.bedrooms);
        setBathrooms(property && property.bathrooms);
        setBuiltupArea(property && property.builtup_area);
        setaddress(property && property.property_address);
        setEstimatedPropertyvalue(
          property && property.estimated_property_value
        );
        setDesiredCash(property && property.desired_cash);
        setMortageBalance(property && property.mortage_balance);
        setstatus(property && property.status);
        setpropId(property && property.id);
        setLoading(false);

        console.log('property.images2', property.images2);
        console.log('Image_URL', Image_URL);
        var images = getImagesArray(property.images2);

        console.log(images);
        if (Image_URL === "http://localhost:4000") {
          /*
           const property_img_url =
             property && property.images2 !== null
               ? property.images2.slice(1)
               : [];
           const property_img_2 =
             property_img_url && property_img_url.slice(0, -1);
           const imagesArray = property_img_2
             ? property_img_2 &&
               property_img_2.split &&
               property_img_2.split(",")
             : [];
             
           const checkArray =
             imagesArray && imagesArray[0] === "null" ? [] : imagesArray;
             setOldFiles(checkArray);
             */
          setOldFiles(images);
        } else {
          /*const imagesArray =
            property && property.images2
              ? property.images2 &&
                property.images2.split &&
                property.images2.split(",")
              : [];
          const checkArray =
            imagesArray && imagesArray[0] === "null" ? [] : imagesArray;
          setOldFiles(checkArray); */

          setOldFiles(images);

        }
      }
    };
    getPropertyData();
  }, [id]);

  const inputHandler = (e, setState) => {
    setState(e.target.value);
  };

  const handleChange = (event) => {
    setstatus(event.target.value);
  };

  const handlerSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("bedrooms", parseInt(bedrooms));
    formData.append("bathrooms", parseInt(bathrooms));
    formData.append("property_type", propertyType);
    formData.append("property_address", address);
    formData.append("builtup_area", parseInt(builtupArea));
    formData.append(
      "estimated_property_value",
      parseInt(estimatedPropertyValue)
    );
    formData.append("id", propId);
    formData.append("desired_cash", parseInt(desiredCash));
    formData.append("mortage_balance", parseInt(mortageBalance));
    formData.append(
      "images",
      allfILES && allfILES.length === 0 ? null : allfILES
    );
    formData.append("status", parseInt(status));
    objectToFormData(files, formData, "propertyImages");

    // const data = {
    //   bedrooms: parseInt(bedrooms),
    //   bathrooms: parseInt(bathrooms),
    //   property_type: propertyType,
    //   property_address: address,
    //   builtup_area: parseInt(builtupArea),
    //   estimated_property_value: parseInt(estimatedPropertyValue),
    //   id: id,
    //   desired_cash: parseInt(desiredCash),
    //   mortage_balance: parseInt(mortageBalance),
    //   status,
    //   propertyImages: files,
    // };
    editProperties(setLoading, setOpen, formData);
  };

  return (
    <div className="Dashboard">
      <div className="DashboardGroup">
        <div className="auction-navbar">
          <Head title="Edit Property" />
          <div className="dashTitle">
            <h4>Edit property</h4>
          </div>
          <Sidenav />
        </div>
        <div className="row">
          <div className="auction-navbar1">
            <Sidebar />
          </div>
          <div className="DashContent">
            <div className="main_box d-flex justify-content-center align-items-center">
              {!loading && (
                <div className="box p-4">
                  <form onSubmit={(e) => handlerSubmit(e)}>
                    <div className="row ">
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="propertyType" className="">
                            Property Type
                          </label>
                          <input
                            // required
                            type="text"
                            className=""
                            id="propertyType"
                            placeholder="e.g Mobile Home 1"
                            value={propertyType}
                            onChange={(e) => inputHandler(e, setPropertyType)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="bedrooms" className="">
                            Bedrooms
                          </label>
                          <input
                            // required
                            type="number"
                            className=""
                            id="bedrooms"
                            placeholder="e.g 4"
                            value={bedrooms}
                            onChange={(e) => inputHandler(e, setBedrooms)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="bathrooms" className="">
                            Bathrooms
                          </label>
                          <input
                            // required
                            type="number"
                            className=""
                            id="bathrooms"
                            placeholder="e.g 2"
                            value={bathrooms}
                            onChange={(e) => inputHandler(e, setBathrooms)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="builtupArea" className="">
                            Builtup Area
                          </label>
                          <input
                            // required
                            type="number"
                            className=""
                            id="buildtuparea"
                            placeholder="e.g 2002"
                            value={builtupArea}
                            onChange={(e) => inputHandler(e, setBuiltupArea)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="estimatedPropertyValue" className="">
                            Est. Home Value
                          </label>
                          <input
                            // required
                            type="number"
                            className=""
                            id="estimatedPropertyValue"
                            placeholder="e.g 23000"
                            value={estimatedPropertyValue}
                            onChange={(e) =>
                              inputHandler(e, setEstimatedPropertyvalue)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="desiredCash" className="">
                            Desired Cash
                          </label>
                          <input
                            // required
                            type="number"
                            className=""
                            id="desiredCash"
                            placeholder="e.g 20000"
                            value={desiredCash}
                            onChange={(e) => inputHandler(e, setDesiredCash)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="mortageBalance" className="">
                            Mortage Balance
                          </label>
                          <input
                            // required
                            type="number"
                            className=""
                            id="mortageBalance"
                            placeholder="e.g 22000"
                            value={mortageBalance}
                            onChange={(e) => inputHandler(e, setMortageBalance)}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-3">
                          <label htmlFor="status">Status</label>
                          <select
                            value={status}
                            className="form-select"
                            onChange={handleChange}
                            aria-label="Default select example"
                          >
                            <option value="Active">Active</option>
                            <option value="Inactive">In Active</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12">
                        <label htmlFor="status">Property Address</label>
                        <div className="mb-3">
                          <textarea
                            // required
                            type="text"
                            className=""
                            id="address"
                            placeholder="e.g 22000"
                            value={address}
                            onChange={(e) => inputHandler(e, setaddress)}
                            rows={6}
                            style={{ width: "100%", borderRadius: 5 }}
                          />
                        </div>
                      </div>
                      <div className="col-12 text-center">
                        <UploadImages
                          files={files}
                          setFiles={setFiles}
                          setNewFiles={setNewFiles}
                          newFiles={newFiles}
                          oldFiles={oldFiles}
                          setOldFiles={setOldFiles}
                        />
                      </div>
                      {open && (
                        <div className="col-12">
                          <div className="mb-3 d-flex justify-content-center align-items-center">
                            <div className="alert alert-success" role="alert">
                              Successfully Updated
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-12">
                        <div className="mb-3 d-flex justify-content-center align-items-center">
                          <button
                            style={{ backgroundColor: "#000000" }}
                            type="submit"
                            className="btn btn-secondary mt-3"
                          >
                            Update Property
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProperty;
