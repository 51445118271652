import React, { useEffect, useRef, useState } from "react";
import "./gist.css";
import InputRange from "react-input-range";

export default function Gist() {
  //its line for find the section open on the mobile or not
  let isMobile = window.matchMedia("(any-pointer:coarse)").matches;

  //its line for view on the specific div
  const ref = useRef(null);
  let isInViewport = useIsInViewport(ref);

  const [value1, setValue1] = useState(isMobile ? 15 : 20); //default 20
  const [value2, setValue2] = useState(isMobile ? 10 * (3 / 4) : 10); //default value 10
  const [isAnimatedEnd, setIsAnimatedEnd] = useState(false);
  const [animationDirection1, setAnimationDirection1] = useState("forward");
  const [animationDirection2, setAnimationDirection2] = useState("forward");
  //   const [sliderStart, setSliderStart ] = useState(false);
  const [status1, setStatus1] = useState(true);
  const [status2, setStatus2] = useState(false);

  useEffect(() => {
    //animation for first value
    if (
      isAnimatedEnd === false &&
      animationDirection1 &&
      isInViewport === true &&
      status1 === true
    ) {
      if (isMobile !== true && animationDirection1 === "forward" && value1 === 19.625) {
        setStatus1(false);
        setStatus2(true);
      }

      if(isMobile === true && animationDirection1 === "forward" && value1 === 14.625){
        setStatus1(false);
        setStatus2(true);
      }

      if (animationDirection1 === "forward") {
        if ((isMobile ? (value1 <= (30.125)*(3/4)) : (value1 <= 30.125)) && isInViewport === true) {
          setTimeout(() => {
          setValue1(value1 + (0.375));
          }, isMobile ? ((62.5)) : 62.5);
        } else {
          setAnimationDirection1("backward");
        }
      } else {
        //backward animation
        if (isMobile ? value1 >= ((9.875)*(3/4)) : value1 >= 9.875) {
          setTimeout(() => {
           setValue1(value1 - 0.375);
          }, 62.5);
        } else {
          setAnimationDirection1("forward");
        }
      }
    }
    if (isInViewport === false) {
      isMobile ? setValue1((20)*(3/4)) : setValue1(20);
      setAnimationDirection1("forward");
    }
  }, [isInViewport, value1, animationDirection1, isAnimatedEnd, status1, isMobile]);


  //animation for second value
  useEffect(() => {
    if (isAnimatedEnd === false &&animationDirection2 &&isInViewport === true && status2 === true) {
      // if(isInViewport === true){
      if (isMobile !== true && animationDirection2 === "forward" && value2 === 9.8125 ) {
        setStatus2(false);
        setStatus1(true);
      }
      if(isMobile === true && animationDirection2 === "forward" && value2 === 7.3125){
        setStatus2(false);
        setStatus1(true);
      }
      if (animationDirection2 === "forward") {
        if ((isMobile ? (value2 <= (15.0625)*(3/4)) : (value2 <= 15.0625)) && isInViewport === true) {
          setTimeout(() => {
            setValue2(value2 + 0.1875);
          }, 62.5);
        } else {
          setAnimationDirection2("backward");
        }
      } else {
        //movement for 2nd slider
        if (isMobile ? value2>=((4.9375)*(3/4)) : value2 >= 4.9375) {
          setTimeout(() => {
            setValue2(value2 - 0.1875);
          }, 62.5);
        } else {
          setAnimationDirection2("forward");
        }
      }
    }
    if (isInViewport === false) {
      (isMobile ? setValue2(15/2) : setValue2(10));// 15/2 means 10*(3/4); 
      setAnimationDirection2("forward");
    }
  }, [isInViewport, value2, animationDirection2, isAnimatedEnd, status2, isMobile]);

  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const observerRef = useRef(null);
    useEffect(() => {
      observerRef.current = new IntersectionObserver(([entry]) =>
        setTimeout(() => {
          setIsIntersecting(entry.isIntersecting);
        }, 500)
      );
    }, []);

    useEffect(() => {
      observerRef.current.observe(ref.current);
      return () => {
        observerRef.current.disconnect();
      };
    }, [ref]);

    return isIntersecting;
  }

  return (
    <div className="gist-section">
      <div className="gist-conatiner">
        <div className="get-started-title">
          <h3>The Gist</h3>
          <p>
            Exuity lets property owners sell a fraction of their properties’ value to passive
            investors who compete via auction to charge the lowest "yield" (defined below).
          </p>
        </div>
        <div className="gist-section-wrapper W-100">
          <div className="gist-left-contents" ref={ref}>
            <div className="gist-slider-wrapper">
              <div className="gist-slider-text">
                <h4>Change in Home Value</h4>
                <p>
                  If property value is higher at time of sale or refinance, both parties' shares’
                  values go up.
                </p>
              </div>
              <div className="gist-slider" onClick={() => setIsAnimatedEnd(true)}>
                {!isMobile ? (
                  <InputRange
                    maxValue={30.125}
                    minValue={9.875}
                    value={value1}
                    onChange={(value) => setValue1(value)}
                    onChangeComplete={(value) => console.log("firstvalue", value)}
                  />
                ) : (
                  <InputRange
                    maxValue={22.59375}  //it is (30.125)*(3/4)
                    minValue={7.40625}   //it is (9.875)*(3/4)
                    value={value1}
                    onChange={(value) => setValue1(value)}
                    onChangeComplete={(value) => console.log("firstvalue", value)}
                  />
                )}
              </div>
            </div>
            <div className="gist-slider-wrapper">
              <div className="gist-slider-text">
                <h4>Yield %</h4>
                <p>
                  If investors require a lower Yield % upfront (see below), owner pays less
                  annually.
                </p>
              </div>
              <div className="gist-slider" onClick={() => setIsAnimatedEnd(true)}>
                {!isMobile ? (
                  <InputRange
                    maxValue={15.0625}
                    minValue={4.9375}
                    value={value2}
                    onChange={(value) => setValue2(value)}
                    onChangeComplete={(value) => console.log(value)}
                  />
                ) : (
                  <InputRange
                    maxValue={15.0625 * (3 / 4)}
                    minValue={4.9375 * (3 / 4)}
                    value={value2}
                    onChange={(value) => setValue2(value)}
                    onChangeComplete={(value) => console.log(value)}
                  />
                )}
              </div>
            </div>
            <div className="gist-slider-texts web-gist-slider-texts">
              <h4>What is Yield?</h4>
              <p>
                We define the “Yield %” as the % of investors’ shares' value the owner owes to
                investors annually with cash or equivalent-value additional shares. The Yield % is
                determined via an investor auction. The more appealing the property, the lower the
                Yield % goes. The Yield % has some similarities to an interest rate, cap rate,
                dividend yield or bond yield.
              </p>
            </div>
          </div>
          <div className="gist-right-contents">
            <div className="homevalue-annualfee align-items-center">
              <div className="gist-homevalue">
                <h6>Home Value</h6>
                <div
                  className="gist-homevalue-icon d-flex align-items-center justify-content-center"
                  style={{ minWidth: "210px", minHeight: "200px" }}>
                  <img
                    src={require("../../Images/home-value-big-icon.svg")}
                    className="img-fluid"
                    alt="Home Value"
                    width={7 * value1}
                    style={{ transition: "all 0.2s" }}
                  />
                </div>
              </div>
              <div className="gist-annualfee">
                <div className="text-center">
                  <h6>Annual Fee</h6>
                </div>
                <div
                  className="d-flex align-items-center justify-content-center gist-annualfee-icon"
                  style={{ minWidth: "200px", minHeight: "200px" }}>
                  <img
                    src={require("../../Images/annual-fee-big-icon.svg")}
                    className="img-fluid"
                    alt="Annual Fee"
                    width={(value1 * value2 * 4) / 20}
                    style={{ transition: "all 0.2s" }}
                  />
                </div>
              </div>
            </div>
            <div className="homevalue-annualfee-lists">
              <ul className="homevalue-annualfee-list">
                <li>
                  <img
                    src={require("../../Images/home-value-investor-icon.svg")}
                    className="img-fluid"
                    alt="Home Value Investor"
                  />
                  <span>Investor’s Share of Eventual Sale Price</span>
                </li>
                <li>
                  <img
                    src={require("../../Images/home-value-owner-icon.svg")}
                    className="img-fluid"
                    alt="Home Value Owner’s"
                  />
                  <span>Owner’s Share of Eventual Sale Price</span>
                </li>
                <li>
                  <img
                    src={require("../../Images/annual-fee-small-icon.svg")}
                    className="img-fluid"
                    alt="Annual Fee"
                  />
                  <span>Annual Fee Payable with Cash or Equity</span>
                </li>
              </ul>
              <div className="gist-slider-texts mobile-gist-slider-texts">
                <h4>What is Yield?</h4>
                <p>
                  The “Yield %” is the percentage of investors’ equity stake that the owner owes to
                  them annually with cash or additional equity. It is determined by auction such
                  that the owner gets the lowest rate investors are willing to offer.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
