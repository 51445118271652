import * as atypes from "../actions/types";

const inital_state = {
  valueGroups: {
    sellBuy: "sell",
    percentage: 25,
    homeValue: 400000
  }
};

export default function (state = inital_state, action) {
  const { type, payload } = action;
  switch (type) {
    case atypes.SET_HERO_DATA:
      return {
        ...state,
        valueGroups: {
          ...state.valueGroups,
          ...payload
        }
      };
    case atypes.GET_HERO_DATA:
      return {
        ...state
      };
    default:
      return state;
  }
}
