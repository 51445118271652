import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import Tooltip from "react-bootstrap/Tooltip";
//Components
import RentTable from "../RentTable/RentTable";
import LoanTable from "../LoanTable/LoanTable";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
//styles
import "./requirement.css";

export default function RequirementSection() {
  const heroData = useSelector((state) => state);
  const [defaultActiveTab, setDefaultActiveTab] = useState();

  useEffect(() => {
    if (heroData.heroData.valueGroups.sellBuy !== undefined) {
      setDefaultActiveTab(
        heroData.heroData.valueGroups.sellBuy === "buy"
          ? "Vs. Rentals"
          : heroData.heroData.valueGroups.sellBuy === "sell"
          ? "Vs. HELOCs"
          : "Requirements"
      );
    }
  }, [heroData.heroData.valueGroups.sellBuy]);

  return (
    <div className="requirement equity-graph-wrapper">
      <ReactTooltip />
      <h3>More Info</h3>

      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <span
            className={defaultActiveTab === "Requirements" ? "nav-link active" : "nav-link"}
            data-toggle="tab"
            role="tab"
            aria-controls="Requirements"
            aria-selected="true"
            onClick={() => {
              setDefaultActiveTab("Requirements");
            }}>
            Requirements
          </span>
        </li>
        <li className="nav-item">
          <span
            className={defaultActiveTab === "Vs. HELOCs" ? "nav-link active" : "nav-link"}
            data-toggle="tab"
            role="tab"
            aria-controls="Vs. HELOCs"
            aria-selected="false"
            onClick={() => {
              setDefaultActiveTab("Vs. HELOCs");
            }}>
            Vs. HELOCs
          </span>
        </li>
        <li className="nav-item">
          <span
            className={defaultActiveTab === "Vs. Rentals" ? "nav-link active" : "nav-link"}
            data-toggle="tab"
            role="tab"
            aria-controls="Vs. Rentals"
            aria-selected="false"
            onClick={() => {
              setDefaultActiveTab("Vs. Rentals");
            }}>
            Vs. Rentals
          </span>
        </li>
      </ul>
      <div className="tab-content">
        <div
          className={
            defaultActiveTab === "Requirements" ? "tab-pane fade show active" : "tab-pane fade"
          }
          role="tabpanel">
          <div className="requirement-wrapper">
            <div className="requirement-card">
              <div className="card-text">
                <span className="text">
                  <h6>Homeowners:</h6>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-1">
                        Meaning your mortgage balance is less than 50% of your house's value
                      </Tooltip>
                    }>
                    <p>
                      Have equity of <span className="underline-text">50% or more</span>
                    </p>
                  </OverlayTrigger>
                </span>
                <span className="text text-2">
                  <h6>Note:</h6>
                  <p>Exuity is not for financing home purchases.</p>
                </span>
              </div>
              <div className="req-card-img">
                <img
                  src={require("../../Images/homeowner-req-icon.svg")}
                  className="img-fluid"
                  alt="HomeOwner SVG"
                />
              </div>
            </div>
            <div className="requirement-card">
              <div className="card-text">
                <span className="text">
                  <h6>Investors: </h6>
                  <p>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-2">
                          Qualify by earning over $200k (or $300k with spouse) or having over $1m in
                          net worth alone or with spouse not including primary residence.
                        </Tooltip>
                      }>
                      <span className="underline-text">Be accredited</span>
                    </OverlayTrigger>
                  </p>
                </span>
                <span className="text text-2">
                  <h6>Note:</h6>
                  <p>Exuity can not be used for 1031 exchanges.</p>
                </span>
              </div>
              <div className="req-card-img">
                <img
                  src={require("../../Images/investor-req-icon.svg")}
                  className="img-fluid"
                  alt="HomeOwner SVG"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            defaultActiveTab === "Vs. HELOCs" ? "tab-pane fade show active" : "tab-pane fade"
          }
          role="tabpanel">
          <div className="loan-equity-table">
            <LoanTable />
          </div>
        </div>
        <div
          className={
            defaultActiveTab === "Vs. Rentals" ? "tab-pane fade show active" : "tab-pane fade"
          }
          role="tabpanel">
          <div className="rental-table">
            <RentTable />
          </div>
        </div>
      </div>
    </div>
  );
}
