import React, { useState, useEffect } from "react";
import Sidenav from "../Sidenav";
import "./identity.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import { loadStripe } from "@stripe/stripe-js";
import { loadUser, startLoading } from "../../store/actions/auth";
import store from "../../store/store";
import { axios_createVerificationSection } from "../../utills/stripe-routes";
import { axios_getIdentityVerifyStatus } from "../../utills/identityVerify-routes";
import Head from "../../Component/Head";
//const stripePromise = loadStripe('pk_test_51KMSNAIbQbOUPmrBWLXJfGS7eomcdoyidFjaOzrTcnwJ2Fe3sQkYuuClD8jseZTxkgyQyQWoRGLFHcFjBBePiSJj00dboO4q5H');
const stripePromise = loadStripe(
  "pk_live_51KMSNAIbQbOUPmrBR7e4H8JyDsYkMsHfZ5CHy1qioGG5hYF1Skk68TdXhRIXnWAZle7VmDhqXEa4obkjWLJKu6IE00LXZHrvY0"
);

export default function Identity() {
  const [stripe, setStripe] = useState();
  //status can be = submitted, requires_input, verified
  const [identityStatus, setIdentityStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios_getIdentityVerifyStatus(getIdentityStatusCallback);
    store.dispatch(startLoading());
    store.dispatch(loadUser());
    setStripe(stripePromise);
  }, []);

  const createVerificationCallback = async (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        // Show the verification modal.

        const { error } = (await stripePromise).verifyIdentity(
          response.data.data.client_secret
        );

        if (error) {
          console.log("[error]", error);
        } else {
          setTimeout(() => {
            setIdentityStatus("submitted");
          }, 10000);
        }
      }
    } else {
      console.log("Oops Something went wrong", response);
    }
  };

  const clickHandler = async () => {
    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return;
    } else {
      axios_createVerificationSection(createVerificationCallback);
    }
  };

  const getIdentityStatusCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true && response.data.data !== null) {
        setIdentityStatus(response.data.data.status);
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="Dashboard">
        <Head title={"Exuity | Identity"} />
        <div className="DashboardGroup">
          <div className="auction-navbar">
            <div className="dashTitle">
              <h4>Identity</h4>
            </div>
            <Sidenav />
          </div>

          <div className="DashContent">
            <div className="row text-center">
              <div className="col-lg-8 offset-lg-2">
                {isLoading ? (
                  <div className="text-center pt-5">
                    <div className="spinner-grow text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <p>Loading. Please wait...</p>
                  </div>
                ) : (
                  <>
                    {identityStatus === "submitted" ? (
                      <div className="mb-3">
                        <h3>Thanks for submitting your identity document.</h3>
                        <p>
                          Our team is reviewing your document. Once confirmed,
                          you will not see the button below.
                        </p>
                      </div>
                    ) : identityStatus === "verified" ? (
                      <div>
                        <h3>Your documents are already verified.</h3>
                      </div>
                    ) : identityStatus === "requires_input" ? (
                      <div className="mb-3">
                        <h3>Somehow your documents are not verified.</h3>
                        <p>Please try again.</p>
                      </div>
                    ) : null}

                    {identityStatus !== "verified" && (
                      <div className={"addPropert-btn"}>
                        <button
                          type="button"
                          disabled={!stripe}
                          onClick={() => clickHandler()}
                        >
                          <span>
                            <svg
                              className="btn-plusIcon"
                              xmlns="http://www.w3.org/2000/svg"
                              width="12px"
                              height="12px"
                            >
                              <path
                                opacity="0.749"
                                fill="rgb(255, 255, 255)"
                                d="M11.000,7.000 L7.000,7.000 L7.000,11.000 C7.000,11.552 6.552,12.000 6.000,12.000 C5.448,12.000 5.000,11.552 5.000,11.000 L5.000,7.000 L1.000,7.000 C0.448,7.000 -0.000,6.552 -0.000,6.000 C-0.000,5.448 0.448,5.000 1.000,5.000 L5.000,5.000 L5.000,1.000 C5.000,0.448 5.448,-0.000 6.000,-0.000 C6.552,-0.000 7.000,0.448 7.000,1.000 L7.000,5.000 L11.000,5.000 C11.552,5.000 12.000,5.448 12.000,6.000 C12.000,6.552 11.552,7.000 11.000,7.000 Z"
                              ></path>
                            </svg>
                          </span>
                          {!stripe ? "Please wait ... " : "Verify Identity"}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="auction-navbar1">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
