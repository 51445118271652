import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  axios_getCsaDataFromCode,
  axios_getCsasArticleData,
  axios_getCsasChildDataByCsasCode
} from "../../../utills/csa-routes";
import Footer from "../../../Component/Footer/Footer";
import Head from "../../../Component/Head";
import Header from "../../../Component/Header/Header";
import CountiesTable from "../../../Component/RegionComponents/CountiesTable";
import CsasTable from "../../../Component/RegionComponents/CsasTable";
import ZipcodeTable from "../../../Component/RegionComponents/ZipcodeTable";
import { multirand } from "../../../utills/helper";
import GetStarted from "../../../Component/GetStarted/GetStarted";
import CityTable from "../../../Component/RegionComponents/CityTable";

export default function CsasPage() {
  const { slug } = useParams();
  const [csaData, setCsaData] = useState();
  const [countyData, setCountyData] = useState();
  const [randomcounties, setRandomCounties] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [zipcodeData, setZipcodeData] = useState();
  const [zipcodes, setZipcodes] = useState([]);
  const [zipcodeCurrentPage, setZipcodeCurrentPage] = useState(0);
  const [totalZipcodeCount, setTotalZipcodeCount] = useState();
  const [countiesCurrentPage, setCountiesCurrentPage] = useState(0);
  const [totalCountiesCount, setTotalCountiesCount] = useState(0);
  const [cityData, setCityData] = useState();
  const [citiesCurrentPage, setCitiesCurrentPage] = useState(0);
  const [totalCitiesCount, setTotalCitiesCount] = useState(0);
  const [csaArticalContent, setCsaArticalContent] = useState();

  useEffect(() => {
    setIsLoading(true);
    axios_getCsaDataFromCode(slug, getCsaDataCallback);
    axios_getCsasArticleData(slug, getCsasArticleCallback);
  }, [slug]);

  useEffect(() => {
    axios_getCsasChildDataByCsasCode(
      slug,
      "counties",
      countiesCurrentPage,
      getCsasChildDataCsasCallback
    );
  }, [slug, countiesCurrentPage]);

  useEffect(() => {
    axios_getCsasChildDataByCsasCode(
      slug,
      "cities",
      citiesCurrentPage,
      getCsasChildDataCitiesCallback
    );
  }, [slug, citiesCurrentPage]);

  useEffect(() => {
    var start = zipcodeCurrentPage * 10;
    var end = start + 10;
    if (zipcodeData) {
      setZipcodes(zipcodeData.slice(start, end));
    }
  }, [zipcodeData, zipcodeCurrentPage]);

  const getCsaDataCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setRandomCounties(multirand(response.data.data.counties, 2));
        // setCountyData(response.data.data.counties);
        setZipcodeData(response.data.data.zipcodes);
        setTotalZipcodeCount(response.data.data.zipcodes.length);
        setZipcodes(response.data.data.zipcodes.slice(0, 10));
        setCsaData(response.data.data.csas);
        if (window.location.hash) {
          document
            .querySelector('[id="' + window.location.hash.substring(1) + '"]')
            .scrollIntoView();
        }
      }
    }
    setIsLoading(false);
  };

  const getCsasChildDataCsasCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setTotalCountiesCount(response.data.data.count);
        setCountyData(response.data.data.rows);
      }
    }
  };

  const getCsasChildDataCitiesCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setTotalCitiesCount(response.data.data.count);
        setCityData(response.data.data.rows);
      }
    }
    setIsLoading(false);
  };

  const getCsasArticleCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setCsaArticalContent(response.data.data);
      }
    }
  };

  return (
    <>
      <div className="App">
        <Header />
        {csaData && csaData.length > 0 && (
          <>
            <Head
              title={`${
                csaData && csaData[0] && csaData[0].name
              } - Combined Statistical Area (CSA) on Exuity`}
            />
            <section className="region-sec" style={{ marginTop: 140 }}>
              <ul className="breadcrumb region-breadcrumb arr-right bg-ffffff">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <img
                      src={require("../../../Images/breadcrumb-home.svg")}
                      alt="Exuity Home Icon"
                    />
                  </Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
                <li className="breadcrumb-item">
                  <Link to="/regiontypes">Region type</Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
                <li className="breadcrumb-item">
                  <Link to="/csas">Combined Statistical Areas (CSAs)</Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>{" "}
                <li className="breadcrumb-item" aria-current="page">
                  <Link to={`/csas/${slug}`} onClick={() => window.location.reload()}>
                    {csaData && csaData[0] && csaData[0].name}
                  </Link>
                </li>
              </ul>

              <p className="region-sub-heading">Combined Statistical Area (CSA):</p>
              <h1 className="region-title">{csaData && csaData[0] && csaData[0].name}</h1>
              <div>
                <p className="region-paragraph">
                  {csaData[0].name} is a combined statistical area (CSA) comprising counties
                  including{" "}
                  {randomcounties.map((item, index) => {
                    if (index === 1) {
                      return (
                        <span key={`c-${index}`}>
                          {" "}
                          and{" "}
                          <Link
                            className="region-link"
                            to={`/counties/${item.state.slug}/${item.slug}`}>
                            {item.name}
                          </Link>
                        </span>
                      );
                    }
                    return (
                      <Link
                        key={`c-${index}`}
                        className="region-link"
                        to={`/counties/${item.state.slug}/${item.slug}`}>
                        {item.name}
                      </Link>
                    );
                  })}
                  .
                </p>

                <div className="article">
                  <h2 className="articletitle">
                    {csaArticalContent && csaArticalContent.article_title}
                  </h2>
                  <div className="articlecontent">
                    {csaArticalContent &&
                      csaArticalContent.article &&
                      csaArticalContent.article
                        .split("\n")
                        .map((item, i) => (
                          <p key={i}>
                            {<span dangerouslySetInnerHTML={{ __html: item && item }} />}
                          </p>
                        ))}
                  </div>
                </div>

                {csaData && csaData.length > 1 && <CsasTable tableData={csaData} />}
                {countyData && countyData.length > 1 && (
                  <CountiesTable
                    tableData={countyData}
                    tableTitle={`Counties in ${csaData && csaData[0] && csaData[0].name}`}
                    totalPageCount={totalCountiesCount}
                    currentPage={countiesCurrentPage}
                    setCurrentPage={setCountiesCurrentPage}
                  />
                )}
                {cityData && cityData.length > 1 && (
                  <CityTable
                    tableData={cityData}
                    tableTitle={`Cities in ${csaData && csaData[0].name}`}
                    totalPageCount={totalCitiesCount}
                    currentPage={citiesCurrentPage}
                    setCurrentPage={setCitiesCurrentPage}
                  />
                )}
                {zipcodeData && zipcodeData.length > 1 && (
                  <ZipcodeTable
                    tableData={zipcodes}
                    tableTitle={`Zip codes in ${csaData && csaData[0] && csaData[0].name}`}
                    totalPageCount={totalZipcodeCount}
                    currentPage={zipcodeCurrentPage}
                    setCurrentPage={setZipcodeCurrentPage}
                  />
                )}
              </div>
            </section>
            <GetStarted
              name={`Buy & Sell Shares of Homes in ${csaData && csaData[0] && csaData[0].name}`}
              description="Exuity lets property owners sell shares of their properties to investors who earn income and appreciation."
            />
          </>
        )}

        {isLoading === true && (
          <div className="text-center pt-5" style={{ marginTop: 120 }}>
            <div className="spinner-grow text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p>Loading. Please wait...</p>
          </div>
        )}
        <div className="footer-section">
          <Footer />
        </div>
      </div>
    </>
  );
}
