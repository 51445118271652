import React, { useState } from "react";
import { Formik, Field, Form, useFormikContext, useField } from "formik";
import * as Yup from "yup";
import { updateUser } from "../store/actions/auth";
import { useDispatch } from "react-redux";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "../login-signup-screens/Form.css";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getYear";
import DatePicker from "react-datepicker";
import range from "lodash/range";

const years = range(1900, getYear(new Date()) + 1, 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setFieldValue(field.name, new Date(val).toISOString().slice(0, 10));
      }}
    />
  );
};

const EditProfileForm = (props) => {
  const dispatch = useDispatch();
  const initialFormValue = {
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    email: props.user.email,
    phoneno: props.user.phoneno,
    password: "",
    date_of_birth: props.user.date_of_birth,
    avatarPreview: props.user.photo,
    uploaded_photo: "",
    address: props.user.address,
    success: false,
  };

  const [formInitialValue, setFormInitialValue] = useState(initialFormValue);

  // Get and set input values
  const onChangeHandler = (e) => {
    setFormInitialValue((formInitialValue) => {
      return { ...formInitialValue, [e.target.name]: e.target.value };
    });
  };

  const onChange4PhoneNo = (e) => {
    setFormInitialValue((formInitialValue) => {
      return { ...formInitialValue, phoneno: e };
    });
  };

  const photoset = (e, formik) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      if (fileReader.readyState === 2) {
        formik.setFieldValue("uploaded_photo", fileReader.result);
        formik.setFieldValue("avatarPreview", fileReader.result);
      }
    };
    fileReader.readAsDataURL(e.target.files[0]);
  };
  const [error, setError] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValue}
      onSubmit={async (values, actions) => {
        Object.keys(values).forEach(function (key) {
          var value = values[key];
          if (typeof value === "undefined" || value === "") {
            values[key] = null;
          }
        });
        
        dispatch(updateUser(values));
      }}
      validationSchema={Yup.object({
        first_name: Yup.string()
          .max(15, "Must be 15 characters or less")
          .required("Your first name is required"),
        last_name: Yup.string()
          .max(15, "Must be 15 characters or less")
          .required("Your last name is required"),
        email: Yup.string()
          .email("Please enter a valid email address.")
          .required("Email is required"),
        password: Yup.string()
          .nullable()
          .min(8, "Password is too short - should be 8 chars minimum.")
          .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
      })}
    >
      {(formik, isSubmitting, setStatus, status) => {
        return (
          <Form className="form-wrapper">

            {!formik.values.success ? (
              <div className="form-box signup-form-box">
                <div className="form-group">
                  <label className="photo-labels" htmlFor="photo">
                    <span>Profile Photo</span>
                  </label>
                  <img
                    src={formik.values.avatarPreview}
                    width="100px"
                    height="100px"
                    alt="avatar Preview"
                  />

                  <Field
                    name="photo_"
                    onChange={(e) => photoset(e, formik)}
                    className={
                      formik.touched.photo && formik.errors.photo
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="file"
                    placeholder="Photo"
                  />
                </div>

                <div className="signup-double-input">
                  <div className="form-group">
                    <label htmlFor="first_name">First Name</label>
                    <Field
                      name="first_name"
                      onChange={(e) => onChangeHandler(e)}
                      className={
                        formik.touched.first_name && formik.errors.first_name
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type="text"
                      placeholder="Your First Name"
                      readOnly
                      disabled
                    />
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.first_name}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label htmlFor="last_name">Last Name</label>
                    <Field
                      name="last_name"
                      onChange={(e) => onChangeHandler(e)}
                      className={
                        formik.touched.last_name && formik.errors.last_name
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type="text"
                      placeholder="Your Last Name"
                      readOnly
                      disabled
                    />
                    {formik.touched.last_name && formik.errors.last_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.last_name}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="signup-double-input">
                  <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <Field
                      name="email"
                      onChange={(e) => onChangeHandler(e)}
                      className={
                        formik.touched.email && formik.errors.email
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type="email"
                      placeholder="Your Email Address"
                      readonly
                      disabled
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="invalid-feedback">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label htmlFor="phoneno">Phone Number</label>

                    <PhoneInput
                      defaultCountry="US"
                      name="phoneno"
                      value={formik.values.phoneno}
                      placeholder="Your phone no."
                      onChange={(e) => onChange4PhoneNo(e)}
                      data-attribute-name="Phone No."
                      data-async
                      inputClass="PhoneInputInput form-control is-invalid"
                      onBlur={() => setError(true)}
                      onFocus={() => setError(false)}
                    />

                    {error && (
                      <>
                        <div className="invalid-feedback d-block">
                          {formik.touched.phoneno &&
                            formik.errors.phoneno &&
                            formik.errors.phoneno}

                          {
                            formInitialValue.phoneno !== "" &&
                            formInitialValue.phoneno
                              ? isValidPhoneNumber(formInitialValue.phoneno)
                                ? undefined
                                : "Invalid phone number"
                              : ""
                          }
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="form-group pass-input">
                  <label
                    className="date_of_birth-labels"
                    htmlFor="date_of_birth"
                  >
                    <span>Date of Birth</span>
                  </label>
                  <DatePickerField name="date_of_birth" />
                </div>
                <div className="form-group">
                  <label className="address-labels" htmlFor="address">
                    <span>Address</span>
                  </label>
                  <Field
                    name="address"
                    default={""}
                    onChange={(e) => onChangeHandler(e)}
                    className={
                      formik.touched.address && formik.errors.address
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Your Address"
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <div className="invalid-feedback">
                      {formik.errors.address}
                    </div>
                  ) : null}
                </div>

                <div className="form-group pass-input">
                  <label className="password-labels" htmlFor="password">
                    <span>Password</span>
                  </label>
                  <Field
                    name="password"
                    onChange={(e) => onChangeHandler(e)}
                    className={
                      formik.touched.password && formik.errors.password
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="password"
                    placeholder="Create New Account Password"
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="invalid-feedback">
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    className="blue-btn"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Please wait..." : "Update"}
                  </button>
                </div>
              </div>
            ) : (
              <div>Profile is updated successfully</div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditProfileForm;
