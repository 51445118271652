import React from "react";
import "../Dashboard.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import AddAuction from "./AddAuction";
import { connect } from "react-redux";
import { loadUser, startLoading, logout } from "../../store/actions/auth";
import store from "../../store/store";
import BidAuctions from "./BidAuctions";
import { NavLink } from "react-router-dom";

class Auctions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      found: 0,
      auctions: []
    };
    this.setFound = this.setFound.bind(this);
  }
  setFound(found) {
    this.setState({ found: found });
  }
  componentDidMount() {
    store.dispatch(startLoading());
    store.dispatch(loadUser());
    document.title = "Exuity | Launch a home equity auction";
  }
  render() {
    return (
      <div className="Dashboard" id="Dashboard">
        <div className="DashboardGroup">
          <div className="auction-navbar">
            <div className="dashTitle">
              <h4>Auctions You Launched</h4>
              {this.state.found === 2 ? (
                <div className="createauction-btn">
                  <NavLink to="/auctions/create">
                    <button type="button">Create Auction</button>
                  </NavLink>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="row">
            <Sidebar />
            {this.state.isVisible ? (
              <div className="DashContent">
                <AddAuction user={this.props.user} />
              </div>
            ) : (
              <div className="DashContent">
                <BidAuctions setFound={this.setFound} />
                <div className="launched-auc-sec"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user
});
export default connect(mapStatetoProps, { loadUser, startLoading, logout })(Auctions);
