import * as atypes from "./types";
import axios from "../..//utills/axios-server";
//import { setAlert } from "./alert";
// import setAuthToken from "../../utills/setAuthToken";
import { setToast } from "./toast";
import {
  axios_loadUser,
  axios_loginUser,
  axios_registerUser,
  axios_updateUser,
} from "../../utills/axios-routes";
import Cookies from "js-cookie";

export const loginUser = (email, password, rememberme) => async (dispatch) => {
  dispatch({
    type: atypes.START_LOADING,
  });

  axios_loginUser(email, password)
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: atypes.LOGIN_SUCCESS,
          payload: { token: response.data.token },
        });

        axios.defaults.headers.common["x-auth-token"] = response.data.token;
        localStorage.setItem("userToken", response.data.token);
        dispatch(loadUser());

        dispatch({
          type: atypes.SET_TOAST,
          payload: [{ msg: "Logged in successfully", type: "success" }],
        });

        if (rememberme) {
          Cookies.set("email", email, { expires: 14 });
          Cookies.set("password", password, {
            expires: 14,
          });
          Cookies.set("rememberme", true, { expires: 14 });
        } else {
          Cookies.set("email", "");
          Cookies.set("password", "");
          Cookies.set("rememberme", "");
        }
        // setTimeout(() => {
        //   window.location.href = "/properties";
        // }, 300);
      } else {
        console.log("error msg is => ", response.data.msg);
        dispatch({
          type: atypes.LOGIN_ERROR,
          // payload: [{ msg:"Invalid Credentials", type: "danger" }],
        });
        // dispatch(setAlert("Invalid Credentials", "danger"));
        dispatch({
          type: atypes.SET_TOAST,
          payload: [{ msg: response.data.msg, type: "success" }],
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: atypes.LOGIN_ERROR,
      });
      dispatch({
        type: atypes.SET_TOAST,
        payload: [{ msg: err.response.data.msg, type: "error" }],
      });
    });
};

export const loadUser = () => async (dispatch) => {
  axios_loadUser()
    .then((response) => {
      if (response.data.success) {
        localStorage.setItem("userphoto", response.data.data.photo);
        dispatch({
          type: atypes.LOAD_USER,
          payload: response.data.data,
        });
      } else {
        setTimeout(() => {
          dispatch({
            type: atypes.SET_TOAST,
            payload: [],
          });

          // localStorage.removeItem("userToken")
        }, 10000);
        dispatch({
          type: atypes.AUTH_ERROR,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: atypes.AUTH_ERROR,
      });
      // localStorage.removeItem("userToken")
    });
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userToken");
  dispatch({
    type: atypes.LOGOUT,
  });
  window.location.href = "/";
};

export const startLoading = () => (dispatch) => {
  dispatch({
    type: atypes.START_LOADING,
  });
};

export const stopLoading = () => (dispatch) => {
  dispatch({
    type: atypes.STOP_LOADING,
  });
};
export const isEmailRegisterd = (email) => async (dispatch) => {
  await axios
    .get("/api/auth/checkemail")
    .then((response) => {
      dispatch({
        type: atypes.LOAD_USER,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: atypes.AUTH_ERROR,
      });
    });
};

export const registerUser = (data) => async (dispatch) => {
  dispatch({
    type: atypes.START_LOADING,
  });
  // const body = JSON.stringify({ fname, lname, email, password, username });
  // try {
  axios_registerUser(data)
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: atypes.REGISTER_SUCCESS,
          payload: response.data,
        });

        dispatch(setToast([{ msg: response.data.msg, type: "success" }]));

        setTimeout(() => {
          window.location.href = "/?signup=success";
          setTimeout(() => {
            dispatch(
              setToast([
                {
                  msg: "Please check your email for an email-confirmation link.",
                  type: "success",
                },
              ])
            );
          }, 2000);
        }, 1000);
      } else {
        dispatch(setToast([{ msg: response.data.msg, type: "warning" }]));
      }

      dispatch({
        type: atypes.STOP_LOADING,
      });
    })
    .catch((err) => {
      dispatch(
        setToast([
          { msg: "Somthing went wrong! Register Failed", type: "error" },
        ])
      );

      dispatch({
        type: atypes.STOP_LOADING,
      });
      dispatch({
        type: atypes.REGISTER_FAIL,
      });
    });
};

export const updateUser = (data) => async (dispatch) => {
  dispatch({
    type: atypes.START_LOADING,
  });

  axios_updateUser(data)
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: atypes.REGISTER_SUCCESS,
          payload: response.data,
        });

        dispatch(setToast([{ msg: response.data.msg, type: "success" }]));
      } else {
        dispatch(setToast([{ msg: response.data.msg, type: "warning" }]));
      }

      dispatch({
        type: atypes.STOP_LOADING,
      });
    })
    .catch((err) => {
      dispatch(
        setToast([
          { msg: "Somthing went wrong! Register Failed", type: "error" },
        ])
      );

      dispatch({
        type: atypes.STOP_LOADING,
      });
      dispatch({
        type: atypes.REGISTER_FAIL,
      });
    });
};

export const resetPassword =
  ({ newPassword, confirmPassword, token }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ newPassword, confirmPassword, token });
    // try {
    await axios
      .post("/api/auth/reset-password", body, config)
      .then((response) => {
        // dispatch({
        //   type: atypes.RESET_PASSWORD,
        //   payload: response.data
        // });
        if (response.data.found) {
          dispatch(
            setToast([
              {
                msg: "Password reset successfully.Redirecting to Login page",
                type: "success",
              },
            ])
          );
          setTimeout(() => {
            window.location.href = "/login";
          }, 1000);
        } else {
          dispatch(
            setToast([
              {
                msg: "Somthing went wrong.Password not updated.Redirecting to Login page",
                type: "error",
              },
            ])
          );
        }
      })
      .catch((err) => {
        // dispatch({
        //   type: atypes.REGISTER_FAIL
        // });
      });
  };
