import React from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {
  axios_sendCodeToEmail,
  axios_confirmCode,
  axios_resetPassword,
} from "../utills/axios-routes";
// import { setToast } from '../store/actions/toast';
import "./Form.css";

const ForgotPassword = (props) => {
  return (
    <Formik
      initialValues={{
        email: "",
        code: "",
        password: "",
        codesent: false,
        codeverified: false,
        success: false,
      }}
      onSubmit={(values, actions) => {
        if (values.codesent === false) {
          axios_sendCodeToEmail(values.email)
            .then((response) => {
              if (response.data.success) {
                actions.setFieldValue("codesent", true);
                props.setToast([
                  {
                    msg: "Please check your email and enter the code",
                    type: "info",
                  },
                ]);
              } else {
                actions.setErrors({ email: response.data.msg });
              }
              actions.setSubmitting(false);
            })
            .catch((err) => {
              actions.setErrors({ email: "user not found" });
              actions.setSubmitting(false);
            });
        }
        if (values.codesent === true && values.codeverified === false) {
          axios_confirmCode(values.email, values.code)
            .then((response) => {
              if (response.data.success) {
                actions.setFieldValue("codeverified", true);
                props.setToast([
                  {
                    msg: "Please check your email and enter the code",
                    type: "info",
                  },
                ]);
              } else {
                actions.setErrors({ email: response.data.msg });
              }
              actions.setSubmitting(false);
            })
            .catch((err) => {
              actions.setErrors({ email: "user not found" });
              actions.setSubmitting(false);
            });
        }
        if (
          values.codesent === true &&
          values.codeverified === true &&
          values.success === false
        ) {
          axios_resetPassword(values.email, values.password, values.code)
            .then((response) => {
              if (response.data.success) {
                actions.setFieldValue("success", true);
                props.setToast([
                  {
                    msg: "Please check your email and enter the code",
                    type: "info",
                  },
                ]);
              } else {
                actions.setErrors({ email: response.data.msg });
              }
              actions.setSubmitting(false);
            })
            .catch((err) => {
              actions.setErrors({ email: "user not found" });
              actions.setSubmitting(false);
            });
        }
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Invalid email address")
          .required("Email is required"),
      })}
    >
      {(formik, isSubmitting) => {
        return (
          <Form className="form-wrapper">
            <div className="brand">
              <Link to="/">
                <img src={require("../Images/exuity-logo.svg")} alt="brand" />
              </Link>
            </div>
            {!formik.values.codesent ? (
              <div className="form-box">
                <div className="form-text-wrapper">
                  <h1>Forgot Password?</h1>
                  <p>
                    Don’t worry! Fill in your email and we’ll send you a link to
                    reset your password.
                  </p>
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email Address</label>
                  <Field
                    name="email"
                    className={
                      formik.touched.email && formik.errors.email
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="email"
                    placeholder="Your Email Address"
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="invalid-feedback">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    className="blue-btn"
                    disabled={formik.isSubmitting}
                  >
                    {formik.isSubmitting ? "Please wait..." : "Send Email"}
                  </button>
                </div>

                <div className="account-creation-link">
                  <p>
                    <Link to="/signin">Return to Sign in</Link>
                  </p>
                </div>
              </div>
            ) : null}
            {formik.values.codesent && !formik.values.codeverified ? (
              <div className="form-box">
                <div className="form-text-wrapper">
                  <h1>Verify code</h1>
                  <p>Please check your email and enter the code below</p>
                </div>

                <div className="form-group">
                  <label htmlFor="code">Code</label>
                  <Field
                    name="code"
                    className={
                      formik.touched.code && formik.errors.code
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="text"
                    placeholder="Enter Code"
                  />
                  {formik.touched.code && formik.errors.code ? (
                    <div className="invalid-feedback">{formik.errors.code}</div>
                  ) : null}
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    className="blue-btn"
                    disabled={formik.isSubmitting}
                  >
                    {formik.isSubmitting ? "Please wait..." : "Verify Code"}
                  </button>
                </div>

                <div className="account-creation-link">
                  <p>
                    <Link to="/signin">Return to Sign in</Link>
                  </p>
                </div>
              </div>
            ) : null}
            {formik.values.codesent &&
            formik.values.codeverified &&
            !formik.values.success ? (
              <div className="form-box">
                <div className="form-text-wrapper">
                  <h1>Enter New Password</h1>
                  <p>Please enter your new password below</p>
                </div>

                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Field
                    name="password"
                    className={
                      formik.touched.password && formik.errors.password
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="password"
                    placeholder="Enter New Password"
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="invalid-feedback">
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    className="blue-btn"
                    disabled={formik.isSubmitting}
                  >
                    {formik.isSubmitting ? "Please wait..." : "Update Password"}
                  </button>
                </div>

                <div className="account-creation-link">
                  <p>
                    <Link to="/signin">Return to Sign in</Link>
                  </p>
                </div>
              </div>
            ) : null}
            {formik.values.codesent &&
            formik.values.codeverified &&
            formik.values.success ? (
              <div className="form-box">
                <div className="form-text-wrapper">
                  <h1>Password Reset Successfully</h1>
                  <p>Your Password is reset successfully.</p>
                </div>

                <div className="account-creation-link">
                  <p>
                    <Link to="/signin">Click Here To Sign In</Link>
                  </p>
                </div>
              </div>
            ) : null}
            <div className="copyrights-text">
              <p>&copy; {new Date().getFullYear()}, Exuity</p>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ForgotPassword;
