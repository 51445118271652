import * as atypes from "./types";

export const setToast = (toasts,options={}) => dispath => {
  
  const data={
    type: atypes.SET_TOAST,
    payload:[...toasts]
    
  };
  dispath(data);
};
