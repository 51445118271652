import React, { useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import DetailMarker from "./DetailMarker";


const Marker = ({ children }) => children;

export default function GoogleMap({places, apiHasLoaded, isHovered, isHoveredProperty}) {
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  var points = [];

  if(places.length>0){
    points = places.map(place => ({
        type: "Feature",
        properties: { cluster: false, id:place.id, place:place },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(place.geometry.location.lng),
            parseFloat(place.geometry.location.lat)
          ]
        }
    }));
  }
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { 
        radius: 75, 
        maxZoom: 20
    }
  });

  return (
     
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
        defaultCenter={{ lat: 52.6376, lng: -1.135171 }}
        defaultZoom={10}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
          apiHasLoaded(map)
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        }}
      >
        {clusters.map(cluster => {
   
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount
          } = cluster.properties;

          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <div
                  className="cluster-marker"
                  style={{
                    width: `${10 + (pointCount / points.length) * 20}px`,
                    height: `${10 + (pointCount / points.length) * 20}px`
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }}
                >
                  {pointCount}
                </div>
              </Marker>
            );
          }

          return (
         
            <DetailMarker
              key={`place-${cluster.properties.id}`}
              lat={latitude}
              lng={longitude}
              property={cluster.properties.place}
              isHovered={isHovered}
              isHoveredProperty={isHoveredProperty}
            >

            </DetailMarker>
          );
        })}
      </GoogleMapReact>
    </div>
  );
}