import React from "react";
import "../Dashboard.css";
import { connect } from "react-redux";
import { loadUser, startLoading, logout } from "../../store/actions/auth";
import { saveProperty } from "../../store/actions/property";
import store from "../../store/store";
// import { BrowserRouter as Router, Link } from "react-router-dom";
import { setToast } from "../../store/actions/toast";
import Toast from "../../components/layout/Toast";
import { ToastProvider } from "react-toast-notifications";
// import { useDispatch } from 'react-redux'
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

class AddProperty extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      isSubmitted: false,
      propertyType: "",
      mortageBalance: "",
      desiredCash: "",
      estimatedPropertyValue: "",
      propertyAddress: "",
      propertyAddressParts: "",
      builtupArea: "",
      Bathrooms: "",
      Bedrooms: "",
      address: "",
      title: "",
    };
  }
  componentDidMount() {
    //	store.dispatch(startLoading());
    //	store.dispatch(loadUser());
  }
  openProperty() {
    this.setState({
      isVisible: true,
      isSubmitted: true,
    });
  }
  setAddress(val) {
    if (typeof val != "undefined") {
      this.setState({ propertyAddress: val.label });
    }
  }
  basicInfo() {
    if (this.state.propertyType !== "" && this.state.title !== "") {
      this.setState({ isVisible: !this.state.isVisible });
    } else {
      if (this.state.title !== "") {
        this.props.setToast([
          { msg: "Please enter property name", type: "error" },
        ]);
      } else if (this.state.propertyType !== "") {
        this.props.setToast([
          { msg: "Please select Property Type", type: "error" },
        ]);
      }
    }
  }
  propertyCost() {
    if (
      this.state.mortageBalance !== "" &&
      this.state.desiredCash !== "" &&
      this.state.estimatedPropertyValue !== ""
    ) {
      this.setState({ isSubmitted: !this.state.isSubmitted });
    } else {
      if (this.state.mortageBalance === "") {
        this.props.setToast([
          { msg: "Please provide Mortage Balance", type: "error" },
        ]);
      } else if (this.state.desiredCash === "") {
        this.props.setToast([
          { msg: "Please provide Desired Cash", type: "error" },
        ]);
      } else if (this.state.estimatedPropertyValue === "") {
        this.props.setToast([
          { msg: "Please provide Estimated Property Value", type: "error" },
        ]);
      }
    }
  }
  propertyAddress() {
    if (
      this.state.propertyAddress !== "" &&
      this.state.builtupArea !== "" &&
      this.state.Bathrooms !== "" &&
      this.state.Bedrooms !== ""
    ) {
      // we need to send data to server
      store
        .dispatch(
          saveProperty(
            this.state.title,
            this.state.propertyType,
            this.state.mortageBalance,
            this.state.desiredCash,
            this.state.estimatedPropertyValue,
            this.state.propertyAddress,
            this.state.builtupArea,
            this.state.Bathrooms,
            this.state.Bedrooms
          )
        )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (this.state.propertyAddress === "") {
        this.props.setToast([
          { msg: "Please provide Property Address", type: "error" },
        ]);
      } else if (this.state.builtupArea === "") {
        this.props.setToast([
          { msg: "Please provide Built Up Area", type: "error" },
        ]);
      } else if (this.state.Bathrooms === "") {
        this.props.setToast([
          { msg: "Please provide Bathrooms", type: "error" },
        ]);
      } else if (this.state.Bedrooms === "") {
        this.props.setToast([
          { msg: "Please provide Bedrooms", type: "error" },
        ]);
      }
    }
  }

  render() {
    const address = this.state.address;
    //  const setAddress = this.setAddress();
    return (
      <div className="AddProperty">
        <ToastProvider>
          <Toast />
        </ToastProvider>
        {this.state.isVisible ? (
          this.state.isSubmitted ? (
            <div className="AddPropertyGroup">
              <div className="AddProperty-ls">
                <div className="AddProperty-t">
                  <h5>
                    Hi, {this.props.user.firstName}! Let’s get started listing
                    your space.
                  </h5>
                </div>
                <div className="AddProperty-steps">
                  <ul>
                    <li className="active-tick">Basic Info</li>
                    <li className="active-tick">Property Cost</li>
                    <li className="active">Address and Area</li>
                  </ul>
                </div>
                <div className="AddProperty-illustration">
                  <img
                    src={require("../../Images/AddProperty-illustration.png")}
                    alt="Add Property Home Illustration"
                  />
                </div>
              </div>
              <div className="AddProperty-rs">
                <div className="AddProperty-rs-Group">
                  <div className="AddProperty-form">
                    <ul className="AddProperty-inputs">
                      <li>
                        <label className="input-label">Property Address</label>

                        <GooglePlacesAutocomplete
                          apiKey="AIzaSyAAkHqRzQUrkvifRENNrC3kKSRwdhX6_Qc&libraries"
                          selectProps={{
                            address,
                            onChange: (o) => {
                              this.setAddress(o);
                            },
                          }}
                        />
                      </li>
                      <li>
                        <label className="input-label">Built Up Area</label>
                        <input
                          type="number"
                          name="Area"
                          placeholder="sq.ft."
                          value={this.state.builtupArea}
                          onChange={(e) =>
                            this.setState({ builtupArea: e.target.value })
                          }
                        />
                      </li>
                      <li>
                        <label className="input-label">Bedrooms</label>
                        <input
                          type="number"
                          name="Bedrooms"
                          placeholder="1"
                          value={this.state.Bedrooms}
                          onChange={(e) =>
                            this.setState({ Bedrooms: e.target.value })
                          }
                        />
                      </li>
                      <li>
                        <label className="input-label">Bathrooms</label>
                        <input
                          type="number"
                          name="Bathrooms"
                          placeholder="1"
                          value={this.state.Bathrooms}
                          onChange={(e) =>
                            this.setState({ Bathrooms: e.target.value })
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="Ap-submitted-btn">
                    <button onClick={() => this.propertyAddress()}>
                      Add Property
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="AddPropertyGroup">
              <div className="AddProperty-ls">
                <div className="AddProperty-t">
                  <h5>
                    Hi, {this.props.user.firstName}! Let’s get started listing
                    your space.
                  </h5>
                </div>
                <div className="AddProperty-steps">
                  <ul>
                    <li className="active-tick">Basic Info</li>
                    <li className="active">Property Cost</li>
                    <li>Address and Area</li>
                  </ul>
                </div>
                <div className="AddProperty-illustration">
                  <img
                    src={require("../../Images/AddProperty-illustration.png")}
                    alt="Add Property Home Illustration"
                  />
                </div>
              </div>
              <div className="AddProperty-rs">
                <div className="AddProperty-rs-Group">
                  <div className="AddProperty-form">
                    <ul className="AddProperty-inputs">
                      <li>
                        <label className="input-label">
                          Mortage Balance ($)
                        </label>
                        <input
                          type="number"
                          name="balance"
                          placeholder="0.00"
                          value={this.state.mortageBalance}
                          onChange={(e) =>
                            this.setState({ mortageBalance: e.target.value })
                          }
                        />
                      </li>
                      <li>
                        <label className="input-label">Desired Cash ($)</label>
                        <input
                          type="number"
                          name="Cash"
                          placeholder="0.00"
                          value={this.state.desiredCash}
                          onChange={(e) =>
                            this.setState({ desiredCash: e.target.value })
                          }
                        />
                      </li>
                      <li>
                        <label className="input-label">
                          Estimated Property Value ($)
                        </label>
                        <input
                          type="number"
                          name="Value"
                          placeholder="0.00"
                          value={this.state.estimatedPropertyValue}
                          onChange={(e) =>
                            this.setState({
                              estimatedPropertyValue: e.target.value,
                            })
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="Ap-submitted-btn">
                    <button onClick={() => this.propertyCost()}>
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="AddPropertyGroup">
            <div className="AddProperty-ls">
              <div className="AddProperty-t">
                <h5>
                  Hi, {this.props.user.firstName}! Let’s get started listing
                  your space.
                </h5>
              </div>
              <div className="AddProperty-steps">
                <ul>
                  <li className="active">Basic Info</li>
                  <li>Property Cost</li>
                  <li>Address and Area</li>
                </ul>
              </div>
              <div className="AddProperty-illustration">
                <img
                  src={require("../../Images/AddProperty-illustration.png")}
                  alt="Add Property Home Illustration"
                />
              </div>
            </div>
            <div className="AddProperty-rs">
              <div className="AddProperty-rs-Group">
                <div className="AddProperty-form">
                  <ul className="AddProperty-inputs">
                    <li>
                      <label className="input-label">Property Name</label>
                      <input
                        type="text"
                        name="title"
                        placeholder="Name of Property"
                        value={this.state.title}
                        onChange={(e) =>
                          this.setState({ title: e.target.value })
                        }
                      />
                    </li>
                  </ul>
                </div>
                <div className="property-type-tags">
                  <h6>Property Type</h6>
                  <div className="property-tags">
                    <button
                      onClick={() =>
                        this.setState({ propertyType: "Detached House" })
                      }
                    >
                      Detached House
                    </button>
                    <button
                      onClick={() =>
                        this.setState({ propertyType: "Condo/Townhouse" })
                      }
                    >
                      Condo/Townhouse
                    </button>
                    <button
                      onClick={() =>
                        this.setState({ propertyType: "Multi-family" })
                      }
                    >
                      Multi-family
                    </button>
                    <button
                      onClick={() =>
                        this.setState({ propertyType: "Mobile Home" })
                      }
                    >
                      Mobile Home
                    </button>
                    <button
                      onClick={() =>
                        this.setState({ propertyType: "Vacant Land" })
                      }
                    >
                      Vacant Land
                    </button>
                    <button
                      onClick={() => this.setState({ propertyType: "Other" })}
                    >
                      Other
                    </button>
                  </div>
                </div>
                <div className="Ap-submitted-btn">
                  <button onClick={() => this.basicInfo()}>Continue</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
});
export default connect(mapStatetoProps, {
  loadUser,
  startLoading,
  logout,
  setToast,
  saveProperty,
})(AddProperty);
