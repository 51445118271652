import React, { useCallback, useEffect, useState } from "react";
import subDays from "date-fns/subDays";
import CurrencyInput from "react-currency-input-field";
// import FloatInput from "react-float-input";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { saveAuction } from "../../store/actions/auction";
import { useDispatch } from "react-redux";
import { delayedSubmit } from "../../store/reducers/helper";
// import { str_into_num } from "../../utills/string-into-number";
import DatePicker from "react-datepicker";
// import * as wjInput from "@grapecity/wijmo.react.input";

import "react-datepicker/dist/react-datepicker.css";
// import commaNumber from "comma-number";
// import currencyFormatter from "currency-formatter";
import "@grapecity/wijmo.styles/wijmo.css";

export default function AuctionCreateForm(props) {
  const dispatch = useDispatch();
  // const [startDate, setStartDate] = useState(new Date());
  // const DOM_InputDateField = useRef(null);

  // const dicimalRegex = /^\d+\.\d{0,2}$/
  // /^(\d{0,2}\.\d{1,2})$/
  // /^(\d{1,2}|\d{0,5}\.\d{1,2})$/
  //  /^[0-9]*(\.[0-9]{0,2})?$/
  // /^[0-9]{1,3}(,[0-9]{3})*(\.[0-9]+)*$/
  // const phoneRegExp = /^[6-9]\d{9}$/

  // const validationHandler = () => {
  //   document
  //     .querySelector(".validation")
  //     .addEventListener("keypress", function (evt) {
  //       if (
  //         (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
  //         evt.which > 57
  //       ) {
  //         evt.preventDefault();
  //       }
  //     });
  // };

  const validationHandler = useCallback(() => {
    document.querySelector(".validation").addEventListener("keypress", function (evt) {
      if ((evt.which !== 8 && evt.which !== 0 && evt.which < 48) || evt.which > 57) {
        evt.preventDefault();
      }
    });
  }, []);

  useEffect(() => {
    validationHandler();
  }, [validationHandler]);

  const AuctionCreateSchema = Yup.object().shape({
    // amount_raising: Yup.number()
    //   .required("Please provide Amount Seeking ($)")
    //   .min(5000, "Amount must be between $5k and $5m")
    //   .test(
    //     "Is positive?",
    //     "Must be multiple of $500",
    //     (value) => value % 500 === 0
    //   )
    //   .max(5000000, "Amount must be between $5k and $5m"),
    max_yield: Yup.number()
      .required("Please provide maximum yield")
      // .matches(dicimalRegex, 'Yield % must be between 0% and 15% dicimal')
      .min(0.1, "Yield % must be between 0% and 15%")
      .max(15, "Yield % must be between 0% and 15%"),
    contract_length: Yup.number()
      .min(1, "Contract length must be between 1 and 15 years")
      .max(15, "Contract length must be between 1 and 15 years")
      .required("Please provide contract length"),
    start_date: Yup.date()
      .min(subDays(new Date(), 1), "Can't start auction in the past.")
      .required("Please provide Auction Launch Date")
  });

  const [maxYield, setmaxYield] = useState();
  const [amountRaising, setAmountRaising] = useState();
  // const [amountRaisingWithComma, setAmountRaisingWithComma] = useState();
  // const [amountRaisingWithCommaC, setAmountRaisingWithCommaC] = useState();
  const [error, setError] = useState(false);
  const [contract_length, setcontract_length] = useState();
  const [dateForAuc, setdateForAuc] = useState();

  const blockInvalidChar = (e, current) => {
    if (current === "yield") {
      if (["e", "E", "+", "-", "."].includes(e.key)) {
        e.preventDefault();
      } else if (e.keyCode === 69 || e.keyCode === 190) {
        e.preventDefault();
      }
    } else {
      if (["e", "E", "+", "-", "."].includes(e.key)) {
        e.preventDefault();
      } else if (e.keyCode === 69 || e.keyCode === 190) {
        e.preventDefault();
      }
    }
  };
  const blockInvalidCha3 = (e, current) => {
    if (
      ![".", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ",", "Backspace"].includes(e.key)
    ) {
      e.preventDefault();
    }
  };
  const decimalInputChange = (e) => {
    let vl = e.target.value;
    vl === 0 && setmaxYield(null);
    if (vl === 0) {
      setmaxYield(vl);
    } else {
      function ParseFloat(str, val) {
        str = str.toString();
        str = str.slice(0, str.indexOf(".") + val + 1);
        return Number(str);
      }
      setmaxYield(ParseFloat(`${vl}`, 2));
    }
    // let value = e.target.value;
    // let valueArr = value.split("");

    // // if(value && value.toLowerCase() === 'e'){
    // //     setmaxYield('')
    // // }
    // if (valueArr[1] === "." && valueArr.length >= 3) {
    //   let newValue = value.substr(0, 4);
    //   setmaxYield(newValue && newValue.toFixed(2));
    // } else if (valueArr.length > 2 && valueArr[2] !== ".") {
    //   value += `.`;
    //   setmaxYield(value && value.toFixed(2));
    // } else if (valueArr[2] === "." && valueArr.length >= 3) {
    //   let newValue = value.substr(0, 5);
    //   setmaxYield(newValue && newValue.toFixed(2));
    // } else if (valueArr[1] === ".") {
    //   value = `0` + value;
    //   setmaxYield(Number(value) && Number(value).toFixed(2));
    // } else {
    //   setmaxYield(value && value.toFixed(2));
    // }
  };

  const handleChange = (e) => {
    if (e.length > 2) {
      setcontract_length(0);
    } else {
      setcontract_length(e);
    }
  };

  // const commaHandler = (e) => {
  //   const currency = parseInt(e.target.value);
  //   const newVal = currency.toLocaleString(undefined, {
  //     maximumFractionDigits: 2,
  //   });
  //   setAmountRaisingWithCommaC(
  //     newVal.toLocaleString(undefined, { maximumFractionDigits: 2 })
  //   );
  // };
  // const num2 = amountRaisingWithComma && amountRaisingWithComma.split && amountRaisingWithComma.split(",");

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          // amount_raising: amountRaising,
          max_yield: maxYield,
          contract_length: contract_length,
          start_date: dateForAuc
        }}
        validationSchema={AuctionCreateSchema}
        onSubmit={(values) => {
          let max_yield = parseFloat(values.max_yield).toFixed(2);
          delayedSubmit(500).then(() => {
            console.log(values);
            // form submit
            dispatch(
              saveAuction(
                props.property_id,
                amountRaising,
                max_yield,
                values.contract_length,
                dateForAuc
              )
            )
              .then((res) => {
                console.log("response", res);
              })
              .catch((err) => {
                console.log("error", err);
              });
          });
        }}>
        {(props) => (
          <Form>
            <div className="AddProperty-form">
              <ul className="AddProperty-inputs">
                <li>
                  <label className="input-label">Amount Seeking ($)</label>
                  <CurrencyInput
                    id="input-example"
                    name="input-name"
                    placeholder="Please enter a number"
                    defaultValue={0}
                    decimalsLimit={2}
                    onValueChange={(value, name) => setAmountRaising(value)}
                    onBlur={() => setError(true)}
                    onKeyDown={(e) => blockInvalidChar(e, "contract_length")}
                  />
                  {error ? (
                    <>
                      {amountRaising === 0 ? (
                        <h1 style={{ fontSize: 12, color: "red", marginTop: 10 }}>
                          Amount Raising is required
                        </h1>
                      ) : amountRaising < 5000 ? (
                        <h1 style={{ fontSize: 12, color: "red", marginTop: 10 }}>
                          Amount must be between $5k and $5m
                        </h1>
                      ) : amountRaising > 5000000 ? (
                        <h1 style={{ fontSize: 12, color: "red", marginTop: 10 }}>
                          Amount must be between $5k and $5m
                        </h1>
                      ) : amountRaising % 500 !== 0 ? (
                        <h1 style={{ fontSize: 12, color: "red", marginTop: 10 }}>
                          Must be multiple of $500
                        </h1>
                      ) : null}
                    </>
                  ) : null}
                </li>
                <li>
                  <label className="input-label">Maximum Yield (%)</label>
                  <Field
                    value={maxYield === 0 ? null : maxYield}
                    type="number"
                    step="0.01"
                    name="max_yield"
                    placeholder="0"
                    className={`form-control ${
                      props.touched.max_yield && props.errors.max_yield ? "is-invalid" : ""
                    }`}
                    onKeyDown={(e) => blockInvalidCha3(e)}
                    onChange={(e) => decimalInputChange(e)}
                    min={0}
                    oninput="validity.valid||(value='');"
                  />
                  <ErrorMessage component="div" name="max_yield" className="invalid-feedback" />
                </li>
                <li>
                  <label className="input-label">Contract Length (Years)</label>
                  <Field
                    step="1"
                    pattern="[0-9]*"
                    type="number"
                    name="contract_length"
                    placeholder="0"
                    value={contract_length}
                    onChange={(e) => handleChange(e.target.value)}
                    className={`form-control validation ${
                      props.touched.contract_length && props.errors.contract_length
                        ? "is-invalid"
                        : ""
                    }`}
                    onKeyDown={(e) => blockInvalidChar(e, "contract_length")}
                    min={0}
                    oninput="validity.valid||(value='');"
                  />
                  <ErrorMessage
                    component="div"
                    name="contract_length"
                    className="invalid-feedback"
                  />
                </li>
                <li>
                  <label className="input-label">Auction Launch Date</label>
                  <DatePicker
                    selected={dateForAuc}
                    onChange={(date) => setdateForAuc(date)}
                    isOutsideRange={true}
                    minDate={subDays(new Date(), 0)}
                  />

                  <ErrorMessage component="div" name="start_date" className="invalid-feedback" />
                </li>
              </ul>
            </div>

            <div className="Ap-submitted-btn text-left pt-4">
              <button type="submit" disabled={props.isSubmitting}>
                {props.isSubmitting === true ? "Please wait..." : "Begin Application"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
