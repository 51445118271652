import React from "react";
import Sidenav from "../Sidenav";
import "../Dashboard.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import TableOwnerIssuances from "./TableOwnerIssuances";
import Head from "../../Component/Head";
class IssuancesOwner extends React.Component {
  render() {
    return (
      <>
        <Head title={'Exuity | Owner | Issuance'} />
        <div className="Dashboard">
          <div className="DashboardGroup">
            <div className="auction-navbar">
              <div className="dashTitle">
                <h4>Home Equity You Issued</h4>
              </div>
              <Sidenav />
            </div>
            <div className="row">
              <div className="auction-navbar1">
                <Sidebar />
              </div>
              <div className="DashContent pt-1 pt-lg-5 " style={{marginTop:"4px"}}>
                <div className="AuctionPage">
                  <TableOwnerIssuances />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

    );
  }
}

export default IssuancesOwner;
