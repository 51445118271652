import { Image_URL } from "./api";
import { PROPERTY_DEFAULT_IMG } from "./imgBaseUrl";
import { getImagesArray } from "./imgBaseUrl";

const URL = Image_URL;

export const URL_PROPERTY = (url) => {
  const imagesArray = getImagesArray(url);
  const image_url = imagesArray.length>0?imagesArray[0]:'';
  if (Image_URL === "http://localhost:4000") {

   
 
   /* const images_string = imagesArray && imagesArray[0];
    const property_img_url2 =
      images_string && images_string.slice && images_string.slice(1); */

    /*  const imagesArray = url ? url && url.split && url.split(",") : "";
      const images_string = imagesArray && imagesArray[0];
      const property_img_url2 =
        images_string && images_string.slice && images_string.slice(1); */
    /*var property_img_url2 = '';
    if (typeof url != null) {
      property_img_url2 = url;
    } */


    // const real_image = URL === "https://api.vessel.us"? url&&url[0] : image_url
    const URL_DATA =
        image_url.length === 0
        ? PROPERTY_DEFAULT_IMG
        : `${URL}/${image_url}`;

    // console.log("getting response Raja", image_url === "null");

    return image_url !== "null" ? URL_DATA : PROPERTY_DEFAULT_IMG;
  } else {
 
    const URL_DATA =
        image_url.length === 0
        ? PROPERTY_DEFAULT_IMG
        : `${URL}/${image_url}`;
    return image_url !== "null" ? URL_DATA : PROPERTY_DEFAULT_IMG;
  }
};
