import React, { useEffect, useState } from "react";
import { Circle, GoogleMap, Marker } from "@react-google-maps/api";

export default function PropertyRegionMap(props) {
  useEffect(() => {
    setLat(props.lat);
    setLng(props.lng);
    setHeight(props.height);
  }, [props]);

  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [height, setHeight] = useState();

  const mapContainerStyle = {
    height: height,
    // width: "800px",
  };

  const center = {
    lat: lat,
    lng: lng,
  };

  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 1000,
    zIndex: 1,
  };

  
  return (
    <>
      {lat && lng && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={12}
          center={center}
        >
          <Circle
            // onLoad={onLoad}
            // onUnmount={onUnmount}
            // required
            center={center}
            // required
            options={options}
          />
          <Marker position={center} />
        </GoogleMap>
      )}
    </>
  );
}
