import React, { createContext, useState } from "react";

export const QueryContext = createContext([]);

export const QueryFinder = ({ children }) => {
  const [query, setQuery] = useState("this is query");
  return (
    <QueryContext.Provider
      value={[
        { query: query, setQuery: setQuery },
        setQuery,
      ]}
    >
      {children}
    </QueryContext.Provider>
  );
};
