import axios from "./axios-server";

export const axios_getStateDataFromCode = (code, callback) => {
  axios
    .get(`/state/getStateDataByCode/${code}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};


export const axios_getStateArticleData = (code, callback) => {
  axios
    .get(`/state/getStateArticleData/${code}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};

export const axios_getAllStates = (page, callback) => {
  axios
    .get(`/state/get-all-state/${page}/10`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
}

export const axios_getStateChildDataByStateCode = (regionType, code, page, callback) => {
  axios.get(`/state/getStateChildByStateCode/${regionType}/${code}/${page}/10`, {
    header: {
      "Content-Type": "applicaiton/json",
    },
  })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! something went wrong", error)
    })
}
