import React from "react";
import Sidenav from "../Sidenav";
import "./paymentowed.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import TablePaymentOwner from "./TablePaymentOwner";

class PaymentOwedOwner extends React.Component {
  render() {
    return (
      <div className="Dashboard">
        <div className="DashboardGroup">
          <div className="auction-navbar">
            <div className="dashTitle">
              <h4>Payments You Owe</h4>
            </div>
            <Sidenav />
          </div>
          <div className="row">
            <div className="auction-navbar1">
              <Sidebar />
            </div>
            <div className="DashContent pt-0 pt-lg-5" style={{marginTop:"-12px"}}>
              <div className="AuctionPage">
                <TablePaymentOwner />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentOwedOwner;
