import React, { useState, useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import "../../components/Dashboard.css";
import { NavigationContext } from "../../hooks/createNavigationContext";

const Sidebar = () => {
	const [open, setOpen] = useState(false);
	const [loading, setloading] = useState(false);
	const [activeNav, setActiveNav] = useContext(NavigationContext);

	useEffect(() => {
		setloading(false);
	}, [loading]);

	const toggle = () => {
		setOpen(!open);
	};
	const handelChange = (val) => {
		if (val === "a") {
			setActiveNav("a");
		} else if (val === "b") {
			setActiveNav("b");
		} else {
			setActiveNav("c");
		}
	};
	return (
		<div>
			{!loading && (
				<div>
					<div id="menu_button">
						<input type="checkbox" id="menu_checkbox" />
						<label
							htmlFor="menu_checkbox"
							id="menu_label"
							onClick={() => toggle()}
						>
							<div id="menu_text_bar" />
						</label>
					</div>
					<div className={"sidebar-sec" + (open ? " in" : "")}>
						<div className="logo-dashbaord">
							<NavLink to="/">
								<img
									src={require("../../Images/logo-dashboard.svg")}
									className="img-fluid"
									alt="brand"
								/>
							</NavLink>
						</div>
						<Accordion preExpanded={activeNav}>
							<AccordionItem uuid="a" onClick={() => handelChange("a")}>
								<AccordionItemHeading>
									<AccordionItemButton>Owner</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<ul className="list-group">
										<li
											className="list-group-item"
										>
											<NavLink
												className={({ isActive }) =>
													isActive ? "navbar__link active" : "navbar__link"
												}
												to="/properties"
											>
												<img
													className="nav-icon"
													alt="Properties Icon"
													src={require("../../Images/property-icon.svg")}
												/>
												<span className="sidebar-menu">Properties</span>
											</NavLink>
										</li>
										<li
											className="list-group-item list-group-item1"
										>
											<NavLink
												className={({ isActive }) =>
													isActive ? "navbar__link active" : "navbar__link"
												}
												to="/auctions"
											>
												<img
													className="nav-icon"
													alt="Properties Icon"
													src={require("../../Images/auction-icon.svg")}
												/>
												<span className="sidebar-menu">Auctions</span>
											</NavLink>
										</li>
										<li
											className="list-group-item"
										>
											<NavLink
												className={({ isActive }) =>
													isActive ? "navbar__link active" : "navbar__link"
												}
												to="/issuancesowner"
											>
												<img
													className="nav-icon"
													alt="Issuances Icon"
													src={require("../../Images/issuances-icon.svg")}
												/>
												<span className="sidebar-menu">Issuances</span>
											</NavLink>
										</li>
										<li
											className="list-group-item"
										>
											<NavLink
												className={({ isActive }) =>
													isActive ? "navbar__link active" : "navbar__link"
												}
												to="/paymentowedowner"
											>
												<img
													className="nav-icon"
													alt="Amount Due Icon"
													src={require("../../Images/amount-icon.svg")}
												/>
												<span className="sidebar-menu">Payments Owed</span>
											</NavLink>
										</li>
										<li
											className="list-group-item"
										>
											<NavLink
												className={({ isActive }) =>
													isActive ? "navbar__link active" : "navbar__link"
												}
												to="/paymentmade"
											>
												<img
													className="nav-icon"
													alt="Payments Icon"
													src={require("../../Images/payment-icon.svg")}
												/>
												<span className="sidebar-menu">Payments Made</span>
											</NavLink>
										</li>
									</ul>
								</AccordionItemPanel>
							</AccordionItem>

							<AccordionItem
								uuid="b"
								onClick={() => handelChange("b")}
							>
								<AccordionItemHeading>
									<AccordionItemButton>Investor</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<ul className="list-group">
										<li className="list-group-item">
											<NavLink
												className={({ isActive }) =>
													isActive ? "navbar__link active" : "navbar__link"
												}
												onClick={() => handelChange("b")}
												to="/propertiesinvestor"
											>
												<img
													className="nav-icon"
													alt="Properties Icon"
													src={require("../../Images/property-icon.svg")}
												/>
												<span className="sidebar-menu">Properties</span>
											</NavLink>
										</li>
										<li className="list-group-item list-group-item1">
											<NavLink
												className={({ isActive }) =>
													isActive ? "navbar__link active" : "navbar__link"
												}
												to="/auctioninvestor"
											>
												<img
													className="nav-icon"
													alt="Properties Icon"
													src={require("../../Images/auction-icon.svg")}
												/>
												<span className="sidebar-menu">Auctions</span>
											</NavLink>
										</li>
										<li className="list-group-item">
											<NavLink
												className={({ isActive }) =>
													isActive ? "navbar__link active" : "navbar__link"
												}
												to="/issuancesinvestor"
											>
												<img
													className="nav-icon"
													alt="Issuances Icon"
													src={require("../../Images/issuances-icon.svg")}
												/>
												<span className="sidebar-menu">Issuances</span>
											</NavLink>
										</li>
										<li className="list-group-item">
											<NavLink
												className={({ isActive }) =>
													isActive ? "navbar__link active" : "navbar__link"
												}
												to="/paymentowedinvestor"
											>
												<img
													className="nav-icon"
													alt="Amount Due Icon"
													src={require("../../Images/amount-icon.svg")}
												/>
												<span className="sidebar-menu">Payments Owed</span>
											</NavLink>
										</li>
										<li className="list-group-item">
											<NavLink
												className={({ isActive }) =>
													isActive ? "navbar__link active" : "navbar__link"
												}
												to="/paymentsinvestor"
											>
												<img
													className="nav-icon"
													alt="Payments Received Icon"
													src={require("../../Images/payments-rec-icon.svg")}
												/>
												<span className="sidebar-menu">Payments Received</span>
											</NavLink>
										</li>
									</ul>
								</AccordionItemPanel>
							</AccordionItem>

							<AccordionItem uuid="c" onClick={() => handelChange("c")}>
								<AccordionItemHeading>
									<AccordionItemButton>Account</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<ul className="list-group">
										<li className="list-group-item">
											<NavLink
												className={({ isActive }) =>
													isActive ? "navbar__link active" : "navbar__link"
												}
												to="/profile"
											>
												<img
													className="nav-icon"
													alt="Profiles Icon"
													src={require("../../Images/profiles-icon.svg")}
												/>
												<span className="sidebar-menu">My Profile</span>
											</NavLink>
										</li>
										<li className="list-group-item">
											<NavLink
												className={({ isActive }) =>
													isActive ? "navbar__link active" : "navbar__link"
												}
												to="/identity"
											>
												<img
													className="nav-icon"
													alt="Confirm Address Icon"
													src={require("../../Images/confirmadd-icon.svg")}
												/>
												<span className="sidebar-menu">Identity</span>
											</NavLink>
										</li>
										<li className="list-group-item">
											<NavLink
												className={({ isActive }) =>
													isActive ? "navbar__link active" : "navbar__link"
												}
												to="/accreditation"
											>
												<img
													className="nav-icon"
													alt="Confirm Address Icon"
													src={require("../../Images/verify-accreditation.svg")}
												/>
												<span className="sidebar-menu">Accreditation</span>
											</NavLink>
										</li>
										<li className="list-group-item">
											<NavLink
												className={({ isActive }) =>
													isActive ? "navbar__link active" : "navbar__link"
												}
												to="/taxinfo"
											>
												<img
													className="nav-icon"
													alt="Confirm Address Icon"
													src={require("../../Images/tax-information.svg")}
												/>
												<span className="sidebar-menu">Tax Information</span>
											</NavLink>
										</li>
										<li className="list-group-item">
											<NavLink
												className={({ isActive }) =>
													isActive ? "navbar__link active" : "navbar__link"
												}
												to="/balance"
											>
												<img
													className="nav-icon"
													alt="Confirm Address Icon"
													src={require("../../Images/balance.svg")}
												/>
												<span className="sidebar-menu">Balance</span>
											</NavLink>
										</li>
									</ul>
								</AccordionItemPanel>
							</AccordionItem>
						</Accordion>
						<Link className="logout-btn" to="/logout">
							<div className="log-link">
								<span>
									<img
										className="log-icon"
										alt="Log Out Icon"
										src={require("../../Images/logout-icon.svg")}
									/>
								</span>
								<span>Log Out</span>
							</div>
						</Link>
					</div>
				</div>
			)}
		</div>
	);
};

export default Sidebar;
