import React from "react";
import "../Dashboard.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
// import * as atypes from "../../store/actions/types";
import { connect } from "react-redux";
import { loadUser, startLoading, logout } from "../../store/actions/auth";
import { saveProperty } from "../../store/actions/property";
import { axios_searchPropertyByAddressZipcode } from "../../utills/property-routes";
import store from "../../store/store";
// import { BrowserRouter as Router, Link } from "react-router-dom";
import { setToast } from "../../store/actions/toast";
import Toast from "../../components/layout/Toast";
import { ToastProvider } from "react-toast-notifications";
// import { useDispatch } from "react-redux";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { delayedSubmit } from "../../store/reducers/helper";
import Head from "../../Component/Head";
// import { Tabs, Tab } from "react-bootstrap-tabs";
import "../../Component/SearchProperty/searchproperty.css";
import "../properties/addProperty.css";

const currentFilter = (
  candidate: { label: string, value: string, data: any },
  input: string
) => {
  return candidate.data.value.types.includes("street_address") ||
    candidate.data.value.types.includes("premise")
    ? true
    : false;
  return true;
};

const filterOption2 = (
  candidate: { label: string, value: string, data: any },
  input: string
) => {
  // const tab = "region";
  return candidate.data.value.types.includes("locality") ||
    candidate.data.value.types.includes("postal_code") ||
    candidate.data.value.types.includes("sublocality") ||
    candidate.data.value.types.includes("postal_code") ||
    candidate.data.value.types.includes("neighborhood") ||
    candidate.data.value.types.includes("administrative_area_level_1") ||
    candidate.data.value.types.includes("administrative_area_level_2") ||
    candidate.data.value.types.includes("administrative_area_level_3")
    ? true
    : false;
  return true;
};

class AddProperty extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      isVisible: false,
      isSubmitted: false,
      submitting: false,
      property_type: "",
      mortage_balance: "",
      desired_cash: "",
      estimated_property_value: "",
      property_address: "",
      property_address_parts: "",
      builtup_area: "",
      bathrooms: "",
      bedrooms: "",
      address: "",
      title: "",
      lat: "",
      lng: "",
      uploaded_photos: [],
      draggingItem: null,
      dragOverItem: null,
      tab: "Property",
    };
    this.selectPhoto = this.selectPhoto.bind(this);
    this.removeIt = this.removeIt.bind(this);
  }

  placeholderHandler = () => {
    const DOM_Placholder = document.querySelector(
      ".addressfilter__single-value"
    );
    let text = "Enter property address...";
    if (DOM_Placholder) {
      DOM_Placholder.innerText = text;
    }
  };
  componentDidMount() {
    store.dispatch(startLoading());
    store.dispatch(loadUser());
    this.placeholderHandler();
  }
  openProperty() {
    this.setState({
      isVisible: true,
      isSubmitted: true,
    });
  }
  findproperties() { }
  setAddress(val, onChangeStateUpdater) {
    // results[0].formatted_address
    if (typeof val != "undefined") {
      /*geocodeByPlaceId(val.value.place_id)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          this.setState({ lat: lat });
          this.setState({ lng: lng });
        });*/
      geocodeByPlaceId(val.value.place_id).then(function (results) {
        var address = [];
        var zipcode = "";
        var city = "";
        var state = "";
        onChangeStateUpdater(results[0].formatted_address);
        for (var i = 0; i < results[0].address_components.length; i++) {
          var v = results[0].address_components[i];
          if (
            v.types.includes("premise") ||
            v.types.includes("street_number") ||
            v.types.includes("route") ||
            v.types.includes("locality")
          ) {
            address.push(v.long_name);
          }
          if (v.types.includes("postal_code")) {
            zipcode = v.long_name;
          }
          if (v.types.includes("administrative_area_level_1")) {
            state = v.long_name;
          }
        }
        /*results[0].address_components.each(function(i,v){
                                        if(v.types.includes('street_number') || v.types.includes('route') || v.types.includes('locality') ){
                                            address.push(v.long_name);
                                        }
                                        if(v.types.includes('postal_code') ){
                                            zipcode = v.long_name;
                                        }
                                        if(v.types.includes('administrative_area_level_1')){
                                            state = v.long_name;
                                        }
                                    });
                                    ;*/
        axios_searchPropertyByAddressZipcode(address.join(", "), zipcode);
      });

      this.setState({ property_address: val.label });
      // axios_searchPropertyByAddressZipcode();
    }
  }
  filterResult() { }
  addressinfo() { }
  basicInfo() {
    if (this.state.property_address !== "") {
      this.setState({ submitting: true });
      delayedSubmit(500).then(() => {
        store
          .dispatch(
            saveProperty(
              "",
              0,
              0,
              0,
              this.state.property_address,
              0,
              0,
              0,
              this.state.lat,
              this.state.lng,
              ""
            )
          )
          .then((res) => { })
          .catch((err) => {
            this.setState({ submitting: false });
          });
      });
    } else {
      if (this.state.property_address === "") {
        this.props.setToast([
          { msg: "Please provide Property Address", type: "error" },
        ]);
      }
    }
  }
  propertyCost() {
    if (
      this.state.mortage_balance !== "" &&
      this.state.desired_cash !== "" &&
      this.state.estimated_property_value !== ""
    ) {
      this.setState({ isSubmitted: !this.state.isSubmitted });
    } else {
      if (this.state.mortage_balance === "") {
        this.props.setToast([
          { msg: "Please provide Mortage Balance", type: "error" },
        ]);
      } else if (this.state.desired_cash === "") {
        this.props.setToast([
          { msg: "Please provide Desired Cash", type: "error" },
        ]);
      } else if (this.state.estimated_property_value === "") {
        this.props.setToast([
          { msg: "Please provide Estimated Property Value", type: "error" },
        ]);
      }
    }
  }
  property_address() {
    this.setState({ submitting: true });

    if (
      this.state.builtup_area !== "" &&
      this.state.bathrooms !== "" &&
      this.state.bedrooms !== ""
    ) {
      // we need to send data to server
      /*delayedSubmit(500).then(()=>{
      store
        .dispatch(
          saveProperty(
            this.state.property_type,
            this.state.mortage_balance,
            this.state.desired_cash,
            this.state.estimated_property_value,
            this.state.property_address,
            this.state.builtup_area,
            this.state.bathrooms,
            this.state.bedrooms,
            this.state.lat,
            this.state.lng,
                                                this.state.uploaded_photos
          )
        )
        .then((res) => {

        })
        .catch((err) => {
          this.setState({submitting:false})
        }); 
                                }) */
    } else {
      console.log(this.state.submitting);
      this.setState({ submitting: false });
      console.log(this.state.submitting);
      if (this.state.builtup_area === "") {
        this.props.setToast([
          { msg: "Please provide Built Up Area", type: "error" },
        ]);
      } else if (this.state.bathrooms === "") {
        this.props.setToast([
          { msg: "Please provide bathrooms", type: "error" },
        ]);
      } else if (this.state.bedrooms === "") {
        this.props.setToast([
          { msg: "Please provide bedrooms", type: "error" },
        ]);
      }
    }
  }
  selectPhoto(e) {
    this.refs.fileUploader.click();
  }
  displayPhotos(event) {
    var that = this;
    var files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        if (fileReader.readyState === 2) {
          var uploaded_photos = that.state.uploaded_photos;
          uploaded_photos.push(fileReader.result);
          that.setState({ uploaded_photos: uploaded_photos });
        }
      };
      fileReader.readAsDataURL(files[i]);
    }
  }
  removeIt(i) {
    var uploaded_photos = this.state.uploaded_photos;
    uploaded_photos.splice(i, 1);
    this.setState({ uploaded_photos: uploaded_photos });
  }
  dragEnd(e, k) {
    var dragOverItem = k;
    var uploaded_photos = this.state.uploaded_photos;
    const listCopy = [...uploaded_photos];
    const draggingItemContent = listCopy[this.state.draggingItem];
    listCopy.splice(this.state.draggingItem, 1);
    listCopy.splice(dragOverItem, 0, draggingItemContent);
    this.setState({ draggingItem: dragOverItem });
    this.setState({ uploaded_photos: listCopy });
  }
  dragStart(e, k) {
    this.setState({ draggingItem: k });
  }
  render() {
    const address = this.state.address;
    // const setAddress = this.setAddress();
    // const filterResult = this.filterResult();
    // const tabs = [
    //   {
    //     label: "Property",
    //   },
    //   {
    //     label: "Region",
    //   },
    // ];
    const currentTabFilter =
      this.state.tab === "Property" ? currentFilter : filterOption2;

    const filterOptionss = (
      candidate: { label: string, value: string, data: any },
      input: string
    ) => {
      return candidate.data.value.types.includes("street_address") ||
        candidate.data.value.types.includes("premise")
        ? true
        : false;
      return true;
    };

    const getBlur = async () => {
      const DOM_Placholder = document.querySelector(
        ".addressfilter__single-value"
      );
      console.log("placeholder ele is : ", DOM_Placholder);
      let text = "Enter property address...";

      if (DOM_Placholder) {
        DOM_Placholder.innerText = text;
      }
      // setblur(true);
    };
    const offBlur = async () => {
      const DOM_Placholder = document.querySelector(
        ".addressfilter__single-value"
      );
      console.log("placeholder ele is : ", DOM_Placholder);
      let text = "Enter property address...";

      if (DOM_Placholder) {
        DOM_Placholder.innerText = text;
      }
      // setblur(false);
    };

    const datahandler = async (val) => {
      const check = val.length > 0 && this.setState({ search: val });

      return check;
    };

    const onChangeStateUpdater = async (val) => {
      this.setState({ search: val });
    };

    return (
      <div className="Dashboard" id="Dashboard">
        <Head title={`Exuity | Claim a property as yours`} />
        <div className="DashboardGroup">
          <div className="row">
            <div className="dashTitle-2">
              <h4>Claim a Property</h4>
            </div>
            <div className="">
              <Sidebar />
            </div>
            <div className="DashContent">
              {this.props.user ? (
                <div className="AddProperty">
                  <ToastProvider>
                    <Toast />
                  </ToastProvider>
                  {this.state.isVisible ? (
                    this.state.isSubmitted ? (
                      <div className="AddPropertyGroup">
                        <div className="AddProperty-ls">
                          <div className="AddProperty-t">
                            <h5>
                              Hi, {this.props.user.first_name}! Let us know
                              which property you own that you'd like to consider
                              getting cash out of.
                            </h5>
                          </div>
                          <div className="AddProperty-steps">
                            <ul>
                              <li className="active-tick">Basic Info</li>
                              <li className="active-tick">Property Cost</li>
                              <li className="active">Property Area</li>
                            </ul>
                          </div>
                          <div className="AddProperty-illustration">
                            <img
                              src={require("../../Images/AddProperty-illustration.png")}
                              alt="Add Property Home Illustration"
                            />
                          </div>
                        </div>
                        <div className="AddProperty-rs">
                          <div className="AddProperty-rs-Group">
                            <div className="AddProperty-form">
                              <ul className="AddProperty-inputs">
                                <li>
                                  <label className="input-label">
                                    Built Up Area
                                  </label>
                                  <input
                                    type="number"
                                    name="Area"
                                    placeholder="sq.ft."
                                    value={this.state.builtup_area}
                                    onChange={(e) =>
                                      this.setState({
                                        builtup_area: e.target.value,
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <label className="input-label">
                                    Bedrooms
                                  </label>
                                  <input
                                    type="number"
                                    name="bedrooms"
                                    placeholder="1"
                                    value={this.state.bedrooms}
                                    onChange={(e) =>
                                      this.setState({
                                        bedrooms: e.target.value,
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <label className="input-label">
                                    Bathrooms
                                  </label>
                                  <input
                                    type="number"
                                    name="bathrooms"
                                    placeholder="1"
                                    value={this.state.bathrooms}
                                    onChange={(e) =>
                                      this.setState({
                                        bathrooms: e.target.value,
                                      })
                                    }
                                  />
                                </li>
                              </ul>
                            </div>
                            <div className="Ap-submitted-btn">
                              <button
                                onClick={() => this.property_address()}
                                readOnly={this.state.submitting}
                              >
                                {this.state.submitting
                                  ? "Please Wait..."
                                  : "Add Property"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="AddPropertyGroup">
                        <div className="AddProperty-ls">
                          <div className="AddProperty-t">
                            <h5>
                              Hi, {this.props.user.first_name}! Let’s get
                              started listing your space.
                            </h5>
                          </div>
                          <div className="AddProperty-steps">
                            <ul>
                              <li className="active-tick">Basic Info</li>
                              <li className="active">Property Cost</li>
                              <li>Property Area</li>
                            </ul>
                          </div>
                          <div className="AddProperty-illustration">
                            <img
                              src={require("../../Images/AddProperty-illustration.png")}
                              alt="Add Property Home Illustration"
                            />
                          </div>
                        </div>
                        <div className="AddProperty-rs">
                          <div className="AddProperty-rs-Group">
                            <div className="AddProperty-form">
                              <ul className="AddProperty-inputs">
                                <li>
                                  <label className="input-label">
                                    Mortage Balance ($)
                                  </label>
                                  <input
                                    type="number"
                                    name="balance"
                                    placeholder="0.00"
                                    value={this.state.mortage_balance}
                                    onChange={(e) =>
                                      this.setState({
                                        mortage_balance: e.target.value,
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <label className="input-label">
                                    Desired Cash ($)
                                  </label>
                                  <input
                                    type="number"
                                    name="Cash"
                                    placeholder="0.00"
                                    value={this.state.desired_cash}
                                    onChange={(e) =>
                                      this.setState({
                                        desired_cash: e.target.value,
                                      })
                                    }
                                  />
                                </li>
                                <li>
                                  <label className="input-label">
                                    Estimated Property Value ($)
                                  </label>
                                  <input
                                    type="number"
                                    name="Value"
                                    placeholder="0.00"
                                    value={this.state.estimated_property_value}
                                    onChange={(e) =>
                                      this.setState({
                                        estimated_property_value:
                                          e.target.value,
                                      })
                                    }
                                  />
                                </li>
                              </ul>
                            </div>
                            <div className="Ap-submitted-btn">
                              <button onClick={() => this.propertyCost()}>
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="AddPropertyGroup">
                      <div className="AddProperty-ls">
                        <div className="AddProperty-t">
                          <h5>
                            Hi, {this.props.user.first_name}! Let us know which
                            property you own that you'd like to consider getting
                            cash out of.
                          </h5>
                        </div>

                        <div className="AddProperty-illustration">
                          <img
                            src={require("../../Images/AddProperty-illustration.png")}
                            alt="Add Property Home Illustration"
                          />
                        </div>
                      </div>
                      <div className="AddProperty-rs align-items-start">
                        <div className="AddProperty-rs-Group">
                          <div className="AddProperty-form">
                            <ul className="AddProperty-inputs">
                              <li>
                                <label className="input-label">
                                  Property Address
                                </label>
                                <div className="add_property_tabs">
                                  <GooglePlacesAutocomplete
                                    apiKey="AIzaSyAAkHqRzQUrkvifRENNrC3kKSRwdhX6_Qc&libraries"
                                    selectProps={{
                                      // isClearable:true,
                                      inputValue: this.state.search,
                                      onInputChange: (e) => datahandler(e),

                                      placeholder: "Enter property address...",
                                      address,
                                      onChange: (o) => {
                                        this.setAddress(
                                          o,
                                          onChangeStateUpdater
                                        );
                                      },
                                      components: {
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                      },
                                      filterOption: filterOptionss,
                                      onFocus: offBlur,
                                      onBlur: getBlur,
                                      onKeyDown: (e) => {
                                        const key = e.key;

                                        if (
                                          key === "Backspace" &&
                                          this.state.search.length === 1
                                        ) {
                                          this.setState({ search: "" });
                                        }
                                      },
                                    }}
                                    autocompletionRequest={{
                                      componentRestrictions: {
                                        country: ["usa"],
                                      },
                                    }}
                                  />
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="Ap-submitted-btn text-left pt-4">
                            <button onClick={() => this.basicInfo()}>
                              Claim Property
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
});
export default connect(mapStatetoProps, {
  loadUser,
  startLoading,
  logout,
  setToast,
  saveProperty,
})(AddProperty);
