import React from "react";
import Sidenav from "../Sidenav";
import "./investmentreceived.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import TableInvestmentsOwner from "./TableInvestmentsOwner";

class InvestmentsReceivedOwner extends React.Component {
	render() {
		return (
			<div className="Dashboard">
				<div className="DashboardGroup">
					<div className="auction-navbar">
						<div className="dashTitle">
							<h4>Investments Received</h4>
						</div>
						<Sidenav />
					</div>
					<div className="row">
						<div className="auction-navbar1">
							<Sidebar />
						</div>
						<div className="DashContent">
							<div className="AuctionPage">
								<TableInvestmentsOwner />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default InvestmentsReceivedOwner;
