import React from "react";
import { useDropzone } from "react-dropzone";
import FontAwesomeIcon from "react-fontawesome";
import { Image_URL } from "../../config/api";
import { onClose } from "../../utills/remove-docs";
import "../../Page/Accreditation/accreditation.css";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  height: 200,
  padding: 4,
  boxSizing: "border-box",
  position: "relative",
  width: 200,
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
const xC = {
  position: "absolute",
  top: 0,
  right: 0,
  color: "white",
  fontWeight: "bold",
  fontSize: 20,
  border: "1px solid grey",
  cursor: "pointer",
  padding: 5,
  margin: 5,
  background: "grey",
};

const UploadImages = ({
  files,
  setFiles,
  setNewFiles,
  newFiles,
  oldFiles,
  setOldFiles,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) =>
        [...prevFiles].concat(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        )
      );
      setNewFiles((prevFiles) =>
        [...prevFiles].concat(
          acceptedFiles.map((e) => e.name.replace(/\s/g, ""))
        )
      );
    },
  });

  const thumbs =
    files &&
    files.map((file) => (
      <div style={thumb} key={file && file.name}>
        <div style={thumbInner}>
          <img src={file && file.preview} style={img} alt="thumbnail Inner" />
        </div>
        <div onClick={() => onClose(files, file, setFiles)} style={xC}>
          x
        </div>
      </div>
    ));

  const thumbs2 =
    oldFiles &&
    oldFiles.map((file) => {
      var property_img_2 = file;
      if (Image_URL === "http://localhost:4000") {
        // const property_img_url = file && file.slice(1)
        // property_img_2 = property_img_url && property_img_url.slice(0,-1)
        // if()

        //console.log("getting property images2 by Raja", property_img_2);
        // if(file.charAt(0) === "\") {
        //  const file2 =   property_img_2 && property_img_2.slice(1)
        //  const file3 =   file2 && file2.slice(1)
        //  const file4 = file3 && file3.slice(0,-1)
        //  const file5 = file4 && file4.slice(0,-1)
        //  property_img_2 = file5
        // }
      } else {
        property_img_2 = file;
      }

      return (
        <div style={thumb} key={file && 'img-' + file.name}>
          <div style={thumbInner}>
            <img
              src={`${Image_URL}/${property_img_2}`}
              style={img}
              alt="Property Img"
            />
          </div>
          <div onClick={() => onClose(oldFiles, file, setOldFiles)} style={xC}>
            x
          </div>
        </div>
      );
    });

  console.log(files);
  return (
    <section className="container text-center">
      <div className="row justify-content-center text-center align-items-center">
        <div className="col-lg-12">
          <div
            style={{ width: "100%" }}
            className="main-upload mt-3 text-center"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div className="uploading mt-5 text-center">
              <FontAwesomeIcon size={"3x"} name="fa-solid fa-upload" />
              <h6 className="mt-3 font-weight-bold">
                {" "}
                Upload Property Images or drag Images here
              </h6>
            </div>
          </div>
        </div>
      </div>

      <aside style={thumbsContainer}>{thumbs}</aside>
      {oldFiles && oldFiles.length > 0 && (
        <aside style={thumbsContainer}>{thumbs2}</aside>
      )}
    </section>
  );
};

export default UploadImages;
