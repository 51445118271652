import React, { useEffect, useState } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SyncLoader from "react-spinners/SyncLoader";

import geo2zip from "geo2zip";
import zipcodes from "zipcodes";
import { SET_TOAST } from "../store/actions/types";
import PropertyRegionStreetView from "./PropertyRegionStreetView";
import { axios_getHpiFromCityState } from "../utills/hpi-routes";
import PropertyRegionMap from "./PropertyRegionMap";
import { axios_getPropertyFromAddressZip } from "../utills/coreLogic-routes";
import { axios_createInvestorProperty, axios_createOwnerProperty } from "../utills/property-routes";
import PropertyRegionChart from "../Component/PropertyRegionChart/PropertyRegionChart";

import "./PropertyRegionSearch.css";

export default function HomePageSearchBar({ defaultActiveTab }) {
  const [address, setAddress] = useState("");
  const [show, setshow] = useState(false);
  const [isShimmers, setIsShimmers] = useState(true);
  const [hpiData, setHpiData] = useState();
  const [placeId, setId] = useState();
  const dispatch = useDispatch();
  const [focs, setfocs] = useState(false);

  const [corelogicData, setCorelogicData] = useState({
    area: 0,
    bedrooms: 0,
    bathrooms: 0,
    clip: 0,
    value: 0
  });

  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null
  });
  const userData = useSelector((state) => state.auth);
  useEffect(() => {}, [userData, focs]);
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    console.log(results[0], " res");
    setId(results[0].place_id);
    const latLng = await getLatLng(results[0]);

    setAddress(value);
    setCoordinates(latLng);
    setshow(true);
    setIsShimmers(true);
    const closestZip = await geo2zip(latLng);

    axios_getPropertyFromAddressZip(
      results[0].formatted_address,
      closestZip[0],
      getClipCallback,
      getClipErrorCallback
    );

    var area = zipcodes.lookup(closestZip[0]);
    const citystatedata = {
      city: area.city,
      state: area.state
    };
    axios_getHpiFromCityState(citystatedata, zipCityDataCallback);
  };
  const getClipErrorCallback = (error) => {
    setIsShimmers(false);
  };

  const getClipCallback = (response) => {
    if (response.status === 200) {
      var data = {
        clip: response.data.property.buildings.data.clip,
        value: response.data.property.taxAssessment.items[0].assessedValue.calculatedTotalValue,
        area:
          response.data.property.buildings.data.allBuildingsSummary.totalAreaSquareFeet !== null
            ? response.data.property.buildings.data.allBuildingsSummary.totalAreaSquareFeet
            : 0,
        bedrooms:
          response.data.property.buildings.data.allBuildingsSummary.bedroomsCount !== null
            ? response.data.property.buildings.data.allBuildingsSummary.bedroomsCount
            : 0,
        bathrooms:
          response.data.property.buildings.data.allBuildingsSummary.bathroomsCount !== null
            ? response.data.property.buildings.data.allBuildingsSummary.bathroomsCount
            : 0
      };
      setCorelogicData(data);
      setIsShimmers(false);
    } else {
      setIsShimmers(false);
    }
  };

  const zipCityDataCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setHpiData(response.data.data);
        setIsShimmers(false);
      }
    } else {
      setIsShimmers(false);
    }
  };
  const handleClose = () => setshow(false);
  const CreatePropertyCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        dispatch({
          type: SET_TOAST,
          payload: [{ msg: "Property Added Successfully.", type: "success" }]
        });
        if (response.data.meta === "Owners") {
          setTimeout(() => {
            window.location.href = "/properties";
          }, 0);
        } else if (response.data.meta === "Investors") {
          setTimeout(() => {
            window.location.href = "/propertiesinvestor";
          }, 0);
        }
      }
    }
  };

  const modalClickHandlerBtn = (clickType, setfocs) => {
    document.getElementById("closePropertyModal").click();
    setfocs(true);
    if (clickType === "Owner") {
      const OwnerPropertyData = {
        owner_id: userData.user && userData.user.id,
        property_address: address,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        bedrooms: corelogicData.bedrooms,
        bathrooms: corelogicData.bathrooms,
        builtup_area: corelogicData.area,
        google_placeid: placeId,
        corelogic_placeid: corelogicData.clip,
        estimated_property_value: corelogicData.value,
        token: localStorage.getItem("userToken")
      };
      axios_createOwnerProperty(OwnerPropertyData, CreatePropertyCallback);
    } else if (clickType === "Investor") {
      setfocs(true);
      const InvestorsPropertyData = {
        user_id: userData.user && userData.user.id,
        property_address: address,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        bedrooms: corelogicData.bedrooms,
        bathrooms: corelogicData.bathrooms,
        builtup_area: corelogicData.area,
        google_placeid: placeId,
        corelogic_placeid: corelogicData.clip,
        token: localStorage.getItem("userToken")
      };
      axios_createInvestorProperty(InvestorsPropertyData, CreatePropertyCallback);
    }
  };

  let propertySearchType;
  if (defaultActiveTab === "Address") {
    propertySearchType = ["street_address", "premise"];
  } else {
    propertySearchType = [
      "locality",
      "postal_code",
      "sublocality",
      "neighborhood",
      "administrative_area_level_1" || "administrative_area_level_2"
    ];
  }

  return (
    <div>
      <Modal show={show} onHide={handleClose} animation={false}>
        <div
          className="modal-dialog property-model modal-lg modal-wrapper modal-dialog-scrollable"
          role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6 map-wrapper">
                  {defaultActiveTab === "Address" ? (
                    <PropertyRegionStreetView
                      lat={coordinates.lat}
                      lng={coordinates.lng}
                      height={"40vh"}
                    />
                  ) : (
                    <PropertyRegionMap
                      lat={coordinates.lat}
                      lng={coordinates.lng}
                      height={"100%"}
                    />
                  )}
                </div>
                <div className="col-lg-6 modals-body-content">
                  <div className="property-model-header">
                    <h4 className="mb-3 dialog-heading">{address}</h4>
                    <Modal.Header closeButton></Modal.Header>
                  </div>

                  {hpiData && hpiData.url && (
                    <p className="mb-2 price-index-link">
                      Price Index:{" "}
                      <Link to={`/hpis/${hpiData.slug}`} rel="noopener noreferrer">
                        {hpiData.name}
                      </Link>
                    </p>
                  )}

                  <>
                    {isShimmers ? (
                      <>
                        <div className="text-center my-5">
                          <SyncLoader color={"#3864e4"} loading={isShimmers} />
                        </div>
                      </>
                    ) : (
                      <>
                        <p className="mb-2 price-index-link">
                          {corelogicData.bedrooms !== "" && corelogicData.bedrooms !== 0 && (
                            <>
                              {corelogicData.bedrooms}{" "}
                              {corelogicData.bedrooms > 1 ? "bedrooms" : "bedroom"} /{" "}
                            </>
                          )}
                          {corelogicData.bathrooms !== "" && corelogicData.bathrooms !== 0 && (
                            <>
                              {corelogicData.bathrooms}{" "}
                              {corelogicData.bathrooms > 1 ? "bathrooms" : "bathroom"} /{" "}
                            </>
                          )}
                          {corelogicData.bathrooms !== "" && corelogicData.bathrooms !== 0 && (
                            <>
                              {corelogicData.bathrooms}{" "}
                              {corelogicData.bathrooms > 1 ? "bathrooms" : "bathroom"} /{" "}
                            </>
                          )}
                          {corelogicData.bathrooms !== "" && corelogicData.bathrooms !== 0 && (
                            <>
                              {corelogicData.bathrooms}{" "}
                              {corelogicData.bathrooms > 1 ? "bathrooms" : "bathroom"} /{" "}
                            </>
                          )}
                          {corelogicData.area !== "" && corelogicData.area !== 0 && (
                            <>{corelogicData.area} sq. ft.</>
                          )}
                        </p>
                        <p className="mb-2 price-index-link">
                          {corelogicData.value !== "" && corelogicData.value !== 0 && (
                            <>
                              {" "}
                              Estimated value : $
                              {corelogicData.value.toLocaleString(undefined, {
                                maximumFractionDigits: 2
                              })}
                            </>
                          )}
                        </p>

                        <div className="mt-4">
                          <PropertyRegionChart hpiData={hpiData && hpiData.hpi_values} />
                        </div>
                      </>
                    )}
                  </>
                  <div className="modal-button-wrapper">
                    <button
                      type="button"
                      className="btn btn-secondary d-none"
                      data-dismiss="modal"
                      id="closePropertyModal">
                      Close
                    </button>
                    {defaultActiveTab === "Address" && (
                      <div className="addPropert-btn">
                        <Link
                          to={{
                            pathname: `${userData.isAuthenticated ? "" : "/signup"}`,
                            state: {
                              cardType: "Owner",
                              addressSignupData: {
                                searchTab: defaultActiveTab === "Address" ? "property" : "region",
                                address,
                                lat: coordinates.lat,
                                lng: coordinates.lng,
                                placeId
                              }
                            }
                          }}
                          onClick={(e) => modalClickHandlerBtn("Owner", setfocs)}>
                          <button type="button bg-dark">
                            <span>
                              <svg
                                className="btn-plusIcon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="12px"
                                height="12px">
                                <path
                                  opacity="0.749"
                                  fill="rgb(255, 255, 255)"
                                  d="M11.000,7.000 L7.000,7.000 L7.000,11.000 C7.000,11.552 6.552,12.000 6.000,12.000 C5.448,12.000 5.000,11.552 5.000,11.000 L5.000,7.000 L1.000,7.000 C0.448,7.000 -0.000,6.552 -0.000,6.000 C-0.000,5.448 0.448,5.000 1.000,5.000 L5.000,5.000 L5.000,1.000 C5.000,0.448 5.448,-0.000 6.000,-0.000 C6.552,-0.000 7.000,0.448 7.000,1.000 L7.000,5.000 L11.000,5.000 C11.552,5.000 12.000,5.448 12.000,6.000 C12.000,6.552 11.552,7.000 11.000,7.000 Z"></path>
                              </svg>
                            </span>
                            I own {address && address.substr(0, address.indexOf(","))}
                          </button>
                        </Link>
                      </div>
                    )}

                    <div className="addPropert-btn">
                      <Link
                        to={{
                          pathname: `${userData.isAuthenticated ? "" : "/signup"}`,
                          state: {
                            cardType: "Investor",
                            addressSignupData: {
                              searchTab: defaultActiveTab === "Address" ? "property" : "region",
                              address,
                              lat: coordinates.lat,
                              lng: coordinates.lng,
                              placeId
                            }
                          }
                        }}
                        onClick={(e) => modalClickHandlerBtn("Investor", setfocs)}>
                        <button type="button">
                          <span>
                            <svg
                              className="btn-plusIcon"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16px"
                              height="16px">
                              <path
                                opacity="0.749"
                                fill="rgb(255, 255, 255)"
                                d="M11.000,7.000 L7.000,7.000 L7.000,11.000 C7.000,11.552 6.552,12.000 6.000,12.000 C5.448,12.000 5.000,11.552 5.000,11.000 L5.000,7.000 L1.000,7.000 C0.448,7.000 -0.000,6.552 -0.000,6.000 C-0.000,5.448 0.448,5.000 1.000,5.000 L5.000,5.000 L5.000,1.000 C5.000,0.448 5.448,-0.000 6.000,-0.000 C6.552,-0.000 7.000,0.448 7.000,1.000 L7.000,5.000 L11.000,5.000 C11.552,5.000 12.000,5.448 12.000,6.000 C12.000,6.552 11.552,7.000 11.000,7.000 Z"></path>
                            </svg>
                          </span>
                          I want to invest in {address && address.substr(0, address.indexOf(","))}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
        searchOptions={{
          strictBounds: false,
          types: propertySearchType,
          componentRestrictions: {
            country: ["usa"]
          }
        }}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div id="preload" style={{ position: "relative" }}>
            <img src={require("../Images/region.svg")} alt="Region" />
            <input
              style={{
                padding: 15,
                width: "100%",
                height: 74,
                border: 0,
                fontFamily: "CircularStd",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "22px",
                background: "#FFFFFF",
                outline: "none",
                boxShadow: "0px 6px 24px rgb(0 0 0 / 6%)",
                borderRadius: "0 10px 10px 10px"
              }}
              className={defaultActiveTab === "Address" ? "property_address_input" : "region_input"}
              {...getInputProps({
                placeholder: defaultActiveTab === "Address" ? "Address?" : "Where in the US?"
              })}
            />
            {address && (
              <div
                className="search-cross"
                onClick={() => setAddress("")}
                style={{
                  position: "absolute",
                  top: 15,
                  right: 17,
                  fontSize: 27,
                  cursor: "pointer"
                }}>
                <span>x</span>
              </div>
            )}

            <div className="search-dropdowns">
              {loading ? (
                <div className="search-loading-state">
                  <div className="search-loading-text">Loading...</div>
                  <div className="spinning-loader">
                    <img src={require("../Images/spinning-loader.gif")} alt="Spinner" />
                  </div>
                </div>
              ) : null}

              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? "#deebff" : "#fff",
                  padding: "8px",
                  margin: "8px",
                  width: "100%",
                  height: 50,
                  fontFamily: "Circular Std Book",
                  marginBottom: 5,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  cursor: "pointer",
                  border: 1,
                  borderRadius: "10px",
                  borderColor: "grey"
                };

                return (
                  <div {...getSuggestionItemProps(suggestion, { style })}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}
