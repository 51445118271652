import React from "react";
import Sidenav from "../Sidenav";
import "./investmentreceived.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import TableInvestmentsInvestor from "./TableInvestmentsInvestor";
import Head from "../../Component/Head";

class PaymentsInvestor extends React.Component {
	render() {
		return (
			<div className="Dashboard">
				<Head title={`Exuity | Investor | Payments Received`} />
				<div className="DashboardGroup">
					<div className="auction-navbar">
						<div className="dashTitle">
							<h4>Payments You've Received</h4>
						</div>
						<Sidenav />
					</div>
					<div className="row">
						<div className="auction-navbar1">
							<Sidebar />
						</div>
						<div className="DashContent pt-0 pt-lg-5">
							<div className="AuctionPage">
								<TableInvestmentsInvestor />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PaymentsInvestor;
