import React, { useEffect, useState } from "react";
import { axios_getIssuanceIParticipated } from "../../utills/issuance-routes";
import { useTable, usePagination } from "react-table";
import moment from "moment";
import "./issuances.css";
import {
  str_into_float_num,
  str_into_num,
} from "../../utills/string-into-number";
import { Redirect } from "react-router-dom";
import { URL_PROPERTY } from "../../config/propertyImagesUrl";

export default function TableInvestorIssuances() {
  const [tableData, setTableData] = useState([]);
  const [redirect, setredirect] = useState(null);
  const [dataFound, setDataFound] = useState(0);

  useEffect(() => {
    setDataFound(1);
    axios_getIssuanceIParticipated(getIssuanceInvesterCallback);
  }, []);

  const getIssuanceInvesterCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setTableData(response.data.data);
        if (response.data.data.length > 0) {
          setDataFound(2);
        } else {
          setDataFound(0);
        }
      }
    } else {
      console.log("Oops Something went wrong", response);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Property",
        accessor: "issuance.auction.property.property_address",
        Cell: (row) => {
          return (
            <div className="property-col dash-tbl-col">
              <img
                width={50}
                height={50}
                style={{ objectFit: "cover" }}
                src={URL_PROPERTY(
                  row &&
                    row.row &&
                    row.row.original &&
                    row.row.original.issuance.auction.property.images2
                )}
                alt="Auction img"
              />
              <h6>{row.value}</h6>
            </div>
          );
        },
      },
      {
        Header: "Amount Invested",
        accessor: "amount_invested",
        Cell: (row) => {
          return <>${str_into_float_num(row.value)}</>;
        },
      },
      {
        Header: "Effective Date",
        accessor: "created_at",
        Cell: (row) => {
          return ` ${
            moment(row.value).isValid()
              ? moment(row.value).format("MMM DD, YYYY")
              : "-"
          }`;
        },
      },
      {
        Header: "Amount Raised",
        accessor: "issuance.auction.amount_raising",
        Cell: (row) => {
          return `$${str_into_num(row.value)}`;
        },
      },
      {
        Header: "Status",
        accessor: "issuance.status",
        Cell: (row) => {
          // if(row.value === null){
          return <span className="tag--active">{row.value}</span>;
          // }else if(row.value === "Warning"){
          // 	return <img src={`${dangerIcon}`} width="24" alt="warning" />;
          // }else {
          // 	return <img src={`${errorIcon}`} width="20" alt="error" />;
          // }
        },
      },
      {
        Header: "Yield",
        accessor: "issuance.yield",
        Cell: (row) => {
          return `${(row.value * 100).toFixed(2)}%`;
        },
      },
      {
        Header: "Term",
        accessor: "issuance.contract_length",
        Cell: (row) => {
          return <span className="text-nowrap">{row.value} Years</span>;
        },
      },
    ],
    []
  );

  const data = tableData;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    // nextPage,
    // previousPage,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // state,
    // gotoPage,
    // pageCount,
    // setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  // const { pageIndex, pageSize } = state;

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div>
      <div className="issuances-table" style={{marginTop:"-54px"}}>
        <table
          {...getTableProps()}
          className="table table-responsive"
          responsive="lg"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr className="header" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className="text-nowrap">
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length === 0 ? (
              <>
                {dataFound === 1 ? (
                  <td colSpan="12" className="date-col">
                    <div className="text-center pt-5">
                      <div className="spinner-grow text-warning" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <p>Loading. Please wait...</p>
                    </div>
                  </td>
                ) : (
                  <tr className="no-hover">
                    <td colSpan="7" className="date-col">
                      <div className="table-empty-state">
                        <div className="empty-state-img">
                          <img
                            src={require("../../Images/empty-state-icon.svg")}
                            alt="empty state img"
                          />
                          <p>No issuances participated in yet</p>
                          <div className="createauction-btn"></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </>
            ) : (
              <>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={() =>
                        setredirect(
                          "/issuancesinvestor-details/" + row.original.code
                        )
                      }
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td className="dash-tbl-col" {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
