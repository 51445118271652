import axios from "./axios-server";

export const axios_getZipcodeDataFromCode = (slug, callback) => {
  axios
    .get(`/zipcode/getZipcodeDataByCode/${slug}`, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};

export const axios_getAllZipcode = (page, callback) => {
  axios
    .get(`zipcode/get-all-zipcode/${page}/10`, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};

export const axios_getZipcodeArticleData = (slug, callback) => {
  axios
    .get(`/zipcode/getZipcodeArticleData/${slug}`, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};

export const axios_getZipcodesNearby = (slug, callback) => {
  axios
    .get(`/zipcode/getZipcodesNearby/${slug}`,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};
