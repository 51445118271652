import React from "react";
import "./header.css";
import { connect } from "react-redux";
import { loadUser, startLoading, stopLoading, logout } from "../../store/actions/auth";
import store from "../../store/store";
import { Link } from "react-router-dom";
import { GetCertified } from "../../components/db";
import MasterModal from "../../components/Modal";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasScrolled: false,
      open: false,
      visibility: false,
      popModal: null
    };
  }
  openModal = (Modal) => {
    switch (Modal) {
      case "modal1":
        return this.setState({ popModal: GetCertified.modal1 });
      default:
        break;
    }
  };

  removeModal = () => {
    this.setState({
      popModal: null
    });
  };

  handleScroll = (event) => {
    const scrollTop = window.pageYOffset;

    if (scrollTop > 50) {
      this.setState({ hasScrolled: true });
    } else {
      this.setState({ hasScrolled: false });
    }
  };

  renderModal() {
    const { popModal } = this.state;
    if (popModal) {
      return (
        <MasterModal
          visible={true}
          removeModal={this.removeModal}
          heading={popModal.heading}
          paragraph={popModal.paragraph}
        />
      );
    }
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    document.addEventListener("keydown", this.escapePressed, false);
    //get user data if saved in localstorage
    if(localStorage.getItem("userToken") !== null){
      store.dispatch(startLoading());
      store.dispatch(loadUser());
      store.dispatch(stopLoading());
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escapePressed, false);
  }

  handelLogout = () => {
    this.props.logout();
  };

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  openChat = () => {
    window.drift.api.openChat();
  };

  render() {
    return (
      <div className={this.state.hasScrolled ? "Header HeaderScrolled" : "Header"}>
        <div className="modal-wrapper">{this.renderModal()}</div>
        <div className="headergroup">
          <div className="brand">
            <Link
              to="/"
              onClick={() =>
                (document.title = "Exuity | Sell or Invest in Shares of Real Properties")
              }>
              <img
                src={require("../../Images/exuity-logo.svg")}
                className="img-fluid"
                alt="brand"
              />
            </Link>
          </div>
          <div className="hamburger" onClick={this.toggle.bind(this)}>
            <div onClick={() => this.setState({ clicked: !this.state.clicked })}>
              {this.state.clicked ? "Close" : "Menu"}
            </div>
          </div>
          <div className={"nav-links" + (this.state.open ? " in " : "")}>
            {this.props.isAuthenticated ? (
              <div>
                <div className="hamburger" onClick={this.toggle.bind(this)}></div>
                <div className={"nav-links" + (this.state.open ? " in " : "")}>
                  <div className="Nav-links">
                    <a href="tel:302-253-7086" className="live-link login-link number">
                      <span className="bold"> 302-253-7086 </span>
                    </a>
                  </div>
                  <div className="Nav-links">
                    <Link to="/logout" className="live-link login-link">
                      <span className="bold"> Log Out</span>
                    </Link>
                  </div>
                  <Link to="/properties" className="nav-btn">
                    <button>Access Account</button>
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <div className="hamburger" onClick={this.toggle.bind(this)}></div>
                <div className={"nav-links" + (this.state.open ? " in " : "")}>
                  <div className="Nav-links">
                    <a href="tel:302-253-7086" className="live-link login-link number">
                      <span className="bold"> 302-253-7086 </span>
                    </a>
                  </div>
                  <div className="Nav-links">
                    <Link to="/signin" className="live-link login-link">
                      <span className="bold"> Log In</span>
                    </Link>
                  </div>
                  <Link className="nav-btn" to="/signup">
                    <button>Join</button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user
});
export default connect(mapStatetoProps, { loadUser, startLoading, logout })(Header);
