import axios from './axios-server';

export const axios_getPaymentsInvestor=(callback)=>{
    axios
    .get(`payment-distribution/getPaymentsYouReceived/`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong ", error)
    });  
}

export const axios_getPaymentsYouReceivedByCode=(code, callback)=>{
    axios
    .get(`payment-distribution/getPaymentsYouReceivedByCode/${code}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong ", error)
    });  
}