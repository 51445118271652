import axios from "./axios-server";

export const axios_getCsaDataFromCode = (slug, callback) => {
  axios
    .get(`/csa/getCsasDataByCode/${slug}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};


export const axios_getCsasArticleData = (slug, callback) => {
  axios
    .get(`/csa/getCsasArticleData/${slug}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    },

    )
    .then((response) => callback(response))
    .catch(error => {
      console.log("Oops! Something went wrong ", error)
    });
}


export const axios_getAllCsasData = (page, callback) => {
  axios
    .get(`/csa/get-all-csas/${page}/10`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};


export const axios_getCsasChildDataByCsasCode = (slug, regionType, page, callback) => {
  axios.get(`/csa/getCsasChildByCsasCode/${slug}/${regionType}/${page}/10`, {
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
}

