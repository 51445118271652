import React, { useEffect, useState } from "react";
import { axios_getAllCounties } from "../../../utills/counties";
import { Link } from "react-router-dom";
import Footer from "../../../Component/Footer/Footer";
import Head from "../../../Component/Head";
import Header from "../../../Component/Header/Header";
import CountiesTable from "../../../Component/RegionComponents/CountiesTable";
import "../region.css";

export default function CountiesSeoPage() {
  const [countyData, setCountyData] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios_getAllCounties(currentPage, countiesCallback);
  }, [currentPage]);

  const countiesCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setCountyData(response.data.data.rows);
        setIsLoading(false);
        setTotalPageCount(response.data.data.count);
      }
    }
  };

  return (
    <>
      <Head title="List of all US Counties - Exuity" />
      <div className="App">
        <Header />
        <div className="region-sec" style={{ marginTop: 140 }}>
          <ul className="breadcrumb region-breadcrumb arr-right bg-ffffff">
            <li className="breadcrumb-item">
              <Link to="/">
                <img src={require("../../../Images/breadcrumb-home.svg")} alt="Exuity Home Icon" />
              </Link>
            </li>
            <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
            <li className="breadcrumb-item">
              <Link to="/regiontypes">Region type</Link>
            </li>
            <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
            <li className="breadcrumb-item">
              <Link to="/counties">Counties</Link>
            </li>
          </ul>

          {isLoading === true && (
            <div className="text-center pt-5">
              <div className="spinner-grow text-warning" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p>Loading. Please wait...</p>
            </div>
          )}

          <div>
            {countyData && countyData.length > 0 && (
              <>
                <h1 className="region-sub-title">All Counties List</h1>
                <CountiesTable
                  tableData={countyData}
                  totalPageCount={totalPageCount}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </>
            )}
          </div>
        </div>
        <div className="footer-section">
          <Footer />
        </div>
      </div>
    </>
  );
}
