import React, { useState, useEffect } from "react";
import Sidenav from "../../components/Sidenav";
import "./accreditation.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import { axios_loadUser } from "../../utills/axios-routes";
import DocDropZone from "./DocDropZone";
import bytesToSize from "../../utills/byteToSize";
import { onClose } from "../../utills/remove-docs";
import moment from "moment";
import { uploadHandler } from "../../utills/upload-doc";
import Head from "../../Component/Head";
import { Link } from "react-router-dom";

export default function Accreditation() {
  const [userData, setUserData] = useState(null);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  // const [loading, setloading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axios_loadUser();
      setIsPageLoading(false)
      setUserData(response.data.data);
      document.title = "Exuity | Account | Verify Accreditation"
    }
    fetchData();
  }, []);

  const fileName = (file) => {
    const fileName = file.split(".");
    const fileNameSmall = fileName[0].toLowerCase();

    const fileExtension = fileName[1];
    const makeFileName = fileNameSmall.substring(0, 10);
    return `${makeFileName}.${fileExtension}`;
  };

  const submit_handler = () => {
    if (files.length < 1) {
      setError(
        "Please upload atleast one document before sending for verification"
      );
      setInterval(() => {
        setError("");
      }, 5000);
    } else {
      // setloading(true);
      uploadHandler(files, setError, setSuccess, setFiles);
    }
  };

  // console.log(files, " files");
  return (
    <div className="Dashboard">
      <Head title={'Exuity | Accreditation'} />
      <div className="DashboardGroup">
        <div className="auction-navbar">
          <div className="dashTitle">
            <h4>Verify Accreditation</h4>
          </div>
          <Sidenav />
        </div>
        <div className="row">
          <div className="auction-navbar1">
            <Sidebar />
          </div>
          <div className="DashContent">
            <div className="accerdition-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h5 className="d-flex mb-lg-3">
                      Your Status:
                      {isPageLoading ? (
                        <span className="text-center d-flex ml-3 align-items-center">
                          <div className="spinner-grow text-warning mr-2" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          Loading...
                        </span>
                      ) : (
                        <div className="status">
                          {userData &&
                            userData.accreditation_verified === true ? (
                            <div className="verified"></div>
                          ) : (
                            <div className="not_verified"></div>
                          )}
                          {userData && userData.accreditation_verified === true
                            ? "Accreditation verified"
                            : "Accreditation is not verified"}{" "}
                        </div>
                      )}
                    </h5>
                    <p className="mb-2">
                      At this time, we require property investors to be
                      accredited. The most <br className="d-lg-block d-none" />
                      common ways that people qualify as accredited are that
                      they earn at least <br className="d-lg-block d-none" />
                      $200,000 annually as an individual or $300,000 annually
                      jointly as a married <br className="d-lg-block d-none" />
                      couple, or have a net worth, not including their primary
                      residence, of at least{" "}
                      <br className="d-lg-block d-none" /> $1,000,000
                      individually or jointly as a married couple. You can see
                      the full list <br className="d-lg-block d-none" /> of
                      qualifying methods{" "}
                      <Link to="https://www.ecfr.gov/current/title-17/chapter-II/part-230/subject-group-ECFR6e651a4c86c0174/section-230.501">
                        here.
                      </Link>
                    </p>
                    <p className="mb-2">
                      To demonstrate your accreditation, you may use either use
                      third-party service, <br className="d-lg-block d-none" />
                      <Link to="Verify Accredited Investors | Investor Accreditation Program | Accredited Investor Certificate |Verify Investor">
                        VerifyInvestor
                      </Link>{" "}
                      (signup{" "}
                      <Link to="Verify Accredited Investors | Investor Accreditation Program | Accredited Investor Certificate |Verify Investor">
                        here
                      </Link>
                      ), and upload the attorney letter they provide or{" "}
                      <br className="d-lg-block d-none" /> request that it be
                      emailed to inquiries@exuity.com. Their fee is $59.
                    </p>
                    <p className="mb-2">
                      Alternatively, you may upload relevant documents such as{" "}
                      <br className="d-lg-block d-none" />
                      tax filings (such as W-2, 1099, K-1), pay stubs, pay
                      stubs, or a letter from an accountant, lawyer or{" "}
                      <br className="d-lg-block d-none" /> employer stating
                      income or net worth. We will review and make a{" "}
                      <br className="d-lg-block d-none" />
                      determination if you are accredited. We do not charge for
                      this.
                    </p>
                    <p>Any questions? Email us at inquiries@exuity.com.</p>
                  </div>
                </div>
                {userData && userData.accreditation_verified === false && (
                  <>
                    <div className="row">
                      <div className="col-lg-12 col-12">
                        <h4 className="mt-3 mb-3 font-weight-bold">
                          Upload documents
                        </h4>
                        <DocDropZone setFiles={setFiles} />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-12 col-12">
                        <h4 className="mt-5 font-weight-bold">
                          Your upload documents
                        </h4>
                        {files && files.length > 0 ? (
                          <div className="tbl">
                            <table className="border-tbl">
                              <thead>
                                <tr className="border-tbl">
                                  <th className="border-tbl">File name</th>
                                  <th className="border-tbl">size</th>
                                  <th className="border-tbl">Uplad date</th>
                                  <th className="border-tbl">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {files &&
                                  files.map((file) => {
                                    return (
                                      <tr
                                        className="border-tbl"
                                        key={file && file.path}
                                      >
                                        <td className="border-tbl">
                                          <Link to="#">
                                            {fileName(file && file.name)}
                                          </Link>
                                        </td>
                                        <td className="border-tbl">
                                          {bytesToSize(file && file.size)}
                                        </td>
                                        <td className="border-tbl">
                                          {moment(
                                            file && file.lastModifiedDate
                                          ).format("DD-MM-YYYY | HH:MM")}
                                        </td>
                                        <td className="border-tbl">
                                          <span
                                            style={{
                                              textDecoration: "underline",
                                              color: "blue",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              onClose(files, file, setFiles)
                                            }
                                          >
                                            delete
                                          </span>{" "}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <h5 className="mt-3 font-weight-bold">None yet.</h5>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-12">
                        <div className="mt-5">
                          <h5>Done Uploading?</h5>
                          <button
                            onClick={() => submit_handler()}
                            type="button"
                            className="btn btn-secondary mt-3"
                          >
                            Request Accreditation Verification
                          </button>
                          {error && (
                            <div
                              className="alert alert-danger mt-3"
                              role="alert"
                            >
                              {error}
                            </div>
                          )}
                          {success && (
                            <div
                              className="alert alert-success mt-3"
                              role="alert"
                            >
                              {success}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
