import axios from "./axios-server";

export const axios_getArticlesListBySlug = (slug, page, callback) => {
  axios
    .get(`/article/getArticleListBySlug/${slug}/${page}/10`, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};

export const axios_getArticleDataBySlug = (slug, callback) => {
  axios
    .get(`/article/getArticleDataBySlug/${slug}`, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};

export const axios_getFeaturedArticles = (callback) => {
  axios
    .get(`/article/get-featured-articles`, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};
