import React, { useEffect, useState } from "react";
import { axios_getAllMsasData } from "../../../utills/msa-routes";
import { Link } from "react-router-dom";
import Footer from "../../../Component/Footer/Footer";
import Head from "../../../Component/Head";
import Header from "../../../Component/Header/Header";
import MsaTable from "../../../Component/RegionComponents/MsaTable";

export default function MsasSeoPage() {
  const [msaData, setMsaData] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios_getAllMsasData(currentPage, getMsaDataCallback);
  }, [currentPage]);

  const getMsaDataCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setMsaData(response.data.data.rows);
        setIsLoading(false);
        setTotalPageCount(response.data.data.count);
      }
    }
  };

  return (
    <>
      <Head title={`List of all Metropolitain Statistical Areas (MSAs) - Exuity`} />
      <div className="App">
        <Header />

        <section className="region-sec" style={{ marginTop: 140 }}>
          <ul className="breadcrumb region-breadcrumb arr-right bg-ffffff">
            <li className="breadcrumb-item">
              <Link to="/">
                <img src={require("../../../Images/breadcrumb-home.svg")} alt="Exuity Home Icon" />
              </Link>
            </li>
            <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
            <li className="breadcrumb-item">
              <Link to="/regiontypes">Region type</Link>
            </li>
            <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
            <li className="breadcrumb-item">
              <Link to="/msas">Msas</Link>
            </li>
          </ul>

          {isLoading === true && (
            <div className="text-center pt-5">
              <div className="spinner-grow text-warning" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p>Loading. Please wait...</p>
            </div>
          )}
          {msaData && msaData.length > 0 && (
            <>
              <h1 className="region-sub-title">All Msas List</h1>
              <MsaTable
                tableData={msaData}
                totalPageCount={totalPageCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </>
          )}
        </section>

        <div className="footer-section">
          <Footer />
        </div>
      </div>
    </>
  );
}
