import React, { useEffect } from "react";

const Head = (props) => {

  useEffect(() => {
    document.title = props.title;
    document.querySelector('meta[name="title"]').content = props.title;
    document.querySelector('meta[property="og:title"]').content = props.title;
  }, [props])


  return (
    <>
      {/* <head>
        <title>{props.title}</title>
      </head> */}
    </>
  );
};

export default Head;
