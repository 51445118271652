import React, { useEffect, useState } from "react";
import Sidenav from "../../components/Sidenav";
import "../../components/Dashboard.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Head from "../../Component/Head";
import { axios_getSinglePropertyByCode } from "../../utills/property-routes";
import { str_into_float_num } from "../../utills/string-into-number";
import { useParams, useHistory, Link } from "react-router-dom";

const PropertyDetailPage = () => {
  const { code } = useParams();
  const history = useHistory();
  const [property, setproperty] = useState(null);
  useEffect(() => {
    axios_getSinglePropertyByCode(code)
      .then((response) => {
        if (response.data.data === null) {
          history.push("/properties");
        }
        setproperty(response.data.data);
      })
      .catch((error) => console.log("getting error on single property"));
  }, [code, history]);

  return (
    <>
      <Head title={"Exuity | Edit Property"} />

      <div className="Dashboard" id="Dashboard">
        <div className="DashboardGroup">
          <Sidenav />
          <div className="row">
            <div className="">
              <Sidebar />
            </div>

            <div className="Dashboard" id="Dashboard">
              <div className="DashboardGroup">
                <div className="auction-navbar">
                  <div className="dashTitle">
                    <h4>Property Details</h4>
                  </div>
                  <div className="createauction-btn createauction-btn-2">
                    <Link to={`/property/${property && property.code}`}>
                      <button type="button">View Property</button>
                    </Link>
                  </div>
                  <div className="createauction-btn createauction-btn-2">
                    {property && property.code !== null && (
                      <Link to={`/edit/property/${property && property.code}`}>
                        <button type="button">Edit Property</button>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="DashContent">
              <div className="">
                {property != null ? (
                  <div>
                    <ul>
                      <li>
                        {" "}
                        <b>Property Type: </b>{" "}
                        {property && property.property_type ? property.property_type : "-"}
                      </li>
                      <li>
                        {" "}
                        <b>Bathrooms: </b> {property && property.bathrooms}{" "}
                      </li>
                      <li>
                        {" "}
                        <b>Bedrooms: </b> {property && property.bedrooms}
                      </li>
                      <li>
                        {" "}
                        <b>Interior Size: </b> {property && property.builtup_area}
                      </li>
                      <li>
                        {" "}
                        <b>Desired cash: </b>
                        {property && property.desired_cash
                          ? "$" + str_into_float_num(property && property.desired_cash)
                          : "Unknown"}
                      </li>
                      <li>
                        {" "}
                        <b>Estimated Property Value: </b>
                        {property && property.estimated_property_value
                          ? "$" + str_into_float_num(property && property.estimated_property_value)
                          : "Unknown"}
                      </li>
                      <li>
                        {" "}
                        <b>Property Address: </b> {property && property.property_address}
                      </li>
                      <li>
                        {" "}
                        <b>Mortgage Balance: </b>
                        {property && property.mortage_balance
                          ? "$" + str_into_float_num(property && property.mortage_balance)
                          : "Unknown"}
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="text-center pt-5">
                    <div className="spinner-grow text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <p>Loading. Please wait...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyDetailPage;
