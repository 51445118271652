import React, { useEffect, useState } from "react";
import CanvasJSReact from "../../assets/canvasjs.react";
import moment from "moment";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function PropertyRegionChart(props) {
  const [hpiData, setHpiData] = useState();
  const [hpiChartData, setHpiChartData] = useState();

  useEffect(() => {
    let chartData = [];
    setHpiData(props.hpiData);
    if (hpiData !== null) {
      hpiData &&
        hpiData.map((item) => {
          const itemData = {
            x: new Date(moment(item.quarter_start_date).format("MMM DD, YYYY")),
            y: item.value,
            z: item.quarter,
          };
          return chartData.push(itemData);
        });
      setHpiChartData(chartData);
    }
  }, [hpiData, props]);

  const options = {
    theme: "light2",
    animationEnabled: true,
    exportEnabled: false,
    height: 260,
    axisX: {
      title: "Year",
      format: "MMMM",
    },
    axisY: {
      title: "Index Value",
      maximum:
        Math.max.apply(
          Math,
          hpiChartData &&
          hpiChartData.map(function (o) {
            return o.y;
          })
        ) +
        (10 / 100) *
        Math.max.apply(
          Math,
          hpiChartData &&
          hpiChartData.map(function (o) {
            return o.y;
          })
        ),
      interval: 50,
    },

    toolTip: {
      shared: true,
      backgroundColor: "#373a4eB3",
      borderThickness: 0,
      fontweight: "bold",
      fontSize: 15,
      fontColor: "white",
      valueFormatString: "##,##",
      content: "Year: {x}<br/>Index Value: {y}",
    },
    data: [
      {
        type: "stackedArea",
        name: "Home Price Index",
        showInLegend: false,
        dataPoints: hpiChartData,
        xValueFormatString: "MMMM, YYYY",
      },
    ],
  };

  return (
    <>
      <div>
        {hpiData && hpiData.length > 0 ? (
          <CanvasJSChart options={options} />
        ) : null}
      </div>

      <div
        className="px-4 py-2 mt-n3 position-absolute"
        style={{ backgroundColor: "#FFF", width: "64px" }}
      ></div>
    </>
  );
}
