import React from "react";
import ReactDOM from "react-dom";
import "./calculationmodal.css";

const CalculationModal = ({
  isShowing,
  hide,
  modalCalculationData,
  setIsTutorialModalOpen,
  setTutorialModalInitialStep,
  modalToggleAction,
  escapePressed
}) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="calculation-modal-overlay" />
          <div
            className="calculation-modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog">
            <div className="calculation-modal">
              <div className="calculation-modal-header">
                <h2 className="modal-title">
                  {modalCalculationData && modalCalculationData.title}
                </h2>
                <button
                  type="button"
                  className="modal-close-button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={hide}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="calculation-modal-body">
                <div className="calculation-modal-box">
                  <ul>
                    {modalCalculationData.modalType === "HomeValue" ||
                    modalCalculationData.modalType === "PropertySellsFor" ? (
                      <>
                        <li>
                          {modalCalculationData.index === 1 ? (
                            <span
                              className="cal-modal-link clickable-link"
                              onClick={() => {
                                hide();
                                setIsTutorialModalOpen(true);
                                setTutorialModalInitialStep(0);
                              }}>
                              Last year's estimated home value
                            </span>
                          ) : (
                            <span
                              className="cal-modal-link clickable-link"
                              onClick={() => {
                                modalToggleAction(
                                  "HomeValue",
                                  modalCalculationData.index - 1,
                                  modalCalculationData.financeFormula.value1
                                );
                              }}>
                              Last year's estimated home value
                            </span>
                          )}
                          <span className="cal-multiply">&times;</span>(<span>1 + </span>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              hide();
                              setIsTutorialModalOpen(true);
                              setTutorialModalInitialStep(3);
                            }}>
                            {" "}
                            appreciation percentage
                          </span>
                          )
                        </li>
                        <li>
                          {modalCalculationData.index === 1 ? (
                            <span
                              className="cal-modal-link clickable-link"
                              onClick={() => {
                                hide();
                                setIsTutorialModalOpen(true);
                                setTutorialModalInitialStep(0);
                              }}>
                              {modalCalculationData.financeFormula.value1}{" "}
                            </span>
                          ) : (
                            <span
                              className="cal-modal-link clickable-link"
                              onClick={() => {
                                modalToggleAction(
                                  "HomeValue",
                                  modalCalculationData.index - 1,
                                  modalCalculationData.financeFormula.value1
                                );
                              }}>
                              {modalCalculationData.financeFormula.value1}{" "}
                            </span>
                          )}
                          <span className="cal-multiply">&times;</span>
                          (1 +{" "}
                          <span
                            className="cal-modal-link clickable-link mx-1"
                            onClick={() => {
                              hide();
                              setIsTutorialModalOpen(true);
                              setTutorialModalInitialStep(3);
                            }}>
                            {" "}
                            6%{" "}
                          </span>
                          )
                        </li>
                        <li>
                          <span>{modalCalculationData.finalValue}</span>
                        </li>
                      </>
                    ) : modalCalculationData.modalType === "InvestorEquityStack" ||
                      modalCalculationData.modalType === "InvestorEntitledTo" ? (
                      <>
                        <li>
                          {modalCalculationData.index === 1 ? (
                            <span
                              className="cal-modal-link clickable-link"
                              onClick={() => {
                                hide();
                                setIsTutorialModalOpen(true);
                                setTutorialModalInitialStep(1);
                              }}>
                              Last year's equity sharing percentage
                            </span>
                          ) : (
                            <span
                              className="cal-modal-link clickable-link"
                              onClick={() => {
                                modalToggleAction(
                                  "InvestorEquityStack",
                                  modalCalculationData.index - 1,
                                  modalCalculationData.financeFormula.value1
                                );
                              }}>
                              Last year's equity sharing percentage
                            </span>
                          )}
                          <span className="cal-multiply">+</span>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              modalToggleAction(
                                "EquityIncrease",
                                modalCalculationData.index - 1,
                                modalCalculationData.financeFormula.value2
                              );
                            }}>
                            Last year's equity sharing percentage increase
                          </span>
                        </li>
                        <li>
                          {modalCalculationData.index === 1 ? (
                            <span
                              className="cal-modal-link clickable-link"
                              onClick={() => {
                                hide();
                                setIsTutorialModalOpen(true);
                                setTutorialModalInitialStep(1);
                              }}>
                              {modalCalculationData.financeFormula.value1}
                            </span>
                          ) : (
                            <span
                              className="cal-modal-link clickable-link"
                              onClick={() => {
                                modalToggleAction(
                                  "InvestorEquityStack",
                                  modalCalculationData.index - 1,
                                  modalCalculationData.financeFormula.value1
                                );
                              }}>
                              {modalCalculationData.financeFormula.value1}
                            </span>
                          )}
                          <span className="cal-multiply">+</span>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              modalToggleAction(
                                "EquityIncrease",
                                modalCalculationData.index - 1,
                                modalCalculationData.financeFormula.value2
                              );
                            }}>
                            {modalCalculationData.financeFormula.value2}
                          </span>
                        </li>
                        <li>
                          <span>{modalCalculationData.finalValue}</span>
                        </li>
                      </>
                    ) : modalCalculationData.modalType === "PaymentOwed" ? (
                      <>
                        <li>
                          {modalCalculationData.index === 0 ? (
                            <span
                              className="clickable-link cal-modal-link"
                              onClick={() => {
                                hide();
                                setIsTutorialModalOpen(true);
                                setTutorialModalInitialStep(0);
                              }}>
                              This year's estimated home value
                            </span>
                          ) : (
                            <span
                              className="clickable-link cal-modal-link"
                              onClick={() => {
                                modalToggleAction(
                                  "HomeValue",
                                  modalCalculationData.index,
                                  modalCalculationData.financeFormula.value1
                                );
                              }}>
                              This year's estimated home value
                            </span>
                          )}
                          <span className="cal-multiply">&times;</span>
                          {modalCalculationData.index === 0 ? (
                            <span
                              className="clickable-link cal-modal-link"
                              onClick={() => {
                                hide();
                                setIsTutorialModalOpen(true);
                                setTutorialModalInitialStep(0);
                              }}>
                              This year's equity sharing percentage
                            </span>
                          ) : (
                            <span
                              className="clickable-link cal-modal-link"
                              onClick={() => {
                                modalToggleAction(
                                  "HomeValue",
                                  modalCalculationData.index,
                                  modalCalculationData.financeFormula.value1
                                );
                              }}>
                              This year's equity sharing percentage
                            </span>
                          )}
                          <span className="cal-multiply">&times;</span>
                          <span
                            className="clickable-link cal-modal-link"
                            onClick={() => {
                              hide();
                              setIsTutorialModalOpen(true);
                              setTutorialModalInitialStep(5);
                            }}>
                            Yield percentage
                          </span>
                        </li>
                        <li>
                          {modalCalculationData.index === 0 ? (
                            <span
                              className="cal-modal-link clickable-link"
                              onClick={() => {
                                hide();
                                setIsTutorialModalOpen(true);
                                setTutorialModalInitialStep(0);
                              }}>
                              {modalCalculationData.financeFormula.value1}
                            </span>
                          ) : (
                            <span
                              className="cal-modal-link clickable-link"
                              onClick={() => {
                                modalToggleAction(
                                  "HomeValue",
                                  modalCalculationData.index,
                                  modalCalculationData.financeFormula.value1
                                );
                              }}>
                              {modalCalculationData.financeFormula.value1}
                            </span>
                          )}
                          <span className="cal-multiply">&times;</span>
                          {modalCalculationData.index === 0 ? (
                            <span
                              className="cal-modal-link clickable-link"
                              onClick={() => {
                                hide();
                                setIsTutorialModalOpen(true);
                                setTutorialModalInitialStep(1);
                              }}>
                              {modalCalculationData.financeFormula.value2}
                            </span>
                          ) : (
                            <span
                              className="cal-modal-link clickable-link"
                              onClick={() => {
                                modalToggleAction(
                                  "InvestorEquityStack",
                                  modalCalculationData.index,
                                  modalCalculationData.financeFormula.value2
                                );
                              }}>
                              {modalCalculationData.financeFormula.value2}
                            </span>
                          )}
                          <span className="cal-multiply">&times;</span>
                          <span
                            className="clickable-link cal-modal-link"
                            onClick={() => {
                              hide();
                              setIsTutorialModalOpen(true);
                              setTutorialModalInitialStep(5);
                            }}>
                            {modalCalculationData.financeFormula.value3}
                          </span>
                        </li>
                        <li>
                          <span>{modalCalculationData.finalValue}</span>
                        </li>
                      </>
                    ) : modalCalculationData.modalType === "CashPaymentMade" ? (
                      <>
                        <li>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              modalToggleAction(
                                "PaymentOwed",
                                modalCalculationData.index,
                                modalCalculationData.financeFormula.value1
                              );
                            }}>
                            Payment owed
                          </span>
                          <span className="cal-multiply">&times;</span>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              hide();
                              setIsTutorialModalOpen(true);
                              setTutorialModalInitialStep(2);
                            }}>
                            percent of payments you make with cash
                          </span>
                        </li>
                        <li>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              modalToggleAction(
                                "PaymentOwed",
                                modalCalculationData.index,
                                modalCalculationData.financeFormula.value1
                              );
                            }}>
                            {modalCalculationData.financeFormula.value1}
                          </span>
                          <span className="cal-multiply">&times;</span>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              hide();
                              setIsTutorialModalOpen(true);
                              setTutorialModalInitialStep(2);
                            }}>
                            {modalCalculationData.financeFormula.value2}
                          </span>
                        </li>
                        <li>
                          <span>{modalCalculationData.finalValue} </span>
                        </li>
                      </>
                    ) : modalCalculationData.modalType === "UnderpaidAmount" ? (
                      <>
                        <li>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              modalToggleAction(
                                "PaymentOwed",
                                modalCalculationData.index,
                                modalCalculationData.financeFormula.value1
                              );
                            }}>
                            Amount owed
                          </span>
                          <span className="cal-multiply">-</span>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              modalToggleAction(
                                "CashPaymentMade",
                                modalCalculationData.index,
                                modalCalculationData.financeFormula.value2
                              );
                            }}>
                            Amount paid
                          </span>
                        </li>
                        <li>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              modalToggleAction(
                                "PaymentOwed",
                                modalCalculationData.index,
                                modalCalculationData.financeFormula.value1
                              );
                            }}>
                            {modalCalculationData.financeFormula.value1}
                          </span>
                          <span className="cal-multiply">-</span>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              modalToggleAction(
                                "CashPaymentMade",
                                modalCalculationData.index,
                                modalCalculationData.financeFormula.value2
                              );
                            }}>
                            {modalCalculationData.financeFormula.value2}
                          </span>
                        </li>
                        <li>
                          <span>{modalCalculationData.finalValue} </span>
                        </li>
                      </>
                    ) : modalCalculationData.modalType === "EquityIncrease" ? (
                      <>
                        <li>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              modalToggleAction(
                                "UnderpaidAmount",
                                modalCalculationData.index,
                                modalCalculationData.financeFormula.value1
                              );
                            }}>
                            Underpaid amount
                          </span>
                          <span className="cal-multiply">/</span>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              modalToggleAction(
                                "HomeValue",
                                modalCalculationData.index + 1,
                                modalCalculationData.financeFormula.value1
                              );
                            }}>
                            Next year's estimated home value
                          </span>
                        </li>
                        <li>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              modalToggleAction(
                                "UnderpaidAmount",
                                modalCalculationData.index,
                                modalCalculationData.financeFormula.value1
                              );
                            }}>
                            {modalCalculationData.financeFormula.value1}
                          </span>
                          <span className="cal-multiply">/</span>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              modalToggleAction(
                                "HomeValue",
                                modalCalculationData.index,
                                modalCalculationData.financeFormula.value1
                              );
                            }}>
                            {modalCalculationData.financeFormula.value2}
                          </span>
                        </li>
                        <li>
                          <span>{modalCalculationData.finalValue}</span>
                        </li>
                      </>
                    ) : modalCalculationData.modalType === "InvestorProceedsFromSale" ||
                      modalCalculationData.modalType === "PayoutFromSale" ? (
                      <>
                        <li>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              modalToggleAction(
                                "HomeValue",
                                modalCalculationData.index,
                                modalCalculationData.financeFormula.value1
                              );
                            }}>
                            Home value at time of sale
                          </span>
                          <span className="cal-multiply">&times;</span>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              modalToggleAction(
                                "InvestorEquityStack",
                                modalCalculationData.index,
                                modalCalculationData.financeFormula.value2
                              );
                            }}>
                            Investor equity stake
                          </span>
                        </li>
                        <li>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              modalToggleAction(
                                "HomeValue",
                                modalCalculationData.index,
                                modalCalculationData.financeFormula.value1
                              );
                            }}>
                            {modalCalculationData.financeFormula.value1}
                          </span>
                          <span className="cal-multiply">&times;</span>
                          <span
                            className="cal-modal-link clickable-link"
                            onClick={() => {
                              modalToggleAction(
                                "InvestorEquityStack",
                                modalCalculationData.index,
                                modalCalculationData.financeFormula.value2
                              );
                            }}>
                            {modalCalculationData.financeFormula.value2}
                          </span>
                        </li>
                        <li>
                          <span>{modalCalculationData.finalValue}</span>
                        </li>
                      </>
                    ) : modalCalculationData.modalType === "CumulativePayback" ||
                      modalCalculationData.modalType === "TotalPaybackToInvestors" ? (
                      <>
                        <li>
                          <span>Total annual payments made</span>
                          <span className="cal-multiply">+</span>
                          {modalCalculationData.index === modalCalculationData.year ? (
                            <span
                              className="cal-modal-link clickable-link"
                              onClick={() => {
                                modalToggleAction(
                                  "InvestorProceedsFromSale",
                                  modalCalculationData.year,
                                  modalCalculationData.financeFormula[modalCalculationData.year]
                                );
                              }}>
                              investor proceeds from sale to date
                            </span>
                          ) : (
                            <span>investor proceeds from sale to date</span>
                          )}
                        </li>
                        <li>
                          {modalCalculationData &&
                            modalCalculationData.financeFormula.map((item, key) => (
                              <span key={key}>
                                <span
                                  className="cal-modal-link clickable-link"
                                  onClick={() => {
                                    modalToggleAction(
                                      key === modalCalculationData.year
                                        ? "InvestorProceedsFromSale"
                                        : "CashPaymentMade",
                                      key,
                                      item
                                    );
                                  }}>
                                  {item}
                                </span>
                                {modalCalculationData.financeFormula.length < 1 ||
                                modalCalculationData.financeFormula.length - 1 !== key ? (
                                  <span className="cal-multiply">+</span>
                                ) : null}
                              </span>
                            ))}
                        </li>
                        <li>
                          <span>
                            ${Math.round(modalCalculationData.finalValue).toLocaleString("en-US")}
                          </span>
                        </li>
                      </>
                    ) : modalCalculationData.modalType === "PropertySellsFor" ? (
                      <></>
                    ) : null}
                  </ul>
                </div>
                <p>{modalCalculationData && modalCalculationData.description}</p>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

export default CalculationModal;
