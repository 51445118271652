import React from "react";
import "./hero.css";
import MasterModal from "../../components/Modal";
import { GetCertified } from "../../components/db";
import HeroScrollPicker from "./HeroScrollPicker";

const randimg = Math.floor(Math.random() * 3) + 1;

class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popModal: null,
      valueGroups: {
        sellBuy: "sell",
        percentage: 25,
        homeValue: 400000
      }
    };
  }

  openModal = (Modal) => {
    switch (Modal) {
      case "modal1":
        return this.setState({ popModal: GetCertified.modal1 });
      default:
        break;
    }
  };

  removeModal = () => {
    this.setState({
      popModal: null
    });
  };

  renderModal() {
    const { popModal } = this.state;
    if (popModal) {
      return (
        <MasterModal
          visible={true}
          removeModal={this.removeModal}
          heading={popModal.heading}
          paragraph={popModal.paragraph}
        />
      );
    }
  }

  handlerSellBuy = (name, value) => {
    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value
      }
    }));
  };

  numFormatter = (num) => {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toString() + "k"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000000) {
      return (num / 1000000).toString() + "m"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  };

  render() {
    return (
      <div className="hero-sec">
        <div className="modal-wrapper">{this.renderModal()}</div>
        <div className="hero-contain">
          <div className="hero-wrapper">
            <style>
              {`
            .hero-wrapper::after{
              background-image:url("`}
              {require("../../Images/hero-img-" + randimg + ".png")}
              {`")
            }
          `}
            </style>
            <div className="hero-left-side">
              <h1>
                <HeroScrollPicker sellBuy={this.state} handlerSellBuy={this.handlerSellBuy} />
              </h1>
              {this.state.valueGroups.sellBuy === "buy" ? (
                <ul className="hero_scroll_listitems">
                  <li>
                    Invest $
                    {this.numFormatter(
                      (
                        (this.state.valueGroups.percentage / 100) *
                        this.state.valueGroups.homeValue
                      ).toFixed(2)
                    )}{" "}
                    into a property.
                  </li>
                  <li>
                    Get annual income&#42; plus {this.state.valueGroups.percentage}% of sale price.
                  </li>
                  <li>Bid on income requirement to get in hot deals.</li>
                </ul>
              ) : (
                <ul className="hero_scroll_listitems">
                  <li>
                    Get lump sum of $
                    {this.numFormatter(
                      (
                        (this.state.valueGroups.percentage / 100) *
                        this.state.valueGroups.homeValue
                      ).toFixed(2)
                    )}
                    .
                  </li>
                  <li>
                    When property sells, pay {this.state.valueGroups.percentage}% of sale price.
                  </li>
                  <li>Investors bid to offer you the lowest annual fee.</li>
                </ul>
              )}
              <div className="skip-to-links">
                <p>Skip to:</p>
                <div className="skip-links">
                  <a href="#gist-section">Simple Gist</a>
                  <a href="#modal-page">Complex Model</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hero;
