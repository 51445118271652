import React from "react";
import Sidenav from "../Sidenav";
import "./properties.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import InvestorTable from "./InvestorTable";
import Head from "../../Component/Head";

class PropertiesInvestor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      found: 0,
      properties: [],
    };
  }
  render() {
    return (
      <div className="Dashboard">
        <Head title={"Exuity | Investor | Properties"} />

        <div className="DashboardGroup">
          <div className="auction-navbar">
            <div className="dashTitle">
              <h4>Properties</h4>
            </div>
            <Sidenav />
          </div>
          <div className="row">
            <div className="auction-navbar1">
              <Sidebar />
            </div>
            <div className="DashContent pt-0 pt-lg-5">
              <div className="AuctionPage">
                <InvestorTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PropertiesInvestor;
