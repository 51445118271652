import React from "react";
import Header from "../../Component/Header/Header";
import "./contact.css";
import ReactFormInputValidation from "react-form-input-validation";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Head from "../../Component/Head";
import { axios_addContact } from "../../utills/contact-routes.js";
import { setToast } from "../../store/actions/toast";
import store from "../../store/store";
class ContactUs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      interest: [],
      fields: {
        firstName: "",
        lastName: "",
        email: "",
        phoneno: "",
        inquiring: "",
        message: "",
        error: "",
        phonenoCountry: "",
      },
      errors: {},
    };

    this.form = new ReactFormInputValidation(this);
    this.form.useRules({
      firstName: "required|min:3",
      lastName: "required|min:3",
      email: "required|email",
      inquiring: "required",
      message: "required|max:300",
      phonenoCountry: { required: true },
      phoneno: { required: true },
    });

    this.form.onformsubmit = (fields) => {
      axios_addContact(fields, this.addContactCallback);
    };
  }

  addContactCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        store.dispatch(
          setToast([
            {
              msg: response.data.message,
              type: "success",
            },
          ])
        );
      } else {
        store.dispatch(
          setToast([
            {
              msg: response.data.message,
              type: "error",
            },
          ])
        );
      }
    }
  };

  removeThe = (error) => {
    return error.replace("The", "");
  };

  handleChange = (field, e) => {
    this.setState({ [field]: e.target.value });
  };

  render() {
    return (
      <div className="contact-page">
        <Head title={"Exuity | Contact Us"} />
        <div className="contact-page-wrapper">
          <Header />
          <div className="contact-form">
            <div className="contact-form-text">
              <h1>Contact Exuity</h1>
              <p>Get in touch with any questions, comments, or suggestions.</p>
            </div>
            <div className="contact-form-wrapper">
              <form onSubmit={this.form.handleSubmit}>
                <div>
                  <ul className="signup-inputs">
                    <li>
                      <label className="input-label">First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        className="first_name"
                        placeholder="First Name"
                        autoComplete="off"
                        value={this.state.fields.firstName}
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        data-attribute-name="First Name"
                        data-async
                      />
                      <label className="error">
                        {this.state.errors.firstName
                          ? this.removeThe(this.state.errors.firstName)
                          : ""}
                      </label>
                    </li>
                    <li>
                      <label className="input-label">Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        className="last_name"
                        placeholder="Last Name"
                        value={this.state.fields.lastName}
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        data-attribute-name="Last Name"
                        data-async
                      />
                      <label className="error">
                        {this.state.errors.lastName
                          ? this.removeThe(this.state.errors.lastName)
                          : ""}
                      </label>
                    </li>
                    <li>
                      <label className="input-label">Email</label>
                      <input
                        type="email"
                        name="email"
                        className="email"
                        placeholder="Your Email"
                        value={this.state.fields.email}
                        onBlur={this.form.handleBlurEvent}
                        onChange={this.form.handleChangeEvent}
                        data-attribute-name="Email"
                        data-async
                      />
                      <label className="error">
                        {this.state.errors.email
                          ? this.removeThe(this.state.errors.email)
                          : ""}
                      </label>
                    </li>
                    <li>
                      <label className="input-label">Phone number</label>
                      <PhoneInput
                        defaultCountry="US"
                        name="phoneno"
                        className="email"
                        placeholder="Your phone no."
                        value={this.state.fields.phoneno}
                        onBlur={this.form.handleBlurEvent}
                        onChange={(e) =>
                          this.setState({
                            fields: { ...this.state.fields, phoneno: e },
                          })
                        }
                        data-attribute-name="Phone No."
                        data-async
                      />
                    </li>
                  </ul>
                  <fieldset className="inquiring-options">
                    <legend>Inquiring as a:</legend>
                    <div className="inquiring-radio-buttons">
                      <label className="choice">
                        {" "}
                        <input
                          type="radio"
                          name="inquiring"
                          onChange={this.form.handleChangeEvent}
                          value="Property Owner"
                        />{" "}
                        Property Owner
                      </label>
                      <label className="choice">
                        {" "}
                        <input
                          type="radio"
                          name="inquiring"
                          onChange={this.form.handleChangeEvent}
                          value="Investor"
                        />{" "}
                        Investor{" "}
                      </label>
                      <label className="choice">
                        {" "}
                        <input
                          type="radio"
                          name="inquiring"
                          onChange={this.form.handleChangeEvent}
                          value="Potential Partner"
                        />{" "}
                        Potential Partner{" "}
                      </label>
                      <label className="choice">
                        {" "}
                        <input
                          type="radio"
                          name="inquiring"
                          onChange={this.form.handleChangeEvent}
                          value="Other"
                        />{" "}
                        Other
                      </label>
                    </div>
                    <label className="error">
                      {this.state.errors.inquiring
                        ? this.state.errors.inquiring
                        : ""}
                    </label>
                  </fieldset>
                  <div className="textarea-msg">
                    <label>How can we help?</label>
                    <textarea
                      name="message"
                      placeholder="Write your message..."
                      maxLength="300"
                      value={this.state.fields.message}
                      onChange={this.form.handleChangeEvent}
                      onBlur={this.form.handleBlurEvent}
                    ></textarea>
                    <label className="error">
                      {this.state.errors.message
                        ? this.state.errors.message
                        : ""}
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary send-btn">
                    Send Message
                  </button>
                </div>
              </form>
            </div>

            <div className="contact-us-boxes">
              <a href="mailto:inquire@exuity.com">
                <div className="contact-us-box">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M24.2557 13.0195L18.0283 16.3684C16.8498 16.9855 15.1977 16.9855 14.0192 16.3684L7.73828 13.0195"
                      stroke="#131521"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.15019 6.66699H23.8143C25.3401 6.68411 26.7923 7.32912 27.8327 8.45183C28.8732 9.57453 29.411 11.0769 29.321 12.6091V19.9359C29.411 21.4681 28.8732 22.9705 27.8327 24.0932C26.7923 25.2159 25.3401 25.8609 23.8143 25.878H8.15019C4.8729 25.878 2.66406 23.2118 2.66406 19.9359V12.6091C2.66406 9.33318 4.8729 6.66699 8.15019 6.66699Z"
                      stroke="#131521"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="text">
                    <h6>Chat with us</h6>
                    <p>inquire@exuity.com</p>
                  </div>
                </div>
              </a>
              <a href="tel:302-253-7086">
                <div className="contact-us-box">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.1106 17.2299C20.8696 21.9875 21.9491 16.4835 24.9792 19.5114C27.9003 22.4317 29.5793 23.0168 25.8782 26.7169C25.4146 27.0895 22.4691 31.5718 12.1176 21.2231C1.76477 10.8732 6.24449 7.9247 6.61716 7.46123C10.3272 3.75093 10.9022 5.43964 13.8234 8.36C16.8534 11.3892 11.3517 12.4723 16.1106 17.2299Z"
                      stroke="#131521"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      opacity="0.4"
                      d="M19.4727 9.56006C21.5854 9.97044 23.2365 11.6227 23.6481 13.7355"
                      stroke="#131521"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <div className="text">
                    <h6>Phone</h6>
                    <p>302-253-7086</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="copyrights-text">
              <p>© 2022, Exuity</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
