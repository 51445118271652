import React from "react";
import { Modal } from "react-bootstrap";

import "../App.css";

class MasterModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      heading: this.props.heading,
      paragraph: this.props.paragraph
    };
  }

  escapePressed = (e) => {
    if (e.key === "Escape") {
      this.closeModal();
    }
  };

  componentDidMount() {
    this.setState({ visible: this.props.visible });
    document.addEventListener("keydown", this.escapePressed, false);
  }

  componentWillUnmount() {
    this.setState({ visible: false, heading: "", paragraph: "" });
    document.removeEventListener("keydown", this.escapePressed, false);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      heading: nextProps.heading,
      paragraph: nextProps.paragraph,
      visible: nextProps.visible
    });
  }

  closeModal = () => {
    this.setState({
      visible: false
    });
    this.props.removeModal();
  };

  openChat = () => {
    window.drift.api.openChat();
  };

  render() {
    return (
      <div>
        <Modal
          show={this.state.visible}
          onHide={this.closeModal}
          centered
          className="modal-dialog-1">
          <Modal.Body className="signup-modal">
            <div className="main-modal-wrapper">
              <iframe title="myFrame" src={this.state.paragraph} allow="fullscreen" />
              <div className="popup-btn">
                <button className="closes" onClick={() => this.closeModal()}>
                  x
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default MasterModal;
