import React from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import "./Form.css";

const ResetPassword = () => {
	return (
		<Formik
			initialValues={{ currentpass: "", newpassword: "", cnewpassword: "" }}
			onSubmit={(values, { setSubmitting }) => {
				setTimeout(() => {
					alert(JSON.stringify(values, null, 2));
					setSubmitting(false);
				}, 1000);
			}}
			validationSchema={Yup.object({
				currentpass: Yup.string().required("Password is required"),
				newpassword: Yup.string()
					.required("Password is required")
					.min(8, "Password is too short - should be 8 chars minimum.")
					.matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
				cnewpassword: Yup.string()
					.required("Password is required")
					.min(8, "Password is too short - should be 8 chars minimum.")
					.matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
			})}
		>
			{(formik, isSubmitting) => (
				<Form className="form-wrapper">
					<div className="brand">
						<Link to="/">
							<img src={require("../Images/exuity-logo.svg")} alt="brand" />
						</Link>
					</div>
					<div className="form-box">
						<div className="form-text-wrapper">
							<h1>Reset Your Password</h1>
							<div className="inline-feeback">
								<img src={require("../Images/Info.svg")} alt="brand" />
								<p>
									Your current password is older than 60 days. You need to set a
									new one.
								</p>
							</div>
						</div>

						<div className="form-group pass-input">
							<label className="password-labels" htmlFor="password">
								<span>Current Password</span>
							</label>
							<Field
								name="currentpass"
								className={
									formik.touched.currentpass && formik.errors.currentpass
										? "form-control is-invalid"
										: "form-control"
								}
								type="password"
								placeholder="Current password"
							/>
							{formik.touched.currentpass && formik.errors.currentpass ? (
								<div className="invalid-feedback">
									{formik.errors.currentpass}
								</div>
							) : null}
						</div>

						<div className="form-group pass-input">
							<label className="password-labels" htmlFor="password">
								<span>New Password</span>
							</label>
							<Field
								name="newpassword"
								className={
									formik.touched.newpassword && formik.errors.newpassword
										? "form-control is-invalid"
										: "form-control"
								}
								type="password"
								placeholder="Type your new password"
							/>
							{formik.touched.newpassword && formik.errors.newpassword ? (
								<div className="invalid-feedback">
									{formik.errors.newpassword}
								</div>
							) : null}
						</div>

						<div className="form-group pass-input">
							<label className="password-labels" htmlFor="password">
								<span>Confirm New Password</span>
							</label>
							<Field
								name="cnewpassword"
								className={
									formik.touched.cnewpassword && formik.errors.cnewpassword
										? "form-control is-invalid"
										: "form-control"
								}
								type="password"
								placeholder="Confirm your new password"
							/>
							{formik.touched.newpassword && formik.errors.cnewpassword ? (
								<div className="invalid-feedback">
									{formik.errors.cnewpassword}
								</div>
							) : null}
						</div>

						<div className="form-group">
							<button
								type="submit"
								className="blue-btn"
								disabled={isSubmitting}
							>
								{isSubmitting ? "Please wait..." : "Update Password"}
							</button>
						</div>

						<div className="account-creation-link">
							<p>
								<Link to="/signin">Return to Sign in</Link>
							</p>
						</div>
					</div>
					<div className="copyrights-text">
						<p>&copy; {new Date().getFullYear()}, Exuity</p>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default ResetPassword;
