import React, { useEffect, useState } from "react";
import Sidenav from "../../components/Sidenav";
import "./taxinfo.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  axios_createTaxInfo,
  axios_getTaxInfo,
} from "../../utills/taxInfo-routes";
import { useDispatch } from "react-redux";
import { SET_TOAST } from "../../store/actions/types";
import Head from "../../Component/Head";

export default function Taxinfo() {
  const dispatch = useDispatch();
  const [taxType, setTaxType] = useState("");
  const [taxInfo, setTaxInfo] = useState(null);
  const [isDisabledField, setIsDisabledField] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios_getTaxInfo(getTaxInfoCallback);
  }, []);

  const getTaxInfoCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true && response.data.data !== null) {
        setIsDisabledField(true);
        setTaxInfo(response.data.data);
        if (response.data.data && response.data.data.individual_name !== "") {
          setTaxType("Individual");
        } else if (response.data.data && response.data.data.entity_name) {
          setTaxType("Corporate");
        } else {
          setTaxType("");
        }
      }
    }
    setIsLoading(false);
  };

  const TaxCreateSchema = () => {
    if (taxType === "Individual") {
      return Yup.object().shape({
        individual_name: Yup.string()
          .required("Please Provide Name")
          .min(2, "Please Provide Valid Name"),
        ssn: Yup.string()
          .required("Please Provide Name")
          .min(2, "Please Provide Valid Name"),
        street_address: Yup.string().required("Please Provide Street Address"),
        city: Yup.string().required("Please Provide City name"),
        state: Yup.string().required("Please Provide State name"),
        zip: Yup.string().required("Please Provide Zip"),
      });
    } else if (taxType === "Corporate") {
      return Yup.object().shape({
        entity_name: Yup.string()
          .required("Please Provide Business or Disregarded Entity Name")
          .min(2, "Please Provide Valid Business or Disregarded Entity Name"),
        ein: Yup.string()
          .required("Please Provide Business or Disregarded Entity Name")
          .min(2, "Please Provide Valid Business or Disregarded Entity Name"),
        street_address: Yup.string().required("Please Provide Street Address"),
        city: Yup.string().required("Please Provide City name"),
        state: Yup.string().required("Please Provide State name"),
        zip: Yup.string().required("Please Provide Zip"),
      });
    }
  };

  const createTaxCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        dispatch({
          type: SET_TOAST,
          payload: [{ msg: "Updated tax Information", type: "success" }],
        });
      } else {
        dispatch({
          type: SET_TOAST,
          payload: [
            {
              msg: "Oops! Something went wrong while updating Tax Info",
              type: "error",
            },
          ],
        });
      }
    }
  };

  return (
    <>
      <div className="Dashboard">
        <Head title={"Exuity | Taxinfo"} />
        <div className="DashboardGroup">
          <div className="auction-navbar">
            <div className="dashTitle container">
              <div>
                <h4 className="pl-0"> Your Tax Information</h4>
                <p>
                  So that we can file and send you appropriate tax forms as
                  needed, please fill out the tax information about yourself or
                  your investment entity.
                </p>
              </div>
            </div>
            <Sidenav />
          </div>
          <div className="row">
            <div className="auction-navbar1">
              <Sidebar />
            </div>
            <div className="DashContent">
              <div className="row">
                <div className="col-lg-6">
                  {isLoading ? (
                    <div className="text-center pt-5">
                      <div className="spinner-grow text-warning" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <p>Loading. Please wait...</p>
                    </div>
                  ) : (
                    <>
                      <div className="property-type-tags mb-3">
                        <h6>Tax Type</h6>
                        <div className="property-tags">
                          <button
                            onClick={() => setTaxType("Individual")}
                            className={taxType === "Individual" ? "active" : ""}
                            disabled={isDisabledField}
                          >
                            Individual
                          </button>
                          <button
                            onClick={() => setTaxType("Corporate")}
                            className={taxType === "Corporate" ? "active" : ""}
                            disabled={isDisabledField}
                          >
                            Corporate Entity
                          </button>
                        </div>
                      </div>
                      <Formik
                        enableReinitialize
                        initialValues={{
                          individual_name: taxInfo && taxInfo.individual_name,
                          entity_name: taxInfo && taxInfo.entity_name,
                          ssn: taxInfo && taxInfo.ssn,
                          ein: taxInfo && taxInfo.ein,
                          street_address: taxInfo && taxInfo.street_address,
                          city: taxInfo && taxInfo.city,
                          state: taxInfo && taxInfo.state,
                          zip: taxInfo && taxInfo.zip,
                        }}
                        validationSchema={TaxCreateSchema}
                        onSubmit={(values) => {
                          axios_createTaxInfo(values, createTaxCallback);
                        }}
                      >
                        {(props) => (
                          <Form>
                            <ul className="AddProperty-inputs">
                              {taxType === "Individual" ? (
                                <>
                                  <li>
                                    <div className="form-group">
                                      <label htmlFor="">Name</label>
                                      <Field
                                        type="text"
                                        name="individual_name"
                                        className={`form-control ${props.touched.individual_name &&
                                          props.errors.individual_name
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        placeholder="Name"
                                        disabled={isDisabledField}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="individual_name"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </li>
                                  <li>
                                    <div className="form-group">
                                      <label htmlFor="">
                                        Social Security Number
                                      </label>
                                      <Field
                                        type="text"
                                        name="ssn"
                                        className={`form-control ${props.touched.ssn && props.errors.ssn
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        placeholder="Social Security Number"
                                        disabled={isDisabledField}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="ssn"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </li>
                                </>
                              ) : taxType === "Corporate" ? (
                                <>
                                  <li>
                                    <div className="form-group">
                                      <label htmlFor="">
                                        Business or Disregarded Entity Name
                                      </label>
                                      <Field
                                        type="text"
                                        name="entity_name"
                                        className={`form-control ${props.touched.entity_name &&
                                          props.errors.entity_name
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        placeholder="Business or Disregarded Entity Name"
                                        disabled={isDisabledField}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="entity_name"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </li>
                                  <li>
                                    <div className="form-group">
                                      <label htmlFor="">
                                        Employer Identification Number
                                      </label>
                                      <Field
                                        type="text"
                                        name="ein"
                                        className={`form-control ${props.touched.ein && props.errors.ein
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        placeholder="Employer Identification Number"
                                        disabled={isDisabledField}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="ein"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </li>
                                </>
                              ) : (
                                false
                              )}

                              {taxType !== "" && (
                                <>
                                  <li>
                                    <div className="form-group">
                                      <label htmlFor="">Street Address</label>
                                      <Field
                                        type="text"
                                        name="street_address"
                                        className={`form-control ${props.touched.street_address &&
                                          props.errors.street_address
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        placeholder="Street Address"
                                        disabled={isDisabledField}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="street_address"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </li>

                                  <li>
                                    <div className="form-group">
                                      <label htmlFor="">City</label>
                                      <Field
                                        type="text"
                                        name="city"
                                        className={`form-control ${props.touched.city &&
                                          props.errors.city
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        placeholder="City"
                                        disabled={isDisabledField}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="city"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </li>

                                  <li>
                                    <div className="form-group">
                                      <label htmlFor="">State</label>
                                      <Field
                                        type="text"
                                        name="state"
                                        className={`form-control ${props.touched.state &&
                                          props.errors.state
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        placeholder="Street Address"
                                        disabled={isDisabledField}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="state"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </li>

                                  <li>
                                    <div className="form-group">
                                      <label htmlFor="">Zip</label>
                                      <Field
                                        type="text"
                                        name="zip"
                                        className={`form-control ${props.touched.zip && props.errors.zip
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        placeholder="Zip"
                                        disabled={isDisabledField}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="zip"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </li>
                                  <div className="Ap-submitted-btn pt-4 text-left">
                                    <button
                                      type="button"
                                      className="mr-3"
                                      onClick={() =>
                                        setIsDisabledField(!isDisabledField)
                                      }
                                    >
                                      {isDisabledField
                                        ? "Edit Tax Info"
                                        : "Cancel"}
                                    </button>
                                    <button
                                      type="submit"
                                      className={
                                        isDisabledField ? "d-none" : ""
                                      }
                                    >
                                      Save Changes
                                    </button>
                                  </div>
                                </>
                              )}
                            </ul>
                          </Form>
                        )}
                      </Formik>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
