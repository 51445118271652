import React, { createContext, useState } from "react";

export const NavigationContext = createContext([]);

export const NavLinkContext = ({ children }) => {
  const [activeNav, setActiveNav] = useState("a");
  return (
    <NavigationContext.Provider
      value={[
        { activeNav: activeNav, setActiveNav: setActiveNav },
        setActiveNav,
      ]}
    >
      	
      {children}
    </NavigationContext.Provider>
  );
};
 