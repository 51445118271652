import React from "react";
import { NavLink } from "react-router-dom";
import { imageChecker } from "../../config/imgBaseUrl";


const PropertyCard = (props) => (
  <div className="PropertyCard">
    <NavLink to={"/property/" + props.code}>
      <div className="card-cta">
        <img src={imageChecker(props.img)} alt="Property img" />
        <span>{props.cta}</span>
      </div>
      <div className="PropertyCard-text">
        <ul className="PropertyCard-lists">
          <li>{props.list1}</li>
          <li>{props.list2}</li>
        </ul>
      </div>
    </NavLink>
  </div>
);

export default PropertyCard;
