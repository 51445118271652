import React, { useEffect, useState } from "react";
import { useTable, usePagination } from "react-table";
import "./properties.css";
import moment from "moment";
import { axios_getOwnerProperty } from "../../utills/property-routes";
// import { NavLink } from 'react-router-dom';
// import { imageChecker } from "../../config/imgBaseUrl";
import { NavLink, Redirect } from "react-router-dom";
import { str_into_num } from "../../utills/string-into-number";
import { URL_PROPERTY } from "../../config/propertyImagesUrl";

export default function OwnerTable(props) {
	const [tableData, setTableData] = useState([]);

	useEffect(() => {
		axios_getOwnerProperty(OwnerPropertyCallback);
	}, []);

	const OwnerPropertyCallback = (response) => {
		if (response.status === 200) {
			if (response.data.status === true) {
				setTableData(response.data.data);
			}
		} else {
			console.log("Oops Something went wrong", response);
		}
	};

	const columns = React.useMemo(
		() => [
			{
				Header: "Property",
				accessor: "property_address",
				Cell: (row) => {
					return (
						<div className="property-col dash-tbl-col">
							<img
								width={50}
								height={50}
								style={{ objectFit: "cover" }}
								src={URL_PROPERTY(
									row && row.row && row.row.original && row.row.original.images2
								)}
								alt="Auction img"
							/>
							<h6>{row.value}</h6>
						</div>
					);
				},
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: (row) => {
					if (row.value === "Active") {
						return <span className="tag--active">{row.value}</span>;
					} else {
						return (
							<span className="tag--inreview" style={{ whiteSpace: "nowrap" }}>
								Under Review
							</span>
						);
					}
				},
			},
			{
				Header: "Est. Value",
				accessor: "estimated_property_value",
				Cell: (row) => {
					if (row.value !== null) {
						return `$${str_into_num(row.value)}`;
					} else {
						return "-";
					}
				},
			},
			{
				Header: "Auction Launched ?",
				accessor: "auction_launched",
			},
			{
				Header: "Date Saved",
				accessor: "createdAt",
				Cell: (row) => {
					return (
						<>
							{moment(row.value).isValid()
								? moment(row.value).format("MMM DD, YYYY")
								: "-"}
						</>
					);
				},
			},
		],
		[]
	);

	const data = tableData;

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		// nextPage,
		// previousPage,
		// canPreviousPage,
		// canNextPage,
		// pageOptions,
		// state,
		// gotoPage,
		// pageCount,
		// setPageSize,
		prepareRow,
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: 10 },
		},
		usePagination
	);
	const [redirect, setredirect] = useState(null);

	// const { pageIndex, pageSize } = state;

	if (redirect) {
		return <Redirect to={redirect} />;
	}

	return (
		<>
			<div>
				<div className="paymentowed-table paymentowed-table-2">
					{props.found === 2 ? (
						<table
							{...getTableProps()}
							className="table table-responsive"
							responsive="lg"
						>
							<thead>
								{headerGroups.map((headerGroup) => (
									<tr className="header" {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map((column) => (
											<th
												{...column.getHeaderProps()}
											>
												{column.render("Header")}
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()}>
								<>
									{page.map((row) => {
										prepareRow(row);
										return (
											<tr
												{...row.getRowProps()}
												onClick={() =>
													setredirect("/property-details/" + row.original.code)
												}
											>
												{row.cells.map((cell) => {
													return (
														<td
															className="dash-tbl-col"
															{...cell.getCellProps()}
														>
															{cell.render("Cell")}
														</td>
													);
												})}
											</tr>
										);
									})}
								</>
							</tbody>
						</table>
					) : props.found === 0 ? (
						<>
							<table
								{...getTableProps()}
								className="table table-responsive"
								responsive="lg"
							>
								<thead>
									{headerGroups.map((headerGroup) => (
										<tr
											className="header"
											{...headerGroup.getHeaderGroupProps()}
										>
											{headerGroup.headers.map((column) => (
												<th
													{...column.getHeaderProps()}
												>
													{column.render("Header")}
												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody>
									<tr>
										<td colSpan={5}>
											<div className="table-empty-state">
												<div className="empty-state-img">
													<img
														src={require("../../Images/empty-state-icon.svg")}
														alt="empty state img"
													/>
													<p>You haven't added any property</p>
												</div>
												<div className="addPropert-btn">
													<NavLink to="/properties/create">
														<button type="button">
															<span>
																<svg
																	className="btn-plusIcon"
																	xmlns="http://www.w3.org/2000/svg"
																	width="12px"
																	height="12px"
																>
																	<path
																		opacity="0.749"
																		fill="rgb(255, 255, 255)"
																		d="M11.000,7.000 L7.000,7.000 L7.000,11.000 C7.000,11.552 6.552,12.000 6.000,12.000 C5.448,12.000 5.000,11.552 5.000,11.000 L5.000,7.000 L1.000,7.000 C0.448,7.000 -0.000,6.552 -0.000,6.000 C-0.000,5.448 0.448,5.000 1.000,5.000 L5.000,5.000 L5.000,1.000 C5.000,0.448 5.448,-0.000 6.000,-0.000 C6.552,-0.000 7.000,0.448 7.000,1.000 L7.000,5.000 L11.000,5.000 C11.552,5.000 12.000,5.448 12.000,6.000 C12.000,6.552 11.552,7.000 11.000,7.000 Z"
																	/>
																</svg>
															</span>
															Add property
														</button>
													</NavLink>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</>
					) : (
						<div className="text-center pt-5">
							<div className="spinner-grow text-warning" role="status">
								<span className="sr-only">Loading...</span>
							</div>
							<p>Loading. Please wait...</p>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
