import React, { useState, useEffect } from "react";
import Sidenav from "../../components/Sidenav";
import Sidebar from "../../Component/Sidebar/Sidebar";
import "./account.css";
import { str_into_float_num } from "../../utills/string-into-number";
import {
  connectAccontType,
  getUserAccounts,
} from "../../utills/balance-routes";
import { Redirect } from "react-router-dom";

const SelectAccount = () => {
  const [accountId, setAccountId] = useState("");
  // const [active, setActive] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setredirect] = useState(null);
  const [showError, setshowError] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const response = await getUserAccounts();
      setAccounts(response.data.balances);
      setLoading(false);
    };
    getData();
  }, []);

  const handleConnect = () => {
    if (!accountId) {
      setshowError(true);
      setTimeout(() => {
        setshowError(false);
      }, 3000);
    } else {
      setLoading(true);
      connectAccontType(accountId, setLoading, setredirect);
      setredirect("/balance");
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="Dashboard">
      <div className="DashboardGroup">
        <div className="auction-navbar">
          <div className="dashTitle">
            <h4> Select Account</h4>
            <button
              type="button"
              onClick={() => handleConnect()}
              //   disabled={!accountId ? true : false}
              className="btn btn-primary mt-3"
            >
              {loading ? "Connecting..." : "Save Changes"}
            </button>
          </div>

          <Sidenav />
        </div>

        <div className="row">
          <div className="auction-navbar1">
            <Sidebar />
          </div>

          <div className="DashContent">
            <div className="container text-center">
              {showError && (
                <div className="alert alert-danger" role="alert">
                  Please select account type to proceed.
                </div>
              )}

              <div className="row text-center">
                <div className="col-12">
                  {accounts &&
                    accounts.map((account, index) => {
                      return (
                        <div
                          key={index}
                          //   onClick={() =>
                          //     handleChange(index, account && account.account_id)
                          //   }
                          className={`${"account-main"} `}
                        >
                          <div className="container text-lg-none text-center  ">
                            <div className="row d-flex jusitfy-content-center align-items-center">
                              <div className="col-lg-6 col-12 ">
                                <div className="container">
                                  <div className="row d-flex justify-content-center align-items-center">
                                    <div className="col-lg-1 col-12 d-flex justify-content-center align-items-center">
                                      <img
                                        width={25}
                                        height={25}
                                        src="/building-columns-solid.svg"
                                        alt="bank icon"
                                      />
                                    </div>
                                    <div className="col-lg-11 col-12">
                                      <h5 className="heading-top d-lg-flex d-block">
                                        {account.name} Account
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-12 ">
                                <div className="">
                                  <h5>
                                    {" "}
                                    <span className="font-weight-bold">
                                      Balance
                                    </span>{" "}
                                    : $
                                    {account.balances.available !== null
                                      ? str_into_float_num(
                                        account.balances.available
                                      )
                                      : 0.0}
                                  </h5>
                                </div>
                              </div>
                              <div className="col-lg-2 col-12 d-flex align-items-center">
                                <div className="form-check d-flex align-items-center">
                                  <input
                                    onChange={() =>
                                      setAccountId(
                                        account && account.account_id
                                      )
                                    }
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault2"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectAccount;
