import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import Router from "./router/routes";
import "./index.css";
import store from "./store/store";
import { Provider } from "react-redux";
import Drift from "react-driftjs";
import { hydrate, render } from 'react-dom';

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(<Provider store={store}>
    <Router />
    <Drift appId="y7se3rdertmz" />
  </Provider>, rootElement);
} else {
  render(<Provider store={store}>
    <Router />
    <Drift appId="y7se3rdertmz" />
  </Provider>, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
