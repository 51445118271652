import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import Footer from "../../Component/Footer/Footer";
import GetStarted from "../../Component/GetStarted/GetStarted";
import Head from "../../Component/Head";
import Header from "../../Component/Header/Header";
import { axios_getArticleDataBySlug } from "../../utills/axios-articles";

import Styles from "./ArticlesPages.module.css";

export default function ArticlesPages() {
  const { slug } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [articleData, setArticleData] = useState();

  useEffect(() => {
    setIsLoading(true);
    axios_getArticleDataBySlug(slug, getArticleCallback);
  }, [slug]);

  const getArticleCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setArticleData(response.data.data);
      } else {
        history.push("/notfound");
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="App">
      <Header />

      {isLoading === true ? (
        <div className="text-center pt-5" style={{ marginTop: 120 }}>
          <div className="spinner-grow text-warning" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p>Loading. Please wait...</p>
        </div>
      ) : (
        <>
          <Head
            title={`${articleData && articleData.article_title} - ${
              articleData && articleData.article_type && articleData.article_type.name
            }`}
          />
          <section className={`${Styles.article_sec}`}>
            <ul className="breadcrumb region-breadcrumb arr-right bg-ffffff">
              <li className="breadcrumb-item">
                <Link to="/">
                  <img src={require("../../Images/breadcrumb-home.svg")} alt="Exuity Home Icon" />
                </Link>
              </li>
              <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
              <li className="breadcrumb-item">
                <Link to="/articletypes">Article type</Link>
              </li>
              <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
              <li className="breadcrumb-item">
                <Link
                  to={`/${
                    articleData && articleData.article_type && articleData.article_type.slug
                  }`}>
                  {articleData && articleData.article_type && articleData.article_type.name}
                </Link>
              </li>
              <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>{" "}
              <li className="breadcrumb-item" aria-current="page">
                <Link to={`/`} onClick={() => window.location.reload()}>
                  {articleData && articleData.name}
                </Link>
              </li>
            </ul>

            <h1 className="region-title mb-4">{articleData && articleData.article_title}</h1>

            {articleData &&
              articleData.article &&
              articleData.article
                .split("\n")
                .map((item, i) => (
                  <p key={i}>{<span dangerouslySetInnerHTML={{ __html: item && item }} />}</p>
                ))}

            <GetStarted
              name="Consider Exuity for Home Finance and Investing"
              description="Exuity lets property owners sell a portion of the value of their properties to investors. It's an alternative to mortgage products and real estate crowdfunding."
            />
          </section>
        </>
      )}

      <div className="footer-section">
        <Footer />
      </div>
    </div>
  );
}
