import React, { useEffect, useState } from "react";
import "./getstarted.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function GetStarted(props) {
  const userAuth = useSelector((state) => state.auth.isAuthenticated);
  const [heading, setHeading] = useState("Get Started");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (props.name !== undefined) {
      setHeading(props.name);
    } else {
      setHeading("Get Started");
    }
  }, [props]);

  useEffect(() => {
    if (props.description !== undefined) {
      setDescription(props.description);
    } else {
      setDescription("Create an account to claim your property and view investment opportunities.");
    }
  }, [props.description]);

  return (
    <div className="get-started">
      <div className="get-started-wrapper">
        <div className="get-started-text">
          <h3>{heading}</h3>
          <p>{description}</p>
        </div>
        <div className="get-started-btn">
          <Link to={`${userAuth ? "/properties" : "/signup"}`} className="nav-btn">
            {props.name === "Get Started" ? (
              <button>Get Started</button>
            ) : (
              <button>Join Now</button>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
}
