export const Owner_steps = [
  {
    element: ".owner_appraised",
    position: "top",
    tooltipClass: "customTooltip",
    intro: ` 
      <div id="apraised">
        <h6><b>Appraised Value</b></h6>
      </div>
       <p class="pt-4"> Once we’ve approved your request to sell equity,
       we will order an interior appraisal of your property from a local independent appraiser. 
       The appraised value will serve as the non-negotiable value 
       at which investors can “buy shares” of your house.  </P>
   `,

    // tooltipClass: "myTooltipClass",
    // highlightClass: "myHighlightClass",
    showBullets: false,
  },
  {
    position: "top",
    element: ".owner_percent_sold",
    intro: `
      <div>
      <h6><b>Percent of value sold</b></h6>
    </div>
    <p class="pt-3"> You may choose to sell some percent of your 
      property's value. This amount would usually be
      capped such that the amount plus any mortgage
      balance you're not paying off is less than 60% 
      of your property's appraised value.
      You would receive the amount you sell minus
      some fees as a lump sum payment.</P>
      `,
  },
  {
    position: "top",
    element: ".Percent_of_payment_made_with_cash",
    intro: `
      <div>
      <h6><b>Percent of payment made with cash</b></h6>
    </div>

    <p class="pt-3">This is the percentage of the yield payments you choose to pay with cash. Any amount less than the amount owed paid in cash gets converted to additional equity for the investors.</P>
      
      `,
  },
  {
    position: "top",
    element: ".appreciation_owner",
    intro: `
      <div>
      <h6><b>Appreciation</b></h6>
    </div>

    <p class="pt-3">After you receive the investment, your property may go up or down in value. That is the risk you are transferring to investors on the portion you sell. This slider simulates what would happen to the amount you would owe with different rates of appreciation or depreciation. Note that while in reality prices go up and down at varying rates year-to-year, this model assumes a constant rate.</P>
      
      `,
  },
  {
    position: "top",
    element: ".owner_sale_refi",
    intro: `
      <div>
      <h6><b>Year you sell property or buy out investors</b></h6>
    </div>

    <p class="pt-3">This is the number of years since the original investment until you either sell the property or buy out investors. You may choose to terminate the contract in either of these ways at any time during your contract length.
    </P>
      
      `,
  },
  {
    position: "top",
    element: ".owner_yield",
    intro: `
      <div>
      <h6><b>Yield</b></h6>
    </div>

    <p class="pt-3">Yield % is the percentage of the value of the investors' stake you owe to them each year with any combination of cash and additional equity. Yield is determined through an auction process. You set the maximum yield you're willing to pay; investors compete among themselves to offer you a lower yield. The result is that you get the lowest rate the market is willing to offer you.</P>
      
      `,
  },
];

export const InvesterSteps = [
  {
    element: ".invester_appraised",
    position: "top",
    intro: ` 
      <div>
        <h6><b>Appraised Value</b></h6>
      </div>
      <p class="pt-3">Before putting a property on the marketplace, we will order an interior appraisal of each property. The appraised value that comes from this process will serve as the value at which you may “buy shares” of the house.
      
      </P>
 `,

    // tooltipClass: "myTooltipClass",
    // highlightClass: "myHighlightClass",
    showBullets: false,
  },
  {
    position: "top",
    element: ".invester_percent_sold",
    intro: `
      <div>
      <h6><b>Percent of value sold</b></h6>
    </div>
    <p class="pt-3"> You may choose to invest in some percent of the property's value, up to the total amount the property owner is seeking. You will be entitled to this percentage of the eventual sale price plus annual payments based on the value of your stake.</P>
      
      `,
  },
  {
    position: "top",
    element: ".invester_Percent_of_payment_made_with_cash",
    doneLabel: "Done",
    intro: `
      <div>
      <h6><b>Percent of payment made with cash</b></h6>
    </div>
    <p class="pt-3">This is the percentage of the yield payments the owner chooses to pay with cash. Any amount less than the amount owed paid in cash gets converted to additional equity for the investors.</P>
      `,
  },
  {
    position: "top",
    element: ".invester_appreciation",
    intro: `
      <div>
      <h6><b>Appreciation</b></h6>
    </div>

    <p class="pt-3">After you make an investment in a property, the property may go up or down in value. The value of your investment will fluctuate proportionally. This slider lets you simulate your returns in various appreciation or depreciation scenarios, assuming a constant annual rate of appreciation.</P>
      
      `,
  },
  {
    position: "top",
    element: ".invester_sale_refi",
    intro: `
      <div>
      <h6><b>Year owner sells property or refinances</b></h6>
    </div>

    <p class="pt-3">This is the number of years since the original investment until the property owner either sells the property or buy out investors by getting another appraisal. The owner may choose to terminate the contract in either of these ways at any time during their contract length.</P>
      
      `,
  },
  {
    position: "top",
    element: ".invester_yield",
    intro: `
      <div>
      <h6><b>Yield</b></h6>
    </div>

    <p class="pt-3">Yield % is the percentage of the value of your stake the owner owes to you each year with any combination of cash and additional equity. Yield is determined through an auction process. The property owner sets a maximum yield they are willing to pay; investors compete to offer a lower yield.</P>
      
      `,
  },
];
