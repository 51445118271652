import axios from "./axios-server";

export const axios_getMsaDataFromCode = (code, callback) => {
  axios
    .get(`/msa/getMsaDataByCode/${code}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};

export const axios_getMsaArticleData = (code, callback) => {
  axios
    .get(`/msa/getMsasArticleData/${code}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
}


export const axios_getAllMsasData = (page, callback) => {
  axios
    .get(`/msa/get-all-msas/${page}/10`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};


export const axios_getMsasChildDataByMsasCode = (slug, regionType, page, callback) => {
  axios.get(`/msa/getMsasChildByMsasCode/${slug}/${regionType}/${page}/10`, {
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
}
