import React from 'react'
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setToast } from '../../store/actions/toast';
import { useEffect } from 'react';

export default function PageNotFound() {
  const history = useHistory();
  const dispatch = useDispatch();
  
  useEffect(()=>{
    dispatch(
      setToast([
        {
          msg: "Sorry; we can't find that page.",
          type: "error",
        },
      ])
    );
    history.push("/")
  },[dispatch, history])

  return (
    <div>
      <h4>Page not found!</h4>
    </div>
  )
}
