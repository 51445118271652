export const SET_ALERT='SET_ALERT';
export const REMOVE_ALERT='REMOVE_ALERT';

export const SET_TOAST='SET_TOAST';



export const REGISTER_SUCCESS='REGISTER_SUCCESS';
export const REGISTER_FAIL='REGISTER_FAIL';
export const RESET_PASSWORD='RESET_PASSWORD';

export const LOAD_USER='LOAD_USER';
export const AUTH_ERROR='AUTH_ERROR';

export const LOGIN_SUCCESS='LOGIN_SUCESS';
export const LOGIN_ERROR='LOGIN_ERROR';

export const LOGOUT='LOGOUT';



export const START_LOADING='START_LOADING';
export const STOP_LOADING='STOP_LOADING';


export const SET_HERO_DATA = 'SET_HERO_DATA';
export const GET_HERO_DATA = 'GET_HERO_DATA';
