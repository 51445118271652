import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import {
  axios_getZipcodeArticleData,
  axios_getZipcodeDataFromCode,
  axios_getZipcodesNearby
} from "../../../utills/zipcode-routes";
import Footer from "../../../Component/Footer/Footer";
import Head from "../../../Component/Head";
import Header from "../../../Component/Header/Header";
import CbsasTable from "../../../Component/RegionComponents/CbsasTable";
import CityTable from "../../../Component/RegionComponents/CityTable";
import CountiesTable from "../../../Component/RegionComponents/CountiesTable";
import CsasTable from "../../../Component/RegionComponents/CsasTable";
import HpiTable from "../../../Component/RegionComponents/HpiTable";
import MsaTable from "../../../Component/RegionComponents/MsaTable";
import StateTable from "../../../Component/RegionComponents/StateTable";
import ZipcodeTable from "../../../Component/RegionComponents/ZipcodeTable";
import PropertyRegionChart from "../../../Component/PropertyRegionChart/PropertyRegionChart";
import GetStarted from "../../../Component/GetStarted/GetStarted";

export default function ZipcodePage() {
  const { slug } = useParams();
  const history = useHistory();

  const [zipcodeData, setZipcodeData] = useState([]);
  const [stateData, setStateData] = useState();
  const [countyData, setCountyData] = useState();
  const [cityData, setCityData] = useState([]);
  const [cbsaData, setCbsaData] = useState([]);
  const [hpiData, setHpiData] = useState([]);
  const [msaData, setMsaData] = useState([]);
  const [csaData, setCsaData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [zipcode, setZipcode] = useState(true);
  const [zipcodeArticalContent, setZipcodeArticalContent] = useState();
  const [zipcodeNearbyData, setZipcodeNearbyData] = useState();

  useEffect(() => {
    setIsLoading(true);
    axios_getZipcodeDataFromCode(slug, zipcodeCallback);
    axios_getZipcodeArticleData(slug, zipcodeArticleDataCallback);
    axios_getZipcodesNearby(slug, zipcodesNearbyCallback);
  }, [slug]);

  const zipcodeCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setCountyData(response.data.data.county);
        setCityData(response.data.data.city);
        setStateData(response.data.data.state);
        if (response.data.data.county[0].cbsa !== null) {
          setCbsaData([response.data.data.county[0].cbsa]);
        }
        if (
          response.data.data.county[0].hpi !== null &&
          response.data.data.county[0].hpi !== undefined
        ) {
          setHpiData([response.data.data.county[0].hpi]);
        }
        if (response.data.data.county[0].msa !== null) {
          setMsaData([response.data.data.county[0].msa]);
        }
        if (response.data.data.county[0].csa !== null) {
          setCsaData([response.data.data.county[0].csa]);
        }
        setZipcode(response.data.data.zipcode[0].zipcode);
        setZipcodeData(response.data.data.zipcode);
        if (window.location.hash) {
          document
            .querySelector('[id="' + window.location.hash.substring(1) + '"]')
            .scrollIntoView();
        }
      }
    }
    setIsLoading(false);
  };

  const zipcodeArticleDataCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setZipcodeArticalContent(response.data.data);
      } else {
        history.push("/notfound");
      }
    }
  };

  const zipcodesNearbyCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setZipcodeNearbyData(response.data.data);
      }
    }
  };

  return (
    <>
      <div className="App">
        <Header />

        {isLoading === true && (
          <div className="text-center pt-5" style={{ marginTop: 120 }}>
            <div className="spinner-grow text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p>Loading. Please wait...</p>
          </div>
        )}

        {zipcodeData && zipcodeData[0] && (
          <>
            <Head
              title={`${zipcode} - ${cityData && cityData[0] && cityData[0].name}, ${
                stateData && stateData[0] && stateData[0].abbrev
              } zip code on Exuity`}
            />
            <section className="region-sec" style={{ marginTop: 140 }}>
              <ul className="breadcrumb region-breadcrumb arr-right bg-ffffff">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <img
                      src={require("../../../Images/breadcrumb-home.svg")}
                      alt="Exuity Home Icon"
                    />
                  </Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
                <li className="breadcrumb-item">
                  <Link to="/regiontypes">Region type</Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
                <li className="breadcrumb-item">
                  <Link to="/zipcodes">Zip codes</Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>{" "}
                <li className="breadcrumb-item">
                  <Link to={`/states/${stateData && stateData[0] && stateData[0].slug}#zipcodes`}>
                    {stateData && stateData[0] && stateData[0].name}
                  </Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>{" "}
                <li className="breadcrumb-item">
                  <Link
                    to={`/counties/${stateData && stateData[0] && stateData[0].slug}/${
                      countyData && countyData[0] && countyData[0].slug
                    }#zipcodes`}>
                    {countyData && countyData[0] && countyData[0].name}
                  </Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>{" "}
                <li className="breadcrumb-item">
                  <Link
                    to={`/cities/${stateData && stateData[0] && stateData[0].slug}/${
                      cityData && cityData[0] && cityData[0].slug
                    }#zipcodes`}>
                    {cityData && cityData[0] && cityData[0].name}
                  </Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>{" "}
                <li className="breadcrumb-item" aria-current="page">
                  <Link to={`/zipcodes/${zipcode}`} onClick={() => window.location.reload()}>
                    {zipcode}
                  </Link>
                </li>
              </ul>

              <p className="region-sub-heading">Zip Code:</p>
              <h1 className="region-title">
                {zipcode}, {cityData && cityData[0] && cityData[0].name},{" "}
                {stateData && stateData[0] && stateData[0].abbrev}{" "}
              </h1>
              <p className="region-paragraph">
                {zipcode} is a{" "}
                <Link to={`/states/${stateData && stateData[0] && stateData[0].slug}`}>
                  {stateData && stateData[0] && stateData[0].name}
                </Link>{" "}
                Zip code within the city of{" "}
                <Link
                  to={`/cities/${stateData && stateData[0] && stateData[0].slug}/${
                    cityData && cityData[0] && cityData[0].slug
                  }`}>
                  {cityData && cityData[0] && cityData[0].name}
                </Link>{" "}
                and the county of{" "}
                <Link
                  to={`/counties/${stateData && stateData[0] && stateData[0].slug}/${
                    countyData && countyData[0] && countyData[0].slug
                  }`}>
                  {countyData && countyData[0] && countyData[0].name}
                </Link>
                . Its population is roughly{" "}
                {Number(zipcodeData[0].population).toLocaleString("en-US")}.
                {msaData && msaData[0] && (
                  <>
                    {" "}
                    Its home values are tracked by the{" "}
                    <Link to={`/msas/${msaData[0] && msaData[0].slug}`}>
                      {msaData[0] && msaData[0].name}
                    </Link>{" "}
                    home price index.
                  </>
                )}
              </p>

              <div className="article">
                <h2 className="articletitle">
                  {zipcodeArticalContent && zipcodeArticalContent.article_title}
                </h2>
                <div className="articlecontent">
                  {zipcodeArticalContent &&
                    zipcodeArticalContent.article &&
                    zipcodeArticalContent.article
                      .split("\n")
                      .map((item, i) => (
                        <p key={i}>{<span dangerouslySetInnerHTML={{ __html: item && item }} />}</p>
                      ))}
                </div>
              </div>

              <div>
                {hpiData && hpiData[0] && (
                  <>
                    <p>
                      Price Index:{" "}
                      <Link to={`/hpis/${hpiData && hpiData[0] && hpiData[0].slug}`}>
                        {hpiData && hpiData[0] && hpiData[0].name}
                      </Link>
                    </p>
                    <PropertyRegionChart hpiData={hpiData && hpiData[0] && hpiData[0].hpi_values} />
                  </>
                )}

                {zipcodeData && zipcodeData.length > 1 && <ZipcodeTable tableData={zipcodeData} />}
                {zipcodeNearbyData && zipcodeNearbyData.length > 0 && (
                  <ZipcodeTable tableData={zipcodeNearbyData} tableTitle={"Zip codes nearby"} />
                )}
                {countyData && countyData.length > 1 && <CountiesTable tableData={countyData} />}
                {cityData && cityData.length > 1 && <CityTable tableData={cityData} />}
                {stateData && stateData.length > 1 && <StateTable tableData={stateData} />}
                {cbsaData && cbsaData.length > 1 && <CbsasTable tableData={cbsaData} />}
                {hpiData && hpiData.length > 1 && <HpiTable tableData={hpiData} />}
                {msaData && msaData.length > 1 && <MsaTable tableData={msaData} />}
                {csaData && csaData.length > 1 && <CsasTable tableData={csaData} />}
              </div>
              <GetStarted
                name={`Buy & Sell Shares of Homes in ${zipcode}, ${
                  cityData && cityData[0] && cityData[0].name
                }, 
                ${stateData && stateData[0] && stateData[0].abbrev}`}
                description="Exuity lets property owners sell shares of their properties to investors who earn income and appreciation."
              />
            </section>
          </>
        )}
        <div className="footer-section">
          <Footer />
        </div>
      </div>
    </>
  );
}
