import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  axios_getCbsasChildDataByCbsasCode,
  axios_getCbsasDataFromCode
} from "../../../utills/cbsa-routes";
import Footer from "../../../Component/Footer/Footer";
import Head from "../../../Component/Head";
import Header from "../../../Component/Header/Header";
import CbsasTable from "../../../Component/RegionComponents/CbsasTable";
import CountiesTable from "../../../Component/RegionComponents/CountiesTable";
import HpiTable from "../../../Component/RegionComponents/HpiTable";
import ZipcodeTable from "../../../Component/RegionComponents/ZipcodeTable";
import { multirand } from "../../../utills/helper";
import GetStarted from "../../../Component/GetStarted/GetStarted";
import CityTable from "../../../Component/RegionComponents/CityTable";

export default function CbsasPage() {
  const { slug } = useParams();
  const [cbsasData, setCbsasData] = useState();
  const [countyData, setCountyData] = useState([]);
  const [hpiData, setHpiData] = useState([]);
  const [randomcounties, setRandomCounties] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [zipcodeData, setZipcodeData] = useState();
  const [cityData, setCityData] = useState();
  const [zipcodes, setZipcodes] = useState([]);
  const [zipcodeCurrentPage, setZipcodeCurrentPage] = useState(0);
  const [totalZipcodeCount, setTotalZipcodeCount] = useState();
  const [countiesCurrentPage, setCountiesCurrentPage] = useState(0);
  const [totalCountiesCount, setTotalCountiesCount] = useState(0);
  const [citiesCurrentPage, setCitiesCurrentPage] = useState(0);
  const [totalCitiesCount, setTotalCitiesCount] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    axios_getCbsasDataFromCode(slug, getCbsasCallback);
  }, [slug]);

  useEffect(() => {
    var start = zipcodeCurrentPage * 10;
    var end = start + 10;
    if (zipcodeData) {
      setZipcodes(zipcodeData.slice(start, end));
    }
  }, [zipcodeData, zipcodeCurrentPage]);

  useEffect(() => {
    axios_getCbsasChildDataByCbsasCode(
      slug,
      "counties",
      countiesCurrentPage,
      getCbsasChildDataCbsasCallback
    );
  }, [slug, countiesCurrentPage]);

  useEffect(() => {
    axios_getCbsasChildDataByCbsasCode(
      slug,
      "cities",
      citiesCurrentPage,
      getCbsasChildDataCitiesCallback
    );
  }, [slug, citiesCurrentPage]);

  const getCbsasCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        console.log(response.data.data.zipcodes);
        setRandomCounties(multirand(response.data.data.counties, 2));
        // setCountyData(response.data.data.counties);
        setHpiData(response.data.data.hpis);
        setZipcodeData(response.data.data.zipcodes);
        setTotalZipcodeCount(response.data.data.zipcodes.length);
        setZipcodes(response.data.data.zipcodes.slice(0, 10));
        setCbsasData(response.data.data.cbsas);
        if (window.location.hash) {
          document
            .querySelector('[id="' + window.location.hash.substring(1) + '"]')
            .scrollIntoView();
        }
      }
    }
    setIsLoading(false);
  };

  const getCbsasChildDataCbsasCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setTotalCountiesCount(response.data.data.count);
        setCountyData(response.data.data.rows);
      }
    }
    setIsLoading(false);
  };

  const getCbsasChildDataCitiesCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setTotalCitiesCount(response.data.data.count);
        setCityData(response.data.data.rows);
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="App">
        <Header />
        {cbsasData && cbsasData.length > 0 && (
          <>
            <Head
              title={`${
                cbsasData && cbsasData[0] && cbsasData[0].name
              } - Core-Based Statistical Area (CBSA) on Exuity`}
            />
            <section className="region-sec" style={{ marginTop: 140 }}>
              <ul className="breadcrumb region-breadcrumb arr-right bg-ffffff">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <img
                      src={require("../../../Images/breadcrumb-home.svg")}
                      alt="Exuity Home Icon"
                    />
                  </Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
                <li className="breadcrumb-item">
                  <Link to="/regiontypes">Region type</Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
                <li className="breadcrumb-item">
                  <Link to="/cbsas">Core-Based Statistical Areas (CBSAs)</Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>{" "}
                <li className="breadcrumb-item" aria-current="page">
                  <Link to={`/cbsas/${slug}`} onClick={() => window.location.reload()}>
                    {cbsasData && cbsasData[0] && cbsasData[0].name}
                  </Link>
                </li>
              </ul>

              <p className="region-sub-heading">Core-Based Statistical Area (CBSA):</p>
              <h1 className="region-title">{cbsasData && cbsasData[0].name}</h1>

              <p className="region-paragraph">
                {cbsasData[0].name} is a Core-Based Statistical Area (CBSA) comprising counties
                including{" "}
                {randomcounties.map((item, index) => {
                  if (index === 1) {
                    return (
                      <>
                        {" "}
                        and{" "}
                        <Link
                          key={`c-${index}`}
                          className="region-link"
                          to={`/counties/${item.state.slug}/${item.slug}`}>
                          {item.name}
                        </Link>
                      </>
                    );
                  }
                  return (
                    <Link
                      key={`c-${index}`}
                      className="region-link"
                      to={`/counties/${item.state.slug}/${item.slug}`}>
                      {item.name}
                    </Link>
                  );
                })}
                .
              </p>

              {cbsasData && cbsasData.length > 1 && <CbsasTable tableData={cbsasData} />}
              {countyData && countyData.length > 1 && (
                <CountiesTable
                  tableData={countyData}
                  tableTitle={`Counties in ${cbsasData && cbsasData[0].name}`}
                  totalPageCount={totalCountiesCount}
                  currentPage={countiesCurrentPage}
                  setCurrentPage={setCountiesCurrentPage}
                />
              )}

              {cityData && cityData.length > 1 && (
                <CityTable
                  tableData={cityData}
                  tableTitle={`Cities in ${cbsasData && cbsasData[0].name}`}
                  totalPageCount={totalCitiesCount}
                  currentPage={citiesCurrentPage}
                  setCurrentPage={setCitiesCurrentPage}
                />
              )}
              {hpiData.length > 1 && <HpiTable tableData={hpiData} />}
              {zipcodeData && zipcodeData.length > 1 && (
                <ZipcodeTable
                  tableData={zipcodes}
                  tableTitle={`Zip codes in ${cbsasData && cbsasData[0].name}`}
                  totalPageCount={totalZipcodeCount}
                  currentPage={zipcodeCurrentPage}
                  setCurrentPage={setZipcodeCurrentPage}
                />
              )}
              <GetStarted
                name={`Buy & Sell Shares of Homes in ${
                  cbsasData && cbsasData[0] && cbsasData[0].name
                }`}
                description={
                  "Exuity lets property owners sell shares of their properties to investors who earn income and appreciation."
                }
              />
            </section>
          </>
        )}

        {isLoading === true && (
          <div className="text-center pt-5" style={{ marginTop: 120 }}>
            <div className="spinner-grow text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p>Loading. Please wait...</p>
          </div>
        )}
        <div className="footer-section">
          <Footer />
        </div>
      </div>
    </>
  );
}
