import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTable, usePagination } from "react-table";

export default function HpiTable(props) {
  const history = useHistory();
  const { setCurrentPage } = props;
  const [tableData, setTableData] = useState([]);
  const [totalPageCount, setTotalPageCount] = useState();

  useEffect(() => {
    if (props.tableData !== undefined) {
      setTableData(props.tableData);
    }
    if (props.totalPageCount !== undefined) {
      setTotalPageCount(props.totalPageCount);
    }
  }, [props]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: (row) => {
          return <span className="region-link">{row.value}</span>;
        }
      },
      {
        Header: "URL",
        accessor: "url",
        Cell: (row) => {
          return `${row.value === "null" ? "" : row.value}`;
        }
      }
    ],
    []
  );

  const data = tableData;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    prepareRow
  } = useTable(
    {
      manualPagination: true,
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      pageCount: totalPageCount ? Math.ceil(totalPageCount / 10) : null
    },
    usePagination
  );

  const { pageIndex } = state;

  useEffect(() => {
    if (setCurrentPage !== undefined) {
      setCurrentPage(pageIndex);
    }
  }, [pageIndex, setCurrentPage]);

  return (
    <>
      <section className="region-container" id="hpis">
        <div className="countywise">
          <div className="region-table-wrapper">
            <h3 className="region-sub-title">{!totalPageCount && "Hpi"}</h3>
            {tableData && (
              <table
                {...getTableProps()}
                className="region-table table table-responsive"
                responsive="lg">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr className="header" {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  <>
                    {page.map((row, index) => {
                      prepareRow(row);
                      return (
                        <tr
                          key={index}
                          className="clickable-link"
                          onClick={() => history.push(`/hpis/${row.original.slug} `)}>
                          {row.cells.map((cell, key) => {
                            return (
                              <td className="dash-tbl-col" {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </>
                </tbody>
              </table>
            )}
            {totalPageCount && props.totalPageCount > 10 && (
              <div className="region-pagination">
                <button
                  className="btn btn-primary"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}>
                  {"<<"}
                </button>{" "}
                <button
                  className="btn btn-primary"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}>
                  Previous
                </button>{" "}
                <button
                  className="btn btn-primary"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}>
                  Next
                </button>{" "}
                <button
                  className="btn btn-primary"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}>
                  {">>"}
                </button>{" "}
                <span className="pagination-text">
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
