import React from "react";
import Sidenav from "../Sidenav";
import "../Dashboard.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import TableInvestorIssunaces from "./TableInvestorIssuances";
import Head from "../../Component/Head";

class IssuancesInvestor extends React.Component {
	render() {
		return (
			<div className="Dashboard">
				<Head title={'Exuity | Investor | Issuance'} />
				<div className="DashboardGroup">
					<div className="auction-navbar">
						<div className="dashTitle">
							<h4>Issuances You Participate In</h4>
						</div>
						<Sidenav />
					</div>
					<div className="row">
						<div className="auction-navbar1">
							<Sidebar />
						</div>
						<div className="DashContent pt-0 pt-lg-5">
							<div className="AuctionPage">
								<TableInvestorIssunaces />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default IssuancesInvestor;
