import axios from './axios-server';

export const axios_getPaymentMadeList=( page, limit, callback)=>{
    axios
    .get(`payment-made/list/${page}/${limit}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong ", error)
    });  
}

export const axios_getPaymentMadeDetailsByCode=(code, callback)=>{
    axios
    .get(`/payment-made/getPaymentMadeDetailsByCode/${code}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong ", error)
    });  
}
