import React from "react";
import "../Dashboard.css";
import "./ExistingBidCard.css";

class ExistingBidCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          property: "Brush Park, Detroit, MI",
          id: "34ft6r",
          centOnDollar: "3.1¢",
          amountToInvest: "$20,000"
        },
        {
          property: "Music Row, Nashville, TN",
          id: "16rhg7",
          centOnDollar: "4.9¢",
          amountToInvest: "$52,000"
        },
        {
          property: "Ruby Hills Single Family",
          id: "iuhi3y",
          centOnDollar: "4.9¢",
          amountToInvest: "$12,000"
        }
      ]
    };
  }
  componentDidMount() {
    const property = this.props.auction.property.property_address;
    this.props.auction.bids.filter(function (v, i) {
      v.property = property;
      return v;
    });
    this.setState({ data: this.props.auction.bids });
  }
  render() {
    return (
      <div className="SubmitBidCard ExistingBidCard">
        <div className="exb-table existingbidcard-table">
          <div className="SubmitBidCardTitle">
            <h6 className="SubmitBidCardHeading">Existing Bids</h6>
            <img src={require("../../Images/exiting-biding-dotted-icon.svg")} alt="Auction img" />
          </div>
          <table className="table table-borderless table-responsive" responsive="lg">
            <thead>
              <tr className="header">
                <th scope="col" className="text-dark">
                  Bid Id
                </th>
                <th scope="col" className="text-dark">
                  Investor
                </th>
                <th scope="col" className="text-dark">
                  Yield
                </th>
                <th scope="col" className="text-dark">
                  Amount Offered
                </th>
                <th scope="col" className="text-dark">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.auction.bids.map((item, index) => (
                <tr key={index}>
                  <td className="text-dark">{item.code}</td>
                  <td className="text-dark">
                    {item.user.first_name} {item.user.last_name}
                  </td>
                  <td className="text-dark">
                    {item.yield === null ? "-" : (item.yield * 100).toFixed(2) + "%"}
                  </td>
                  <td className="text-dark">${item.amount_offered.toLocaleString("en-US")}</td>
                  <td className="status-col text-dark">
                    {item.status === "Won" ? (
                      <span className="tag--success">Won</span>
                    ) : item.status === "Cancelled" ? (
                      <span className="tag--closed">Cancelled</span>
                    ) : item.status === "Active" ? (
                      <span className="tag--active">Active</span>
                    ) : item.status === "Insufficent_funds" ? (
                      <span className="tag--closed">Insufficent_funds</span>
                    ) : (
                      <span className="tag--closed">{item.status}</span>
                    )}
                  </td>
                </tr>
              ))}
              {this.props.auction.bids.length === 0 && (
                <tr>
                  <td colSpan="4" className="text-center text-dark">
                    No bids yet
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default ExistingBidCard;
