import React from "react";
import Sidenav from "../../components/Sidenav";
import "../../components/Dashboard.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import { connect } from "react-redux";
import { loadUser, startLoading, logout } from "../../store/actions/auth";
import store from "../../store/store";
import { NavLink } from "react-router-dom";
import EditProfileForm from "../../components/EditProfileForm";
import Head from "../../Component/Head";

class ProfileUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      found: 0,
    };
  }
  componentDidMount() {
    store.dispatch(startLoading());
    store.dispatch(loadUser());
  }
  render() {
    return (
      <div className="Dashboard" id="Dashboard">
        <div className="DashboardGroup">
          <Head title="Update Profile" />
          <Sidenav />
          <div className="row">
            <div className="">
              <Sidebar />
            </div>

            <div className="Dashboard" id="Dashboard">
              <div className="DashboardGroup">
                <div className="auction-navbar">
                  <div className="dashTitle">
                    <h4>My Profile</h4>
                  </div>
                  <div className="createauction-btn createauction-btn-2">
                    {/* <div className="addPr  opert-btn"> */}
                    <NavLink to="/profile">
                      <button type="button">View Profile</button>
                    </NavLink>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="DashContent">
              <div className="PropertyCardGroups">
                {this.props.user != null ? (
                  <EditProfileForm user={this.props.user} />
                ) : (
                  <div className="text-center pt-5">
                    <div className="spinner-grow text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <p>Loading. Please wait...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
});
export default connect(mapStatetoProps, { loadUser, startLoading, logout })(
  ProfileUpdate
);
