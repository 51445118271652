import { useEffect, useState, useCallback } from "react";

// const KEY_NAME_ESC = "Escape";
// const KEY_EVENT_TYPE = "keyup";

const useModal = () => {
	const [isShowing, setIsShowing] = useState(false);

	function toggle() {
		setIsShowing(!isShowing);
	}

	const escapePressed = useCallback((e) => {
		if (e.keyCode === 27) {
			setIsShowing(false);
		}
	}, []);

	useEffect(() => {
		document.addEventListener("keydown", escapePressed);
	}, [escapePressed])


	// const handleEscKey = useCallback((event) => {
	// 	if (event.key === KEY_NAME_ESC) {
	// 		toggle();
	// 	}
	// });

	// useEffect(() => {
	// 	document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

	// 	return () => {
	// 		document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
	// 	};
	// }, [handleEscKey]);

	return {
		isShowing,
		toggle,
	};
};

export default useModal;
