import commaNumber from "comma-number";

export const str_into_float_num = (num) => {
	const strIntoNum = parseFloat(num).toFixed(2)
    const usd_currency = commaNumber(strIntoNum)
    return usd_currency
}

export const str_into_num = (num) => {
	const strIntoNum = parseInt(num)
    const usd_currency = commaNumber(strIntoNum)
    return `${usd_currency}`
}
