import { logout } from "../store/actions/auth";
import { useDispatch } from "react-redux";
import "./Form.css";

const LogoutPage = (props) => {
  const dispatch = useDispatch();
  return dispatch(logout());
};

export default LogoutPage;
