import axios from './axios-server';


export const axios_createVerificationSection = (callback) => {
    return axios.post('stripe/create-verification-session', 
    { data: "" }, 
    {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong ", error)
    });
}