import React from "react";
import { imageChecker } from "../../config/imgBaseUrl";

class SearchCard extends React.Component {
  constructor(props) {
    super(props);
    this.setMarker.bind(this);
    this.that = this;
    this.state = {};
    this.handleHover = this.handleHover.bind(this);
  }
  handleHover() {
    this.props.setHover(this.props.property);
  }
  setMarker(props) {
    this.props.setMarker(this.props.property);
  }
  render() {
    return (
      <div onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
        <a href={"/property/" + this.props.property.code}>
          <div className="searchCardGroup">
            <div className="s-card-img">
              <img src={imageChecker("")} alt="Auction img" />
              <span className="price-tag">$3.4m</span>
              <div className="active-issuance">
                <span>
                  Active -{" "}
                  {this.props.property && this.props.property.property_type}
                </span>
              </div>
            </div>
            <div className="s-card-texts">
              <h6>{this.props.property.property_address}</h6>

              <p>
                Appraised at: <span className="color-bold">$3,412,581</span>
              </p>
              <p>
                Yield: <span className="bold">TBD</span>
              </p>
              <p>
                Investments: <span className="bold">$5k - $200K</span>
              </p>

              <div className="s-cards-icons">
                {this.props.property.bedrooms === 0 &&
                  this.props.property.bedrooms === "0" && (
                    <span className="beds-grey s-cta">
                      {this.props.property.bedrooms} br
                    </span>
                  )}
                {this.props.property.bathrooms === 0 &&
                  this.props.property.bathrooms === "0" && (
                    <span className="baths-grey s-cta">
                      {this.props.property.bathrooms} ba
                    </span>
                  )}
                {this.props.property.builtup_area === 0 &&
                  this.props.property.builtup_area === "0" && (
                    <span className="sqft-grey s-cta">
                      {this.props.property.builtup_area} ft
                    </span>
                  )}
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default SearchCard;
