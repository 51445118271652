import React, { useState } from "react";
import { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Slider from "react-slick";
import { OverlayTrigger } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import Tooltip from "react-bootstrap/Tooltip";
import { axios_getFeaturedRegions } from "../../utills/axios-featuredRegions";
import styles from "./FeaturedRegions.module.css";

export default function FeaturedRegions() {
  const [featuredRegionsData, setFeaturedRegionsData] = useState([]);
  useEffect(() => {
    axios_getFeaturedRegions(featuredRegionsCallback);
  }, []);

  const featuredRegionsCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setFeaturedRegionsData(response.data.data);
      }
    }
  };

  const SlickNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className={`${styles.slick_next_arrow} ${styles.slick_arrows}`}
        onClick={() => onClick()}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-arrow-right">
          <line x1="5" y1="12" x2="19" y2="12"></line>
          <polyline points="12 5 19 12 12 19"></polyline>
        </svg>
      </div>
    );
  };

  const SlickPrevArrow = (props) => {
    const { onClick } = props; //onClick from slick
    return (
      <div
        className={`${styles.slick_prev_arrow} ${styles.slick_arrows}`}
        onClick={() => onClick()}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-arrow-left">
          <line x1="19" y1="12" x2="5" y2="12"></line>
          <polyline points="12 19 5 12 12 5"></polyline>
        </svg>
      </div>
    );
  };

  var slickSettings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="region_card_container">
      <ReactTooltip />
      <div className="text-center">
        <h3 className={`${styles.featured_region_title}`}>Featured Regions</h3>
      </div>
      <div className={`${styles.featured_container_wrapper}`}>
        {featuredRegionsData.length > 0 ? (
          <Slider {...slickSettings}>
            {featuredRegionsData.map(
              (item, index) =>
                item.featuredTitle !== null &&
                item.featuredTitle !== "" &&
                item.featuredContent !== null &&
                item.featuredContent !== "" && (
                  <NavLink to={item.featuredLink}>
                    <div key={index} className={`${styles.featured_regions_card}`}>
                      <div>
                        <span className={`${styles.slider_badge}`}>
                          <Link to={item.tagLink}>{item.tag}</Link>
                        </span>
                      </div>
                      <OverlayTrigger placement="top" overlay={
												<Tooltip id="tooltip-1">
													{item.featuredTitle}
												</Tooltip>
											}>
                      <h5 className={`${styles.featured_card_title}`}>
                        <Link to={item.featuredLink}>{item.featuredTitle}</Link>
                      </h5>
                      </OverlayTrigger>
                      <p className={`${styles.featured_regions_text}`}>{item.featuredContent}</p>
                    </div>
                  </NavLink>
                )
            )}
          </Slider>
        ) : (
          <div className="text-center pt-5">
            <div className="spinner-grow text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p>Loading. Please wait...</p>
          </div>
        )}
      </div>
      <div className="text-center">
        <Link to="/regiontypes">
          <button className={`${styles.browse_all_btn} btn rounded-pill px-4 py-2`}>
            Browse all regions
          </button>
        </Link>
      </div>
    </div>
  );
}
