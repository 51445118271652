import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Toast from "../components/layout/Toast";
import { ToastProvider } from "react-toast-notifications";

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>		<ToastProvider>
    <Toast />
  </ToastProvider>
  {children}
  </>
  );
};

export default ScrollToTop;
