import axios from "./axios-server";

export const axios_getCbsasDataFromCode = (slug, callback) => {
  axios
    .get(`/cbsas/getCbsasDataByCode/${slug}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};


export const axios_getAllCbsasData = (page, callback) => {
  axios
    .get(`/cbsas/get-all-cbsas/${page}/10`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};



export const axios_getCbsasChildDataByCbsasCode = (slug, regionType, page, callback) => {
  axios.get(`/cbsas/getCbsasChildByCbsasCode/${slug}/${regionType}/${page}/10`, {
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
}