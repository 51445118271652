import axios from './axios-server';

export const axios_getClipFromAddressZip=(streetAddress, zip, callback)=>{
    axios
    .get(`/search/property?streetAddress=${streetAddress}&zipCode=${zip}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong ", error)
    });  
}
export const axios_getPropertyFromAddressZip=(streetAddress, zip, callback, errorCallback)=>{
    axios
    .get(`/search/property-by-address?streetAddress=${streetAddress}&zipCode=${zip}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong ", error)
        errorCallback(error)
    });  
}
export const axios_getClipData=(clip, callback)=>{
    axios
    .get(`/search/property-info?clip=${clip}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong ", error)
    });  
}