import React from "react";

const Spinner = () => {
  return (
    <div className="spinner-border text-secondary" role="status">
      <span className="visually-hidden"></span>
    </div>
  );
};

export default Spinner;
