export const BenefitsData = {
  modal1: {
    heading: "Definition: Appraised Value",
    paragraph: "https://embed.getguru.com/card/TBLnBGqc/Property-Owner-Benefit-1"
  },
  modal2: {
    heading: "How Easy Is Approval?",
    paragraph:"https://embed.getguru.com/card/cxgzRpEi/Property-Owner-Benefit-2"
  },
  modal3: {
    heading: "How Are Payments Optional?",
    paragraph:"https://embed.getguru.com/card/ideBE4MT/Property-Owner-Benefit-3"
  },
  modal4: {
    heading: "What Happens in an Economic Downturn?",
    paragraph: `When you commit to a traditional mortgage, the amount you owe is fixed, regardless of fluctuations in your home's value. With Vessel, the amount you "owe" increases or decreases in direct proportion to fluctuations in your home's value. During a downturn, you’ll owe less—and you won’t find yourself "underwater” or “upside down."`
  },
  modal5: {
    heading: "Where do my returns come from?",
    paragraph: "https://embed.getguru.com/card/cbK69jBi/Investor-Benefit-1"
  },
  modal6: {
    heading: "What Traditional Hassles Do Investors Avoid?",
    paragraph: "https://embed.getguru.com/card/ck4bKpki/Investor-Benefit-2"
  },
  modal7: {
    heading: "How Is Income Guaranteed?",
    paragraph: "https://embed.getguru.com/card/ia4bKpbT/Investor-Benefit-3"
  },
  modal8: {
    heading: "Can I sell my portion of the investment?",
    paragraph:
      "Vessel does not prevent you from reselling your stake of the investment. You are required by law to abide by restrictions on resale, including a one-year hold period. At present, Vessel does not match equity owners with potential purchasers, but we will support the administration of such private transactions."
  }
};

export const CalculationData = {
  modal1: {
    heading: "What Do I Receive as a Homeowner?",
    paragraph:
      "Vessel delivers the full financing amount upfront. Use your Vessel investment to pursue other investment opportunities, or to fund large expenditures such as a family vacation, paying off debt, doing home improvement, or making a down payment on another property. How will you use the money?"
  },
  modal2: {
    heading: "How Easy Is Approval?",
    paragraph:
      "We will seek a credit report and perform a title search. While we reject high-risk applicants, and notably, any homeowner with less than 50% equity built up (i.e. LTV > 50%), we approve many more types of property owners than mortgage lenders would, including owners of investment properties. Investors on Vessel assess the risks and make their investment offers accordingly."
  },
  modal3: {
    heading: "How Are Payments Optional?",
    paragraph:
      "Any amount underpaid from the annual fee gets automatically paid with equity at the then-fair market value. You have the option of making as little as $0 in cash payments for up to 10 years."
  },
  modal4: {
    heading: "What Happens in an Economic Downturn?",
    paragraph: `When you commit to a traditional mortgage, the amount you owe is fixed, regardless of fluctuations in your home's value. With Vessel, the amount you "owe" increases or decreases in direct proportion to fluctuations in your home's value. During a downturn, you’ll owe less—and you won’t find yourself "underwater” or “upside down."`
  },
  modal5: {
    heading: "Where do my returns come from?",
    paragraph: `When you invest in a property on Vessel, your invested money gets the benefits of appreciation along with the home's value and annual income on top. For example, if a property's "Dividend Yield" is X%, and the home price appreciates annually by Y%, then your gross annual return will be roughly (X+Y)% on principal invested over 10 years.`
  },
  modal6: {
    heading: "What Traditional Hassles Do Investors Avoid?",
    paragraph:
      "By investing in a home through Vessel instead of buying an investment property and renting it out, you'll avoid the hassles of being a landlord, such as finding tenants, fixing faucets, securing a mortgage, and paying property taxes."
  },
  modal7: {
    heading: "How Is Income Guaranteed?",
    paragraph: `The Homeowner owes a fixed percentage of the fair market value of your stake of equity as a “dividend” payment every year. We guarantee income as such: if the homeowner underpays (or does not pay at all) the annual fee they owe, we add 10% to the underpaid amount, and convert that to additional equity rights for the homeowner at the then-fair market value. We try ensure that the homeowner will have at least 20% equity remaining at the end of 10 years even if they pay $0 in payments for all 10 years."`
  },
  modal8: {
    heading: "Can I sell my portion of the investment?",
    paragraph:
      "Vessel does not prevent you from reselling your stake of the investment. You are required by law to abide by restrictions on resale, including a one-year hold period. At present, Vessel does not match equity owners with potential purchasers, but we will support the administration of such private transactions."
  },
  modal9: {
    heading: "Can I sell my portion of the investment?",
    paragraph:
      "Vessel does not prevent you from reselling your stake of the investment. You are required by law to abide by restrictions on resale, including a one-year hold period. At present, Vessel does not match equity owners with potential purchasers, but we will support the administration of such private transactions."
  },
  modal10: {
    heading: "Can I sell my portion of the investment?",
    paragraph:
      "Vessel does not prevent you from reselling your stake of the investment. You are required by law to abide by restrictions on resale, including a one-year hold period. At present, Vessel does not match equity owners with potential purchasers, but we will support the administration of such private transactions."
  }
};

export const FeatureData = {
  modal1: {
    heading: "Definition: Appraised Value",
    paragraph:
      "https://embed.getguru.com/card/cRK7B9Ki/Equity-Sharing"
  },
  modal2: {
    heading: "Definition: Loan to Value Ratio",
    paragraph: "https://embed.getguru.com/card/i8gzL74T/Annual-Income"
  },
  modal3: {
    heading: "Definition: Equity Sharing Percentage",
    paragraph: "https://embed.getguru.com/card/iXeBbqBT/Historic-Appreciation"
  },
  modal4: {
    heading: "Definition: Dividend Yield",
    paragraph: "https://embed.getguru.com/card/ideBby9T/Hypothetical-Returns"
  },
  modal5: {
    heading: "Definition: Historic Appreciation",
    paragraph:
      "This is the best estimate of the annualized appreciation over the past 10 years per Zillow data. Past appreciation does not indicate future appreciation, but it may serve as a guide."
  },
  modal6: {
    heading: "Definition: Internal Rate of Return (IRR)",
    paragraph:
      "IRR is a measure of what your annual return will be over the life of your investment. We calculate IRR as [1+Annual Appreciation]*[1+Dividend Yield]-1. This does not take into account Vessel’s fees, and assumes that past appreciation will stay constant."
  }
};

export const HowItData = {
  modal1: {
    heading: "How Am I Approved?",
    paragraph: "https://embed.getguru.com/card/c4kez8ri/How-Am-I-Approved"
  },
  modal2: {
    heading: "How Does the Appraisal Work, and How Is It Used?",
    paragraph: "https://embed.getguru.com/card/iBLnB5bT/How-Does-the-Appraisal-Work-and-How-Is-It-Used"
  },
  modal3: {
    heading: "How do auctions work?",
    paragraph: "https://embed.getguru.com/card/TxgzRA5c/Property-Owner-HIW-3"
  },
  modal4: {
    heading: "How Do I Receive the Money?",
    paragraph: `Cash is wired to you within 14 days of auction completion and document signing.`
  },
  modal5: {
    heading: "What are the requirements for investors?",
    paragraph: "https://embed.getguru.com/card/cdeBE8ei/Investor-HIW-1"
  },
  modal6: {
    heading: "How do I actually invest in deals?",
    paragraph: "https://embed.getguru.com/card/To4bK8Bc/Investor-HIW-2"
  },
  modal7: {
    heading: "How Is the Investment Finalized?",
    paragraph: "https://embed.getguru.com/card/cdeBEAEi/Investor-HIW-3"
  },
  modal8: {
    heading: "What Will My Return Be?",
    paragraph: `Once you make an investment, you'll immediately begin to accrue payments from the homeowner. You'll receive these payments either as cash or as additional equity rights at the homeowner’s discretion. When the property is sold, you'll receive an amount that corresponds to the equity you initially purchased plus the equity you accrued through underpaid annual payments. `
  }
};


export const Herodata = {
  modal1: {
    heading: "Definition: Appraised Value",
    paragraph: "https://embed.getguru.com/card/c5GjRpxi/Introduction"
  }
}

export const GetCertified = {
	modal1: {
		heading: "Clendly",
		paragraph: "https://calendly.com/vessel-nik",
	},
};