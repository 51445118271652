import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  axios_getCountiesChildDataByCountiesCode,
  axios_getCountyArticleData,
  axios_getCountyValuesFromCode
} from "../../../utills/counties";
import Footer from "../../../Component/Footer/Footer";
import Head from "../../../Component/Head";
import Header from "../../../Component/Header/Header";
import CbsasTable from "../../../Component/RegionComponents/CbsasTable";
import CityTable from "../../../Component/RegionComponents/CityTable";
import ContiesTable from "../../../Component/RegionComponents/CountiesTable";
import CsasTable from "../../../Component/RegionComponents/CsasTable";
import HpiTable from "../../../Component/RegionComponents/HpiTable";
import MsaTable from "../../../Component/RegionComponents/MsaTable";
import StateTable from "../../../Component/RegionComponents/StateTable";
import ZipcodeTable from "../../../Component/RegionComponents/ZipcodeTable";
import { simpleNumber, multirand } from "../../../utills/helper";
import PropertyRegionChart from "../../../Component/PropertyRegionChart/PropertyRegionChart";
import GetStarted from "../../../Component/GetStarted/GetStarted";

export default function CountiesPage() {
  const { state, county } = useParams();
  const [stateData, setStateData] = useState([]);
  const [hpiData, setHpiData] = useState([]);
  const [cbsaData, setcbsaData] = useState([]);
  const [csasData, setcsasData] = useState([]);
  const [msaData, setMsaData] = useState([]);
  const [countyData, setCountyData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [zipcodeData, setZipcodeData] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);
  const [zipcodeCurrentPage, setZipcodeCurrentPage] = useState(0);
  const [totalZipcodeCount, setTotalZipcodeCount] = useState();
  const [randomcities, setRandomCities] = useState();
  const [randomzipcodes, setRandomZipcodes] = useState();
  const [populationformat, setPopulation] = useState();

  const [citiesCurrentPage, setCitiesCurrentPage] = useState(0);
  const [totalCitiesCount, setTotalCitiesCount] = useState(0);
  const [countyArticalContent, setCountyArticalContent] = useState();

  useEffect(() => {
    setIsLoading(true);
    axios_getCountyValuesFromCode(state, county, getcountyDetailCallback);
    axios_getCountyArticleData(state, county, getCountyArticleCallback);
  }, [state, county]);

  useEffect(() => {
    var start = zipcodeCurrentPage * 10;
    var end = start + 10;
    if (zipcodeData) {
      setZipcodes(zipcodeData.slice(start, end));
    }
  }, [zipcodeData, zipcodeCurrentPage]);

  useEffect(() => {
    axios_getCountiesChildDataByCountiesCode(
      state,
      county,
      "cities",
      citiesCurrentPage,
      getCountiesChildDataCountiesCallback
    );
  }, [state, county, citiesCurrentPage]);

  const getcountyDetailCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        var population = 0;
        for (var i = 0; i < response.data.data.zipcodesDetails.length; i++) {
          population += response.data.data.zipcodesDetails[i].population;
        }
        setPopulation(simpleNumber(population), 0);
        setStateData(response.data.data.stateDetails);
        // setCityData(response.data.data.citiesDetails);
        setHpiData(response.data.data.hpiDetails);
        setcbsaData(response.data.data.cbsaDetails);
        setcsasData(response.data.data.csasDetail);
        setMsaData(response.data.data.msaDetails);
        setZipcodeData(response.data.data.zipcodesDetails);
        setTotalZipcodeCount(response.data.data.zipcodesDetails.length);
        setZipcodes(response.data.data.zipcodesDetails.slice(0, 10));
        // random cities and countries
        setRandomZipcodes(multirand(response.data.data.zipcodesDetails, 2));
        setRandomCities(multirand(response.data.data.citiesDetails, 2));
        //
        //  console.log(response.data.data.zipcodesDetails);
        setCountyData(response.data.data.counties);
        if (window.location.hash) {
          document
            .querySelector('[id="' + window.location.hash.substring(1) + '"]')
            .scrollIntoView();
        }
      }
    }
    setIsLoading(false);
  };

  const getCountiesChildDataCountiesCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setTotalCitiesCount(response.data.data.count);
        setCityData(response.data.data.rows);
      }
    }
  };

  const getCountyArticleCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setCountyArticalContent(response.data.data);
      }
    }
  };

  return (
    <>
      <div className="App">
        <Header />
        {countyData && countyData[0] && (
          <>
            <Head
              title={`${countyData && countyData[0] && countyData[0].name} - ${
                stateData && stateData[0] && stateData[0].name
              } county on Exuity`}
            />
            <div className="region-sec" style={{ marginTop: 140 }}>
              <ul className="breadcrumb region-breadcrumb arr-right bg-ffffff">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <img
                      src={require("../../../Images/breadcrumb-home.svg")}
                      alt="Exuity Home Icon"
                    />
                  </Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
                <li className="breadcrumb-item">
                  <Link to="/regiontypes">Region type</Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
                <li className="breadcrumb-item">
                  <Link to="/counties">Counties</Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>{" "}
                <li className="breadcrumb-item">
                  <Link to={`/states/${stateData && stateData[0] && stateData[0].slug}#counties`}>
                    {stateData && stateData[0] && stateData[0].name}
                  </Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>{" "}
                <li className="breadcrumb-item" aria-current="page">
                  <Link
                    to={`/counties/${stateData && stateData[0] && stateData[0].slug}/${county}`}
                    onClick={() => window.location.reload()}>
                    {countyData && countyData[0] && countyData[0].name}
                  </Link>
                </li>
              </ul>

              <p className="region-sub-heading">County:</p>
              <h1 className="region-title">
                {countyData && countyData[0] && countyData[0].name},{" "}
                {stateData && stateData[0] && stateData[0].name}
              </h1>

              <p className="region-paragraph">
                {countyData[0].name} is a county in{" "}
                <Link to={`/states/${stateData && stateData[0] && stateData[0].slug}`}>
                  {stateData && stateData[0] && stateData[0].name}
                </Link>
                . Its population is roughly {populationformat}.{" "}
                {hpiData && hpiData[0] && cbsaData && cbsaData[0] && (
                  <>
                    {hpiData && hpiData[0] && (
                      <>
                        It is a part of the{" "}
                        <Link to={`/hpis/${hpiData[0].slug}`}>{hpiData[0].name}</Link> home price
                        index and{" "}
                      </>
                    )}
                    the{" "}
                    {cbsaData && cbsaData[0] && (
                      <>
                        <Link to={`/cbsas/${cbsaData[0].slug}`}>{cbsaData[0].name}</Link>
                      </>
                    )}{" "}
                    CBSA.{" "}
                  </>
                )}
                Its cities include{" "}
                {randomcities.map((item, index) => {
                  if (index === 1) {
                    return (
                      <>
                        {" "}
                        and{" "}
                        <Link
                          key={item.slug}
                          className="region-link"
                          to={`/cities/${stateData[0].slug}/${item.slug}`}>
                          {item.name}
                        </Link>
                      </>
                    );
                  }
                  return (
                    <Link
                      key={item.slug}
                      className="region-link"
                      to={`/cities/${stateData[0].slug}/${item.slug}`}>
                      {item.name}
                    </Link>
                  );
                })}{" "}
                including Zip codes such as{" "}
                {randomzipcodes.map((item, index) => {
                  if (index === 1) {
                    return (
                      <>
                        {" "}
                        and{" "}
                        <Link
                          key={item.slug}
                          className="region-link"
                          to={`/zipcodes/${item.slug}/`}>
                          {item.zipcode}
                        </Link>
                      </>
                    );
                  }
                  return (
                    <Link key={item.slug} className="region-link" to={`/zipcodes/${item.slug}`}>
                      {item.zipcode}
                    </Link>
                  );
                })}
                .
              </p>

              <div className="article">
                <h2 className="articletitle">
                  {countyArticalContent && countyArticalContent.article_title}
                </h2>
                <div className="articlecontent">
                  {countyArticalContent &&
                    countyArticalContent.article &&
                    countyArticalContent.article
                      .split("\n")
                      .map((item, i) => (
                        <p key={i}>{<span dangerouslySetInnerHTML={{ __html: item && item }} />}</p>
                      ))}
                </div>
              </div>

              {hpiData && (
                <>
                  <p>
                    Price Index:{" "}
                    <Link to={`/hpis/${hpiData && hpiData[0].slug}`}>
                      {" "}
                      {hpiData && hpiData[0].name}
                    </Link>
                  </p>
                  <PropertyRegionChart hpiData={hpiData && hpiData[0] && hpiData[0].hpi_values} />
                </>
              )}

              {countyData && countyData.length > 1 && <ContiesTable tableData={countyData} />}
              {stateData && stateData.length > 1 && <StateTable tableData={stateData} />}
              {cityData && cityData.length > 1 && (
                <CityTable
                  tableData={cityData}
                  tableTitle={`Cities in ${countyData && countyData[0] && countyData[0].name}`}
                  totalPageCount={totalCitiesCount}
                  currentPage={citiesCurrentPage}
                  setCurrentPage={setCitiesCurrentPage}
                />
              )}

              {zipcodeData && zipcodeData.length > 1 && (
                <ZipcodeTable
                  tableData={zipcodes}
                  tableTitle={`Zip codes in ${countyData && countyData[0] && countyData[0].name}`}
                  totalPageCount={totalZipcodeCount}
                  currentPage={zipcodeCurrentPage}
                  setCurrentPage={setZipcodeCurrentPage}
                />
              )}

              {hpiData && hpiData.length > 1 && <HpiTable tableData={hpiData} />}
              {cbsaData && cbsaData.length > 1 && <CbsasTable tableData={cbsaData} />}
              {csasData && csasData.length > 1 && <CsasTable tableData={csasData} />}
              {msaData && msaData.length > 1 && <MsaTable tableData={msaData} />}

              <GetStarted
                name={`Buy & Sell Shares of Homes in ${
                  countyData && countyData[0] && countyData[0].name
                }, ${stateData && stateData[0] && stateData[0].name}`}
                description="Exuity lets property owners sell shares of their properties to investors who earn income and appreciation."
              />
            </div>
          </>
        )}

        {isLoading === true && (
          <div className="text-center pt-5" style={{ marginTop: 120 }}>
            <div className="spinner-grow text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p>Loading. Please wait...</p>
          </div>
        )}
        <div className="footer-section">
          <Footer />
        </div>
      </div>
    </>
  );
}
