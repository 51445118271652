import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Picker from "react-scrollable-picker";
import { setHeroData } from "../../store/actions/heroData";
import "./heroscrollpicker.css";

class HeroScrollPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueGroups: {
        sellBuy: "sell",
        percentage: 25,
        text1: "of a",
        homeValue: 400000,
        text2: "house."
      },
      optionGroups: {
        sellBuy: [
          { value: "buy", label: "Buy" },
          { value: "sell", label: "Sell" }
        ],
        percentage: [
          { value: 5, label: "5%" },
          { value: 10, label: "10%" },
          { value: 15, label: "15%" },
          { value: 20, label: "20%" },
          { value: 25, label: "25%" },
          { value: 30, label: "30%" },
          { value: 35, label: "35%" },
          { value: 40, label: "40%" },
          { value: 45, label: "45%" },
          { value: 50, label: "50%" },
          { value: 55, label: "55%" }
        ],
        text1: [{ value: "of a", label: "of a" }],
        homeValue: [
          { value: 50000, label: "$50k" },
          { value: 100000, label: "$100k" },
          { value: 150000, label: "$150k" },
          { value: 200000, label: "$200k" },
          { value: 250000, label: "$250k" },
          { value: 300000, label: "$300k" },
          { value: 400000, label: "$400k" },
          { value: 500000, label: "$500k" },
          { value: 600000, label: "$600k" },
          { value: 750000, label: "$750k" },
          { value: 1000000, label: "$1M" },
          { value: 1250000, label: "$1.25M" },
          { value: 1500000, label: "$1.5M" },
          { value: 2000000, label: "$2M" },
          { value: 2500000, label: "$2.5M" },
          { value: 3000000, label: "$3M" },
          { value: 4000000, label: "$4M" },
          { value: 5000000, label: "$5M" }
        ],
        text2: [{ value: "house.", label: "house." }]
      }
    };
  }

  componentDidMount() {
    if (this.scrollRef && this.scrollRef.addEventListener) {
      this.scrollRef.addEventListener("scroll", (ev) => {
        console.log(ev);
      });
    }
  }

  // Update the value in response to user picking event
  handleChange = (name, value) => {
    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value
      }
    }));
    this.props.handlerSellBuy(name, value)

    //update hero reducer data
    this.props.setHeroData({[name]: value})
  };

  throttle = (fn, wait) => {
    let time = Date.now();
    return function (e) {
      if (time + wait - Date.now() < 0) {
        fn(e);
        time = Date.now();
      }
    };
  };

  changeVal = () => {
    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        title: "ms"
      }
    }));
  };

  changeValScroll = (e) => {
    e.persist();
    console.log("TEST_WHEEL", e);
    const diff = parseInt(e.deltaY / 100, 10);
    const currentValue = this.state.valueGroups.title;
    const currentIndex = this.state.optionGroups.title.findIndex((i) => i.value === currentValue);
    let newIndex = currentIndex + diff;
    if (newIndex < 0) {
      newIndex = 0;
    }
    if (newIndex > this.state.optionGroups.title.length - 1) {
      newIndex = this.state.optionGroups.title.length - 1;
    }
    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        title: this.state.optionGroups.title[newIndex].value
      }
    }));
  };

  render() {
    const { optionGroups, valueGroups } = this.state;

    return (
      <Fragment>
        <Picker
          optionGroups={optionGroups}
          valueGroups={valueGroups}
          onChange={this.handleChange}
          height={180}
          itemHeight={36}
          ref={this.scrollRef}
        />
      </Fragment>
    );
  }
}

export default connect(null,{setHeroData})(HeroScrollPicker);
