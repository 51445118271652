import * as atypes from "./types";
// import axios from '../..//utills/axios-server';
//import { setAlert } from "./alert";
// import { setToast } from "./toast";
import {
  axios_addProperty,
  axios_loadProperty,
  axios_loadProperties,
  axios_createProperty,
} from "../../utills/property-routes";
//import { useHistory } from "react-router-dom";

export const createProperty = (property_address) => async (dispatch) => {
  dispatch({
    type: atypes.START_LOADING,
  });

  axios_createProperty(property_address)
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: atypes.SET_TOAST,
          payload: [{ msg: "Property added successfully", type: "success" }],
        });

        setTimeout(() => {
          window.location.href = "/properties";
        }, 2000);
      } else {
        dispatch({
          type: atypes.SET_TOAST,
          payload: [
            {
              msg: "All fields are required. Kindly check and resubmit",
              type: "error",
            },
          ],
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: atypes.SET_TOAST,
        payload: [
          { msg: "Something went wrong. Please try again", type: "error" },
        ],
      });
    });
};

export const saveProperty =
  (
    property_type,
    mortage_balance,
    desired_cash,
    estimated_property_value,
    property_address,
    builtup_area,
    bathrooms,
    bedrooms,
    lat,
    lng,
    uploaded_photos
  ) =>
  async (dispatch) => {
    dispatch({
      type: atypes.START_LOADING,
    });

    axios_addProperty(
      property_type,
      mortage_balance,
      desired_cash,
      estimated_property_value,
      property_address,
      builtup_area,
      bathrooms,
      bedrooms,
      lat,
      lng,
      uploaded_photos
    )
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: atypes.SET_TOAST,
            payload: [{ msg: "Property added successfully", type: "success" }],
          });

          setTimeout(() => {
            window.location.href = "/properties";
          }, 2000);
        } else {
          dispatch({
            type: atypes.SET_TOAST,
            payload: [
              {
                msg: "All fields are required. Kindly check and resubmit",
                type: "error",
              },
            ],
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: atypes.SET_TOAST,
          payload: [
            { msg: "Something went wrong. Please try again", type: "error" },
          ],
        });
      });
  };

export const loadProperty = (id) => async (dispatch) => {
  axios_loadProperty(id)
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: atypes.LOAD_USER,
          payload: response,
        });
      } else {
        dispatch({
          type: atypes.AUTH_ERROR,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: atypes.AUTH_ERROR,
      });
      // localStorage.removeItem("userToken")
    });
};

export const deleteProperty = () => (dispatch) => {};

export const startLoading = () => (dispatch) => {
  dispatch({
    type: atypes.START_LOADING,
  });
};

export const stopLoading = () => (dispatch) => {
  dispatch({
    type: atypes.STOP_LOADING,
  });
};

export const updateProperty =
  ({ newPassword, confirmPassword, token }) =>
  async (dispatch) => {};

export const loadProperties = (page) => async (dispatch) => {
  axios_loadProperties(page)
    .then((response) => {
      if (response.data.success) {
        dispatch({
          type: atypes.LOAD_USER,
          payload: response,
        });
      } else {
        dispatch({
          type: atypes.AUTH_ERROR,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: atypes.AUTH_ERROR,
      });
      // localStorage.removeItem("userToken")
    });
};
