import React from "react";
import Sidenav from "../../components/Sidenav";
import "../../components/Dashboard.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import { connect } from "react-redux";
import { loadUser, startLoading, logout } from "../../store/actions/auth";
import store from "../../store/store";
import { NavLink } from "react-router-dom";
import Head from "../../Component/Head";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      found: 0,
    };
  }
  componentDidMount() {
    store.dispatch(startLoading());
    store.dispatch(loadUser());
  }
  render() {
    return (
      <div className="Dashboard" id="Dashboard">
        <Head title={"Exuity | Edit Profile"} />
        <div className="DashboardGroup">
          <Sidenav />
          <div className="row">
            <div className="">
              <Sidebar />
            </div>

            <div className="Dashboard" id="Dashboard">
              <div className="DashboardGroup">
                <div className="auction-navbar">
                  <div className="dashTitle">
                    <h4>My Profile</h4>
                  </div>
                  <div className="createauction-btn createauction-btn-2">
                    {/* <div className="addPr  opert-btn"> */}
                    <NavLink to="/profile-update">
                      <button type="button">Edit Profile</button>
                    </NavLink>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="DashContent">
              <div className="PropertyCardGroup">
                {this.props.user != null ? (
                  <div>
                    <img
                      src={this.props.user.photo}
                      alt="user profile"
                      width="100px"
                      height="100px"
                    />
                    <ul>
                      <li>
                        {" "}
                        <b>Name: </b> {this.props.user.first_name}{" "}
                        {this.props.user.last_name}
                      </li>
                      <li>
                        {" "}
                        <b>Email: </b> {this.props.user.email}
                      </li>
                      <li>
                        {" "}
                        <b>Phone: </b> {this.props.user.phoneno}
                      </li>
                      <li>
                        {" "}
                        <b>Date of Birth: </b> {this.props.user.date_of_birth}
                      </li>
                      <li>
                        {" "}
                        <b>Address: </b> {this.props.user.address}
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="text-center pt-5">
                    <div className="spinner-grow text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <p>Loading. Please wait...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
});
export default connect(mapStatetoProps, { loadUser, startLoading, logout })(
  Profile
);
