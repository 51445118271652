import React from "react";
import "../../Component/Header/header.css";
import { Link } from "react-router-dom";

class CalculatorHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasScrolled: false,
		};
	}

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);
		document.addEventListener("keydown", this.escapePressed, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escapePressed, false);
	}

	handleScroll = (event) => {
		const scrollTop = window.pageYOffset;

		if (scrollTop > 50) {
			this.setState({ hasScrolled: true });
		} else {
			this.setState({ hasScrolled: false });
		}
	};

	render() {
		return (
			<div
				className={
					this.state.hasScrolled
						? "Header fullheader HeaderScrolled5"
						: "Header fullheader HeaderScrolled5"
				}
			>
				<div className="fullheadergroup">
					<div className="brand">
						<Link to="/">
							<img src={require("../../Images/exuity-logo.svg")} alt="brand" />
						</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default CalculatorHeader;
