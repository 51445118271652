import React, { Component } from "react";
import "../PropertyPage.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Image_URL } from "../../config/api";


class HeroGallery extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			photoIndex: 0,
			isOpen: false,
		};
	}
	
	render() {
		// const images = [
		// 	"https://i.ibb.co/nfmrZWc/detail-img1.png",
		// 	"https://share.getcloudapp.com/2Nuv1KvL",
		// 	"https://cl.ly/fcdbeb163ae0/download/detail-img4.png",
		// ];
		const images = this.props.imagesList
		const { photoIndex, isOpen } = this.state;

		return (
			<div>
				<button
					className="showmore-btn"
					type="button"
					onClick={() => this.setState({ isOpen: true })}
				>
					Show More
				</button>

				{isOpen && (
					<Lightbox
						mainSrc={`${Image_URL}/${images[photoIndex]}`}
						nextSrc={images[(photoIndex + 1) % images.length]}
						prevSrc={images[(photoIndex + images.length - 1) % images.length]}
						onCloseRequest={() => this.setState({ isOpen: false })}
						onMovePrevRequest={() =>
							this.setState({
								photoIndex: (photoIndex + images.length - 1) % images.length,
							})
						}
						onMoveNextRequest={() =>
							this.setState({
								photoIndex: (photoIndex + 1) % images.length,
							})
						}
					/>
				)}
			</div>
		);
	}
}

export default HeroGallery;
