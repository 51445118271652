// import React, { useCallback, useState } from "react";
// import { useDropzone } from "react-dropzone";
// import FontAwesomeIcon from "react-fontawesome";
// import axios from "axios";
// import "./style.css";
// import { onClose } from "../../utills/remove-docs";
// import { uploadDoc } from "../../utills/upload-docs";

// function DropZone({ setFiles, files, uploadHandler }) {
//   const onDrop = useCallback((acceptedFiles) => {
//     // Do something with the files
//     setFiles(acceptedFiles);
//   }, []);
//   const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

//   return (
//     <>
//       <div {...getRootProps()}>
//         <input {...getInputProps()} />
//         {isDragActive ? (
//           <p>Drop the files here ...</p>
//         ) : (
//           <div style={{ textAlign: "center", cursor: "pointer" }}>
//             <FontAwesomeIcon size={"5x"} name="fa-solid fa-upload" />
//             <h6>Upload Docs</h6>
//           </div>
//         )}
//       </div>
//       {files.length > 0 && (
//         <div className="list">
//           {files.map((file, index) => {
//             return (
//               <div className="list-items">
//                 <div
//                   style={{ marginTop: 10, marginBottom: 10 }}
//                   key={index}
//                   className="container "
//                 >
//                   <div className="row">
//                     <div className="col-lg-12">
//                       <div className="row">
//                         <div className="col-lg-8 col-8">
//                           <span
//                             style={{
//                               fontSize: 12,
//                             }}
//                           >
//                             {file.path}
//                           </span>
//                         </div>
//                         <div className="col-lg-4 col-6">
//                           <span
//                             onClick={() => onClose(files, file, setFiles)}
//                             style={{ fontWeight: "bold", cursor: "pointer" }}
//                           >
//                             X
//                           </span>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       )}
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignIems: "center",
//           marginTop: 20,
//         }}
//       >
//         <button
//           onClick={() => uploadHandler()}
//           type="button"
//           className="btn btn-dark"
//         >
//           Upload
//         </button>
//       </div>
//     </>
//   );
// }

// const objectToFormData = (data, formData, namespace) => {
//   let fd = formData || new FormData();
//   let formKey;

//   if (Array.isArray(data)) {
//     data.forEach((item) => {
//       fd.append(namespace, item);
//     });
//   }

//   return fd;
// };

// const UploadDoc = () => {
//   const [files, setFiles] = useState([]);

//   return (
//     <div className="main">
//       <div className="upload-docs">
//         <DropZone
//           setFiles={setFiles}
//           files={files}
//           uploadHandler={uploadHandler}
//         />
//       </div>
//     </div>
//   );
// };

// export default UploadDoc;
import React from 'react'

const UploadDoc = () => {
  return (
    <div>UploadDoc</div>
  )
}

export default UploadDoc