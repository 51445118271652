import axios from './axios-server';

export const axios_addContact=(data, callback)=>{
    axios
    .post(`/contact/add-contact`,{ data }, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong ", error)
    });  
}