import axios from "./axios-server";

export const axios_getCityDataFromCode = (state,city, callback) => {
  axios
    .get(`/city/getCityDataByCode/${state}/${city}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};

export const axios_getCityArticleData = (state,city, callback) => {
  axios
    .get(`/city/getCityArticleData/${state}/${city}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};


export const axios_getAllCities = (page, callback) => {
  axios
    .get(`/city/get-all-cities/${page}/10`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};

export const axios_getCityChildDataByStateCode = (state, city, regionType, page, callback) => {
  axios.get(`/city/getCitiesChildByCityCode/${state}/${city}/${regionType}/${page}/10`, {
    headers: {
      "Content-Type": "application/json",
    },
  })
  .then((response) => callback(response))
  .catch((error) => {
    console.log("Oops! Something went wrong ", error);
  });
}
