import React from "react";
// import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import "./auctions.css";
import { axios_loadAuctions } from "../../utills/auction-routes";
import { axios_loadProperties } from "../../utills/property-routes";
import { str_into_num } from "../../utills/string-into-number";
import { imageChecker } from "../../config/imgBaseUrl";
import Head from "../../Component/Head";
import { URL_PROPERTY } from "../../config/propertyImagesUrl";

const Row = ({ auction, navLink }) => (
  <tr
    onClick={() =>
      auction.status !== "Under_Review"
        ? navLink("/auction/" + auction.code)
        : null
    }
  // style={{  }}
  >
    {auction && auction.status !== "Under_Review" ? (
      <td className="property-col">
        <NavLink to={"/auction/" + auction.code}>
          <img
            width={50}
            height={50}
            style={{ objectFit: "cover" }}
            src={URL_PROPERTY(
              auction && auction.property && auction.property.images2
            )}
            alt="Auction img 1"
          />
        </NavLink>
        <NavLink to={"/auction/" + auction.code}>
          <h6>{auction.property && auction.property.property_address}</h6>
        </NavLink>
      </td>
    ) : (
      <td className="property-col">
        <img
          src={imageChecker(
            auction &&
            auction.property &&
            auction.property.images &&
            auction.property.images[0]
          )}
          alt="Auction img 1"
        />
        <h6>{auction.property && auction.property.property_address}</h6>
      </td>
    )}

    <td className="amount-raising-col">
      ${str_into_num(auction.amount_raising)}
    </td>
    <td className="status-col">
      {auction.status === "Success" ? (
        <span className="tag--active">Success</span>
      ) : auction.status === "Fail" ? (
        <span className="tag--closed">Fail</span>
      ) : auction.status === "Active" ? (
        <span className="tag--active">Active</span>
      ) : auction.status === "Under_Review" ? (
        <span className="tag--inreview">Under Review</span>
      ) : (
        <span className="tag--inreview">{auction.status}</span>
      )}
    </td>
    <td className="date-col">
      {new Date(auction.start_date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })}
    </td>
  </tr>
);

class BidAuctions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      found: 0,
      auctions: [],
      properties: [],
    };
    this.navLink = this.navLink.bind(this);
  }
  loadProperties() {
    axios_loadProperties(1, 1000)
      .then((value) => {
        this.setProperties(value.data.properties);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  setProperties(properties) {
    this.setState({ properties: properties });
  }
  loadAuctions() {
    axios_loadAuctions(0, 10000)
      .then((value) => {
        this.setAuctions(value.data.auctions);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  setAuctions(auctions) {
    this.setState({ auctions: auctions });
    if (auctions.length > 0) {
      this.setState({
        found: 2,
      });
      this.props.setFound(2);
    } else {
      this.setState({
        found: 0,
      });
      this.props.setFound(0);
      this.loadProperties();
    }
  }
  componentDidMount() {
    this.setState({
      found: 1,
    });
    this.props.setFound(1);

    this.loadAuctions();
  }
  navLink(url) {
    this.props.history.push(url);
  }
  render() {
    return (
      <div>
        <Head title={"Exuity | Owner | Auctions"} />
        <div className="auction-table paymentowed-table paymentowed-table-2">
          {this.state.found === 2 ? (
            <table className="table table-responsive" responsive="lg">
              <thead>
                <tr className="header">
                  <th>Property</th>
                  <th>Amount Raising</th>
                  <th>Status</th>
                  <th>Start Date</th>
                </tr>
              </thead>
              <tbody>
                {this.state.auctions.map(function (i) {
                  return (
                    <>
                      {i.status === "Under_Review" ? (
                        <Row key={i.id} auction={i} />
                      ) : (
                        <Row key={i.id} auction={i} navLink={this.navLink} />
                      )}
                    </>
                  );
                }, this)}
              </tbody>
            </table>
          ) : (
            <div>
              {this.state.found === 1 ? (
                <table className="table table-responsive" responsive="lg">
                  <thead>
                    <tr className="header">
                      <th>Property</th>
                      <th>Amount Raising</th>
                      <th>Status</th>
                      <th>Start Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="12" className="date-col">
                        <div className="text-center pt-5">
                          <div
                            className="spinner-grow text-warning"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                          <p>Loading. Please wait...</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <table className="table table-responsive" responsive="lg">
                  <thead>
                    <tr className="header">
                      <th>Property</th>
                      <th>Amount Raising</th>
                      <th>Status</th>
                      <th>Start Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="4">
                        <div className="table-empty-state">
                          <div className="empty-state-img">
                            <img
                              src={require("../../Images/empty-state-icon.svg")}
                              alt="empty state img"
                            />
                            <p>You haven't launched any auctions</p>
                            <div className="createauction-btn createauction-btn-2">
                              {this.state.properties.length === 0 ? (
                                <NavLink to="/properties/create">
                                  <button type="button">
                                    Claim a Property
                                  </button>
                                </NavLink>
                              ) : (
                                <NavLink to="/auctions/create">
                                  <button type="button">Create Auction</button>
                                </NavLink>
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(BidAuctions);
