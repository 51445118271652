import axios from "./axios-server";

const objectToFormData = (data, formData, namespace) => {
  let fd = formData || new FormData();
  // let formKey;

  if (Array.isArray(data)) {
    data.forEach((item) => {
      fd.append(namespace, item);
    });
  }

  return fd;
};

export const uploadHandler = (files, setError, setSuccess, setFiles) => {
  const formData = new FormData();
  objectToFormData(files, formData, "verify_doc");
  axios
    .post("/user/verify-docs/", formData, {
      headers: {
        "x-auth-token": localStorage.getItem("userToken"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data.success === false) {
        setError(res.data.msg);
        // setloading(false);
        setFiles([]);
        setInterval(() => {
          setError("");
        }, 5000);
      } else {
        setSuccess(res.data.msg);
        setError("");
        setFiles([]);
        // setloading(false);
        setInterval(() => {
          setSuccess("");
        }, 5000);
      }
    })
    .catch((err) => {
      // alert(err.response.data.message)
      alert("somthing went wrong");
    });
};
