import React from "react";
// import { Link } from "react-router-dom";

class DetailMarker extends React.Component {
  constructor(props) {
    super(props);
    this.setMarker.bind(this);
    this.that = this;
    this.state = {
      isHovered: false,
    };
    this.handleHover = this.handleHover.bind(this);
  }
  handleHover() {
    this.setState((prevState) => ({
      isHovered: !prevState.isHovered,
    }));
  }
  setMarker(props) {
    this.props.setMarker(this.props.property);
  }
  render() {
    var propertyInfoClass = this.state.isHovered
      ? "propertyhovered"
      : "propertynothovered";
    propertyInfoClass += this.props.isHovered
      ? " markerhovered"
      : " markernothovered";

    return (
      <div
        className={propertyInfoClass}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleHover}
      >
        <button className="property-marker">
          <img src={require("../../Images/map-marker.svg")} alt="brand" />
        </button>
        <div className="searchCardGroup">
          <div className="s-card-img">
            <img
              src={require("../../Images/single-f-home.png")}
              alt="single home"
            />
            <span className="price-tag">$3.4m</span>
            <div className="active-issuance">
              <span>Active - {this.props.property.property_type}</span>
            </div>
          </div>
          <div className="s-card-texts">
            <h6>{this.props.property.property_address}</h6>

            <p>
              Appraised at: <span className="color-bold">$3,412,581</span>
            </p>
            <p>
              Yield: <span className="bold">TBD</span>
            </p>
            <p>
              Investments: <span className="bold">$5k - $200K</span>
            </p>

            <div className="s-cards-icons">
              <span className="beds-grey s-cta">
                {this.props.property.bedrooms} br
              </span>
              <span className="baths-grey s-cta">
                {this.props.property.bathrooms} ba
              </span>
              <span className="sqft-grey s-cta">
                {this.props.property.builtup_area} ft
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailMarker;
