import axios from './axios-server';

export const axios_getHpiFromZipCity = (data, callback) => {
    axios
        .post(`/hpi/get-hpi-from-zipCity`, { data }, {
            headers: {
                'Content-Type': 'application/json'
            }
        },

        )
        .then((response) => callback(response))
        .catch(error => {
            console.log("Oops! Something went wrong ", error)
        });
}
export const axios_getHpiFromCityState = (data, callback) => {
    axios
        .get(`/hpi/get-hpi-from-city-state?city=${data.city}&state=${data.state}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        },

        )
        .then((response) => callback(response))
        .catch(error => {
            console.log("Oops! Something went wrong ", error)
        });
}
export const axios_getHpiValuesFromCityState = (data, callback) => {
    axios
        .get(`/hpi/get-hpi-values-from-city-state?city=${data.city}&state=${data.state}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        },

        )
        .then((response) => callback(response))
        .catch(error => {
            console.log("Oops! Something went wrong ", error)
        });
}

export const axios_getHpiValuesFromCode = (slug, callback) => {
    axios
        .get(`/hpi/getHpiValuesFromCode/${slug}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        },

        )
        .then((response) => callback(response))
        .catch(error => {
            console.log("Oops! Something went wrong ", error)
        });
}


export const axios_getHpiArticleData = (slug, callback) => {
    axios
        .get(`/hpi/getHpiArticleData/${slug}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        },

        )
        .then((response) => callback(response))
        .catch(error => {
            console.log("Oops! Something went wrong ", error)
        });
}


export const axios_getHpiChildDataByHpiCode = (slug, regionType, page, callback) => {
    axios.get(`/hpi/getHpiChildByHpiCode/${slug}/${regionType}/${page}/10`, {
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => callback(response))
        .catch((error) => {
            console.log("Oops! Something went wrong ", error);
        });
}


export const axios_getAllHpis = (page, callback) => {
    axios
        .get(`/hpi/get-all-hpis/${page}/10`, {
            headers: {
                'Content-Type': 'application/json'
            }
        },

        )
        .then((response) => callback(response))
        .catch(error => {
            console.log("Oops! Something went wrong ", error)
        });
}

