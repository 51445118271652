import React from "react";
import Table from "react-bootstrap/Table";
import "./RentTable.css";

class RentTable extends React.Component {
	render() {
		return (
			<Table className="loan-table" bordered>
				<thead>
					<tr>
						<th>Benefit to Investor</th>
						<th>Exuity</th>
						<th>Rental Property</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>No such expenses: mortgage, maintenance, tax, insurance</td>
						<td>
							<img
								src={require("../../Images/table-tick-icon.svg")}
								alt="Check Icon"
							/>
						</td>
						<td>
							<img
								src={require("../../Images/table-cross-icon.svg")}
								alt="Cross Icon"
							/>
						</td>
					</tr>
					<tr>
						<td>
							No hassle of having tenants; no loss of revenue during vacancy
						</td>
						<td>
							<img
								src={require("../../Images/table-tick-icon.svg")}
								alt="Check Icon"
							/>
						</td>
						<td>
							<img
								src={require("../../Images/table-cross-icon.svg")}
								alt="Cross Icon"
							/>
						</td>
					</tr>
					<tr>
						<td>Easily diversify accross many properties or regions</td>
						<td>
							<img
								src={require("../../Images/table-tick-icon.svg")}
								alt="Check Icon"
							/>
						</td>
						<td>
							<img
								src={require("../../Images/table-cross-icon.svg")}
								alt="Cross Icon"
							/>
						</td>
					</tr>
					<tr>
						<td>Freedom to do whatever you want with property</td>
						<td>
							<img
								src={require("../../Images/table-cross-icon.svg")}
								alt="Cross Icon"
							/>
						</td>
						<td>
							<img
								src={require("../../Images/table-tick-icon.svg")}
								alt="Check Icon"
							/>
						</td>
					</tr>
					<tr>
						<td>Easy to get (risk-magnifying) leverage with mortgage</td>
						<td>
							<img
								src={require("../../Images/table-cross-icon.svg")}
								alt="Cross Icon"
							/>
						</td>
						<td>
							<img
								src={require("../../Images/table-tick-icon.svg")}
								alt="Check Icon"
							/>
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}
}

export default RentTable;
