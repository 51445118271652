import React from "react";
import Table from "react-bootstrap/Table";
import "./LoanTable.css";

class LoanTable extends React.Component {
	render() {
		return (
			<Table className="rent-table" bordered>
				<thead>
					<tr>
						<th>Benefit to Owner</th>
						<th>Exuity</th>
						<th>Equity Loan</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Owe less if home value drops</td>
						<td>
							<img
								src={require("../../Images/table-tick-icon.svg")}
								alt="Check Icon"
							/>
						</td>
						<td>
							<img
								src={require("../../Images/table-cross-icon.svg")}
								alt="Cross Icon"
							/>
						</td>
					</tr>
					<tr>
						<td>No issue if you miss some or all payments</td>
						<td>
							<img
								src={require("../../Images/table-tick-icon.svg")}
								alt="Check Icon"
							/>
						</td>
						<td>
							<img
								src={require("../../Images/table-cross-icon.svg")}
								alt="Cross Icon"
							/>
						</td>
					</tr>
					<tr>
						<td>Qualify with or without good credit and income</td>
						<td>
							<img
								src={require("../../Images/table-tick-icon.svg")}
								alt="Check Icon"
							/>
						</td>
						<td>
							<img
								src={require("../../Images/table-cross-icon.svg")}
								alt="Cross Icon"
							/>
						</td>
					</tr>
					<tr>
						<td>Keep all gains if property appreciates</td>
						<td>
							<img
								src={require("../../Images/table-cross-icon.svg")}
								alt="Cross Icon"
							/>
						</td>
						<td>
							<img
								src={require("../../Images/table-tick-icon.svg")}
								alt="Check Icon"
							/>
						</td>
					</tr>
					<tr>
						<td>Write off interest payments on taxes</td>
						<td>
							<img
								src={require("../../Images/table-cross-icon.svg")}
								alt="Cross Icon"
							/>
						</td>
						<td>
							<img
								src={require("../../Images/table-tick-icon.svg")}
								alt="Check Icon"
							/>
						</td>
					</tr>
				</tbody>
			</Table>
		);
	}
}

export default LoanTable;
