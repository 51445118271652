import React from "react";
import "./investmentreceived.css";

class TableInvestmentsOwner extends React.Component {
	render() {
		return (
			<div>
				<div className="investments-table">
					<table className="table table-responsive" responsive="lg">
						<thead>
							<tr className="header">
								<th>Property</th>
								<th>Amount Owed</th>
								<th>Amount Paid</th>
								<th>Due Date</th>
								<th>Est. Home Value</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="property-col dash-tbl-col">
									<img
										src={require("../../Images/auc-launched-img1.png")}
										alt="Auction img"
									/>
									<h6>702 Sylvaner Dr.</h6>
								</td>
								<td className="dash-tbl-col">
									<p>$90,000</p>
								</td>
								<td className="dash-tbl-col">
									<p>$75,000</p>
								</td>
								<td className="dash-tbl-col">
									<p>Jan 7, 2022</p>
								</td>
								<td className="dash-tbl-col">
									<p>$200,000</p>
								</td>
							</tr>
							<tr>
								<td className="property-col dash-tbl-col">
									<img
										src={require("../../Images/auc-launched-img2.png")}
										alt="Auction img"
									/>
									<h6>702 Sylvaner Dr.</h6>
								</td>
								<td className="dash-tbl-col">
									<p>$90,000</p>
								</td>
								<td className="dash-tbl-col">
									<p>$75,000</p>
								</td>
								<td className="dash-tbl-col">
									<p>Jan 7, 2022</p>
								</td>
								<td className="dash-tbl-col">
									<p>$200,000</p>
								</td>
							</tr>
							<tr>
								<td className="property-col dash-tbl-col">
									<img
										src={require("../../Images/auc-launched-img3.png")}
										alt="Auction img"
									/>
									<h6>702 Sylvaner Dr.</h6>
								</td>
								<td className="dash-tbl-col">
									<p>$90,000</p>
								</td>
								<td className="dash-tbl-col">
									<p>$75,000</p>
								</td>
								<td className="dash-tbl-col">
									<p>Jan 7, 2022</p>
								</td>
								<td className="dash-tbl-col">
									<p>$200,000</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default TableInvestmentsOwner;
