import React, { useEffect, useState } from "react";
import NewEquity from "../../Component/NewEquity/NewEquity";
import Head from "../../Component/Head";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";


// import "../../components/equitygraph.css";
// import { useSearchParams } from "react-router-dom";

export default function ModelPage(props) {
  const queryParams = new URLSearchParams(props.location.search);
  const [homeValue, setHomeValue] = useState(1000000);
  const [yieldValue, setYieldValue] = useState(8);
  const [appreciation, setAppreciation] = useState(6);


  useEffect(() => {
    if (queryParams.get("homevalue") !== null) {
      setHomeValue(Number(queryParams.get("homevalue")));
    }
    if (queryParams.get("yield") !== null) {
      setYieldValue(Number(queryParams.get("yield")));
    }
    if (queryParams.get("appreciation") !== null) {
      setAppreciation(Number(queryParams.get("appreciation")));
    }
  }, [props, queryParams]);

  return (
    <div>
      <Head title="Example &amp; Financial Model" />


      <div className="App">
        <Header />
        <div className="modal-equity">
          <NewEquity
            homevalue={homeValue}
            yieldValue={yieldValue}
            appreciation={appreciation}
          />
        </div>


        <div className="footer-section">
          <Footer />
        </div>
      </div>
    </div>
  );
}
