import React from "react";
import "../../Component/Header/header.css";
import "../../Page/Calculator/Calculator.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import CalculatorInputs from "./CalculatorInputs";
import InvestmentReturnsGraph from "../PropertyPage/InvestmentReturnsGraph";
import InvesmentRCards from "../PropertyPage/InvestmentR-card";

class CalculatorTabs extends React.Component {
  constructor(props) {
    super();
    this.state = {
      // Takes active tab from props if it is defined there
      activeTab: props.activeTab || 1,
      open: false,
      clicked: true
    };

    // Bind the handleSelect function already here (not in the render function)
    this.handleSelect = this.handleSelect.bind(this);
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    return (
      <div>
        <div id="menu_button" className="menu-calculator">
          <input type="checkbox" id="menu_checkbox" />
          <label
            for="menu_checkbox"
            id="menu_label"
            onClick={this.toggle.bind(this)}
          >
            <div id="menu_text_bar" />
          </label>
        </div>
        <Tabs
          activeKey={this.state.activeTab}
          onSelect={this.handleSelect}
          className={"nav-tabing" + (this.state.open ? " in" : "")}
        >
          <Tab eventKey={1} title="Calculator">
            <div className="CalculatorGroup">
              <CalculatorInputs />
              <div className="calculatorGraph">
                <InvestmentReturnsGraph />
              </div>
              <div className="calculatorInvestmentCards">
                <InvesmentRCards />
              </div>
            </div>
          </Tab>
          <Tab eventKey={2} title="Investor Application" disabled>
            Tab 2 content
          </Tab>
          <Tab eventKey={3} title="Homeowner Application" disabled>
            Tab 3 content
          </Tab>
          <Tab eventKey={4} title="My Account" disabled>
            Tab 4 content
          </Tab>
        </Tabs>
      </div>
    );
  }

  handleSelect(selectedTab) {
    this.setState({
      activeTab: selectedTab
    });
  }
}

export default CalculatorTabs;
