import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  axios_getMsaArticleData,
  axios_getMsaDataFromCode,
  axios_getMsasChildDataByMsasCode
} from "../../../utills/msa-routes";
import Footer from "../../../Component/Footer/Footer";
import Head from "../../../Component/Head";
import Header from "../../../Component/Header/Header";
import ContiesTable from "../../../Component/RegionComponents/CountiesTable";
import MsaTable from "../../../Component/RegionComponents/MsaTable";
import ZipcodeTable from "../../../Component/RegionComponents/ZipcodeTable";
import { multirand } from "../../../utills/helper";
import GetStarted from "../../../Component/GetStarted/GetStarted";
import CityTable from "../../../Component/RegionComponents/CityTable";

export default function MsaPage() {
  const { slug } = useParams();
  const [msaData, setMsaData] = useState([]);
  const [countyData, setCountyData] = useState();
  const [randomcounties, setRandomCounties] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [zipcodeData, setZipcodeData] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);
  const [zipcodeCurrentPage, setZipcodeCurrentPage] = useState(0);
  const [totalZipcodeCount, setTotalZipcodeCount] = useState();
  const [countiesCurrentPage, setCountiesCurrentPage] = useState(0);
  const [totalCountiesCount, setTotalCountiesCount] = useState(0);
  const [cityData, setCityData] = useState();
  const [citiesCurrentPage, setCitiesCurrentPage] = useState(0);
  const [totalCitiesCount, setTotalCitiesCount] = useState(0);
  const [msaArticalContent, setMsaArticalContent] = useState();

  useEffect(() => {
    setIsLoading(true);
    axios_getMsaDataFromCode(slug, getMsaDataCallback);
    axios_getMsaArticleData(slug, getMsaArticleCallback);
  }, [slug]);

  useEffect(() => {
    var start = zipcodeCurrentPage * 10;
    var end = start + 10;
    if (zipcodeData) {
      setZipcodes(zipcodeData.slice(start, end));
    }
  }, [zipcodeData, zipcodeCurrentPage]);

  useEffect(() => {
    axios_getMsasChildDataByMsasCode(
      slug,
      "counties",
      countiesCurrentPage,
      getMsasChildDataMsasCallback
    );
  }, [slug, countiesCurrentPage]);

  useEffect(() => {
    axios_getMsasChildDataByMsasCode(
      slug,
      "cities",
      citiesCurrentPage,
      getMsasChildDataCitiesCallback
    );
  }, [slug, citiesCurrentPage]);

  const getMsaDataCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setRandomCounties(multirand(response.data.data.counties, 2));
        // setCountyData(response.data.data.counties);
        setZipcodeData(response.data.data.zipcodes);
        setTotalZipcodeCount(response.data.data.zipcodes.length);
        setZipcodes(response.data.data.zipcodes.slice(0, 10));
        setMsaData(response.data.data.msa);
        if (window.location.hash) {
          document
            .querySelector('[id="' + window.location.hash.substring(1) + '"]')
            .scrollIntoView();
        }
      }
    }
    setIsLoading(false);
  };

  const getMsasChildDataMsasCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setTotalCountiesCount(response.data.data.count);
        setCountyData(response.data.data.rows);
      }
    }
  };

  const getMsasChildDataCitiesCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setTotalCitiesCount(response.data.data.count);
        setCityData(response.data.data.rows);
      }
    }
    setIsLoading(false);
  };

  const getMsaArticleCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setMsaArticalContent(response.data.data);
      }
    }
  };

  return (
    <>
      <div className="App">
        <Header />
        {msaData && msaData.length > 0 && (
          <>
            <Head
              title={`${
                msaData && msaData[0] && msaData[0].name
              } - Metropolitan Statistical Area (MSA) on Exuity`}
            />
            <section className="region-sec" style={{ marginTop: 140 }}>
              <ul className="breadcrumb region-breadcrumb arr-right bg-ffffff">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <img
                      src={require("../../../Images/breadcrumb-home.svg")}
                      alt="Exuity Home Icon"
                    />
                  </Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
                <li className="breadcrumb-item">
                  <Link to="/regiontypes">Region type</Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
                <li className="breadcrumb-item">
                  <Link to="/msas">Metropolitan Statistical Areas (MSAs)</Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>{" "}
                <li className="breadcrumb-item" aria-current="page">
                  <Link to={`/msas/${slug}`} onClick={() => window.location.reload()}>
                    {msaData && msaData[0] && msaData[0].name}
                  </Link>
                </li>
              </ul>

              <p className="region-sub-heading">Metropolitan Statistical Area (MSA):</p>
              <h1 className="region-title">{msaData && msaData[0].name}</h1>

              <p className="region-paragraph">
                {msaData[0].name} is a Metropolitan Statistical Area (MSA) comprising{" "}
                {randomcounties.length > 1 ? (
                  <>
                    counties including{" "}
                    {randomcounties.map((item, index) => {
                      if (index === 1) {
                        return (
                          <>
                            {" "}
                            and{" "}
                            <Link
                              key={`c-${index}`}
                              className="region-link"
                              to={`/counties/${item.state.slug}/${item.slug}`}>
                              {item.name}
                            </Link>
                          </>
                        );
                      }
                      return (
                        <Link
                          key={`c-${index}`}
                          className="region-link"
                          to={`/counties/${item.state.slug}/${item.slug}`}>
                          {item.name}
                        </Link>
                      );
                    })}
                  </>
                ) : (
                  <>
                    the county of{" "}
                    {randomcounties.map((item, index) => {
                      if (index === 1) {
                        return (
                          <>
                            {" "}
                            and{" "}
                            <Link
                              key={`c-${index}`}
                              className="region-link"
                              to={`/counties/${item.state.slug}/${item.slug}`}>
                              {item.name}
                            </Link>
                          </>
                        );
                      }
                      return (
                        <Link
                          key={`c-${index}`}
                          className="region-link"
                          to={`/counties/${item.state.slug}/${item.slug}`}>
                          {item.name}
                        </Link>
                      );
                    })}
                  </>
                )}
                .
              </p>

              <div className="article">
                <h2 className="articletitle">
                  {msaArticalContent && msaArticalContent.article_title}
                </h2>
                <div className="articlecontent">
                  {msaArticalContent &&
                    msaArticalContent.article &&
                    msaArticalContent.article
                      .split("\n")
                      .map((item, i) => (
                        <p key={i}>{<span dangerouslySetInnerHTML={{ __html: item && item }} />}</p>
                      ))}
                </div>
              </div>

              <div>
                {msaData && msaData.length > 1 && <MsaTable tableData={msaData} />}
                {countyData && countyData.length > 1 && (
                  <ContiesTable
                    tableData={countyData}
                    tableTitle={`Counties in ${msaData && msaData[0].name}`}
                    totalPageCount={totalCountiesCount}
                    currentPage={countiesCurrentPage}
                    setCurrentPage={setCountiesCurrentPage}
                  />
                )}
                {cityData && cityData.length > 1 && (
                  <CityTable
                    tableData={cityData}
                    tableTitle={`Cities in ${msaData && msaData[0].name}`}
                    totalPageCount={totalCitiesCount}
                    currentPage={citiesCurrentPage}
                    setCurrentPage={setCitiesCurrentPage}
                  />
                )}
                {zipcodeData && zipcodeData.length > 1 && (
                  <ZipcodeTable
                    tableData={zipcodes}
                    tableTitle={`Zip codes in ${msaData && msaData[0].name}`}
                    totalPageCount={totalZipcodeCount}
                    currentPage={zipcodeCurrentPage}
                    setCurrentPage={setZipcodeCurrentPage}
                  />
                )}
              </div>
              <GetStarted
                name={`Buy & Sell Shares of Homes in ${msaData && msaData[0] && msaData[0].name}`}
                description="Exuity lets property owners sell shares of their properties to investors who earn income and appreciation."
              />
            </section>
          </>
        )}

        {isLoading === true && (
          <div className="text-center pt-5" style={{ marginTop: 120 }}>
            <div className="spinner-grow text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p>Loading. Please wait...</p>
          </div>
        )}
        <div className="footer-section">
          <Footer />
        </div>
      </div>
    </>
  );
}
