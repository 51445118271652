import axios from './axios-server';

export const axios_getPaymentOwed=( page, limit, callback, errorCallback)=>{
    axios
    .get(`/payment-owed/getPaymentOwedOwner`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong ", error)
        errorCallback(error)
    });  
}


export const getPaymentOwedInvestor=(callback, errorCallback)=>{
    axios
    .get(`/payment-owed/getPaymentOwedInvestor`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) =>{ 
        callback(response)})
    .catch(error => {
        console.log("Oops! Something went wrong ", error)
        errorCallback(error)
    });  
}

export const axios_getPaymentOwedOwnerByCode=(code, callback)=>{
    axios
    .get(`/payment-owed/getPaymentOwedOwnerByCode/${code}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong ", error)
    });  
}

export const axios_getPaymentOwedInvestorByCode=(code, callback)=>{
    axios
    .get(`/payment-owed/getPaymentOwedInvestorByCode/${code}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    },
    
    )
    .then((response) => callback(response))
    .catch(error => {
        console.log("Oops! Something went wrong ", error)
    });  
}