import axios from 'axios';
export const zapier_visitor_lead = (data) => {
  axios
    .post(`https://hooks.zapier.com/hooks/catch/6513208/bqpf51w/`, data, {
      headers: {
        // "Content-Type": "application/json",
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    })
    .then((response) => {
      console.log('Zapier Sucess', response);
    })
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};