import React from "react";
// import * as atypes from "../../store/actions/types";
import "../Dashboard.css";
import Sidenav from "../Sidenav";
import Sidebar from "../../Component/Sidebar/Sidebar";
import { connect } from "react-redux";
import { loadUser, startLoading, logout } from "../../store/actions/auth";
import { saveAuction } from "../../store/actions/auction";
import { axios_loadProperties } from "../../utills/property-routes";
import store from "../../store/store";
// import { BrowserRouter as Router, Link } from "react-router-dom";
import { setToast } from "../../store/actions/toast";
// import Toast from "../../components/layout/Toast";
// import { ToastProvider } from "react-toast-notifications";
// import { useDispatch } from 'react-redux'
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

class AddAuction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      isSubmitted: false,
      propertyType: "",
      mortageBalance: "",
      desiredCash: "",
      estimatedPropertyValue: "",
      propertyAddress: "",
      propertyAddressParts: "",
      builtupArea: "",
      Bathrooms: "",
      Bedrooms: "",
      address: "",
      title: "",
      propertyId: "",
      properties: [],
    };
  }
  loadProperties() {
    axios_loadProperties(1, 1000)
      .then((value) => {
        this.setProperties(value.data.properties);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  setProperties(properties) {
    this.setState({ properties: properties });
  }
  componentDidMount() {
    //	store.dispatch(startLoading());
    //	store.dispatch(loadUser());
    this.loadProperties();
  }
  openProperty() {
    this.setState({
      isVisible: true,
      isSubmitted: true,
    });
  }
  setAddress(val) {
    if (typeof val != "undefined") {
      this.setState({ propertyAddress: val.label });
    }
  }
  basicInfo() {
    if (this.state.propertyId !== "") {
      this.setState({ isVisible: !this.state.isVisible });
    } else {
      if (this.state.propertyId !== "") {
        this.props.setToast([
          { msg: "Please select a Property", type: "error" },
        ]);
      }
    }
  }
  propertyCost() {
    if (
      this.state.salePrice !== "" &&
      this.state.maxYield !== "" &&
      this.state.contractLength !== "" &&
      this.state.startDate !== ""
    ) {
      store
        .dispatch(
          saveAuction(
            this.state.propertyId,
            this.state.salePrice,
            this.state.maxYield,
            this.state.contractLength,
            this.state.startDate
          )
        )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (this.state.salePrice === "") {
        this.props.setToast([
          { msg: "Please provide sale price", type: "error" },
        ]);
      } else if (this.state.maxYield === "") {
        this.props.setToast([
          { msg: "Please provide max yield", type: "error" },
        ]);
      } else if (this.state.contractLength === "") {
        this.props.setToast([
          { msg: "Please provide contract length", type: "error" },
        ]);
      } else if (this.state.startDate === "") {
        this.props.setToast([
          { msg: "Please enter start date", type: "error" },
        ]);
      }
    }
  }

  setPropertyId(id) {
    console.log(id);
    this.setState({ propertyId: id });
  }

  render() {
    //  const address  = this.state.address;
    //  const setAddress = this.setAddress();
    //  const propt = this;
    return (
      <div className="Dashboard" id="Dashboard">
        <div className="DashboardGroup">
          <Sidenav />
          <div className="row">
            <div className="">
              <Sidebar />
            </div>
            <div className="DashContent"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
});
export default connect(mapStatetoProps, {
  loadUser,
  startLoading,
  logout,
  setToast,
  saveAuction,
})(AddAuction);
