import React, { useEffect, useState } from "react";
import Head from "../../../Component/Head";
import Header from "../../../Component/Header/Header";
import Footer from "../../../Component/Footer/Footer";
import { Link, useParams } from "react-router-dom";
import CountiesTable from "../../../Component/RegionComponents/CountiesTable";
import ZipcodeTable from "../../../Component/RegionComponents/ZipcodeTable";
import {
  axios_getHpiArticleData,
  axios_getHpiChildDataByHpiCode,
  axios_getHpiValuesFromCode
} from "../../../utills/hpi-routes";
import "../region.css";
import { multirand } from "../../../utills/helper";
import PropertyRegionChart from "../../../Component/PropertyRegionChart/PropertyRegionChart";
import GetStarted from "../../../Component/GetStarted/GetStarted";
import CityTable from "../../../Component/RegionComponents/CityTable";

export default function HpiRegionPage() {
  const { slug } = useParams();
  const [hpiData, setHpiData] = useState();
  const [hpiValues, setHpiValues] = useState();
  const [countyData, setCountyData] = useState();
  const [randomcounties, setRandomCounties] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [zipcodeData, setZipcodeData] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);
  const [zipcodeCurrentPage, setZipcodeCurrentPage] = useState(0);
  const [totalZipcodeCount, setTotalZipcodeCount] = useState();
  const [countiesCurrentPage, setCountiesCurrentPage] = useState(0);
  const [totalCountiesCount, setTotalCountiesCount] = useState(0);
  const [cityData, setCityData] = useState();
  const [citiesCurrentPage, setCitiesCurrentPage] = useState(0);
  const [totalCitiesCount, setTotalCitiesCount] = useState(0);
  const [hpiArticalContent, setHpiArticalContent] = useState();

  useEffect(() => {
    setIsLoading(true);
    axios_getHpiValuesFromCode(slug, getHpiValueCallback);
    axios_getHpiArticleData(slug, getHpiArticleCallback);
  }, [slug]);

  useEffect(() => {
    var start = zipcodeCurrentPage * 10;
    var end = start + 10;
    if (zipcodeData) {
      setZipcodes(zipcodeData.slice(start, end));
    }
  }, [zipcodeData, zipcodeCurrentPage]);

  useEffect(() => {
    axios_getHpiChildDataByHpiCode(
      slug,
      "counties",
      countiesCurrentPage,
      getHpiChildDataCountiesCallback
    );
  }, [slug, countiesCurrentPage]);

  useEffect(() => {
    axios_getHpiChildDataByHpiCode(
      slug,
      "cities",
      citiesCurrentPage,
      getHpiChildDataCitiesCallback
    );
  }, [slug, citiesCurrentPage]);

  const getHpiValueCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setRandomCounties(multirand(response.data.data.counties, 2));
        setHpiValues(response.data.data.hpi_values);
        // setCountyData(response.data.data.counties);
        setZipcodeData(response.data.data.zipcodes);
        setTotalZipcodeCount(response.data.data.zipcodes.length);
        setZipcodes(response.data.data.zipcodes.slice(0, 10));
        setHpiData(response.data.data.hpi);
        if (window.location.hash) {
          document
            .querySelector('[id="' + window.location.hash.substring(1) + '"]')
            .scrollIntoView();
        }
      }
    }
    setIsLoading(false);
  };

  const getHpiChildDataCountiesCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setTotalCountiesCount(response.data.data.count);
        setCountyData(response.data.data.rows);
      }
    }
  };

  const getHpiChildDataCitiesCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setTotalCitiesCount(response.data.data.count);
        setCityData(response.data.data.rows);
      }
    }
  };

  const getHpiArticleCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setHpiArticalContent(response.data.data);
      }
    }
  };

  return (
    <>
      <div className="App">
        <Header />
        <section className="region-sec" style={{ marginTop: 140 }}>
          {cityData !== undefined && cityData.length > 0 && hpiData && hpiData.name.length > 0 && (
            <>
              <Head title={`${hpiData && hpiData.name} - home price index on Exuity`} />
              <section className="region-sec" style={{ marginTop: 120 }}>
                <ul className="breadcrumb region-breadcrumb arr-right bg-ffffff">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <img
                        src={require("../../../Images/breadcrumb-home.svg")}
                        alt="Exuity Home Icon"
                      />
                    </Link>
                  </li>
                  <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
                  <li className="breadcrumb-item">
                    <Link to="/regiontypes">Region type</Link>
                  </li>
                  <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
                  <li className="breadcrumb-item">
                    <Link to="/hpis">HPIs</Link>
                  </li>
                  <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>{" "}
                  <li className="breadcrumb-item" aria-current="page">
                    <Link to={`/hpis/${slug}`} onClick={() => window.location.reload()}>
                      {hpiData && hpiData.name}
                    </Link>
                  </li>
                </ul>

                <p className="region-sub-heading">
                  Federal Housing Finance Agency Home Price Index (FHFA HPI):
                </p>
                <h1 className="region-sub-title-1">
                  {hpiData && hpiData.name} Federal Housing Finance Agency Home Price Index (FHFA
                  HPI)
                </h1>
                <p className="region-paragraph">
                  {hpiData && hpiData.name} is a Federal Housing Finance Agency home price index
                  that comprises{" "}
                  {randomcounties.length > 1 ? (
                    <>
                      counties including{" "}
                      {randomcounties.map((item, index) => {
                        if (index === 1) {
                          return (
                            <>
                              {" "}
                              and{" "}
                              <Link
                                key={`c-${index}`}
                                className="region-link"
                                to={`/counties/${item.state.slug}/${item.slug}`}>
                                {item.name}
                              </Link>
                            </>
                          );
                        }
                        return (
                          <Link
                            key={`c-${index}`}
                            className="region-link"
                            to={`/counties/${item.state.slug}/${item.slug}`}>
                            {item.name}
                          </Link>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      the county of{" "}
                      {randomcounties.map((item, index) => {
                        if (index === 1) {
                          return (
                            <>
                              {" "}
                              and{" "}
                              <Link
                                key={`c-${index}`}
                                className="region-link"
                                to={`/counties/${item.state.slug}/${item.slug}`}>
                                {item.name}
                              </Link>
                            </>
                          );
                        }
                        return (
                          <Link
                            key={`c-${index}`}
                            className="region-link"
                            to={`/counties/${item.state.slug}/${item.slug}`}>
                            {item.name}
                          </Link>
                        );
                      })}
                    </>
                  )}
                  . Its most recent index value is {hpiValues[0].value}.
                </p>

                <div className="article">
                  <h2 className="articletitle">
                    {hpiArticalContent && hpiArticalContent.article_title}
                  </h2>
                  <div className="articlecontent">
                    {hpiArticalContent &&
                      hpiArticalContent.article &&
                      hpiArticalContent.article
                        .split("\n")
                        .map((item, i) => (
                          <p key={i}>
                            {<span dangerouslySetInnerHTML={{ __html: item && item }} />}
                          </p>
                        ))}
                  </div>
                </div>
              </section>
              <section className="region-sec-2 region-chart">
                {hpiValues && hpiData && (
                  <>
                    <p>
                      Price Index:{" "}
                      <Link to={`/hpis/${hpiData && hpiData.slug}`}>{hpiData && hpiData.name}</Link>
                    </p>
                    <PropertyRegionChart hpiData={hpiValues} />
                  </>
                )}
              </section>
              <div>
                {/* <section className="region-sec-2"> */}
                {countyData && countyData.length > 1 && (
                  <>
                    <h3 className="region-sub-title-2">Counties List for this HPI</h3>
                    <CountiesTable
                      tableData={countyData}
                      currentPage={setCountiesCurrentPage}
                      totalPageCount={totalCountiesCount}
                      setCurrentPage={setCountiesCurrentPage}
                    />
                  </>
                )}
                {cityData && cityData.length > 1 && (
                  <CityTable
                    tableData={cityData}
                    tableTitle={`Cities in ${hpiData && hpiData.name}`}
                    totalPageCount={totalCitiesCount}
                    currentPage={citiesCurrentPage}
                    setCurrentPage={setCitiesCurrentPage}
                  />
                )}
                {zipcodeData && zipcodeData.length > 1 && (
                  <ZipcodeTable
                    tableData={zipcodes}
                    tableTitle={`Zip codes in ${hpiData && hpiData.name}`}
                    totalPageCount={totalZipcodeCount}
                    currentPage={zipcodeCurrentPage}
                    setCurrentPage={setZipcodeCurrentPage}
                  />
                )}
              </div>
              <GetStarted
                name={`Buy & Sell Shares of Homes in ${hpiData && hpiData.name}`}
                description="Exuity lets property owners sell shares of their properties to investors who earn income and appreciation."
              />
              {/* </section> */}
            </>
          )}
        </section>
        {isLoading === true && (
          <div className="text-center pt-5" style={{ marginTop: 120 }}>
            <div className="spinner-grow text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p>Loading. Please wait...</p>
          </div>
        )}
        <div className="footer-section">
          <Footer />
        </div>
      </div>
    </>
  );
}
