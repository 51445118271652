import React from "react";
import "../PropertyPage.css";

const InvesmentRCards = (props) => (
  <div className="InvesmentRCards">
    <div className="InvesmentRCardsGroup">
      <div className="InvesmentRCard">
        <div className="InvesmentRCard-img">
          <img
            src={require("../../Images/invesmentcard-icon1.svg")}
            alt="investment card"
          />
          <div className="InvesmentRCard-text">
            <span className="InvesmentRCard-subtitle">Property sells for</span>
            <p className="InvesmentRCard-digits">$938,569</p>
          </div>
        </div>
      </div>
      <div className="InvesmentRCard">
        <div className="InvesmentRCard-img">
          <img
            src={require("../../Images/invesmentcard-icon2.svg")}
            alt="investment card"
          />
          <div className="InvesmentRCard-text">
            <span className="InvesmentRCard-subtitle">
              You proceeds from sale
            </span>
            <p className="InvesmentRCard-digits">$187,713</p>
          </div>
        </div>
      </div>
      <div className="InvesmentRCard">
        <div className="InvesmentRCard-img">
          <img
            src={require("../../Images/invesmentcard-icon3.svg")}
            alt="investment card 3"
          />
          <div className="InvesmentRCard-text">
            <span className="InvesmentRCard-subtitle">Profit from equity</span>
            <p className="InvesmentRCard-digits">$87,713</p>
          </div>
        </div>
      </div>
      <div className="InvesmentRCard">
        <div className="InvesmentRCard-img">
          <img
            src={require("../../Images/invesmentcard-icon4.svg")}
            alt="investment card 4"
          />
          <div className="InvesmentRCard-text">
            <span className="InvesmentRCard-subtitle">
              FV of interest pyments
            </span>
            <p className="InvesmentRCard-digits">$55,296</p>
          </div>
        </div>
      </div>
      <div className="InvesmentRCard">
        <div className="InvesmentRCard-img">
          <img
            src={require("../../Images/invesmentcard-icon5.svg")}
            alt="investment card 5"
          />
          <div className="InvesmentRCard-text">
            <span className="InvesmentRCard-subtitle">Total cash returned</span>
            <p className="InvesmentRCard-digits">$243,009</p>
          </div>
        </div>
      </div>
      <div className="InvesmentRCard">
        <div className="InvesmentRCard-img">
          <img
            src={require("../../Images/invesmentcard-icon6.svg")}
            alt="investment card 6"
          />
          <div className="InvesmentRCard-text">
            <span className="InvesmentRCard-subtitle">Total return of</span>
            <p className="InvesmentRCard-digits">143%</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default InvesmentRCards;
