import React, { useEffect, useState } from "react";
import { axios_getIssuanceOwnerList } from "../../utills/issuance-routes";
import { useTable, usePagination } from "react-table";
import moment from "moment";
import { Link, Redirect } from "react-router-dom";
import { URL_PROPERTY } from "../../config/propertyImagesUrl";

export default function TableOwnerIssuances() {
  const [tableData, setTableData] = useState([]);
  const [redirect, setredirect] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const gettIssuanceOwnerCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        if (response.data.data.auction !== null) {
          setTableData(response.data.data);
        }
      }
    } else {
      console.log("Oops Something went wrong", response);
    }
    setIsLoading(false);
  };

  const errorCallback = (error) => {
    console.log("getting error handling", error);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Property",
        accessor: "auction.property.property_address",
        Cell: (row) => {
          return (
            <div className="property-col dash-tbl-col">
              <img
                width={50}
                height={50}
                style={{ objectFit: "cover" }}
                src={URL_PROPERTY(
                  row && row.row && row.row.original && row.row.original.auction.property.images2
                )}
                alt="Auction img"
              />
              <h6>{row.value}</h6>
            </div>
          );
        }
      },
      {
        Header: "Effective Date",
        accessor: "created_at",
        Cell: (row) => {
          return (
            <>{moment(row.value).isValid() ? moment(row.value).format("MMM DD, YYYY") : "-"}</>
          );
        }
      },
      {
        Header: "Amount Raised",
        accessor: "amount",
        Cell: (row) => {
          return `$${Number(row.value).toLocaleString("en-US")}`;
        }
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => {
          if (row.value === "Complete") {
            return <span className="tag--active">Complete</span>;
          } else {
            return <span className="tag--closed">Fail</span>;
          }
        }
      },
      {
        Header: "Yield",
        accessor: "yield",
        Cell: (row) => {
          return `${(row.value * 100).toFixed(2)}%`;
        }
      },
      {
        Header: "Term",
        accessor: "contract_length",
        Cell: (row) => {
          return `${row.value} Years`;
        }
      }
    ],
    []
  );

  const data = tableData;

  const { getTableProps, getTableBodyProps, headerGroups, page, state, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    usePagination
  );

  const { pageIndex, pageSize } = state;

  useEffect(() => {
    axios_getIssuanceOwnerList(pageIndex, pageSize, gettIssuanceOwnerCallback, errorCallback);
  }, [pageIndex, pageSize]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <div>
        <div className="issuances-table">
          <div className="table-responsive">
            <table {...getTableProps()} className="table table-responsive" responsive="lg">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr className="header" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.length > 0 ? (
                  <>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          onClick={() =>
                            setredirect("/issuancesowner-details/" + row.original.code)
                          }>
                          {row.cells.map((cell) => {
                            return (
                              <td className="dash-tbl-col" {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {isLoading ? (
                      <td colSpan="12" className="date-col">
                        <div className="text-center pt-5">
                          <div className="spinner-grow text-warning" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          <p>Loading. Please wait...</p>
                        </div>
                      </td>
                    ) : (
                      <tr className="no-hover">
                        <td colSpan="6" className="date-col">
                          <div className="table-empty-state">
                            <div className="empty-state-img">
                              <img
                                src={require("../../Images/empty-state-icon.svg")}
                                alt="empty state img"
                              />
                              <p>
                                No equity issued yet <br /> Reason: No
                                <Link to="/auctions"> auctions launched</Link>
                              </p>
                              <div className="createauction-btn"></div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
