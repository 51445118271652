import React, { useEffect, useState } from "react";
import "./auctions.css";
import { useTable, usePagination } from "react-table";
import moment from "moment";
import { axios_auctionsIbided } from "../../utills/auction-routes";
import { Redirect } from "react-router";
import { URL_PROPERTY } from "../../config/propertyImagesUrl";

export default function LaunchedActions() {
	const [tableData, setTableData] = useState([]);
	const [redirect, setredirect] = useState(null);
	const [dataFound, setDataFound] = useState(0)

	useEffect(() => {
		setDataFound(1)
		axios_auctionsIbided(getAuctionCallback);
	}, []);

	const getAuctionCallback = (response) => {
		if (response.status === 200) {
			if (response.data.status === true) {
				setTableData(response.data.data);
				if(response.data.data.length >0){
					setDataFound(2)
				}else{
					setDataFound(0)
				}
			}
		}
	};

	const columns = React.useMemo(

		() => [
			{
				Header: "Property",
				accessor: "property.property_address",
				Cell: (row) => {
					return (
						<div className="property-col dash-tbl-col">
							<img
								width={50}
								height={50}
								style={{objectFit:'cover'}}
								src={URL_PROPERTY(row && row.row && row.row.original && row.row.original.property.images2)}
								alt="Auction img"
							/>
							<h6>{row.value}</h6>
						</div>
					);
				},
			},
			{
				Header: "Amount Raising",
				accessor: "amount_raising",
				Cell: (row) => {
					return `$${Number(row.value).toLocaleString("en-US")}`;
				},
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: (row) => {
					if (row.value === "Success") {
						return <span className="tag--active">{row.value}</span>;
					} else {
						return <span className="tag--inreview">{row.value}</span>;
					}
				},
			},
			{
				Header: "Start Date",
				accessor: "start_date",
				Cell: (row) => {
					return ` ${
						moment(row.value).isValid()
							? moment(row.value).format("MMM DD, YYYY")
							: "-"
					}`;
				},
			},
		],
		[]
	);

	const data = tableData;

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		// nextPage,
		// previousPage,
		// canPreviousPage,
		// canNextPage,
		// pageOptions,
		// state,
		// gotoPage,
		// pageCount,
		// setPageSize,
		prepareRow,
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
		},
		usePagination
	);

	// const { pageIndex, pageSize } = state;
	if (redirect) {
		return <Redirect to={redirect} />;
	  };

	return (
		<>
			<div>
				<div className="auction-table">
					<table
						{...getTableProps()}
						className="table table-responsive"
						responsive="lg"
					>
						<thead>
							{headerGroups.map((headerGroup) => (
								<tr className="header" {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => (
										<th {...column.getHeaderProps()}>
											{column.render("Header")}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{page.length === 0 ? (
								<>
								{dataFound === 1?(
									<td colSpan="12" className="date-col">
									 <div className="text-center pt-5">
										<div className="spinner-grow text-warning" role="status">
										<span className="sr-only">Loading...</span>
										</div>
										<p>Loading. Please wait...</p>
									</div>
									</td>
								):(
								<tr className="no-hover">
									<td colSpan="4" className="date-col">
										<div className="table-empty-state">
											<div className="empty-state-img">
												<img
													src={require("../../Images/empty-state-icon.svg")}
													alt="empty state img"
												/>
												<p>No auctions bid on yet</p>
												<div className="createauction-btn"></div>
											</div>
										</div>
									</td>
								</tr>
								)}
								</>
								) : (
								<>
									{page.map((row) => {
										prepareRow(row);
										return (
											<tr {...row.getRowProps()}
											onClick={() => setredirect("/AuctionInvestor-details/"+ row.original.code)}
											>
												{row.cells.map((cell) => {
													return (
														<td
															className="dash-tbl-col"
															{...cell.getCellProps()}
														>
															{cell.render("Cell")}
														</td>
													);
												})}
											</tr>
										);
									})}
								</>
							)}
						</tbody>
					</table>

				</div>
			</div>
		</>
	);
}
