
import * as atypes from "./types";

export const getHeroData = () => { 
  return {
    type: atypes.GET_HERO_DATA,
  }
};

export const setHeroData = (heroUpdatedData) => { 
  return {
    type: atypes.SET_HERO_DATA,
    payload: heroUpdatedData
  }
};
