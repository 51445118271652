import React, { Component } from "react";
import "./App.css";
import "./Component/Header/header.css";
import Header from "./Component/Header/Header";
import Modal from "react-modal";
import Footer from "./Component/Footer/Footer";
import "./circular/stylesheet.css";
import { Herodata } from "../src/components/db";
import MasterModal from "../src/components/Modal";
import Hero from "./Component/Hero/Hero";
import { startLoading } from "./store/actions/auth";
import store from "./store/store";
import RequirementSection from "./Component/RequirementSection/RequirementSection";
import { setToast } from "./store/actions/toast";
import GetStarted from "./Component/GetStarted/GetStarted";
import Head from "./Component/Head";
import SearchProperty from "./Component/SearchProperty/SearchProperty";
import NewEquity from "./Component/NewEquity/NewEquity";
import Gist from "./Component/Gist/Gist";
import FeaturedRegions from "./Component/FeaturedRegions/FeaturedRegions";
import FeaturedArticles from "./Component/FeaturedArticles/FeaturedArticles";

Modal.setAppElement("#root");

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsOpen: false,
			modalIsOpenSignIn: false,
			popModal: null,
			jwt: localStorage.getItem("token") || null,
			active: 0,
			isPageLoading: true,
		};
	}

	componentDidMount() {
		const queryParams = new URLSearchParams(this.props.location.search);
		const signup = queryParams.get("signup");
		if (signup) {
			store.dispatch(
				setToast([
					{
						msg: "Please check your email for an email-confirmation link.",
						type: "success",
					},
				])
			);
		}
		store.dispatch(startLoading());
		this.setState({ isPageLoading: false });
	}

	openModal = (Modal) => {
		switch (Modal) {
			case "modal1":
				return this.setState({ popModal: Herodata.modal1 });
			default:
				break;
		}
	};
	removeJWT = () => {
		this.setState({ jwt: null });
	};

	renderModal() {
		const { popModal } = this.state;
		if (popModal) {
			return (
				<MasterModal
					visible={true}
					removeModal={this.removeModal}
					heading={popModal.heading}
					paragraph={popModal.paragraph}
				/>
			);
		}
	}

	removeModal = () => {
		this.setState({
			popModal: null,
		});
	};

	openSignUpModal = () => {
		this.setState({ modalIsOpen: true });
	};
	openSignInModal = () => {
		this.setState({ modalIsOpenSignIn: true });
	};

	closeModal = () => {
		this.setState({ modalIsOpen: false });
	};

	closeSignInModal = () => {
		this.setState({ modalIsOpenSignIn: false });
	};
	handlerActive = (val) => {
		this.setState({ active: val });
	};
	render() {
		return (
			<>
				<Head title={"Exuity | Sell or Invest in Shares of Real Properties"} />
				<div className="App">
					{this.renderModal()}
					<div className="hero">
						<div className="sticky-header">
							<Header />
						</div>
						<Hero />
					</div>
					{!this.state.isPageLoading && (
						<>
							<div className="search-sec">
								<SearchProperty key="12251asdfasdfasdfasdf" />
							</div>

							<section className="requirement-section mt-5 pt-4">
								<div className="section-title">
									<RequirementSection key="12251asdfasdfasdfasdfa" />
								</div>
							</section>

							<div className="gist-component" id="gist-section">
								<Gist />
							</div>

							<section className="financial-model-sec" id="modal-page">
								<NewEquity />
							</section>

							<section className="mt-5 pt-5">
								<FeaturedArticles />
							</section>

							<section className="mt-5 pt-5">
								<FeaturedRegions />
							</section>

							<section className="get-started-section">
								<GetStarted key="12251asdfasdfasdfasdfv" />
							</section>
						</>
					)}
					<div className="footer-section">
						<Footer />
					</div>
				</div>
			</>
		);
	}
}

export default App;
