import React from "react";
import "./Calculator.css";
import CalculatorHeader from "../../components/CalculatorPage/CalculatorHeader";
import CalculatorTabs from "../../components/CalculatorPage/CalculatorTabs";
import Footer from "../../Component/Footer/Footer";

class Calculator extends React.Component {
  constructor() {
    super();
    this.state = {
      isHidden: true
    };
  }
  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden
    });
  }

  render() {
    return (
      <div className="CalculatorPage">
        <CalculatorHeader />

        <div className="CalculatorTabs-Group">
          <CalculatorTabs />
        </div>
        <div className="footer-section">
          <Footer />
        </div>
      </div>
    );
  }
}

export default Calculator;
