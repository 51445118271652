import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { QueryFinder } from "../../hooks/searchQueryContext";
//components
import HomePageSearchBar from "../../components/HomePageSearchBar";
//styles
import "./searchproperty.css";

const SearchProperty = () => {
  const [defaultActiveTab, setDefaultActiveTab] = useState();

  const heroData = useSelector((state) => state);

  useEffect(() => {
    if (heroData.heroData.valueGroups.sellBuy !== undefined) {
      setDefaultActiveTab(heroData.heroData.valueGroups.sellBuy === "buy" ? "Region" : "Address");
    }
  }, [heroData.heroData.valueGroups.sellBuy]);

  return (
    <QueryFinder>
      {" "}
      <div className="searchproperty">
        <div className="searchproperty-wrapper">
          <div className="searchproperty-text">
            <p>Type an address or region you’re interested in.</p>
          </div>
          <div className="search-wrapper property-search">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <span
                  className={defaultActiveTab === "Address" ? "nav-link active" : "nav-link"}
                  data-toggle="tab"
                  role="tab"
                  aria-controls="Address"
                  aria-selected="false"
                  onClick={() => {
                    setDefaultActiveTab("Address");
                  }}>
                  Address
                </span>
              </li>
              <li className="nav-item">
                <span
                  className={defaultActiveTab === "Region" ? "nav-link active" : "nav-link"}
                  data-toggle="tab"
                  role="tab"
                  aria-controls="Region"
                  aria-selected="false"
                  onClick={() => {
                    setDefaultActiveTab("Region");
                  }}>
                  Region
                </span>
              </li>
            </ul>
            <HomePageSearchBar className="property-search" defaultActiveTab={defaultActiveTab} />
          </div>
        </div>
      </div>
    </QueryFinder>
  );
};

export default SearchProperty;
