import React from "react";
import "../Dashboard.css";
import "./properties.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
// import PropertyCard from "../PropertyCard";
import { connect } from "react-redux";
import { loadUser, startLoading, logout } from "../../store/actions/auth";
import { axios_loadProperties } from "../../utills/property-routes";
import store from "../../store/store";
// import { BrowserRouter as Router, Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
// import PropertiesList from "./PropertiesList";
import OwnerTable from "./OwnerTable";
import Head from "../../Component/Head";

class Properties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      found: 0,
      properties: [],
    };
  }

  openProperty() {
    this.setState({
      isVisible: true,
    });
  }
  loadProperties() {
    axios_loadProperties(1, 10000)
      .then((value) => {
        this.setProperties(value.data.properties);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  setProperties(properties) {
    this.setState({ properties: properties });
    if (properties.length > 0) {
      this.setState({
        found: 2,
      });
    } else {
      this.setState({
        found: 0,
      });
    }
  }
  componentDidMount() {
    store.dispatch(startLoading());
    store.dispatch(loadUser());
    this.setState({
      found: 1,
    });
    this.loadProperties();
  }
  render() {
    return (
      <>
        <Head title={"Exuity | Owner | Properties"} />
        <div className="Dashboard" id="Dashboard">
          <div className="DashboardGroup">
            <div className="auction-navbar">
              <div className="dashTitle">
                <h4>Your Properties</h4>
              </div>
              <div className="createauction-btn createauction-btn-2">
                <NavLink to="/properties/create">
                  <button type="button">Claim property</button>
                </NavLink>
              </div>
            </div>

            <div className="row">
              <div className="">
                <Sidebar />
              </div>
              <div className="DashContent">
                <OwnerTable
                  found={this.state.found}
                  properties={this.state.properties}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
});
export default connect(mapStatetoProps, { loadUser, startLoading, logout })(
  Properties
);
