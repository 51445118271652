import React from "react";
import Sidenav from "../Sidenav";
import "./paymentmade.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import TablePaymentMade from "./TablePaymentMade";
import Head from "../../Component/Head";

class PaymentMade extends React.Component {
	render() {
		return (
			<>
				<Head title={'Exuity | Owner | Payments Made'} />

				<div className="Dashboard">
					<div className="DashboardGroup">
						<div className="auction-navbar">
							<div className="dashTitle">
								<h4>Payments You Made</h4>
							</div>
							<Sidenav />
						</div>
						<div className="row">
							<div className="auction-navbar1">
								<Sidebar />
							</div>
							<div className="DashContent pt-0 pt-lg-5">
								<div className="AuctionPage">
									<TablePaymentMade />
								</div>
							</div>
						</div>
					</div>
				</div>
			</>

		);
	}
}

export default PaymentMade;
