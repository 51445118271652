import React from "react";
import "./SearchPage.css";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";
import SearchCards from "../../components/SearchPage/SearchCards";
import { axios_searchProperties } from "../../utills/property-routes";
import GoogleMap from "../../components/SearchPage/GoogleMap";
import PropTypes from "prop-types";
// import ReactPaginate from "react-paginate";

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      WindowSize: window.innerWidth,
      isVisible: false,
      found: 0,
      properties: [],
      isToggleOn: true,
      places: [],
      mapApiLoaded: false,
      mapInstance: null,
      mapApi: null,
      totalPages: 0,
      currentPage: 0,
      totalItems: 0,
      bounds: null,
      zoom: 10,
      isHovered: false,
      isHoveredProperty: null,
    };

    this.handleResize = this.handleResize.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setMarker = this.setMarker.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.setHover = this.setHover.bind(this);
  }
  setBounds(bounds) {
    this.setState({ bounds: bounds });
  }
  setZoom(zoom) {
    this.setState({ zoom: zoom });
  }
  apiHasLoaded = (map, maps) => {
    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });
    this.loadProperties(this.state.currentPage, 20);
  };
  loadProperties(page, size) {
    axios_searchProperties(page, size)
      .then((value) => {
        this.setProperties(value.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  setProperties(res) {
    if (res.items.length > 0) {
      this.setState({ properties: res.items });
      var places = [];
      for (var i = 0; i < res.items.length; i++) {
        places.push(this.propertyMarker(res.items[i]));
      }
      this.setState({ places: places });

      var latlng = {
        lat: parseFloat(res.items[0].lat),
        lng: parseFloat(res.items[0].lng),
      };
      this.state.mapInstance.setCenter(latlng);

      this.setState({
        totalPages: res.totalPages,
      });
      this.setState({
        totalItems: res.totalItems,
      });
      this.setState({
        currentPage: res.currentPage,
      });
      this.setState({
        found: 2,
      });
    } else {
      this.setState({ properties: [] });
      this.setState({
        found: 0,
      });
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setState({
      found: 1,
    });
    document.title = "Exuity | Search Properties";
  }
  componentWillUnmount() {
    window.addEventListener("resize", null);
  }
  handlePageClick(event) {
    console.log(event);
    this.loadProperties(event.selected, 20);
  }
  handleResize(WindowSize, event) {
    this.setState({ WindowSize: window.innerWidth });
  }

  handleClick() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }
  propertyMarker(property) {
    if (property.lat === "" || property.lat === null) {
      property.lat = 0;
      property.lng = 0;
    }
    var place = {
      type: "Feature",
      properties: { cluster: false },
      id: property.id,
      geometry: {
        type: "Point",
        location: {
          lat: property.lat,
          lng: property.lng,
        },
      },
      show: true,
      name: property.property_address,
    };
    return place;
  }
  setMarker(property) {
    var place = this.propertyMarker(property);
    this.setState({ places: [place] });
    var latlng = {
      lat: parseFloat(property.lat),
      lng: parseFloat(property.lng),
    };
    this.state.mapInstance.setCenter(latlng);
  }
  setHover(property) {
    this.setState((prevState) => ({
      isHovered: !prevState.isHovered,
      isHoveredProperty: prevState.isHovered === false ? property : null,
    }));
    var latlng = {
      lat: parseFloat(property.lat),
      lng: parseFloat(property.lng),
    };
    this.state.mapInstance.setCenter(latlng);
    //  this.setState({isHoveredProperty:property});
    if (this.state.isHovered === false) {
      //  this.setState({isHoveredProperty:null});
    }
  }

  render() {
    let width = window.innerWidth;
    if (width > 991) {
      return (
        <div
          hidden={this.state.WindowSize < 1024 ? "hidden" : ""}
          className="searchPage"
        >
          <div className="search-header mb-5">
            <Header />
          </div>
          <div className="searchPageGroup">
            <div className="searchpageContent">
              <div
                className="searchcard-sec mt-0"
                style={{
                  overflow: "scroll",
                  overflowX: "hidden",
                  height: "100vh",
                }}
              >
                <SearchCards
                  properties={this.state.properties}
                  found={this.state.found}
                  setMarker={this.setMarker}
                  setHover={this.setHover}
                />
                {/* <ReactPaginate
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={this.handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={this.state.totalPages}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                /> */}
              </div>
              <div className="search-map">
                <GoogleMap
                  apiHasLoaded={this.apiHasLoaded}
                  places={this.state.places}
                  isHovered={this.state.isHovered}
                  isHoveredProperty={this.state.isHoveredProperty}
                ></GoogleMap>
              </div>
            </div>
          </div>
          <div className="footer-section pt-5">
            <Footer />
          </div>
        </div>
      );
    } else if (this.state.isToggleOn) {
      return (
        <div className="searchPage">
          <div className="search-header" style={{ marginBottom: 10 }}>
            <Header />
          </div>
          <div className="searchPageGroup">
            <div className="map-list-toggle" onClick={this.handleClick}>
              {this.state.isToggleOn ? (
                <img
                  src={require("../../Images/List-icon.png")}
                  alt="search list"
                />
              ) : (
                <img
                  src={require("../../Images/google-maps-icon.svg")}
                  alt="search list map"
                />
              )}
            </div>
            <div className="searchpageContent">
              <div className="search-map">
                <GoogleMap
                  apiHasLoaded={this.apiHasLoaded}
                  places={this.state.places}
                  isHovered={this.state.isHovered}
                  isHoveredProperty={this.state.isHoveredProperty}
                ></GoogleMap>
              </div>
            </div>
          </div>
          <div className="footer-section">
            <Footer />
          </div>
        </div>
      );
    } else {
      return (
        <div className="searchPage">
          <div className="search-header" style={{ marginBottom: 10 }}>
            <Header />
          </div>
          <div className="searchPageGroup">
            <div className="map-list-toggle" onClick={this.handleClick}>
              {this.state.isToggleOn ? (
                <img
                  src={require("../../Images/List-icon.png")}
                  alt="search list icon"
                />
              ) : (
                <img
                  src={require("../../Images/google-maps-icon.svg")}
                  alt="search list map icon"
                />
              )}
            </div>

            <div className="searchpageContent">
              <div className="searchcard-sec">
                <SearchCards
                  properties={this.state.properties}
                  found={this.state.found}
                  setMarker={this.setMarker}
                  setHover={this.setHover}
                />
              </div>
            </div>
          </div>
          <div className="footer-section">
            <Footer />
          </div>
        </div>
      );
    }
  }
}

export default SearchPage;

const InfoWindow = (props) => {
  const { place } = props;
  const infoWindowStyle = {
    position: "relative",
    bottom: 150,
    left: "-45px",
    width: 220,
    backgroundColor: "white",
    boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
    padding: 10,
    fontSize: 14,
    zIndex: 100,
    display: "none",
  };
  return (
    <div style={infoWindowStyle}>
      <div style={{ fontSize: 16 }}>{place.name}</div>
    </div>
  );
};

const Marker = ({ show, place }) => {
  const markerStyle = {
    border: "1px solid white",
    borderRadius: "50%",
    height: 10,
    width: 10,
    backgroundColor: show ? "red" : "blue",
    cursor: "pointer",
    zIndex: 10,
  };
  const showinfowindow = {
    show: false,
  };
  return (
    <>
      <div
        style={markerStyle}
        onMouseEnter={() => {
          this.showinfowindow.show = true;
        }}
        onMouseLeave={() => {
          this.showinfowindow.show = true;
        }}
      />
      {show && <InfoWindow place={place} showit={showinfowindow.show} />}
    </>
  );
};

InfoWindow.propTypes = {
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    rating: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.string),
    price_level: PropTypes.number,
    opening_hours: PropTypes.shape({
      open_now: PropTypes.bool,
    }),
  }).isRequired,
};

Marker.propTypes = {
  show: PropTypes.bool.isRequired,
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    rating: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.string),
    price_level: PropTypes.number,
    opening_hours: PropTypes.shape({
      open_now: PropTypes.bool,
    }),
  }).isRequired,
};
