import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArticlesListTable from "../../Component/ArticlesListTable/ArticlesListTable";
import Footer from "../../Component/Footer/Footer";
import Head from "../../Component/Head";
import Header from "../../Component/Header/Header";
import { axios_getArticlesListBySlug } from "../../utills/axios-articles";

export default function ArticlesList(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [articleListData, setArticleListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPageCount, setTotalCount] = useState();
  const [articleType, setArticleType] = useState("");

  useEffect(() => {
    setArticleType(props.location && props.location.pathname.slice(1));
    setIsLoading(true);
    axios_getArticlesListBySlug(
      props.location && props.location.pathname.slice(1),
      0,
      getCityCallback
    );
  }, [props]);

  useEffect(() => {
    if (currentPage !== 0) {
      axios_getArticlesListBySlug(articleType, currentPage, getCityCallback);
    }
  }, [currentPage, articleType]);

  const getCityCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setArticleListData(response.data.data.rows);
        setTotalCount(response.data.data.count);
      }
    }
    setIsLoading(false);
  };
  return (
    <>
      <Head
        title={`All Articles List in ${
          articleListData &&
          articleListData[0] &&
          articleListData[0].article_type &&
          articleListData[0].article_type.name
        } - Exuity`}
      />
      <div className="App">
        <Header />

        <section className="region-sec" style={{ marginTop: 140 }}>
          <ul className="breadcrumb region-breadcrumb arr-right bg-ffffff">
            <li className="breadcrumb-item">
              <Link to="/">
                <img src={require("../../Images/breadcrumb-home.svg")} alt="Exuity Home Icon" />
              </Link>
            </li>
            <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
            <li className="breadcrumb-item">
              <Link to="/articletypes">Article type</Link>
            </li>
            <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
            <li className="breadcrumb-item">
              <Link to={props.location && props.location.pathname}>
                {articleListData &&
                  articleListData[0] &&
                  articleListData[0].article_type &&
                  articleListData[0].article_type.name}
              </Link>
            </li>
          </ul>

          {isLoading === true && (
            <div className="text-center pt-5">
              <div className="spinner-grow text-warning" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p>Loading. Please wait...</p>
            </div>
          )}

          {!isLoading && articleListData && articleListData.length > 0 && (
            <>
              <h1 className="region-sub-title">All Articles List</h1>
              <ArticlesListTable
                tableData={articleListData}
                totalPageCount={totalPageCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                articleType={articleType}
              />
            </>
          )}

          {!isLoading && totalPageCount === 0 && (
            <h1 className="region-sub-title text-center my-5">No Article List Found</h1>
          )}
        </section>

        <div className="footer-section">
          <Footer />
        </div>
      </div>
    </>
  );
}
