import axios from "./axios-server";

export const axios_loginUser = (email, password) => {
  return axios.post(
    "/auth/signin",
    { email, password },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const axios_registerUserByShiv = (data, callback) => {
  axios
    .post(`/user/signup`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};

export const axios_registerUser = (data) => {
  return axios.post("/user/signup", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const axios_updateUser = (data) => {
  return axios.post("/user/update", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const axios_activateUser = (data) => {
  return axios.post("/auth/activate-account", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axios_loadUser = () => {
  return axios.post(
    "/auth/userData",
    {},
    {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("userToken"),
      },
    }
  );
};

export const axios_sendCodeToEmail = (email) => {
  return axios.post(
    "/auth/sendcode",
    { email },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const axios_confirmCode = (email, code) => {
  return axios.post(
    "/auth/confirmcode",
    { email, code },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const axios_resetPassword = (email, password, code) => {
  return axios.post(
    "/auth/resetpassword",
    { email, code, password },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
