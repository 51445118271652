import axios from 'axios';
import API_URL from '../config/api';

const instance=axios.create({
    baseURL:API_URL
    
})

if (localStorage.userToken) {
    instance.defaults.headers.common["x-auth-token"] = localStorage.userToken;
  } else {
    delete instance.defaults.headers.common["x-auth-token"];
  }

export default instance;
