import axios from "./axios-server";

export const axios_getCountyValuesFromCode = (state, county, callback) => {
  axios
    .get(`counties/get-counties-by-code/${state}/${county}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};

export const axios_getCountyArticleData = (state, county, callback) => {
  axios
    .get(`/counties/getCountyArticleData/${state}/${county}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};

export const axios_getAllCounties = (page, callback) => {
  axios
    .get(`counties/get-all-counties/${page}/10`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
};


export const axios_getCountiesChildDataByCountiesCode = (state, county, regionType, page, callback) => {
  axios.get(`/counties/getCountiesChildByCountiesCode/${state}/${county}/${regionType}/${page}/10`, {
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => callback(response))
    .catch((error) => {
      console.log("Oops! Something went wrong ", error);
    });
}
