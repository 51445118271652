import axios from "./axios-server";

export const axios_getFeaturedRegions = (callback) => {
    axios
        .get(`/featured-regions/get-featured-regions`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => callback(response))
        .catch((error) => {
            console.log("Oops! Something went wrong ", error);
        });
};