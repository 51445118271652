import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { axios_getProposalFromCode } from "../../utills/proposal-routes";
import { zapier_visitor_lead } from "../../utills/zapier";
import { str_into_num } from "../../utills/string-into-number";
import Footer from "../../Component/Footer/Footer";
import Head from "../../Component/Head";
import Header from "../../Component/Header/Header";
import NewEquity from "../../Component/NewEquity/NewEquity";
import moment from "moment";
import GetStarted from "../../Component/GetStarted/GetStarted";
import { axios_getHpiFromZipCity } from "../../utills/hpi-routes";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import "./proposal.css";
import PropertyRegionChart from "../../Component/PropertyRegionChart/PropertyRegionChart";

export default function PropertiesProposal() {
  const [isLoading, setIsLoading] = useState(true);
  const { code } = useParams();
  const [proposalData, setProposalData] = useState();
  const [appreciation, setAppreciation] = useState();
  // const [equity, setEquity] = useState(0);
  const [currentValue, setCurrentValue] = useState(0);
  // const [exchangePercent, setExchangePercent] = useState(0);
  const [hpiData, setHpiData] = useState();
  const [noData, setNoData] = useState();

  useEffect(() => {
    setIsLoading(true);
    axios_getProposalFromCode(code, getProposalValueCallback);
  }, [code]);

  const getProposalValueCallback = async (response) => {
    if (response.status === 200 && response.data.status === true) {
      setProposalData(response.data.data);
      const cityzipdata = {
        city: response.data.data.city,
        zipcode: response.data.data.zip
      };
      axios_getHpiFromZipCity(cityzipdata, zipCityDataCallback);
      console.log(document.referrer);
      zapier_visitor_lead({
        code: code,
        url: window.location.href,
        date: new Date().toUTCString(),
        detail: response.data.data,
        referrer: document.referrer
      });
    } else {
      setNoData(true);
    }
    setIsLoading(false);
  };

  const zipCityDataCallback = (response) => {
    if (response.status === 200 && response.data.status === true) {
      setHpiData(response.data.data.county.hpi);
    }
  };

  useEffect(() => {
    if (proposalData) {
      setAppreciation((proposalData.est_value / (proposalData.purchase_amt - 1)) * 100 - 1 * 100);

      setCurrentValue(proposalData.est_value * 0.5 - proposalData.est_open_loans_amt);
    }
  }, [proposalData]);

  const properCase = (string) => {
    let sentence = string.toLowerCase().split(" ");
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }

    return sentence.join(" ");
  };

  return !noData ? (
    <>
      <Head title={`Exuity | ${proposalData && properCase(proposalData.address)} Proposal`} />
      <div className="App">
        <Header />

        {isLoading ? (
          <section
            className="my-5 container loader d-flex align-items-center justify-content-center"
            style={{ marginTop: 140 }}>
            <span className="text-center d-flex ml-3 align-items-center">
              <div className="spinner-grow text-warning mr-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              Loading...
            </span>
          </section>
        ) : (
          <>
            <div className="proposal-page-wrapper">
              <Accordion preExpanded={["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k"]}>
                <AccordionItem uuid="a">
                  <section className="proposal-card mt-card ">
                    <div className="proposal-container">
                      <h1 className="page-title">
                        Exuity's Proposal to Owners of &nbsp;
                        {proposalData && properCase(proposalData.address)}
                      </h1>
                      <div className="proposal-wrapper">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="card-title">
                              <img
                                src={require("../../Images/introduction-card.svg")}
                                alt="Introduction Icon"
                              />
                              <h4>Introduction</h4>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="proposal-para">
                            {proposalData &&
                              (proposalData.primary_first &&
                              proposalData.primary_first != null &&
                              proposalData.secondary_first &&
                              proposalData.secondary_first != null
                                ? properCase(
                                    `${proposalData.primary_first} & ${proposalData.secondary_first},`
                                  )
                                : proposalData.primary_first && proposalData.primary_first != null
                                ? properCase(`${proposalData.primary_first},`)
                                : proposalData.secondary_first &&
                                  proposalData.secondary_first != null
                                ? properCase(`${proposalData.secondary_first},`)
                                : " ")}
                          </p>
                          <p className="proposal-para mt-4">
                            This is a proposal to “sell and rent back” a portion of the value of{" "}
                            {proposalData && properCase(proposalData.address)} to investors on
                            Exuity’s home equity marketplace. This is an alternative to selling the
                            property outright or taking out another mortgage. Our perhaps-incorrect
                            estimate is that the property is worth around $
                            {proposalData && str_into_num(proposalData.est_value)}{" "}
                            {proposalData &&
                            proposalData.purchase_amt < proposalData.est_value &&
                            proposalData.purchase_amt !== null ? (
                              <>
                                ({Math.round(appreciation)}% appreciation since you purchased it for
                                ${str_into_num(proposalData && proposalData.purchase_amt)} in{" "}
                                {moment(proposalData && proposalData.purchase_date).isValid()
                                  ? moment(proposalData && proposalData.purchase_date).format(
                                      "YYYY"
                                    )
                                  : "-"}{" "}
                                - nice!)
                              </>
                            ) : null}{" "}
                            {proposalData &&
                            proposalData.est_open_loans_amt !== null &&
                            proposalData.est_open_loans_amt !== 0 ? (
                              <>
                                and that your mortgage balance is $
                                {str_into_num(proposalData && proposalData.est_open_loans_amt)}
                              </>
                            ) : (
                              "and that you have no mortgage"
                            )}
                            {proposalData &&
                            proposalData.est_open_loans_amt !== null &&
                            proposalData.est_open_loans_amt !== 0 ? (
                              <>
                                . This means that, for example, in exchange for 50% of your eventual
                                sale price + an annual fee you could pay off your mortgage and get $
                                {str_into_num(currentValue)} in cash or you could keep your mortgage
                                and get $
                                {str_into_num(
                                  proposalData &&
                                    proposalData.est_value * 0.5 - proposalData.est_open_loans_amt
                                )}{" "}
                                in exchange for{" "}
                                {proposalData &&
                                  (
                                    ((proposalData.est_value * 0.5 -
                                      proposalData.est_open_loans_amt) /
                                      proposalData.est_value) *
                                    100
                                  ).toFixed(2)}
                                % of your eventual sale price + an annual fee.
                              </>
                            ) : (
                              <>
                                . This means that, for example, you could get $
                                {str_into_num(
                                  proposalData &&
                                    proposalData.est_value * 0.5 - proposalData.est_open_loans_amt
                                )}{" "}
                                in exchange for{" "}
                                {proposalData &&
                                  (
                                    ((proposalData.est_value * 0.5 -
                                      proposalData.est_open_loans_amt) /
                                      proposalData.est_value) *
                                    100
                                  ).toFixed(2)}
                                % of your eventual sale price + an annual fee.
                              </>
                            )}
                          </p>
                          <p className="proposal-para mt-3">
                            You may skip ahead to any of the following sections:{" "}
                            <a className="hash-link" href="#concept-pitch">
                              concept pitch
                            </a>
                            ,{" "}
                            <a className="hash-link" href="#com-mortgage">
                              comparison to mortgage products
                            </a>
                            ,{" "}
                            <a className="hash-link" href="#pro-benefits">
                              program benefits
                            </a>
                            ,{" "}
                            <a className="hash-link" href="#pro-drawbacks">
                              program drawbacks
                            </a>
                            ,{" "}
                            <a className="hash-link" href="#math">
                              math and formulas
                            </a>
                            ,{" "}
                            <a className="hash-link" href="#legal-str">
                              legal details
                            </a>
                            ,{" "}
                            <a className="hash-link" href="#faqs">
                              FAQs
                            </a>
                            ,{" "}
                            <a className="hash-link" href="#recap">
                              recap
                            </a>
                            , and{" "}
                            <a className="hash-link" href="#financialModel">
                              financial model.
                            </a>
                          </p>
                        </AccordionItemPanel>
                      </div>
                    </div>
                  </section>
                </AccordionItem>

                <AccordionItem uuid="b" id="concept-pitch">
                  <section className="proposal-card mt-card-2">
                    <div className="proposal-container">
                      <div className="proposal-wrapper">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="card-title">
                              <img src={require("../../Images/pitch-card.svg")} alt="Pitch Icon" />
                              <h4>Concept Pitch</h4>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="proposal-para mb-content">
                            Exuity is a “home equity exchange.” This means, plainly, you can let
                            rich people compete among themselves to offer you the best terms on
                            buying a fraction of {proposalData && properCase(proposalData.address)}{" "}
                            value from you. You get a lump sum of cash—a fraction of your property’s
                            appraised value—in exchange for that same fraction of the eventual sale
                            price. You also pay investors an annual fee with cash or additional
                            equity, which you can think of as renting back the portion you sold, and
                            there’s no issue if you miss a payment. The fee is determined by a rate
                            (the “Yield %”), which itself is determined by an investor auction
                            designed to get you the lowest rate possible, as low as 0%.
                          </p>
                        </AccordionItemPanel>
                      </div>
                    </div>
                  </section>
                </AccordionItem>

                <AccordionItem uuid="c" id="process">
                  <section className="proposal-card mt-card-2">
                    <div className="proposal-container">
                      <div className="proposal-wrapper">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="card-title">
                              <img
                                src={require("../../Images/process-card.svg")}
                                alt="Process Icon"
                              />
                              <h4>Process</h4>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <ol className="proposal-list">
                            <li>
                              We get your property appraised and collect other reports about the
                              property and the owners credit history.
                            </li>
                            <li>
                              <Link to="/signup" className="live-link login-link blue-link"></Link>
                              You choose the fraction of the appraised value you’d like to convert
                              to cash (the “Equity Sharing %”). You set a maximum Yield % and pick a
                              contract length (usually 5, 10, or 15 years).
                            </li>
                            <li>
                              We run an online auction through which investors bid on the lowest
                              Yield % they’ll require on their slice of the home value. If there’s a
                              lot of demand and investors believe your property will appreciate a
                              lot, the Yield % would be low, as low a 0%. If the property is seen as
                              risky and without much appreciation potential, you may either pay
                              close to the maximum you set or just not get enough investor interest
                              to complete the transaction.
                            </li>
                            <li>
                              if the deal does go through, we record documents on your title,
                              securing the investors’ rights to future sale proceeds. The property
                              stays in your name and actual ownership does not change. You do not
                              deal with individual investors, as their money is pooled through an
                              investment vehicle and we provide the online portal that makes every
                              aspect as easy as possible.
                            </li>
                            <li>
                              Every year, you owe the Yield % of investors’ equity stake’s value
                              with cash or additional equity until you sell or refinance.
                            </li>
                            <li>
                              If you make improvements to your property that increase its value, you
                              keep the gains associated with those improvements.
                            </li>
                            <li>
                              At the time of sale or refinance, investors are entitled to their
                              then-current Equity Sharing % of the sale price or appraised value. We
                              remove the documents recorded on your title and the relationship ends.
                            </li>
                            <li>
                              There are various other details to be aware of such as the specific
                              formulas that govern the above, how we property improvements,
                              responsibility of maintenance costs and taxes, death of an owner,
                              legal structure, the security mechanism, our fees, and more.
                            </li>
                            <br></br>
                          </ol>
                        </AccordionItemPanel>
                      </div>
                    </div>
                  </section>
                </AccordionItem>

                <AccordionItem uuid="f" id="com-mortgage">
                  <section className="proposal-card mt-card-2">
                    <div className="proposal-container">
                      <div className="proposal-wrapper">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="card-title">
                              <img
                                src={require("../../Images/legal-structure-card.svg")}
                                alt="Legal Structure Icon"
                              />
                              <h4>Comparison to mortgage products</h4>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="proposal-para mb-content">
                            The primary differences from mortgage products are the following:
                          </p>
                          <ol className="proposal-list">
                            <li>
                              If the property value goes down (or up), you owe proportionately less
                              (or more). This means you can’t go “underwater” and lose your house.
                              If the home value drops by 40%, you owe 40% less when you sell. With
                              Exuity, you avoid speculating on home values and lock in the current
                              value on a portion of your property’s value.
                            </li>
                            <li>
                              Monthly payments are almost certainly much lower than any mortgage as
                              there isn’t the notion of “principal paydown.” Additionally, periodic
                              cash payments are optional as you may “pay” them with
                              equivalent-values shares of the property instead of cash.
                            </li>
                            <li>
                              Because monthly cash payments are not required, qualifying is much
                              simpler than with mortgages, as ability to make consistent monthly
                              payments is less relevant. The main qualifying criteria is how much
                              equity you have in the property (50% minimum but the closer to 100%
                              the better), and the level of appeal the property has to investors.
                            </li>
                          </ol>
                        </AccordionItemPanel>
                      </div>
                    </div>
                  </section>
                </AccordionItem>

                <AccordionItem uuid="d" id="pro-benefits">
                  <section className="proposal-card mt-card-2">
                    <div className="proposal-container">
                      <div className="proposal-wrapper">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="card-title">
                              <img
                                src={require("../../Images/program-benefits-card.svg")}
                                alt="benefits Icon"
                              />
                              <h4>Program benefits</h4>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>

                        <AccordionItemPanel>
                          <ul className="proposal-list">
                            <li>
                              Convert some of your property’s appreciated value to cash at its fair
                              market value. Don’t gamble on the future.
                            </li>
                            <li>
                              If property value goes down, owe proportionally less. (Does your
                              mortgage do that?)
                            </li>
                            <li>
                              Get the best terms on your equity any investor will offer. We’re on
                              your side.
                            </li>
                            <li>You keep gains from improvements you make.</li>
                            <li>You decide when to sell (within a contract length).</li>
                            <li>
                              No penalty on missed payments; it just converts to equivalent value
                              additional equity for investors.
                            </li>
                            <li>
                              Great credit and income helps get better terms but isn’t required to
                              qualify.
                            </li>
                          </ul>
                        </AccordionItemPanel>
                      </div>
                    </div>
                  </section>
                </AccordionItem>

                <AccordionItem uuid="e" id="pro-drawbacks">
                  <section className="proposal-card mt-card-2">
                    <div className="proposal-container">
                      <div className="proposal-wrapper">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="card-title">
                              <img
                                src={require("../../Images/program-drawbacks-card.svg")}
                                alt="drawbacks Icon"
                              />
                              <h4>Program drawbacks</h4>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <ul className="proposal-list">
                            <li>
                              If your home appreciates, you owe proportionally more (still, the more
                              your home goes up in value, the more you make)
                            </li>
                            <li>
                              You can’t deduct interest payments from taxes (as there are no
                              interest payments)
                            </li>
                            <li>
                              It’s a new, somewhat complicated program operated by a startup company
                              without much history
                            </li>
                            <li>
                              Lack of legal precedent surrounding this concept compared to
                              mortgages.
                            </li>
                          </ul>
                        </AccordionItemPanel>
                      </div>
                    </div>
                  </section>
                </AccordionItem>

                <AccordionItem uuid="g" id="math">
                  <section className="proposal-card mt-card-2">
                    <div className="proposal-container">
                      <div className="proposal-wrapper">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="card-title">
                              <img src={require("../../Images/math-card.svg")} alt="Math Icon" />
                              <h4>Math and Formulas</h4>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="proposal-para mb-content">
                            The following are the key formulas to be aware of:
                          </p>
                          <ul className="proposal-list">
                            <li>
                              Initial equity sharing percentage = investment amount / initial
                              appraised value
                            </li>
                            <li>
                              Amount owed = property value at beginning of year * equity sharing % *
                              yield %
                            </li>
                            <li>Underpaid amount = Amount owed - amount paid</li>
                            <li>
                              Equity increase % = underpaid amount / end of year est. home value
                            </li>
                            <li>
                              Investor proceeds from sale = equity sharing % * gross sale price (or
                              appraised value)
                            </li>
                          </ul>
                        </AccordionItemPanel>
                      </div>
                    </div>
                  </section>
                </AccordionItem>

                <AccordionItem uuid="h" id="legal-str">
                  <section className="proposal-card mt-card-2">
                    <div className="proposal-container">
                      <div className="proposal-wrapper">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="card-title">
                              <img
                                src={require("../../Images/legal-structure-card.svg")}
                                alt="Legal Structure Icon"
                              />
                              <h4>Legal Structure</h4>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p className="proposal-para mb-content">
                            Investments into a property are pooled through property-specific
                            investment vehicles (Delaware Statutory Trusts) which contracts with the
                            property owner per the terms above and secures the investment with a
                            trust deed and purchase option. The contract itself is an option
                            contract (as opposed to a loan or an actual equity purchase), whereby if
                            the property owner fails to abide by the terms or provide liquidity to
                            investors within the term length, the investment vehicle has the right
                            (the “option”) to buy and sell the property to provide liquidity for
                            investors. Exuity has a subsidiary LLC that serves as the Trustee and
                            Trust Manager of the trust, and in general makes decisions and acts on
                            behalf of the trust, but is not itself an owner of the trust.
                          </p>
                          <p className="proposal-para">
                            Note that Vessel is a marketplace, not an investor. We take a neutral,
                            managerial role over the course of the investments.
                          </p>
                        </AccordionItemPanel>
                      </div>
                    </div>
                  </section>
                </AccordionItem>

                <AccordionItem uuid="i" id="faqs">
                  <section className="proposal-card mt-card-2">
                    <div className="proposal-container">
                      <div className="proposal-wrapper proposal-wrapper-2">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="card-title">
                              <img src={require("../../Images/faqs-card.svg")} alt="FAQs Icon" />
                              <h4>FAQs</h4>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>

                        <AccordionItemPanel>
                          <div className="faq-list">
                            <h5>Ownership &amp; Legal Structure</h5>
                            <ul className="proposal-list">
                              <li>
                                You (the current owners) would remain the sole owners of the
                                property from a legal standpoint. We just record documents on your
                                title cementing our rights to the proceeds from an eventual sale.
                              </li>
                            </ul>
                          </div>

                          <div className="faq-list">
                            <h5>Taxes &amp; Maintenance Responsibility.</h5>
                            <ul className="proposal-list">
                              <li>
                                You, the original property owner, pay all maintenance costs, you
                                decide when to sell, no one else has the right to use your property.
                              </li>
                            </ul>
                          </div>

                          <div className="faq-list">
                            <h5>Relationship to Investors</h5>
                            <ul className="proposal-list">
                              <li>
                                You only deal with Exuity. The individual investors in your property
                                remain anonymous to you and we deal with them.
                              </li>
                            </ul>
                          </div>

                          <div className="faq-list">
                            <h5>"Payments Optional" ...Meaning?</h5>
                            <ul className="proposal-list">
                              <li>
                                You are not required to make ongoing cash payments as, at your
                                discretion, you may opt to let those payments accrue to additional
                                equity.
                              </li>
                            </ul>
                          </div>

                          <div className="faq-list">
                            <h5>Credit Score Requirements</h5>
                            <ul className="proposal-list">
                              <li>
                                While impressive income and credit scores can help you get better
                                terms from investors, they are not required, as we don’t need to
                                make absolutely sure you can a!ord monthly payments, as they aren’t
                                required anyway.
                              </li>
                            </ul>
                          </div>
                        </AccordionItemPanel>
                      </div>
                    </div>
                  </section>
                </AccordionItem>

                <AccordionItem uuid="j" id="recap">
                  <section className="proposal-card mt-card-2">
                    <div className="proposal-container">
                      <div className="proposal-wrapper">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="card-title">
                              <img src={require("../../Images/recap-card.svg")} alt="Recap Icon" />
                              <h4>Recap</h4>
                            </div>
                          </AccordionItemButton>
                        </AccordionItemHeading>

                        <AccordionItemPanel>
                          <p className="proposal-para mb-content">
                            You choose some portion of the value of your home to convert to cash.
                            That same portion of your sale price is due upon property sale, and
                            there’s some percentage of the value of the portion you sold that you
                            owe every year. If you don’t pay it with cash, it gets converted to
                            additional equity for investors.{" "}
                          </p>
                          <p className="proposal-para">
                            Please click the Start Tutorial button below to see how each of the
                            variables affects the outcome of what you pay.
                          </p>
                        </AccordionItemPanel>
                      </div>
                    </div>
                  </section>
                </AccordionItem>

                {hpiData && hpiData.url && (
                  <AccordionItem uuid="k">
                    <section className="proposal-card mt-card-2">
                      <div className="proposal-container">
                        <div className="proposal-wrapper">
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <div className="card-title">
                                <img
                                  src={require("../../Images/program-drawbacks-card.svg")}
                                  alt="drawbacks Icon"
                                />
                                <h4>Home Price Index Chart</h4>
                              </div>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <div>
                              <p className="mb-2 price-index-link">
                                The following home price index would be used to estimate changes in
                                home value after the initial appraisal.
                              </p>
                              {hpiData && hpiData.url && (
                                <p className="mb-2 price-index-link">
                                  Price Index:{" "}
                                  {hpiData && hpiData.url !== "null" ? (
                                    <a href={hpiData.url} target="_blank" rel="noopener noreferrer">
                                      {hpiData.name}
                                    </a>
                                  ) : (
                                    hpiData.name
                                  )}
                                </p>
                              )}
                              <PropertyRegionChart hpiData={hpiData && hpiData.hpi_values} />
                            </div>
                          </AccordionItemPanel>
                        </div>
                      </div>
                    </section>
                  </AccordionItem>
                )}
              </Accordion>
            </div>

            <div className="proposal-chart" id="financialModel">
              <NewEquity est_value={proposalData && proposalData.est_value} />
            </div>

            <GetStarted />
          </>
        )}

        <div className="footer-section">
          <Footer />
        </div>
      </div>
    </>
  ) : (
    <h1>Proposals Not Found</h1>
  );
}
