import React, { Component } from "react";
import "../Component/Header/header.css";
import "../login-signup-screens/Form.css";
import Modal from "react-modal";
import { Herodata } from "../components/db";
import MasterModal from "../components/Modal";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { axios_activateUser } from "../utills/axios-routes";
import "../login-signup-screens/Form.css";

Modal.setAppElement("#root");

class Activation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalIsOpenSignIn: false,
      popModal: null,
      jwt: localStorage.getItem("token") || null,
      message: "Validating email. Please wait...",
    };
  }

  componentDidMount() { }

  openModal = (Modal) => {
    switch (Modal) {
      case "modal1":
        return this.setState({ popModal: Herodata.modal1 });
      default:
        break;
    }
  };
  removeJWT = () => {
    this.setState({ jwt: null });
  };

  renderModal() {
    const { popModal } = this.state;
    if (popModal) {
      return (
        <MasterModal
          visible={true}
          removeModal={this.removeModal}
          heading={popModal.heading}
          paragraph={popModal.paragraph}
        />
      );
    }
  }

  removeModal = () => {
    this.setState({
      popModal: null,
    });
  };

  openSignUpModal = () => {
    this.setState({ modalIsOpen: true });
  };
  openSignInModal = () => {
    this.setState({ modalIsOpenSignIn: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  closeSignInModal = () => {
    this.setState({ modalIsOpenSignIn: false });
  };

  checkActicationToken = () => {
    const parsed = queryString.parse(window.location.search);

    axios_activateUser({ token: parsed.token })
      .then((response) => {
        //this.setState({message:response.data.msg})
        window.location.href = "/signin/?success=1";
      })
      .catch((err) => {
        this.setState({ message: err.response.data.msg });
      });
  };

  componentDidMount = () => {
    this.checkActicationToken();
  };

  render() {
    return (
      <div className="Activation">
        <div className="form-wrapper">
          <div className="brand">
            <Link to="/">
              <img src={require("../Images/exuity-logo.svg")} alt="brand" />
            </Link>
          </div>
          <div>{this.state.message}</div>
          <div className="account-creation-link">
            <p>
              <Link to="/signin">Please Sign in</Link>
            </p>
          </div>
          <div className="copyrights-text">
            <p>&copy; {new Date().getFullYear()}, Exuity.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Activation;
