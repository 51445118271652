import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Link, useLocation } from "react-router-dom";
import { registerUser } from "../store/actions/auth";
import { useDispatch } from "react-redux";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import "./Form.css";
import PropertyCardSignup from "./PropertyCardSignup";
import { axios_registerUserByShiv } from "../utills/axios-routes";
import {
  axios_createInvestorProperty,
  axios_createOwnerProperty,
} from "../utills/property-routes";
import { SET_TOAST } from "../store/actions/types";
import Head from "../Component/Head";

const SignUpForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [password, setPassword] = useState("");
  const initialFormValue = {
    first_name: first_name,
    last_name: last_name,
    email: email,
    phoneno: "",
    password: "",
    success: false,
  };
  const [formInitialValue, setFormInitialValue] = useState(initialFormValue);
  const [propertyAddress, setPropertyAddress] = useState();
  const [googlePlaceId, setGooglePlaceId] = useState();

  // Get and set input values
  // const onChangeHandler = (e) => {
  //   e.persist();

  //   setFormInitialValue((formInitialValue) => {
  //     return { ...formInitialValue, [e.target.name]: e.target.value };
  //   });
  // };

  const onChange4PhoneNo = (e) => {
    // e.persist();
    setFormInitialValue((formInitialValue) => {
      return { ...formInitialValue, phoneno: e };
    });
  };

  useEffect(() => {
    if (location.state && location.state.addressSignupData !== undefined) {
      setPropertyAddress(location.state.addressSignupData.address);
      setGooglePlaceId(location.state.addressSignupData.placeId);
    }
  }, [location]);

  const CreatePropertyCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        dispatch({
          type: SET_TOAST,
          payload: [{ msg: "Successfully signed up.", type: "success" }],
        });
        setTimeout(() => {
          window.location.href = "/";

          dispatch({
            type: SET_TOAST,
            payload: [{ msg: " An activation email is sent at your email address. Kindly Check and confirm your account.", type: "success" }],
          });

        }, 7000);
      }

    }
  };

  const registerUserCallback = (response) => {
    if (response.status === 200) {
      if (response.data.success === true) {
        localStorage.setItem("userToken", response.data.token);
        if (location.state.cardType === "Owner") {
          const OwnerPropertyData = {
            owner_id: response.data.data.id,
            property_address: propertyAddress,
            latitude: location.state.lat,
            longitude: location.state.lng,
            google_placeid: googlePlaceId,
            token: response.data.token,
          };
          axios_createOwnerProperty(OwnerPropertyData, CreatePropertyCallback);
        } else if (location.state.cardType === "Investor") {
          const InvestorsPropertyData = {
            user_id: response.data.data.id,
            property_address: propertyAddress,
            latitude: location.state.lat,
            longitude: location.state.lng,
            google_placeid: googlePlaceId,
            token: response.data.token,
          };
          axios_createInvestorProperty(
            InvestorsPropertyData,
            CreatePropertyCallback
          );
        }
      }
    }
  };

  // const phoneRegExp =
  //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  // function validate(phone) {
  //   var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

  //   if (regex.test(phone)) {
  //     // Valid international phone number
  //   } else {
  //     setError("Invalid phone number");
  //     setTimeout(() => {
  //       setError("");
  //     }, 3000);
  //     // Invalid international phone number
  //   }
  // }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        first_name: first_name,
        last_name: last_name,
        email: email,
        phoneno: "",
        password: password,
        success: false,
      }}
      onSubmit={async (values, actions) => {
        if (
          location.state &&
          location.state.cardType &&
          location.state !== "undefined"
        ) {
          axios_registerUserByShiv(values, registerUserCallback);
        } else {
          dispatch(registerUser(values));
        }
      }}
      validationSchema={Yup.object({
        first_name: Yup.string()
          .max(15, "Must be 15 characters or less")
          .required("Your first name is required"),
        last_name: Yup.string()
          .max(15, "Must be 15 characters or less")
          .required("Your last name is required"),
        email: Yup.string()
          .email("Please enter a valid email address.")
          .required("Email is required"),
        // phoneno: Yup.string()
        // 	.required("Phone Number is required"),
        // .matches(phoneRegExp, 'Phone Number is not valid')
        // .min(10, "Phone Number must have 10 digits")
        // .max(10, "Phone Number must have 10 digits"),
        password: Yup.string()
          .required("Password is required")
          .min(8, "Password is too short - should be 8 chars minimum.")
          .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
      })}
    >
      {(formik, isSubmitting, setStatus, status) => {
        return (
          <Form className="form-wrapper">
            <Head title={"Exuity | Sign up"} />

            <div className="brand">
              <Link to="/">
                <img src={require("../Images/exuity-logo.svg")} alt="brand" />
              </Link>
            </div>
            {location && location.state && location.state.addressSignupData && (
              <PropertyCardSignup />
            )}
            {!formik.values.success ? (
              <div className="form-box signup-form-box">
                <div className="form-text-wrapper">
                  <h1>Join</h1>
                  <p>Join Exuity to move money in and out of real estate.</p>
                </div>

                <div className="signup-double-input">
                  <div className="form-group">
                    <label htmlFor="first_name">First Name</label>
                    <input
                      value={first_name}
                      name="first_name"
                      onChange={(e) => setFirst_name(e.target.value)}
                      className={
                        formik.touched.first_name && formik.errors.first_name
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type="text"
                      placeholder="Your First Name"
                    />
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.first_name}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label htmlFor="last_name">Last Name</label>
                    <input
                      name="last_name"
                      onChange={(e) => setLast_name(e.target.value)}
                      className={
                        formik.touched.last_name && formik.errors.last_name
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type="text"
                      placeholder="Your Last Name"
                    />
                    {formik.touched.last_name && formik.errors.last_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.last_name}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="signup-double-input">
                  <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      className={
                        formik.touched.email && formik.errors.email
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      type="email"
                      placeholder="Your Email Address"
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="invalid-feedback">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label htmlFor="phoneno">Phone Number</label>
                    {/* <Field
											name="phoneno"
											className={
												formik.touched.phoneno && formik.errors.phoneno
													? "form-control is-invalid"
													: "form-control"
											}
											type="tel"
											placeholder="Your Phone Number"
										/> */}

                    <PhoneInput
                      defaultCountry="US"
                      name="phoneno"
                      placeholder="Your phone no."
                      // value={this.state.fields.phoneno}
                      // onBlur={this.form.handleBlurEvent}
                      onChange={(e) => onChange4PhoneNo(e)}
                      // onChange={(e) => handleChange("phoneno")(e)}
                      data-attribute-name="Phone No."
                      data-async
                      onBlur={() => setError(true)}
                      onFocus={() => setError(false)}
                    // inputClass="PhoneInputInput form-control is-invalid"
                    // required
                    />

                    <div className="invalid-feedback d-block">
                      {formik.touched.phoneno &&
                        formik.errors.phoneno &&
                        formik.errors.phoneno}

                      {/* { 
                        formInitialValue.phoneno !== "" &&
                        formInitialValue.phoneno
                          ? isValidPhoneNumber(formInitialValue.phoneno)
                            ? undefined
                            : "Invalid phone number"
                          : "" //Phone number required
                      } */}
                    </div>

                    {error && (
                      <>
                        <div className="invalid-feedback d-block">
                          {formik.touched.phoneno &&
                            formik.errors.phoneno &&
                            formik.errors.phoneno}

                          {
                            formInitialValue.phoneno !== "" &&
                              formInitialValue.phoneno
                              ? isValidPhoneNumber(formInitialValue.phoneno)
                                ? undefined
                                : "Invalid phone number"
                              : "" //Phone number required
                          }
                        </div>
                      </>
                    )}
                    {/* {formik.touched.phoneno && formik.errors.phoneno ? (
											<div className="invalid-feedback">
												{formik.errors.phoneno}
											</div>
										) : null} */}
                  </div>
                </div>

                <div className="form-group pass-input">
                  <label className="password-labels" htmlFor="password">
                    <span>Password</span>
                  </label>
                  <input
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    className={
                      formik.touched.password && formik.errors.password
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type="password"
                    placeholder="Create Your Account Password"
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="invalid-feedback">
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    className="blue-btn"
                    disabled={formik.isSubmitting}
                  >
                    {formik.isSubmitting ? "Please wait..." : "Join"}
                  </button>
                </div>

                <div className="account-creation-link">
                  <p>
                    Already a user? <Link to="/signin">Log In</Link>
                  </p>
                </div>
              </div>
            ) : (
              <div>
                An activation email is sent at your email address. Kindly Check
                and confirm your account.
              </div>
            )}
            <div className="copyrights-text">
              <p>&copy; {new Date().getFullYear()}, Exuity</p>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignUpForm;
