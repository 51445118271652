import React, { useEffect, useState } from "react";
import { axios_getPaymentOwed } from "../../utills/paymentOwed-routes";
import { useTable, usePagination } from "react-table";
import "./paymentowed.css";
import moment from "moment";
import { str_into_float_num } from "../../utills/string-into-number";
import { Link, Redirect } from "react-router-dom";
import Head from "../../Component/Head";
import { URL_PROPERTY } from "../../config/propertyImagesUrl";

export default function TablePaymentOwner() {
  const [tableData, setTableData] = useState([]);
  const [redirect, setredirect] = useState(null);
  const [dataFound, setDataFound] = useState(0);

  useEffect(() => {
    setDataFound(1);
    axios_getPaymentOwed(1, 10, getPaymentCallback, errorCallback);
  }, []);

  const getPaymentCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        const getValuePaymentData = response.data.data;
        setTableData(getValuePaymentData);
        if (response.data.data.length > 0) {
          setDataFound(2);
        } else {
          setDataFound(0);
        }
      }
    } else {
      console.log("Oops Something went wrong", response);
    }
  };

  const errorCallback = (error) => {
    console.log("getting error handling", error);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Property",
        accessor: "issuance.auction.property.property_address",
        Cell: (row) => {
          return (
            <div className="property-col dash-tbl-col">
              <img
                width={50}
                height={50}
                style={{ objectFit: "cover" }}
                src={URL_PROPERTY(
                  row &&
                    row.row &&
                    row.row.original &&
                    row.row.original.issuance.auction.property.images2
                )}
                alt="Auction img"
              />
              <h6>{row.value}</h6>
            </div>
          );
        }
      },
      {
        Header: "Amount Owed",
        accessor: "payment_owed_year_end",
        Cell: (row) => {
          const value = str_into_float_num(row.value);
          return <>${value.toLocaleString("en-US")}</>;
        }
      },
      {
        Header: "Amount Paid",
        accessor: "payments_mades",
        Cell: (row) => {
          if (row.value.length > 0) {
            const arr =
              row &&
              row.value &&
              row.value.map((e) => {
                return e.amount;
              });
            const getAmount =
              arr &&
              arr.reduce &&
              arr.reduce((a, b) => {
                return a + b;
              });
            const value = str_into_float_num(getAmount);
            return <>${value.toLocaleString("en-US")}</>;
          } else {
            const value = str_into_float_num(0);
            return <>${value.toLocaleString("en-US")}</>;
          }
        }
      },
      {
        Header: "Due Date",
        accessor: "year_start_date",
        Cell: (row) => {
          return ` ${
            moment(row.value).isValid() ? moment(row.value).add(1, "y").format("MMM DD, YYYY") : "-"
          }`;
        }
      },
      {
        Header: "Est. Home Value",
        accessor: "home_value",
        Cell: (row) => {
          const value = str_into_float_num(row.value);
          return <>${value.toLocaleString("en-US")}</>;
        }
      }
    ],
    []
  );

  const data = tableData;

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    usePagination
  );

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <div>
        <Head title={"Exuity | Owner | Payments Owed"} />
        <div className="paymentowed-table paymentowed-table-2">
          <table {...getTableProps()} className="table table-responsive" responsive="lg">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr className="header" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length > 0 ? (
                <>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        onClick={() =>
                          setredirect("/paymentowedowner-details/" + row.original.code)
                        }>
                        {row.cells.map((cell) => {
                          return (
                            <td className="dash-tbl-col" {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                  {dataFound === 1 ? (
                    <td colSpan="12" className="date-col">
                      <div className="text-center pt-5">
                        <div className="spinner-grow text-warning" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                        <p>Loading. Please wait...</p>
                      </div>
                    </td>
                  ) : (
                    <tr className="no-hover">
                      <td colSpan="12" className="date-col">
                        <div className="table-empty-state">
                          <div className="empty-state-img">
                            <img
                              src={require("../../Images/empty-state-icon.svg")}
                              alt="empty state img"
                            />
                            <p>
                              No payment owed yet <br />
                              Reason: No <Link to="/issuancesowner">home equity issued</Link>
                            </p>
                            <div className="createauction-btn"></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
