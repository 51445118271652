import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  axios_getCityChildDataByStateCode,
  axios_getCityDataFromCode,
  axios_getCityArticleData
} from "../../../utills/city-routes";
import Footer from "../../../Component/Footer/Footer";
import Head from "../../../Component/Head";
import Header from "../../../Component/Header/Header";
import CityTable from "../../../Component/RegionComponents/CityTable";
import CountiesTable from "../../../Component/RegionComponents/CountiesTable";
import StateTable from "../../../Component/RegionComponents/StateTable";
import ZipcodeTable from "../../../Component/RegionComponents/ZipcodeTable";
import { simpleNumber, multirand } from "../../../utills/helper";
import PropertyRegionChart from "../../../Component/PropertyRegionChart/PropertyRegionChart";
import GetStarted from "../../../Component/GetStarted/GetStarted";

export default function CityPage(props) {
  const { state, city } = useParams();
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState();
  const [zipcodeData, setZipcodeData] = useState([]);
  const [countyData, setCountyData] = useState();
  const [population, setPopulation] = useState();
  const [msa, setMSA] = useState();
  const [cbsa, setCBSA] = useState();
  const [csa, setCSA] = useState();
  const [randomzipcodes, setRandomZipcodes] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [zipcodeCurrentPage, setZipcodeCurrentPage] = useState(0);
  const [totalZipcodeCount, setTotalZipcodeCount] = useState();
  const [hpiData, setHpiData] = useState([]);
  const [cityArticalContent, setCityArticalContent] = useState();

  useEffect(() => {
    setIsLoading(true);
    axios_getCityDataFromCode(state, city, getCityCallback);
    axios_getCityArticleData(state, city, getCityArticleCallback);
  }, [state, city]);

  useEffect(() => {
    axios_getCityChildDataByStateCode(
      state,
      city,
      "zipcodes",
      zipcodeCurrentPage,
      getCityChildDataZipCodeCallback
    );
  }, [state, city, zipcodeCurrentPage]);

  const getCityCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        var population = 0;
        for (var i = 0; i < response.data.data.zipcodes.length; i++) {
          population += response.data.data.zipcodes[i].population;
        }
        setRandomZipcodes(multirand(response.data.data.zipcodes, 2));

        setPopulation(simpleNumber(population));
        setStateData(response.data.data.state);
        setZipcodeData(response.data.data.zipcodes);
        setCountyData(response.data.data.counties);
        setHpiData(response.data.data.counties.hpi);
        setCSA(response.data.data.csas);
        setMSA(response.data.data.msas);
        setCBSA(response.data.data.cbsas);
        setCityData(response.data.data.city);
        if (window.location.hash) {
          document
            .querySelector('[id="' + window.location.hash.substring(1) + '"]')
            .scrollIntoView();
        }
      }
    }
    setIsLoading(false);
  };

  const getCityChildDataZipCodeCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setTotalZipcodeCount(response.data.data.count);
        setZipcodeData(response.data.data.rows);
      }
    }
    setIsLoading(false);
  };

  const getCityArticleCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setCityArticalContent(response.data.data);
      }
    }
  };
  return (
    <>
      <div className="App">
        <Header />
        {cityData && cityData.length > 0 && (
          <>
            <Head title={`${cityData && cityData[0] && cityData[0].name} - city on Exuity`} />
            <section className="region-sec" style={{ marginTop: 140 }}>
              <ul className="breadcrumb region-breadcrumb arr-right bg-ffffff">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <img
                      src={require("../../../Images/breadcrumb-home.svg")}
                      alt="Exuity Home Icon"
                    />
                  </Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
                <li className="breadcrumb-item">
                  <Link to="/regiontypes">Region type</Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
                <li className="breadcrumb-item">
                  <Link to="/cities">Cities</Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>{" "}
                <li className="breadcrumb-item">
                  <Link to={`/states/${stateData && stateData[0] && stateData[0].slug}#cities`}>
                    {stateData && stateData[0] && stateData[0].name}
                  </Link>
                </li>
                <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>{" "}
                <li className="breadcrumb-item" aria-current="page">
                  <Link to={`/cities/${state}/${city}`} onClick={() => window.location.reload()}>
                    {cityData && cityData[0] && cityData[0].name}
                  </Link>
                </li>
              </ul>

              <div>
                <p className="region-sub-heading">City:</p>
                <h1 className="region-title">
                  {cityData[0].name}, {stateData[0].abbrev}
                </h1>
                <p className="region-paragraph">
                  {cityData[0].name} is a city in the state of{" "}
                  <Link to={`/states/${stateData[0].slug}`}>{stateData[0].name}</Link> with a
                  population of roughly {population}. It is in{" "}
                  <Link to={`/counties/${stateData[0].slug}/${countyData.slug}`}>
                    {countyData.name}
                  </Link>
                  ,{" "}
                  {msa && (
                    <>
                      which itself is part of the <Link to={`/msas/${msa.slug}`}>{msa.name}</Link>{" "}
                      (MSAD) home price index,
                    </>
                  )}{" "}
                  {cbsa && (
                    <>
                      the <Link to={`/cbsas/${cbsa.slug}`}>{cbsa.name}</Link> core-based statistical
                      areas (CBSA),
                    </>
                  )}{" "}
                  {msa && (
                    <>
                      the <Link to={`/msas/${msa.slug}`}>{msa.name}</Link> metropolitain statistical
                      area (MSA)
                    </>
                  )}{" "}
                  {csa && (
                    <>
                      and the <Link to={`/csas/${csa.slug}`}>{csa.name}</Link> combined statistical
                      area (CSA)
                    </>
                  )}
                  . Its Zip codes include{" "}
                  {randomzipcodes &&
                    randomzipcodes.map((item, index) => {
                      if (index === 1) {
                        return (
                          <>
                            {" "}
                            and{" "}
                            <Link
                              key={`z-${index}`}
                              className="region-link"
                              to={`/zipcodes/${item.slug}`}>
                              {item.zipcode}
                            </Link>
                          </>
                        );
                      }
                      return (
                        <Link
                          key={`z-${index}`}
                          className="region-link"
                          to={`/zipcodes/${item.slug}`}>
                          {item.zipcode}
                        </Link>
                      );
                    })}
                  .
                </p>

                <div className="article">
                  <h2 className="articletitle">
                    {cityArticalContent && cityArticalContent.article_title}
                  </h2>
                  <div className="articlecontent">
                    {cityArticalContent &&
                      cityArticalContent.article &&
                      cityArticalContent.article
                        .split("\n")
                        .map((item, i) => (
                          <p key={i}>
                            {<span dangerouslySetInnerHTML={{ __html: item && item }} />}
                          </p>
                        ))}
                  </div>
                </div>
              </div>

              {hpiData && (
                <>
                  <p>
                    Price Index:{" "}
                    <Link to={`/hpis/${hpiData && hpiData.slug}`}>{hpiData && hpiData.name}</Link>
                  </p>
                  <PropertyRegionChart hpiData={hpiData && hpiData.hpi_values} />
                </>
              )}
              {countyData && countyData.length > 1 && <CountiesTable tableData={countyData} />}

              {cityData && cityData.length > 1 && <CityTable tableData={cityData} />}

              {zipcodeData && zipcodeData.length > 1 && (
                <ZipcodeTable
                  tableData={zipcodeData}
                  tableTitle={`Zip codes in ${cityData && cityData[0] && cityData[0].name}`}
                  totalPageCount={totalZipcodeCount}
                  currentPage={zipcodeCurrentPage}
                  setCurrentPage={setZipcodeCurrentPage}
                />
              )}

              {stateData && stateData.length > 1 && <StateTable tableData={stateData} />}

              <GetStarted
                name={`Buy & Sell Shares of Homes in ${
                  cityData && cityData[0] && cityData[0].name
                }, ${stateData && stateData[0] && stateData[0].abbrev}`}
                description="Exuity lets property owners sell shares of their properties to investors who earn income and appreciation."
              />
            </section>
          </>
        )}

        {isLoading === true && (
          <div className="text-center pt-5" style={{ marginTop: 120 }}>
            <div className="spinner-grow text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p>Loading. Please wait...</p>
          </div>
        )}
        <div className="footer-section">
          <Footer />
        </div>
      </div>
    </>
  );
}
