import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  axios_getStateArticleData,
  axios_getStateChildDataByStateCode,
  axios_getStateDataFromCode
} from "../../../utills/state-routes";
import Footer from "../../../Component/Footer/Footer";
import Head from "../../../Component/Head";
import Header from "../../../Component/Header/Header";
import CityTable from "../../../Component/RegionComponents/CityTable";
import CountiesTable from "../../../Component/RegionComponents/CountiesTable";
import StateTable from "../../../Component/RegionComponents/StateTable";
import ZipcodeTable from "../../../Component/RegionComponents/ZipcodeTable";
import GetStarted from "../../../Component/GetStarted/GetStarted";

export default function StatePage() {
  const { slug } = useParams();
  const [stateData, setStateData] = useState();
  const [cityData, setCityData] = useState([]);
  const [countyData, setCountyData] = useState();
  const [zipcodeData, setZipcodeData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [zipcodeCurrentPage, setZipcodeCurrentPage] = useState(0);
  const [totalZipcodeCount, setTotalZipcodeCount] = useState();
  const [countiesCurrentPage, setCountiesCurrentPage] = useState(0);
  const [totalCountiesCount, setTotalCountiesCount] = useState(0);
  const [citiesCurrentPage, setCitiesCurrentPage] = useState(0);
  const [totalCitiesCount, setTotalCitiesCount] = useState(0);
  const [stateArticleData, setStateArticleData] = useState();

  const [isnextpage, setIsnetpage] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios_getStateDataFromCode(slug, getStateCallback);
    axios_getStateArticleData(slug, getStateArticleCallback);
  }, [slug]);

  useEffect(() => {
    if (isnextpage === false) {
      setIsLoading(true);
    }

    axios_getStateChildDataByStateCode(
      "zipcodes",
      slug,
      zipcodeCurrentPage,
      getStateChildDataZipCodeCallback
    );
  }, [slug, zipcodeCurrentPage, isnextpage]);

  useEffect(() => {
    if (isnextpage === false) {
      setIsLoading(true);
    }
    axios_getStateChildDataByStateCode(
      "counties",
      slug,
      countiesCurrentPage,
      getStateChildDataCountiesCallback
    );
  }, [slug, countiesCurrentPage, isnextpage]);

  useEffect(() => {
    if (isnextpage === false) {
      setIsLoading(true);
    }
    axios_getStateChildDataByStateCode(
      "cities",
      slug,
      citiesCurrentPage,
      getStateChildDataCitiesCallback
    );
  }, [slug, citiesCurrentPage, isnextpage]);

  const getStateCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setStateData(response.data.data);
        if (window.location.hash) {
          if (document.querySelector('[id="' + window.location.hash.substring(1) + '"]') != null) {
            document
              .querySelector('[id="' + window.location.hash.substring(1) + '"]')
              .scrollIntoView();
          }
        }
        setIsnetpage(true);
      }
    }
    setIsLoading(false);
  };

  const getStateArticleCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setStateArticleData(response.data.data);
      }
    }
  };

  const getStateChildDataZipCodeCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setTotalZipcodeCount(response.data.data.count);
        setZipcodeData(response.data.data.rows);
        if (window.location.hash && window.location.hash === "#zipcodes") {
          document
            .querySelector('[id="' + window.location.hash.substring(1) + '"]')
            .scrollIntoView();
        }
      }
    }
    //setIsLoading(false);
  };

  const getStateChildDataCountiesCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setTotalCountiesCount(response.data.data.count);
        setCountyData(response.data.data.rows);
        if (window.location.hash && window.location.hash === "#counties") {
          document
            .querySelector('[id="' + window.location.hash.substring(1) + '"]')
            .scrollIntoView();
        }
      }
    }
    // setIsLoading(false);
  };

  const getStateChildDataCitiesCallback = (response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        setTotalCitiesCount(response.data.data.count);
        setCityData(response.data.data.rows);
        setIsLoading(false);
        if (window.location.hash && window.location.hash === "#cities") {
          document
            .querySelector('[id="' + window.location.hash.substring(1) + '"]')
            .scrollIntoView();
        }
      }
    }
  };

  return (
    <>
      <div className="App">
        <Header />

        <section className="region-sec" style={{ marginTop: 140 }}>
          <ul className="breadcrumb region-breadcrumb arr-right bg-ffffff">
            <li className="breadcrumb-item">
              <Link to="/">
                <img src={require("../../../Images/breadcrumb-home.svg")} alt="Exuity Home Icon" />
              </Link>
            </li>
            <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
            <li className="breadcrumb-item">
              <Link to="/regiontypes">Region type</Link>
            </li>
            <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>
            <li className="breadcrumb-item">
              <Link to="/states">States</Link>
            </li>
            <i className="fa fa-chevron-right text-secondary" aria-hidden="true"></i>{" "}
            <li className="breadcrumb-item" aria-current="page">
              <Link to={`/states/${slug}`} onClick={() => window.location.reload()}>
                {stateData && stateData.name}
              </Link>
            </li>
          </ul>

          {isLoading === true && (
            <div className="text-center pt-5">
              <div className="spinner-grow text-warning" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p>Loading. Please wait...</p>
            </div>
          )}

          {!isLoading && stateData && (
            <div>
              <Head title={`${stateData && stateData.name} - state on Exuity`} />
              <p className="region-sub-heading">State:</p>
              <h1 className="region-title">{stateData && stateData.name}</h1>
              <p className="region-paragraph">
                <span dangerouslySetInnerHTML={{ __html: stateData.regionParagraph }}>
                </span>
              </p>
              {/* <p className="region-paragraph">
                {stateData.name} (abbreviated as {stateData.abbrev}) is a state in the United
                States with a population of roughly {populationformat} and covers an area{" "}
                {Number(stateData.sq_miles).toLocaleString("en-US")} square miles. Its capital is{" "}
                <Link to={`/cities/${stateData.slug}/${statecapitalslug}`}>
                  {stateData.capital}
                </Link>
                . Its counties include{" "}
                {randomcounties.map((item, index) => {
                  if (index === 1) {
                    return (
                      <>
                        {" "}
                        and{" "}
                        <Link
                          key={item.slug}
                          className="region-link"
                          to={`/counties/${stateData.slug}/${item.slug}/`}>
                          {item.name}
                        </Link>
                      </>
                    );
                  }
                  return (
                    <Link
                      key={item.slug}
                      className="region-link"
                      to={`/counties/${stateData.slug}/${item.slug}`}>
                      {item.name}
                    </Link>
                  );
                })}
                . Its cities include{" "}
                {randomcities.map((item, index) => {
                  if (index === 1) {
                    return (
                      <>
                        {" "}
                        and{" "}
                        <Link
                          key={item.slug}
                          className="region-link"
                          to={`/cities/${stateData.slug}/${item.slug}`}>
                          {item.name}
                        </Link>
                      </>
                    );
                  }
                  return (
                    <Link
                      key={item.slug}
                      className="region-link"
                      to={`/cities/${stateData.slug}/${item.slug}`}>
                      {item.name}
                    </Link>
                  );
                })}
                .
              </p> */}

              <div className="article">
                <h2 className="articletitle">
                  {stateArticleData && stateArticleData.article_title}
                </h2>
                <div className="articlecontent">
                  {stateArticleData &&
                    stateArticleData.article &&
                    stateArticleData.article
                      .split("\n")
                      .map((item, i) => (
                        <p key={i}>{<span dangerouslySetInnerHTML={{ __html: item }} />}</p>
                      ))}
                </div>
              </div>
            </div>
          )}
          <div>
            {!isLoading && stateData && stateData.length > 1 && (
              <StateTable tableData={stateData} />
            )}
            {!isLoading && countyData && countyData.length > 1 && (
              <CountiesTable
                tableData={countyData}
                tableTitle={`Counties in ${stateData && stateData.name}`}
                totalPageCount={totalCountiesCount}
                currentPage={countiesCurrentPage}
                setCurrentPage={setCountiesCurrentPage}
              />
            )}

            {!isLoading && cityData && cityData.length > 1 && (
              <CityTable
                tableData={cityData}
                tableTitle={`Cities in ${stateData && stateData.name}`}
                totalPageCount={totalCitiesCount}
                currentPage={citiesCurrentPage}
                setCurrentPage={setCitiesCurrentPage}
              />
            )}

            {!isLoading && zipcodeData && zipcodeData.length > 1 && (
              <ZipcodeTable
                tableData={zipcodeData}
                tableTitle={`Zip codes in ${stateData && stateData.name}`}
                totalPageCount={totalZipcodeCount}
                currentPage={zipcodeCurrentPage}
                setCurrentPage={setZipcodeCurrentPage}
              />
            )}
          </div>
          {!isLoading && (
            <GetStarted
              name={`Buy & Sell Shares of Homes in ${stateData && stateData.name}, ${
                stateData && stateData.abbrev
              }`}
              description="Exuity lets property owners sell shares of their properties to investors who earn income and appreciation."
            />
          )}
        </section>
        <div className="footer-section">
          <Footer />
        </div>
      </div>
    </>
  );
}
