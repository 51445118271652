import React from "react";
import Sidenav from "../Sidenav";
import "../Dashboard.css";
import "./auctions.css";
import Sidebar from "../../Component/Sidebar/Sidebar";
import LaunchedAuction from "./LaunchedActions";
import Head from "../../Component/Head";

class AuctionsInvestor extends React.Component {

	componentDidMount() {
		document.title = "Exuity | Investor | Auctions"
	}

	render() {
		return (
			<div className="Dashboard">
				<Head title={'Exuity | Investor | Auctions'} />
				<div className="DashboardGroup">
					<div className="auction-navbar">
						<div className="dashTitle">
							<h4>Auctions You're Watching</h4>
						</div>
						<Sidenav />
					</div>
					<div className="row">
						<div className="auction-navbar1">
							<Sidebar />
						</div>
						<div className="DashContent pt-0 pt-lg-5">
							<div className="AuctionPage">
								<LaunchedAuction />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AuctionsInvestor;
